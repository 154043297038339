<template>
  <div>
    <!-- <div class="card">
		<div class="card-body">
			<div class="row">
				<div class="col-sm-10">
					<h5>{{ content.titulo }}</h5>
				</div>
				<div class="col-sm-2">
					<div class="media--50x50">
						<img :src="content.image" alt="" />
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-sm-12">
					{{ content.instituicao }}
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">{{ content.categoria.name }}</div>
				<div class="col-md-6 text-right">{{ content.validade }}</div>
			</div>

			<div class="row">
				<div class="col-md-6">{{ content.local }}</div>
				

				<div class="col-md-6 text-right">
					<figcaption class="blockquote-footer">Fonte: <cite :title="content.fonte"> {{content.fonte}} </cite></figcaption>
				</div>
			</div>
		</div>
	</div> -->
    <v-card
      class="mx-auto d-flex flex-column"
      outlined
      elevation="1"
      min-height="180"
    >
      <v-list-item three-line :to="detailsRoute">
        <div class="recomend-class">
          <v-chip
            x-small
            label
            dark
            :color="'red darken-1'"
            v-if="content.estado == false"
            >Aguarda Aprovação</v-chip
          >
          <v-chip x-small label dark :color="'green darken-1'" v-else
            >Aprovado</v-chip
          >
        </div>
        <v-list-item-content>
          <!-- <div class="text-h6 mb-1">
						{{ content.titulo }}
					</div> -->
          <!-- <p class="text-h6 mb-1">{{ content.titulo }}</p> -->
          <p class="text-subtitle-2 text-sm-h6 mb-1">{{ content.titulo }}</p>
          <v-list-item-subtitle>
            {{ content.instituicao }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <span v-if="content.candidaturas == false"
              >A vaga recebe candidaturas externas</span
            >
            <span v-else
              >Número de Candidatos: {{ content.candidates.length }}</span
            >
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <span><v-icon>mdi-eye</v-icon> {{ content.clicks }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar
          v-if="content.image"
          size="60"
          height="auto"
          rounded
          color="white"
          ><img :src="content.image" alt=""
        /></v-list-item-avatar>
      </v-list-item>
      <v-spacer></v-spacer>
      <v-card-actions
        :class="[expired ? 'left-border-expired' : 'left-border-active']"
      >
        <v-chip small light outlined>
          Local:
          <!-- <span v-if="content.local">
						{{ content.local }}
					</span>
					<span v-else>
						<span v-for="local in content.locals" :key="local.id" class="mr-1">
							{{ local.name }}
						</span>
					</span> -->
          <span class="mr-1">
            {{ content.local }}
          </span>
          <span v-if="content.locals">
            <span v-for="local in content.locals" :key="local.id" class="mr-1">
              {{ local.name }}
            </span>
          </span>
        </v-chip>
        <v-spacer></v-spacer>
        <v-chip
          small
          light
          outlined
          :color="expired ? 'red darken-1' : 'green darken-1'"
          >Validade: {{ content.validade | formatDate }}</v-chip
        >
        <!-- <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer> -->
        <!-- <v-chip small light outlined class="hidden-xs-only"
					>Fonte:
					<span
						class="font-weight-thin font-italic text-caption text-disabled"
						>{{ content.fonte }}</span
					></v-chip
				> -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      required: true,
      type: Object,
    },

    detailsRoute: {
      required: true,
      type: Object,
      name: "",
      params: {},
    },
  },

  // created() {},

  data() {
    return {};
  },

  computed: {
    expired() {
      let today = new Date();
      let deadlineDate = new Date(this.content.validade);

      return today > deadlineDate ? true : false;
    },
  },

  filters: {
    formatDate: (date) => {
      if (date) {
        let newDate = new Date(date);
        let newFormatedDate =
          newDate.getDate() +
          "-" +
          (newDate.getMonth() + 1) +
          "-" +
          newDate.getFullYear();
        return newFormatedDate;
      }

      return "";
    },
  },

  methods: {},
};
</script>

<style scoped>
.recomend-class {
  position: absolute;
  top: -10px;
  right: 1px; /* Position them outside of the screen */
  width: 100px; /* Set a specific width */
  text-decoration: none; /* Remove underline */
  font-size: 20px; /* Increase font size */
  color: white; /* White text color */
}
.green-gugla {
  color: #76ff03;
}

.red-gugla {
  color: #ff1744;
}

.left-border-expired {
  /* red accent-3 */
  border-left: 4px solid #ff1744 !important;
}

.bottom-border-active {
  /* red accent-3 */
  border-bottom: 4px solid #ff1744 !important;
}

.left-border-active {
  /* light-green accent-4 */
  border-left: 4px solid #64dd17 !important;
  /*  */
}

.bottom-border-active {
  /* light-green accent-4 */
  border-bottom: 4px solid #64dd17 !important;
}

.card {
  background-color: #fff;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  /* margin: 8px;
	min-width: 290px; */
  overflow: hidden;
  position: relative;
}

/* .media {
	position: relative;
}

[class*='media--'] {
	height: 0;
	overflow: hidden;
}

.media--50x50 img {
	position: absolute;
} */

.media--50x50 {
  /* margin: 16px;
	padding-bottom: 50px; */
  height: 50px;
  width: 50px;
}

.media--50x50 > img {
  height: 100%;
  /* left: 50%;
	-moz-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	-o-transform: translate(-50%, 0);
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0); */
  width: 50px;
}

.card-actions {
  position: absolute;
  bottom: 0;
}
</style>