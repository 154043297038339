var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"wrap":""}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{staticClass:"register-page",attrs:{"cols":"6"}},[_c('v-container',{staticStyle:{"heigth":"100vh"},attrs:{"fill-height":""}},[_c('v-row',{attrs:{"wrap":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{attrs:{"wrap":""}})],1)],1)],1)],1):_vm._e(),_c('v-col',{staticStyle:{"background-color":"#f0f2f5"},attrs:{"cols":_vm.isMobile ? '6' : '12'}},[_c('v-container',{staticStyle:{"height":"100vh"},attrs:{"fill-height":""}},[_c('v-row',{attrs:{"wrap":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"wrap":"","justify":"center","align":"center"}},[_c('v-col',[_c('validation-observer',{ref:"autoRegisterForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.autoRegister($event)}}},[_c('v-card',{attrs:{"elevation":"2","max-width":"470","disabled":_vm.disabled}},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"height":"4","indeterminate":"","color":"#FF5414"}}),_c('v-card-title',[_c('v-img',{staticStyle:{"display":"block","margin-left":"auto","margin-right":"auto"},attrs:{"src":require('../../assets/img/Logo-Empresas-830x354.png'),"max-width":"180","max-height":"180","alt":"Gugla Empresas"}})],1),_c('v-card-subtitle',{staticStyle:{"margin-bottom":"0px","margin-top":"0px"}},[_c('h2',{staticClass:"text-center",staticStyle:{"color":"#FF5414"}},[_vm._v("Crie a sua Conta")])]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Nome","error-messages":errors,"color":"#FF4500"},model:{value:(_vm.candidate.name),callback:function ($$v) {_vm.$set(_vm.candidate, "name", $$v)},expression:"candidate.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Sobrenome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Sobrenome","error-messages":errors,"color":"#FF4500"},model:{value:(_vm.candidate.last_name),callback:function ($$v) {_vm.$set(_vm.candidate, "last_name", $$v)},expression:"candidate.last_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"E-mail ou Telemovel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"E-mail ou Telemovel","error-messages":errors,"color":"#FF4500"},model:{value:(_vm.candidate.email_telefone),callback:function ($$v) {_vm.$set(_vm.candidate, "email_telefone", $$v)},expression:"candidate.email_telefone"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Senha","rules":"required|strong_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"rules":[_vm.rules.required, _vm.rules.min],"type":_vm.show1 ? 'text' : 'password',"name":"input-10-1","label":"Senha","hint":"Mínimo de 7 caracteres","counter":"","error-messages":errors,"color":"#FF4500"},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.candidate.password),callback:function ($$v) {_vm.$set(_vm.candidate, "password", $$v)},expression:"candidate.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Confirmar Senha","rules":"required|confirmed:Senha|strong_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"rules":[_vm.rules.required, _vm.rules.min],"type":_vm.show1 ? 'text' : 'password',"name":"confirm-new-password","label":"Confirmar Senha","hint":"Mínimo de 7 characters","counter":"","error-messages":errors,"color":"#FF4500"},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.candidate.password_confirmation),callback:function ($$v) {_vm.$set(_vm.candidate, "password_confirmation", $$v)},expression:"candidate.password_confirmation"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","small":"","elevation":"4","dark":"","color":"#FF4500"}},[_vm._v("Registar ")])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1),_c('loader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }