import { render, staticRenderFns } from "./subscricaoDiariaComponent.vue?vue&type=template&id=45057138&scoped=true&"
import script from "./subscricaoDiariaComponent.vue?vue&type=script&lang=js&"
export * from "./subscricaoDiariaComponent.vue?vue&type=script&lang=js&"
import style0 from "./subscricaoDiariaComponent.vue?vue&type=style&index=0&id=45057138&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45057138",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCheckbox,VCol,VContainer,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VRow,VSelect})
