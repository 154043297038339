<template>
  <div>
    <validation-observer ref="observer" v-slot="{}">
      <v-form @submit.prevent="onSubmit">
        <v-row justify="space-betwen">
          <v-col cols="8">
            <v-btn
              :to="{ name: 'recruiter.contents' }"
              class="ml-0"
              tile
              elevation="4"
              dark
              color="red acent-3"
              small
            >
              <v-icon left small> mdi-arrow-left </v-icon>Voltar</v-btn
            >
          </v-col>
          <v-col cols="12" md="4" class="pl-0 mb-6 md-4 text-start">
            <span>Está enfrentando alguma dificuldade?</span>
            <br />
            <v-btn
              :href="`mailto:suporte@marrar.co.mz?subject=Publicar um Concurso`"
              class="ml-0"
              tile
              elevation="4"
              dark
              color="#FF5414"
              small
            >
              <v-icon left small> mdi-message-settings </v-icon>Suporte</v-btn
            >
          </v-col>
        </v-row>
        <v-card style="margin-bottom: 60px;">
          <v-card-title>
            <!-- <h4 class="grey--text font-weight-regular">Concurso</h4> -->
            <v-chip small label dark :color="'green darken-1'"
              >Publicar Concurso ou Pedido de cotação</v-chip
            >
          </v-card-title>
          <v-card-text>
            <v-row wrap v-if="imageUploadPreview">
              <v-col cols="12" md="12" class="text-center">
                <v-avatar size="100">
                  <img :src="imageUploadPreview" alt="" />
                </v-avatar>
              </v-col>
            </v-row>
            <!-- <v-row wrap>
            <v-col cols="12" md="12">
              <v-file-input
                ref="image_upload"
                dense
                truncate-length="1"
                label="Carregar Imagem"
                accept="image"
                @change="onFileChange"
              ></v-file-input>
            </v-col>
          </v-row> -->
            <v-row wrap>
              <v-col cols="8" md="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Título"
                  rules="required"
                >
                  <v-text-field
                    ref="titulo"
                    dense
                    v-model="content.titulo"
                    label="Título"
                    placeholder="Título do anúncio"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row wrap>
              <!-- <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Local"
                rules="required"
              >
                <v-select
                  ref="local"
                  dense
                  :error-messages="errors"
                  :items="localsList"
                  v-model="content.local"
                  chips
                  label="Local"
                  class="mt-0"
                ></v-select>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6" style="margin-top:0px;">
              <validation-provider
                v-slot="{ errors }"
                name="Industria"
                rules="required"
              >
                <v-select
                  ref="Industria"
                  dense
                  :error-messages="errors"
                  v-model="content.industria"
                  :items="industrias"
                  item-text="name"
                  item-value="id"
                  chips
                  label="Industria"
                  class="mt-0"
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Empresa"
                rules="required"
              >
                <v-autocomplete
								v-model="selected_empresa"
								:items="empresas"
								item-text="nome"
								item-value="id"
								label="Instituição"
								:error-messages="errors"
								no-data-text="Nenhuma Instituição encontrada"
								class="mt-0"
								/>
              </validation-provider>
            </v-col> -->
              <v-col cols="12" md="6" style="margin-top:15px;">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      v-model="content.validade"
                      label="Validade"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    dense
                    v-model="content.validade"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" style="margin-top:15px;">
                <validation-provider
                  v-slot="{ errors }"
                  name="contacto"
                  rules="required"
                >
                  <v-text-field
                    ref="contacto"
                    dense
                    v-model="contacto"
                    label="Contacto"
                    placeholder="Coloque o seu contacto"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <!-- <v-col cols="12" md="6" style="margin-top:-10px;">
              <validation-provider
                v-slot="{ errors }"
                name="categoria"
                rules="required"
              >
                <v-select
                  ref="categoria"
                  v-model="content.categoria_id"
                  :items="categorias"
                  item-text="name"
                  item-value="id"
                  label="Categoria"
                  :error-messages="errors"
                  no-data-text="Nenhuma categoria encontrada"
                  chips
                  class="mt-0"
                >
                </v-select>
              </validation-provider>
            </v-col> -->
            </v-row>
            <v-row wrap> </v-row>
            <v-row
              wrap
              v-if="!anexoConteudoUploadPreview"
              style="margin-bottom: 50px"
            >
              <v-col md="12">
                <v-card elevation="0">
                  <vue-editor
                    ref="conteudo"
                    placeholder="Por favor descreva o producto/serviço que procura ou descreva o seu concurso/pedido de cotação"
                    style="height: 300px; margin-top: 20px"
                    :editor-toolbar="customToolbar"
                    v-model="content.conteudo_formatado"
                  >
                  </vue-editor>
                </v-card>
                <!--<vue-editor 
							ref="conteudo" 
							placeholder="Descrição do anuncio..." 
							style="height: 250px;"
							:editor-toolbar="customToolbar"
							v-model="concurso.conteudo"></vue-editor>-->
              </v-col>
            </v-row>

            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <!-- <v-row wrap v-if="!anexoConteudoUploadPreview">
            <v-col md="12">
              <v-textarea
                ref="conteudo"
                auto-grow
                dense
                clearable
                clear-icon="mdi-close-circle"
                label="Descriçao"
                v-model="content.conteudo"
                value="This is clearable text."
              ></v-textarea>
            </v-col>
          </v-row> -->

            <!-- <v-row wrap v-if="anexoConteudoUploadPreview">
            <v-col cols="12" md="12" class="text-center">
              <v-img>
                <img
                  placeholder="Anexoq"
                  :src="anexoConteudoUploadPreview"
                  class="anexo"
                  alt=""
                />
              </v-img>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" md="12">
              <v-file-input
                ref="anexo_conteudo"
                dense
                show-size
                small-chips
                truncate-length="20"
                label="Anexo12"
                accept="image/*"
                @change="onAnexoConteudoFileChange"
              ></v-file-input>
            </v-col>
          </v-row> -->
          </v-card-text>
          <v-card-actions>
            <v-btn type="submit" small elevation="4" color="red accent-3" dark>
              <v-icon small class="mr-2"> mdi-check </v-icon>
              {{ actionSubmit }}
            </v-btn>
            <v-btn
              :to="{ name: 'recruiter.contents' }"
              exact
              outlined
              small
              elevation="4"
              color="red accent-3"
              dark
              class="mx-4"
            >
              <v-icon small class="mr-2"> mdi-cancel </v-icon>
              Cancelar
            </v-btn>
            <v-btn
              outlined
              small
              elevation="4"
              color="red accent-3"
              dark
              @click.prevent="clean"
              class="ml-0"
            >
              <v-icon small class="mr-2"> mdi-eraser </v-icon>
              Limpar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <!-- <v-divider class="my-5"></v-divider>
    <v-row justify="start">
      <v-col cols="12" class="pl-0 mb-6 md-4 text-start">
        <span>Está enfrentando alguma dificuldade?</span>
        <br />
        <v-btn
          :href="`mailto:suporte@marrar.co.mz?subject=Publicar um Concurso`"
          class="ml-0"
          tile
          elevation="4"
          dark
          color="#FF5414"
          small
        >
          <v-icon left small> mdi-message-settings </v-icon>Suporte</v-btn
        >
      </v-col>
    </v-row> -->
    </validation-observer>
  </div>
</template>
<script>
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from "vee-validate";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} não pode estar vazio",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueEditor,
  },

  props: {
    content: {
      required: false,
      type: Object || Array,
      default: () => {
        return {
          id: "",
          //   image: "",
          titulo: "",
          empresa_id: "empty",
          validade: "",
          categoria_id: 2,
          recruiter_contact: "",
          fonte: "-",
          local: "-",
          industria: "-",
          //   fonte: "",
          //   conteudo: "",
          conteudo_formatado: "",
        };
      },
    },

    updating: {
      required: true,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected_locals: [],
      selected_empresa: null,
      contacto: "",
      empresa: {
        nome: "",
        image: "",
        id: null,
      },
      tab: null,
      image_upload: null,
      anexo_conteudo_upload: null,

      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      name: "",
      categorias: [
        {
          id: 2,
          name: "Concurso",
        },
        {
          id: 5,
          name: "Pedido de Cotação",
        },
      ],
      localsList: [
        "Cabo Delgado",
        "Gaza",
        "Inhambane",
        "Manica",
        "Maputo",
        "Nampula",
        "Niassa",
        "Sofala",
        "Tete",
        "Zambézia",
      ],
      industrias: [
        "Administração e Secretariado Cliente",
        "Aquisições e Procurement",
        "Assistente",
        "Auditoria e Consultoria",
        "Comunicação Social",
        "Construção Civil",
        "Contabilidade e Finanças",
        "Design",
        "Direito e Justiça",
        "Electricidade",
        "Gestão de Projectos",
        "Gestão e Assessoria",
        "Gestão Financeira",
        "Gestor Provincial",
        "Higiene e Segurança",
        "Hotelaria e Turismo",
        "informática e Programação",
        "Manutenção e Técnica",
        "Marketing e Publicidade",
        "Mecânica",
        "Meio Ambiente",
        "Monitoria e Avaliação",
        "Oficial Técnico",
        "Operário",
        "Recursos",
        "Humanos",
        "Relações Públicas",
        "Saúde",
        "Seguros",
        "Serviços Gerais",
        "Serviços Sociais",
        "Supervisão e Coordenação",
        "Topografia e Geologia",
        "Transportes e Logística",
        "Vendas/Comércio",
        "Outro",
      ],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "bullet" }, { list: "ordered" }, { list: "check" }],
        [{ color: [] }],
        ["link"],
      ],
    };
  },

  created() {
    // this.getCategories(2);
    // this.getCreateContent();
    this.getEmpresas();
    this.getLocals();
    // console.log(this.$store.createContent.locals);
  },

  methods: {
    getEmpresas() {
      this.$store.dispatch("getMinhasEmpresas");
    },
    onSubmit() {
      this.$refs.observer.validate();
      // console.log(this.$store);

      let action = this.updating
        ? "content_recruiter/updateContent"
        : "content_recruiter/storeContents";
      const formData = new FormData();

      formData.append("id", this.content.id);
      formData.append("titulo", this.content.titulo);
      formData.append("empresa_id", this.empresa.id);
      formData.append("conteudo_formatado", this.content.conteudo_formatado);
      formData.append("validade", this.content.validade);
      formData.append("categoria_id", this.content.categoria_id);
      formData.append("local", this.content.local);
      formData.append("fonte", this.content.fonte);
      formData.append("recruiter_contacto", this.contacto);
      formData.append("industria", this.content.industria);
      formData.append("image", this.empresa.image);
      //   formData.append("fonte", this.content.fonte);

      //   if (this.image_upload != null) {
      //     formData.append("image", this.image_upload);
      //   } else {
      //     formData.append("image", this.content.image);
      //   }

      if (this.anexo_conteudo_upload != null) {
        formData.append("anexo_conteudo", this.anexo_conteudo_upload);
      } else {
        formData.append("anexo_conteudo", this.content.anexo_conteudo);
      }

      this.$store.dispatch("storeConcurso", formData).then(() => {
        Swal.fire({
          title: "Sucesso!",
          text: this.updating
            ? "Actualizado com sucesso."
            : "Submetido com sucesso.  O administrador entrará em contato em breve para fornecer mais informações.",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#FF1744",
        }).then(() => {
          // this.$router.push({ name: "site.contents" });
          this.$router.push({ name: "recruiter.contents" });
        });
      });
    },

    onFileChange(e) {
      this.image_upload = e;
    },

    onAnexoConteudoFileChange(e) {
      this.anexo_conteudo_upload = e;
    },

    // getCategories(category_code = 2) {
    // 	this.$store.dispatch("category_on_recruiter/getCategories", {
    // 		category_code,
    // 	});
    // },

    getCreateContent() {
      this.$store.dispatch("getCreateContent");
    },
    getLocals() {
      this.$store.dispatch("getLocals");
    },

    clean() {
      this.content.titulo = "";
      this.content.instituicao = "";
      this.content.validade = "";
      this.content.categoria_id = "";
      this.content.local = "";
      this.content.fonte = "";
      this.content.conteudo = "";
      this.$refs.observer.reset();
    },
  },

  computed: {
    // empresas() {
    //   return this.$store.state.createContent.empresas;
    // },
    empresas() {
      return this.$store.state.minhasempresas;
    },
    locals() {
      return this.$store.state.locals;
    },
    categories() {
      return this.$store.state.category_on_recruiter.items;
    },

    // locals() {
    //   return this.$store.state.createContent.locals;
    //   // return []
    // },

    defaultSelectedCategory() {
      if (this.updating) {
        return this.content.categoria_id;
      } else {
        let contest_category_filtered = this.categories.filter(
          (cat) => cat.code == 2
        );
        return contest_category_filtered[0].id;
      }
    },

    today() {
      return new Date();
    },

    // eslint-disable-next-line vue/return-in-computed-property
    imageUploadPreview() {
      if (this.image_upload) {
        return URL.createObjectURL(this.image_upload);
      } else if (this.content.image) {
        return this.content.image;
      }
      return;
    },

    // eslint-disable-next-line vue/return-in-computed-property
    anexoConteudoUploadPreview() {
      if (this.anexo_conteudo_upload) {
        return URL.createObjectURL(this.anexo_conteudo_upload);
      } else if (this.content.anexo_conteudo) {
        return this.content.anexo_conteudo;
      }
      return;
    },

    actionSubmit() {
      return this.updating ? "Actualizar" : "Submeter";
    },

    // date() {
    // 	if(this.updating) {
    // 		let content_date = new Date(this.content.validade).toISOString().substr(0, 10)
    // 		return content_date
    // 	} else {
    // 		return new Date().toISOString().substr(0, 10)
    // 	}
    // }
  },
  watch: {
    concurso(concurso) {
      console.log(concurso.locals);
      this.selected_locals = concurso.locals.map((local) => local.id);
    },
    selected_empresa(selected) {
      this.empresa = this.empresas.find((em) => em.id === selected);
      console.log(this.empresa);
    },
  },
};
</script>
<style scoped>
.anexo {
  width: 100%;
}
</style>
