<template>
  <div>
    <v-dialog v-model="dialog" max-width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" id="openPop1" style="display: none">
          Open Dialog
        </v-btn>
      </template>
      <v-card class="mx-auto d-flex flex-column" style="padding-bottom: 0px">
        <div class="meuCard" style="
            margin: 0;
            padding-bottom: 0px;
            overflow-x: hidden;
            overflow-y: hidden;
          ">
          <v-row wrap>
            <v-col cols="12" md="6">
              <div class="textoCabelho">
                <h1 style="font-weight: bold; color: #111">
                  Ainda não é Subscrito
                </h1>
                <br />
                <p class="text-justify-left text-body-2 text-md-body-1">
                  Subscreva-se e tenha acesso de todos os concursos de empresas
                  anunciados na plataforma.
                </p>
                <!-- <h3 style="font-weight: bold; color: #ff4500">
                  Beneficios de ter uma Subscrição
                </h3> -->
              </div>

              <br />

              <h3 style="font-weight: bold; color: #ff4500; margin: 10px">
                Beneficios de ter uma Subscrição
              </h3>

              <v-list>
                <v-list-item v-for="(validation_note, index_validation_note) in this
                  .validation_notes" :key="index_validation_note">
                  <v-list-item-icon>
                    <v-icon color="#111">
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <p class="text-justify-left text-body-2 text-md-body-1">
                      {{ validation_note.description }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <div class="text-center">
                <v-btn color="#D5172A" dark rounded @click="goToSubscricao">Efectuar a Subscrição</v-btn>
              </div>

              <p class="text-center font-weight-bold">
                <!-- Junte-se a mais de {{ total }} utilizadores subscritos12 -->
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <img class="hidden-sm-and-down" src="../../assets/subscricao.png" width="100%" height="100%" alt="" />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <v-card class="mx-auto d-flex flex-column" outlined elevation="1" min-height="180px" v-if="content.categoria">
      <v-list-item three-line @click="goToContent(content)">
        <div class="recomend-class" v-if="content.is_recomended">
          <v-chip x-small label dark :color="'green darken-1'">recomendado</v-chip>
        </div>

        <v-list-item-content>
          <p class="text-subtitle-2 text-sm-h6 mb1">
            {{ content.titulo }}
          </p>
          <p v-if="content.industria && content.industria != 'null'" :class="[
              active_subscription == 0 && content.not_free == 1
                ? 'ocultarCard'
                : 'text-subtitle-2 mb1',
            ]">
            {{ content.industria }}
          </p>
          <v-list-item-subtitle :class="[
            active_subscription == 0 && content.not_free == 1
              ? 'ocultarCard'
              : '',
          ]">
            {{ content.instituicao }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <div v-if="active_subscription == 0 && content.not_free == 1">
          <v-list-item-avatar size="60" height="auto" rounded color="">
            <img :src="require('../../assets/key-content.png')" alt="" />
          </v-list-item-avatar>
        </div>
        <div v-else>
          <v-list-item-avatar v-if="content.image" size="60" height="auto" rounded color="">
            <img :src="content.image" alt="" />
          </v-list-item-avatar>
          <v-list-item-avatar v-else size="60" height="auto" rounded color="">
            <img :src="require('../../assets/img/content-default.png')" alt="" />
          </v-list-item-avatar>
        </div>
      </v-list-item>
      <v-spacer></v-spacer>
      <div :class="[
          active_subscription == 0 && content.not_free == 1
            ? 'ocultarCard'
            : '',
        ]">
        <v-card-actions :class="[expired ? 'left-border-expired' : 'left-border-active']">
          <v-chip small light outlined>
            Local:
            <span v-if="content.local">
              {{ content.local }}
            </span>
            <span v-else>
              <span v-for="local in content.locals" :key="local.id" class="mr-1">
                {{ local.name }}
              </span>
            </span>
          </v-chip>
          <v-spacer></v-spacer>
          <v-chip small light outlined :color="expired ? 'red darken-1' : 'green darken-1'">
            Validade: {{ content.validade | formatDate }}
          </v-chip>
        </v-card-actions>
      </div>
    </v-card>
    <v-card class="mx-auto d-flex flex-column" outlined elevation="1" min-height="140px" v-else @click="goToPublicity(content)"
      target="_blank">
      <v-img :src="content.image_mobile" :alt="content.titulo" min-height="140px" class="white--text align-end"></v-img>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active_subscription: "",
      dialog: false,
      validation_notes: [
        {
          code: 1,
          description: `Tenha acesso a todos os concursos de empresas públicas e privadas;`,
        },
        {
          code: 2,
          description: `Aceda todos os concursos publicados nos jornais e muito mais;`,
        },
        {
          code: 3,
          description: `Seja notificado quando novos concursos são publicados na plataforma.`,
        },
      ],
    };
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  computed: {
    expired() {
      let today = new Date();
      let deadLineDate = new Date(this.content.validade);
      today.setHours(0, 0, 0, 0);
      deadLineDate.setHours(0, 0, 0, 0);
      //console.log(today, " -- " , deadLineDate )

      return today > deadLineDate ? true : false;
    },
  },
  created() {
    this.active_subscription = sessionStorage.getItem("active_subscription");
  },
  filters: {
    formatDate(date) {
      if (date) {
        let newDate = new Date(date);
        let newFormatedDate =
          newDate.getDate() +
          "-" +
          (newDate.getMonth() + 1) +
          "-" +
          newDate.getFullYear();
        return newFormatedDate;
      }

      return "";
    },
  },
  methods: {
    goToContent(content) {
      // :to="{ name: 'detailsRoute', params: { id: content.id } }"
      //   console.log(content.not_free);
      if (content.not_free == true && this.active_subscription == 0) {
        let recruiter = localStorage.getItem("RECRUITER_ID");
        let params = {
          content_id: content.id,
          recruiter_id: recruiter == null ? "" : recruiter,
        };
        this.$store.dispatch("recordContent", params).then((res) => { });
        this.dialog = true;
      } else {
        this.$router.push({ name: "detailsRoute", params: { id: content.id } });
      }
    },
    goToSubscricao() {
      this.$router.push({ name: "site.subscricao" });
    },
    goToPublicity(content){
      let data = {
        id: content.id
      }
      if (localStorage.getItem('TOKEN_AUTH') && typeof localStorage.getItem('TOKEN_AUTH') === 'string'){
        // token = localStorage.getItem('TOKEN_AUTH')
        this.$store.dispatch("contarPublicidadeAuth", data).then((res) => { });
      } else {
        this.$store.dispatch("contarPublicidade", data).then((res) => { });
      }
      window.open(content.url, '_blank');
      // window.location = content.url
    }
  },
};
</script>

<style>
.recomend-class {
  position: absolute;
  top: -10px;
  right: -14px;
  /* Position them outside of the screen */
  width: 100px;
  /* Set a specific width */
  text-decoration: none;
  /* Remove underline */
  font-size: 20px;
  /* Increase font size */
  color: white;
  /* White text color */
}

.green-gugla {
  color: #76ff03;
}

.red-gugla {
  color: #ff1744;
}

.left-border-expired {
  /* red accent-3 */
  border-left: 4px solid #ff1744 !important;
}

.bottom-border-active {
  /* red accent-3 */
  border-bottom: 4px solid #ff1744 !important;
}

.left-border-active {
  /* light-green accent-4 */
  border-left: 4px solid #64dd17 !important;
  /*  */
}

.bottom-border-active {
  border-bottom: 4px solid #64dd17 !important;
}

.card {
  background-color: #fff;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  /* margin: 8px;
	min-width: 290px; */
  overflow: hidden;
  position: relative;
}

.media--50x50 {
  height: 50px;
  width: 50px;
}

.media--50x50>img {
  height: 100%;
  width: 50px;
}

.card-actions {
  position: absolute;
  bottom: 0;
}

.ocultarCard {
  filter: blur(4px);
}

.textoCabelho {
  padding: 20px;
  margin-top: 40px;
  /* margin: 30px; */
}
</style>
