<template>
  <v-container class="mt-8">
    <v-container :class="isMobile ? 'pl-12' : 'pl-0'">
      <v-btn elevation="2" class="no-uppercase" small @click="goToperfiDash()">
        <v-icon style="padding-right: 14px" small> mdi-arrow-left </v-icon>
        Voltar
      </v-btn>
      <h3>{{ empresa.nome }} <v-icon @click="goToEmpresa(empresa.id)" style="cursor: pointer;   color: #ff4500;" :class="isMobile ? 'pl-10' : 'pl-0'">
          mdi-eye-outline
        </v-icon></h3>
    </v-container>
    <!-- minhas Empresas  -->
    <div :class="isMobile ? 'mt-7' : 'mt-1'" style="padding-bottom: 15%;">
      <v-row wrap justify="center">
        <v-col cols="12" md="3"><BarChart :objectData="empresa.info_resumo" :appName="Resumo" v-if="mount"/></v-col>
        <!-- <v-col cols="12" md="3"><BarChart :objectData="empresa.info_resumo" :appName="Resumo" v-if="mount && empresa.info_resumo.website > 0 && empresa.info_resumo.contacto > 0"/> <p>Sem dados</p></v-col> -->
        <v-col cols="12" md="9">
          <h4>Resumo de pedidos de acesso</h4>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Total dos que pediram website
                  </th>
                  <th class="text-left">
                    Total dos que pediram contacto
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ empresa.info_resumo.website }}</td>
                  <td>{{ empresa.info_resumo.contacto }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" md="12">
        <h4>Mensagens</h4>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Nome
                  </th>
                  <th class="text-left">
                    E-mail
                  </th>
                  <th class="text-left">
                    Telefone
                  </th>
                  <th class="text-left">
                    Serviço
                  </th>
                  <th class="text-left">
                    Comentário
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in empresa.leadsempresas" :key="item.name">
                  <td>{{ item.nome }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.telefone }}</td>
                  <td>{{ item.servico }}</td>
                  <td>{{ item.comentario }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import BarChart from './graficos/BarChart.vue'
export default {
  name: "MinhaEmpresa",
  components:{
    BarChart
  },
  data() {
    return {
      empresa: {
        info_resumo: {
          website: 0,
          contacto: 0,
        }
      },
      recorddetalhesempresa: [],
      website: 0,
      contacto: 0,
      data_charts: {},
      mount: false
    }
  },
  props: {
    id: {
      required: true,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
    empresas() {
      return this.$store.state.minhasempresas;
    },
  },
  created() {
    this.getEmpresaWithAuth(this.id)
  },
  methods: {
    goToperfiDash() {
      this.$router.push({ name: "perfil.minhasEmpresas" });
    },
    getMinhasEmpresas() {
      this.$store.dispatch("getMinhasEmpresas");
    },
    goToEmpresa(item) {
      this.$router.push({ name: "empresa.show", params: { id: item } });
      // this.$router.push({ name: "perfil.minhas.empresas", params: { id: item } });
    },
    getEmpresaWithAuth(id) {
      this.$store.dispatch("loaderChange", true);
      this.$store
        .dispatch("getEmpresaWithAuth", { id })
        .then((res) => {
          this.empresa = res.data.data;
          this.recorddetalhesempresa = this.empresa.recorddetalhesempresa
          this.$store.dispatch("loaderChange", false);
          scroll(0, 0);
          this.mount = true
        }).catch((err) => {
          this.$store.dispatch("loaderChange", false);
          return err;
        });
    },
  },
};
</script>
  
<style scoped>
.nhe :hover {
  color: #ff4500;
}

.no-uppercase {
  text-transform: unset !important;
  background-color: #f66459 !important;
  color: white;
}

h3 {
  color: #ff4500;
  margin-left: 7px;
  margin-top: 40px;
}
</style>