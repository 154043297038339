<template>
  <v-container class="mt-8">
    <div>
      <v-row wrap align="center" justify="center">
        <v-col cols="12" md="12">
          <div
            style="
              background-color: #fdece5;
              border: 1px solid #ff4500;
              margin-bottom: 30px;
            "
          >
            <p style="padding: 10px" class="text-center">
              <span style="font-weight: 900; width: 300px"
                >Notificações de Concursos</span
              >
              ajudam você a ser notificado quando novos concursos estão
              disponiveis.
            </p>
          </div>
          <v-row wrap justify="center">
            <v-col cols="12" sm="6" md="6">
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn icon large>
                      <v-icon color="#1D79F3">mdi-email</v-icon>
                    </v-btn>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-wrap">
                      <!-- <p class="text-wrap">Receber notificações por e-mail</p> -->
                      Receber notificações por e-mail
                    </v-list-item-title>
                    <!-- <v-list-item-subtitle
                      >Receber notificações por e-mail</v-list-item-subtitle
                    > -->
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-row justify="center">
                      <v-btn
                        color="#ff4500"
                        dark
                        v-if="!newsletter.receive_newsletter"
                        @click="onSubmitReceiveNewsletter"
                      >
                        Activar
                      </v-btn>
                      <v-btn
                        color="#ff4500"
                        dark
                        v-else
                        @click="onSubmitReceiveNewsletter"
                      >
                        Desactivar
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn icon large>
                      <v-icon color="#47C156">mdi-whatsapp</v-icon>
                    </v-btn>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-wrap"
                      >Ou aceda o grupo pelo link</v-list-item-title
                    >
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-row justify="center">
                      <v-btn color="#ff4500" dark @click="goToWhatsapp">
                        Entrar
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
  <script>
export default {
  components: {},
  data() {
    return {
      files: [
        {
          color: "blue",
          icon: "mdi-email",
          title: "Receber Notificações",
        },
        {
          color: "amber",
          icon: "mdi-whatsapp",
          title: "Kitchen remodel",
        },
      ],
      tem_empresa: "",
      content_fav: false,
      drawer: false,
      initialsNameUser: "",
      dialog_newsletters_invite: false,
      dialog_newsletters: false,
      newsletter: {
        receive_newsletter: false,
      },
    };
  },
  computed: {
    user() {
      let user = this.$store.state.candidate;
      this.newsletter.receive_newsletter = user.receive_newsletter;
      return user;
    },
  },

  created() {
    let token = localStorage.getItem("TOKEN_AUTH");
    this.tem_empresa = localStorage.getItem("TEM_EMPRESA");
    if (!token) {
      this.content_fav = true;
    }
  },

  watch: {
    user() {
      const full_name = this.user.name.split(" ");
      const initials = full_name.shift().charAt(0) + full_name.pop().charAt(0);
      this.initialsNameUser = initials.toUpperCase();
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then((response) => {
        this.$router.push({ name: "recruiter.login" });
      });
    },

    onSubmitReceiveNewsletter() {
      this.newsletter.receive_newsletter = !this.newsletter.receive_newsletter;
      this.$store
        .dispatch("auth_recruiter/receiveNewsletter", this.newsletter)
        .then((response) => {
          this.dialog_newsletters = false;
        });
    },
    goToWhatsapp() {
      // location.replace("https://chat.whatsapp.com/C4wtywpKT0F4FfyOAqjPcw");
      window.open(
        "https://chat.whatsapp.com/C4wtywpKT0F4FfyOAqjPcw",
        "_blank" // <- This is what makes it open in a new window.
      );
    },
  },
};
</script>
  
  <style scoped>
</style>