<template>
  <v-container>
    <v-card flat width="auto">
      <v-card-title>Meus Concursos Favoritos</v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Titulo</th>
                <th class="text-left">Instituicao</th>
                <th class="text-left">Validade</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="content in favoritecontents" :key="content.id">
                <td>{{ content.titulo }}</td>
                <td>{{ content.instituicao }}</td>
                <td>{{ content.validade }}</td>
                <td>
                  <v-btn
                    type="submit"
                    elevation="4"
                    color="secondary"
                    dark
                    small
                    class="mr-2"
                    @click="verConcurso(content)"
                  >
                    <v-icon small class="mr-2"> mdi-eye </v-icon>
                    Ver concurso
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider style=""></v-divider>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {},
  computed: {
    getCandidate() {
      let candidate = this.$store.state.candidate;
      return candidate;
    },
    favoritecontents() {
      return this.$store.state.items;
    },
  },
  created() {
    this.$store.dispatch("getFavoriteContents");
  },
  methods: {
    verConcurso(content){
        this.$router.push({name: 'detailsRoute', params: {id: content.id}})
    }
  }
};
</script>

<style>
</style>