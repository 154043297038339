<template>
  <v-container class="mt-8">
    <v-container :class="isMobile ? 'mb-10' : 'mb-16'">
      <v-row wrap>
        <v-col cols="12" style="">
          <Header1 />
        </v-col>

        <v-col cols="12" class="conteudo" :class="isMobile ? 'ml-16' : 'ma-0'">
          <Main1
            v-for="(pergunta, i) in perguntas"
            :pergunta="pergunta"
            :posicao="i"
            :key="i"
            :open="pergunta.open"
            @abrir="abrir"
          ></Main1>
        </v-col>
      </v-row>
    </v-container>
  </v-container>

  <!-- <div class="tudo">
      <Header1></Header1>
      <div class="conteudo">
          <Main1 
            v-for="(pergunta,i) in perguntas"
            
            :pergunta="pergunta"
            :posicao="i"
            :key="i"
            :open="pergunta.open"
            @abrir = "abrir"
          ></Main1>
        </div>
    </div> -->
</template>
  
  <script>
import Header1 from "./FAQS/Header1.vue";
import Main1 from "./FAQS/Main1.vue";

export default {
  name: "MainFaqs",
  components: {
    Header1,
    Main1,
  },
  data() {
    return {
      perguntas: [
        {
          code: 1,
          question:
            "Que tipo de empresas pode participar em concursos publicados no gugla empresas?",
          answer:
            "De principio qualquer empresa pode participar nos concursos publicados no gugla empresas, pois dependendo dos requisitos de cada concurso, poderá ser lhe requisitado ou não certos documentos para que possa participar no concurso.",
          open: false,
        },
        {
          code: 2,
          question:
            "Porque usar o gugla empresas em vez do jornal para visualizar concursos?",
          answer:
            "Através Do gugla empresas você poderá visualizar concursos num único lugar, sem interrupção de noticias, comunicados, publicidade de qualquer lugar a qualquer mormente que desejar, seja do celular ou computador. Podendo também ser notificado por WhatsApp ou email sobre as novas atualizações de concursos",
          open: false,
        },
        {
          code: 3,
          question: "Oque acontece após efetuar a subscrição?",
          answer: "",
          open: false,
        },
        {
          code: 4,
          question: "Quantos concursos são publicados no gugla empresas?",
          answer:
            "São em media publicados 15 a 25 concursos públicos e privados diariamente.",
          open: false,
        },
        {
          code: 5,
          question: "A quanto tempo está operacional o Gugla Empresas?",
          answer: " O Gugla Empresas está operacional desde dezembro de 2019",
          open: false,
        },
      ],
      passos: [
        {
          info: "deny",
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
  methods: {
    abrir: function (posicao) {
      this.perguntas = this.perguntas.map((pergunta, i) => {
        if (posicao === i) {
          pergunta.open = !pergunta.open;
        }
        return pergunta;
      });
    },
  },
};
</script>
  
  <style scoped>
.conteudo {
  align-content: center;
  box-sizing: border-box;
}
/*  .tudo{
    margin-top: 2rem;
    margin-bottom: 5rem;
    text-align: justify;

 :class="{'ma-0': $vuetify.breakpoint.smAndDown, 'ml-16': $vuetify.breakpoint.mdAndUp}" 
  } */
</style>