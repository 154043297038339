<template>
  <v-container class="mt-8">
    <div>
      <v-row wrap justify="center">
        <v-col cols="12" md="12">
          <div
            style="
              background-color: #fdece5;
              border: 1px solid #ff4500;
              margin-bottom: 40px;
            "
            class="pa-4 mb-auto"
          >
            <v-row wrap>
              <v-col cols="12" md="9">
                <h4>
                  Por favor faça o login para ter acesso as funcionalidades da
                  plataforma
                </h4>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  elevation="2"
                  color="#FF4500"
                  dark
                  :to="{ name: 'candidate.login' }"
                  >Login | Registar</v-btn
                >
              </v-col>
            </v-row>
          </div>

          <div
            style="margin-bottom: 10px; min-height: 200px"
            class="pa-14 mb-auto"
          >
            <v-row wrap>
              <v-col cols="12" md="6">
                <h1>Gugla Empresas um mar de Oportunidades</h1>
                <p>
                  O gugla empresa torna possível que as empresas divulguem
                  concursos e também visualizem concursos publicados por outras
                  empresas, encontre aqui diversos concursos públicos para
                  fornecimento de bens e prestação de serviços disponíveis a
                  nível nacional.
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <img
                  class="hidden-sm-and-down"
                  height="auto"
                  width="300px"
                  src="../assets/ui/11000.png"
                />
              </v-col>
            </v-row>
          </div>

          <div
            style="background-color: #f5f5f5; margin-bottom: 10px"
            class="pa-2 mb-auto"
          >
            <v-row wrap justify="center">
              <h2 class="pa-4">Outras Vantagens de Fazer Login</h2>
            </v-row>
            <v-row wrap>
              <v-col cols="12" md="6">
                <v-row wrap>
                  <v-col cols="4" md="3">
                    <img
                      height="auto"
                      width="120px"
                      src="../assets/ui/concursos1.png"
                    />
                  </v-col>
                  <v-col cols="8" md="9">
                    <h3>Concursos Publicados</h3>
                    <p>
                      Visualize concursos públicos que são diariamente
                      partilhados a nível nacional no gugla empresas e faça a
                      sua proposta.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row wrap>
                  <v-col cols="4" md="3">
                    <img
                      height="auto"
                      width="120px"
                      src="../assets/ui/favoritos.png"
                    />
                  </v-col>
                  <v-col cols="8" md="9">
                    <h3>Concursos Favoritos</h3>
                    <p>
                      Selecione como favoritos os concursos do seu interesse que
                      gostaria de ver mais tarde.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row wrap>
                  <v-col cols="4" md="3">
                    <img
                      height="auto"
                      width="120px"
                      src="../assets/ui/publiicar.png"
                    />
                  </v-col>
                  <v-col cols="8" md="9">
                    <h3>Publicar Concursos</h3>
                    <p>
                      Precisa de fornecedores de bens ou prestação de serviços,
                      publique os seus concursos para que sejam vistos pelas
                      diversas e variadas empresas registadas no gugla empresas.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row wrap>
                  <v-col cols="4" md="3">
                    <img
                      height="auto"
                      width="120px"
                      :src="require('../assets/ui/notify.png')"
                    />
                  </v-col>
                  <v-col cols="8" md="9">
                    <h3>Active Notificações</h3>
                    <p>
                      Seja notificado ao ser adicionado um novo concurso no
                      gugla empresas, para que não perca nenhuma oportunidade de
                      fechar negócios.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row wrap>
                  <v-col cols="4" md="3">
                    <img
                      height="auto"
                      width="120px"
                      src="../assets/ui/todasempresas.png"
                    />
                  </v-col>
                  <v-col cols="8" md="9">
                    <h3>Todas Empresas</h3>
                    <p>
                      Visite perfis de empresas registadas no gugla empresas e
                      confira os serviços por eles prestados para maior
                      comodidade na hora de buscar por bens ou serviços para si.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row wrap>
                  <v-col cols="4" md="3">
                    <img
                      height="auto"
                      width="120px"
                      src="../assets/ui/minhasempresas.png"
                    />
                  </v-col>
                  <v-col cols="8" md="9">
                    <h3>Minhas Empresas</h3>
                    <p>
                      Veja empresas por si criadas adicione remova os serviços
                      que a sua empresa presta e actualize as informações das
                      mesmas.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row wrap>
                  <v-col cols="4" md="3">
                    <img
                      height="auto"
                      width="120px"
                      src="../assets/ui/adicionarempresa.png"
                    />
                  </v-col>
                  <v-col cols="8" md="9">
                    <h3>Criar Empresa</h3>
                    <p>
                      Adicione a sua empresa com a descrição completa,
                      localização, contactos e categorias de serviços que a sua
                      empresa presta no gugla empresas onde poderá ser vista por
                      empresários como também singulares.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row wrap>
                  <v-col cols="4" md="3">
                    <img
                      height="auto"
                      width="120px"
                      src="../assets/ui/publicarvagas.png"
                    />
                  </v-col>
                  <v-col cols="8" md="9">
                    <h3>Publique Vagas</h3>
                    <p>
                      Alcance um grande número de candidatos qualificados que
                      visitam o Gugla Empresas diariamente, publicando as suas
                      vagas de emprego.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row wrap>
                  <v-col cols="4" md="3">
                    <img
                      height="auto"
                      width="120px"
                      src="../assets/ui/basededados.png"
                    />
                  </v-col>
                  <v-col cols="8" md="9">
                    <h3>Base de Dados Candidatos</h3>
                    <p>
                      Tenha acesso aos CV's de candidatos registados no Gugla
                      Empregos e selecione candidatos de acordo com as suas
                      preferencias e requisitos na hora.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row wrap>
                  <v-col cols="4" md="3">
                    <img
                      height="auto"
                      width="120px"
                      src="../assets/ui/candidatosfavoritos.png"
                    />
                  </v-col>
                  <v-col cols="8" md="9">
                    <h3>Meus Candidatos Favoritos</h3>
                    <p>
                      Marque Candidatos com perfil que lhe é interessante como
                      favoritos para que possa acede-lós de forma isolada a
                      qualquer momento que desejar.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
  <script>
export default {
  data() {
    return {};
  },
  computed: {
    isMobile() {
      console.log(this.$vuetify.breakpoint.name);
      return this.$vuetify.breakpoint.mdAndUp ? true : false;
    },
  },
};
</script>
  
  <style>
.styleImg {
  height: auto;
  width: 300px;
}
.imgSmall {
  height: auto;
  width: 250px;
  position: relative;
  left: -10px;
}
.imgSmallMobile {
  height: auto;
  width: 200px;
  position: relative;
  left: -30px;
  top: 25px;
}
.alignTest1 {
  padding: 10px;
  margin: 50px 0 10px 0;
}
</style>