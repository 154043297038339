<template>
  <v-container class="mt-8">
    <v-container :class="isMobile ? 'pl-12' : 'pl-0'">
      <v-btn elevation="2" class="no-uppercase" small @click="goToperfiDash()">
        <v-icon style="padding-right: 14px" small> mdi-arrow-left </v-icon>
        Voltar
      </v-btn>

      <h3>Notificações</h3>
    </v-container>
    <div
      :class="isMobile ? 'pl-14' : 'pl-2'"
      style="text-align: justify; margin: "
    >
      <span>
        Olá <b>{{ getCandidate.name }}</b> active as notificações por meio do
        <strong>email </strong> e por <strong>Whatsapp </strong> e seja notificado sempre que tiver novos concursos . Clique no botão abaixo para
        ativar.
      </span> 
    </div>

    <v-container style="margin-top: 10px; align-items: center" class="mx-auto">
      <v-row style="" :class="isMobile ? 'ml-16' : 'ml-2'">
        <v-col cols="12" md="8">
          <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-btn icon small>
                <v-icon color="#1D79F3">mdi-email</v-icon>
              </v-btn>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-wrap">
                Receber notificações pelo E-mail
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-row justify="center">
                <v-switch
                  @click="onSubmitReceiveNewsletter" 
                  color="#ff4500"
                  v-model="recruiter.receive_newsletter"
                ></v-switch>
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-btn icon small>
                <v-icon color="green">mdi-whatsapp</v-icon>
              </v-btn>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-wrap">
                Receber notificações pelo whatsapp
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-row justify="center">
                <v-switch
                  @click="whatsappListener"
                  color="#ff4500"
                  v-model="whatsapp.notify_whatsapp"
                ></v-switch>
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="whatsapp.notify_whatsapp">
            <v-list-item-avatar>
              <v-btn icon small>
                <v-icon color="green">mdi-whatsapp</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-text-field 
                label="Contacto whatsapp"
                dense
                placeholder="8X1234567"
                v-model="whatsapp.whatsapp_contact"
                ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="whatsapp.notify_whatsapp">
            <v-list-item-avatar></v-list-item-avatar>
            <v-list-item-content></v-list-item-content>
            <v-list-item-action>
              <v-row>
                <v-btn elevation="2" class="no-uppercase" small @click="submiteWhatsappNewsletter">
                  Guardar
                </v-btn>
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-avatar>
              <v-btn icon large>
                <v-icon color="#47C156">mdi-whatsapp</v-icon>
              </v-btn>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-wrap">
                Notificações por Whatsapp
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-row justify="center">
                <v-switch
                  color="#ff4500"
                ></v-switch>
              </v-row>
            </v-list-item-action>
          </v-list-item> -->
        </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2';
export default {
  name: "Notificacoes",
  data(){
    return{
      recruiter: {receive_newsletter: ''},
      whatsapp: {
        notify_whatsapp: false,
        whatsapp_contact: ""
      }
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
    getCandidate() {
      let candidate = this.$store.state.candidate;
      this.recruiter.receive_newsletter = candidate.receive_newsletter
      this.whatsapp.notify_whatsapp = candidate.notify_whatsapp
      this.whatsapp.whatsapp_contact = candidate.whatsapp_contact
      return candidate;
    },
  },
  created(){
    this.getMe()
  },
  methods: {
    goToperfiDash() {
      this.$router.push({ name: "perfil.dash" });
    },
    onSubmitReceiveNewsletter(){
      let data = {
        receive_newsletter: this.recruiter.receive_newsletter,
      };
      this.$store
        .dispatch("receiveNewsletter", data)
        .then((response) => {
          this.$store.dispatch('me')
        });
    },
    submiteWhatsappNewsletter() {
      this.$store.dispatch('receiveWhatasppNewsletter', this.whatsapp)
      .then((response) => {
        Swal.fire({
            title: "Sucesso!",
            text: "Newsletter por whatsapp ativado.",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#FF1744",
          }).then(() => {
            this.emailSubject = "";
            this.emailBody = "";
            this.dialogEmail = false;
          });
          this.$store.dispatch('me')
        });
    },
    getMe(){
      this.$store.dispatch("loaderChange", true);
      this.$store.dispatch("me").then((data) => {
        this.$store.dispatch("loaderChange", false);
      });
    },
    whatsappListener() {
      if (this.whatsapp.notify_whatsapp === false) {
        this.whatsapp.whatsapp_contact = null
        this.$store.dispatch('receiveWhatasppNewsletter', this.whatsapp)
        .then((response) => {
            this.$store.dispatch('me')
          });
      }
    }
  },
  
};
</script>
<style scoped>
.no-uppercase {
  text-transform: unset !important;
  background-color: #f66459 !important ;
  color: white;
}
h3 {
  color: #ff4500;
  margin-left: 7px;
  margin-top: 20px;
}
</style>