/* eslint-disable vue/no-unused-components */
<template>
  <v-row wrap>
    <div :class="!isMobile ? 'home-mobile' : 'home'">
      <v-btn v-if="!isMobile" tile color="#FF4500" text dark @click="home">
        <v-icon v-if="!isMobile" size="27px" left> mdi-home </v-icon>
        voltar
      </v-btn>
      <v-btn v-else tile color="#FF4500" text dark @click="home">
        <v-icon large left> mdi-home </v-icon>
        Voltar ao gugla
      </v-btn>
    </div>

    <v-col :cols="isMobile ? '12' : '12'" style="background-color: #f0f2f5">
      <v-container fill-height style="height: 100vh">
        <v-row wrap justify="center" align="center">
          <v-col cols="12" :md="isMobile ? '6' : '6'" xl="4" sm="6">
            <v-container fill-height fluid>
              <v-row wrap justify="center" align="center">
                <v-col>
                  <validation-observer ref="autoRegisterForm" v-slot="{}">
                    <v-form @submit.prevent="autoRegister">
                      <v-card elevation="2" max-width="470" :disabled="disabled">
                        <v-progress-linear v-show="loading" height="4" indeterminate color="#FF5414"></v-progress-linear>
                        <v-card-title>
                          <v-img :src="
                            require('../../assets/img/Logo-Empresas-830x354.png')
                          " max-width="180" max-height="180" alt="Gugla Empresas"
                            style="display: block; margin-left: auto; margin-right: auto" />
                        </v-card-title>
                        <v-card-subtitle style="margin-bottom: 0px; margin-top: 0px">
                          <h2 class="text-center" style="color: #FF5414;">Crie a sua Conta</h2>
                        </v-card-subtitle>
                        <v-card-text>
                          <validation-provider v-slot="{ errors }" name="Nome" rules="required">
                            <v-text-field outlined v-model="candidate.name" label="Nome" :error-messages="errors"
                              color="#FF4500" />
                          </validation-provider>
                          <validation-provider v-slot="{ errors }" name="Sobrenome" rules="required">
                            <v-text-field outlined v-model="candidate.last_name" label="Sobrenome"
                              :error-messages="errors" color="#FF4500" />
                          </validation-provider>
                          <validation-provider v-slot="{ errors }" name="E-mail ou Telemovel" rules="required">
                            <v-text-field outlined v-model="candidate.email_telefone" label="E-mail ou Telemovel"
                              :error-messages="errors" color="#FF4500" />
                          </validation-provider>
                          <validation-provider v-slot="{ errors }" name="Senha" rules="required|strong_password">
                            <v-text-field outlined v-model="candidate.password"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]"
                              :type="show1 ? 'text' : 'password'" name="input-10-1" label="Senha"
                              hint="Mínimo de 7 caracteres" counter :error-messages="errors"
                              @click:append="show1 = !show1" color="#FF4500"></v-text-field>
                          </validation-provider>
                          <validation-provider v-slot="{ errors }" name="Confirmar Senha"
                            rules="required|confirmed:Senha|strong_password">
                            <v-text-field outlined v-model="candidate.password_confirmation"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]"
                              :type="show1 ? 'text' : 'password'" name="confirm-new-password" label="Confirmar Senha"
                              hint="Mínimo de 7 characters" counter :error-messages="errors"
                              @click:append="show1 = !show1" color="#FF4500"></v-text-field>
                          </validation-provider>

                          <span>Já possui uma conta?
                            <span class="login" @click="login" style="color: #FF5414;font-weight: bold">
                              Entrar</span>.</span>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn type="submit" small elevation="4" dark color="#FF4500">Registar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </validation-observer>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <loader />
  </v-row>
</template>

<script>
import Loader from "../loader/Loader.vue";
import Swal from "sweetalert2";
import Footer from '../footer/footer.vue'
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from "vee-validate";
import {
  required,
  digits,
  email,
  confirmed,
  max,
  regex,
} from "vee-validate/dist/rules";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} não pode estar vazio!",
});

extend("digits", {
  ...digits,
  message: "{_field_} deve conter {length} dígitos. ({_value_})!",
});

extend("email", {
  ...email,
  message: "O email deve ser válido!",
});
extend("confirmed", {
  ...confirmed,
  message: "O valor para o campo {_field_} não coincide!",
});

extend("strong_password", {
  message: (field) =>
    "A " +
    field +
    " deve conter pelo menos 1 caracter minúsculo, 1 caracter maiúsculo, 1 dígito e um cumprimento não inferior a 7!",
  validate: (value) => {
    // let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/); // Com caracteres especiais
    let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/);
    return pattern.test(value);
  },
});
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Loader,
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    Footer
  },
  data() {
    return {
      disabled: false,
      loading: false,
      candidate: {
        name: "",
        last_name: "",
        email: "",
        password: "",
        email_telefone: ""
      },
      password_confirmation: "",
      confirm_password: "",
      show1: false,
      rules: {
        required: (value) => !!value || "Obrigatório.",
        min: (v) => v.length >= 7 || "Mínimo de 7 caracteres",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  created() {
    var linkTag = document.createElement("link");
    linkTag.setAttribute("rel", "canonical");
    linkTag.setAttribute(
      "href",
      `https://empresas.gugla.co.mz/candidate/register`
    );
    document.head.appendChild(linkTag);
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
  methods: {
    login() {
      this.$router.push({ name: "candidate.login" });
    },
    isNumber(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
    autoRegister() {
      this.disabled = true
      this.loading = true
      let recruiter = {
        name: this.candidate.name + this.candidate.last_name,
        password: this.candidate.password,
        password_confirmation: this.candidate.password_confirmation,
      }

      if(this.isNumber(this.candidate.email_telefone) == true){
        recruiter['contacto'] = '+258' + this.candidate.email_telefone
      }else{
        recruiter['email'] = this.candidate.email_telefone
      }
      // console.log(this.isNumber(this.candidate.email_telefone))
      // this.$store.dispatch("loaderChange", true);
      this.$store
        .dispatch("register", recruiter)
        .then((response) => {
          if(this.isNumber(this.candidate.email_telefone) == true){
            this.$router.push({ name: 'recruiter.register.otp', params: { telefone: this.candidate.email_telefone } })
          }else{
            this.$router.push({name: 'recruiter.verify.email.otp', params: {emailverify: this.candidate.email_telefone}})
          }
          console.log(response)
          // Swal.fire({
          // 	title: "Sucesso!",
          // 	html: `A sua conta foi registada!<br> Aceda ao seu email para activar a sua conta e extrair os dados de acesso.`,
          // 	icon: "success",
          // 	confirmButtonText: "Ok",
          // 	confirmButtonColor: "#FF1744",
          // }).then(() => {
          // 	this.$router.push({ name: "candidate.login" });
          // });
          this.$store.dispatch("loaderChange", false);
        })
        .catch((error) => {
          // this.$store.dispatch("loaderChange", false);
          // alert(error.message)
          this.loading = false
          this.disabled = false
          console.log(error);
        });
    },
    home() {
      this.$router.push({ name: "site.contents" });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

.login {
  color: cornflowerblue;
}

.login:hover {
  cursor: pointer;
}

.red-gugla {
  color: #ff1744;
}

.basil {
  background-color: #fffbe6 !important;
}

.basil--text {
  color: #356859 !important;
}

.shadow-left {
  box-shadow: 20px 20px 20px 20px #888888;
  /* for all browsers */
  -moz-box-shadow: 20px 20px 20px 20px #888888;
  /* for old firefox */
  -webkit-box-shadow: 20px 20px 20px 20px #888888;
  /* override for Chrome & Safari */
}

.text1 {
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  padding: 20px;
}

.home {
  position: absolute;
  top: 30px;
  left: 30px;
}

.home-mobile {
  position: absolute;
  top: 15px;
  left: 15px;
}

.mobile {
  /* padding-top: 60px; */
  /* background-color: red; */
  /* style="background-color: #F0F2F5; padding-top: 20%;" */
  padding-top: 50%;
  margin-top: 70%;
  background-color: #F0F2F5;
}
</style>