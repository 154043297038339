import Vue from 'vue';
// import Meta from 'vue-meta'
import Router from 'vue-router';
import store from '../store'
import Main from '../components/Main.vue'
import MainEmpresa from '../components/MainEmpresa.vue'
import MainRecruiter from '../components/MainRecruiter.vue'
import homeContent from '../components/homeContent.vue';

import homeEmpresa from '../components/homeEmpresa.vue';
import homeMinhasEmpresas from '../components/homeMinhasEmpresas.vue';
import createEmpresa from '../components/createEmpresa.vue';
import contentDetailsSite from "../components/content/contentDetailsSite.vue"
import empresaDetailsSite from "../components/empresa/empresaDetailsSite.vue"
import FormEmpresa from "../components/empresa/FormEmpresa.vue"
import editEmpresa from "../components/empresa/editEmpresaComponent.vue"
import ActivateUserCandidate from '../components/candidate/ActivateUserCandidate.vue'
import StepsAfterRegisterCandidate from "../components/candidate/StepsAfterRegisterCandidate.vue"
import LoginCandidateComponent from '../components/candidate/LoginCandidateComponent.vue'
import PasswordRecruiterComponent from '../components/candidate/PasswordRecruiterComponent.vue'
import RegisterCandidateComponent from '../components/candidate/RegisterCandidateComponent.vue'
import RegisterRecruiterInvite from '../components/candidate/RegisterRecruiterInvite.vue'
import OtpRecruiterComponent from '../components/candidate/OtpRecruiterComponent.vue'
import OtpRecruiterPasswordComponent from '../components/candidate/OtpRecruiterPasswordComponent.vue'
import OtpRecruiterWithEmail from '../components/candidate/OtpRecruiterWithEmail.vue'
import OtpRecruiterVerify from '../components/candidate/OtpRecruiterVerify.vue'
import RegisterRecruiterComponent from '../components/candidate/RegisterRecruiterComponent.vue'
import otpteste from '../components/candidate/otpteste.vue'
import ForgotPasswordCandidate from '../components/candidate/ForgotPasswordCandidate.vue'
import ActivateUserRecruiter from '../components/candidate/ActivateUserRecruiter.vue'
import VerifyEmailRecruiter from '../components/candidate/VerifyEmailRecruiter.vue'
import RecoveryPasswordRecruiter from '../components/candidate/RecoveryPasswordRecruiter.vue'
import NewPassword from '../components/candidate/NewPassword.vue'
import ChangePassword from '../components/candidate/ChangePasswordCandidate.vue'
import candidateComponent from '../components/candidate/CandidateComponent.vue'
import DashboardComponent from '../components/candidate/Profile/Dashboard.vue'
import CancelNewsletter from '../components/candidate/cancelNewsletterComponent.vue'
import CancelAccount from '../components/candidate/cancelAccountComponent.vue'
import CareersComponent from '../components/careers/CareersComponent.vue'
import careerDetails from '../components/careers/careerDetails.vue'
import error404 from '../components/error/error404.vue'
import recoveryPassword from '../components/candidate/recoveryPassword.vue'
// recruiter
import CandidateComponent from '../components/recruiter/candidate/CandidateComponent.vue'
import CandidateDetailsComponent from '../components/recruiter/candidate/CandidateDetailsComponent.vue'
import CandidateFavoriteComponent from '../components/recruiter/candidate/CandidateFavoriteComponent.vue'
import CandidateDetailsFavoriteComponent from '../components/recruiter/candidate/CandidateDetailsFavoriteComponent.vue'
// vagas
import VagaRecruiter from '../components/recruiter/vagas/VagaRecruiter.vue'
import VagaCreateRecruiter from '../components/recruiter/vagas/VagaCreateRecruiter.vue'
// import VagaEditRecruiter from '../components/recruiter/vagas/VagaEditRecruiter.vue'
import VagaDetailsRecruiter from '../components/recruiter/vagas/VagaDetailsRecruiter.vue'
// contents
import ContentRecruiter from '../components/recruiter/contents/ContentRecruiter.vue'
import ContentCreateRecruiter from '../components/recruiter/contents/ContentCreateRecruiter.vue'
// recruiter notificacaoes
import Notificacoes from '../components/recruiter/Notificacoes.vue'
//
import oportunidades from '../components/oportunidades.vue';
import subscricao from '../components/candidate/Profile/Subscricao.vue';
import subscricaoDiaria from '../components/candidate/Profile/subscricaoDiariaComponent.vue';
import availablePlansComponents from '../components/candidate/Profile/SubscricaoPlanosComponent.vue';
import PassosSubscricao from '../components/candidate/Profile/PassosSubscricao.vue';
import PromocoesComponent from '../components/candidate/Profile/PromocoesComponent.vue';
// minhasCandidaturas
import MinhasCandidaturasComponent from '../components/recruiter/minhasCandidaturas/CandidateComponent.vue'
import MinhasCandidaturasDetails from '../components/recruiter/minhasCandidaturas/MinhasCandidaturasDetails'

/* Perguntas Frequentes */
import MainFaqs from '../components/MainFaqs.vue'
/* Perfil Prototipo */
import FormPerfil from '../components/Perfil/FormPerfil.vue'
import MeusConcursosFavoritosPerfil from '../components/Perfil/MeusConcursosFavoritosPerfil.vue'
import MinhasEmpresasPerfil from '../components/Perfil/MinhasEmpresasPerfil.vue'
import MinhaEmpresaPerfil from '../components/Perfil/MinhaEmpresaPerfil.vue'
import NotificacoesPerfil from '../components/Perfil/NotificacoesPerfil.vue'
import EstadoSubscricaoPerfil from '../components/Perfil/EstadoSubscricaoPerfil.vue'
import PerfilDash from '../components/Perfil/ProfileDash2.vue'
import MainPerfilDash from '../components/MainPerfilDash.vue'


/* Recuperar Senha */
import DadosRecuperacao from '../components/recuperacaoSenha/DadosRecuperacao.vue'
import EscolhaRecuperacao from '../components/recuperacaoSenha/EscolhaRecuperacao.vue'
import CodigoRecuperacao from '../components/recuperacaoSenha/CodigoRecuperacao.vue'
import NovaSenhaRecuperacao from '../components/recuperacaoSenha/NovaSenhaRecuperacao.vue'

/* Convidar Amigos */

import ConvidarAmigos from '../components/Convite/ConvidarAmigos.vue'



Vue.use(Router);
// Vue.use(Meta);

// eslint-disable-next-line no-sparse-arrays
const routes = [
  {
    path: '/',
    component: Main,
    children: [

      { path: '/', component: homeContent, name: 'site.contents' },
      {
        path: "/contents/:id/show",
        component: contentDetailsSite,
        name: 'detailsRoute',
        props: true
      },
      {path: 'promocoes/:valor', component: PromocoesComponent, name: 'site.promo', props: true},
      { path: 'oportunidades', component: oportunidades, name: 'site.oportunidades' },
      { path: 'planos-disponiveis', component: availablePlansComponents, name: 'site.available.plans' },
      { path: 'subscricao/:tipo_subscricao?/:empresa_id?', component: subscricao, name: 'site.subscricao' , props: true},
      { path: 'subscricao-diaria', component: subscricaoDiaria, name: 'site.subscricao.diaria' , props: true},
      { path: 'subscricao/passos/:valor/:mes?/:dias?/:promo?', component: PassosSubscricao, name: 'site.subscricao.passos', props: true }, // passos de subscricao
      { path: '/steps-after-register/:success/:email', component: StepsAfterRegisterCandidate, name: 'site.steps_after_register', props: true },
      {
        path: '/careers',
        component: CareersComponent,
        name: 'site.careers_page'
      },
      {
        path: '/faqs', component: MainFaqs, name: 'perguntas'
      },

      {
        path: '/convidar-amigos', component: ConvidarAmigos, name: 'convidar.amigos',
      },


      /*  {
         path: '/estado-subscricao', component: EstadoSubscricao, name: 'recruiter.perfil'
       }, */

    ]
  },
  {
    path: '/empresas',
    component: MainEmpresa,
    children: [
      { path: '/empresas', component: homeEmpresa, name: 'empresa' },
      { path: 'criar-empresa', component: createEmpresa, name: 'empresa.create' },
      { path: 'registar', component: FormEmpresa, name: 'empresa.register' },
      { path: '/empresas/:id/atualizar', component: editEmpresa, name: 'empresa.edit', props: true },
      { path: '/empresa/:id/show', component: empresaDetailsSite, name: 'empresa.show', props: true },
      { path: 'minhas-empresas', component: homeMinhasEmpresas, name: 'empresa.minhas' },
    ]
  },
  {
    path: '/careers/:slug',
    component: careerDetails,
    name: 'site.careers_page.details',
    props: true
  },
  /* {
    path: '/perfil',
    component: candidateComponent,
    // meta: {auth: true},
    children: [
      { path: '/', component: DashboardComponent, name: 'candidate.dashboard' },
    ]
  }, */


  {
    path: '/perfil',
    component: MainPerfilDash,
    children: [
      { path: '', component: PerfilDash, name: 'perfil.dash' },
      { path: '/informacoes-pessoais', component: FormPerfil, name: 'perfil.infoPessoal' },
      { path: '/notificacoes', component: NotificacoesPerfil, name: 'perfil.notificacoes' },
      { path: '/minhas-empresas', component: MinhasEmpresasPerfil, name: 'perfil.minhasEmpresas' },
      { path: '/minhas-empresas/:id/resumo', component: MinhaEmpresaPerfil, name: 'perfil.minhas.empresas' , props: true},
      { path: '/estado-subscricao', component: EstadoSubscricaoPerfil, name: 'perfil.estadoSubscricao' },
      { path: '/concursos-favoritos', component: MeusConcursosFavoritosPerfil, name: 'perfil.concursosFavoritos' },

    ]

  },
  {
    path: '/recruiter',
    component: MainRecruiter,
    children: [
      { path: 'notificacoes', component: Notificacoes, name: 'site.notificacoes' },
      // CONTENTS
      { path: 'contents', component: ContentRecruiter, name: 'recruiter.contents' },
      { path: 'contents/create', component: ContentCreateRecruiter, name: 'recruiter.contents.create' },
      // VAGAS
      { path: 'vagas', component: VagaRecruiter, name: 'recruiter.vagas' },
      { path: 'vagas/create', component: VagaCreateRecruiter, name: 'recruiter.vagas.create' },
      // { path: 'vagas/:id/edit', component: VagaEditRecruiter, name: 'recruiter.vagas.edit', props: true },
      { path: 'vagas/:id/show', component: VagaDetailsRecruiter, name: 'recruiter.vagas.show', props: true },
      // Candidates Database
      { path: 'candidates', component: CandidateComponent, name: 'recruiter.candidatos' },
      { path: 'candidate/:id/profile', component: CandidateDetailsComponent, name: 'recruiter.candidate.show', props: true },
      { path: 'candidate-favorites/:id/profile', component: CandidateDetailsFavoriteComponent, name: 'recruiter.candidate_favorites.show', props: true },
      { path: 'candidates-favorites', component: CandidateFavoriteComponent, name: 'recruiter.favorites' },
      { path: 'minhas-candidaturas/:id', component: MinhasCandidaturasComponent, name: 'recruiter.minhasCandidaturas', props: true },
      { path: 'minhas-candidaturas/:id/profile', component: MinhasCandidaturasDetails, name: 'recruiter.minhasCandidaturas.show', props: true }
    ],
  },
  ,
  {
    path: '/recruiter/forgotpassword',
    component: ForgotPasswordCandidate,
    name: 'candidate.forgotpassword'
  },
  /* Recuperacao de senha */
  {
    path: '/recuperar-senha',
    component: DadosRecuperacao,
    name: 'recuperacao.dados'
  },
  {
    path: '/recuperar-escolha',
    component: EscolhaRecuperacao,
    name: 'recuperacao.escolha'
  },

  {
    path: '/recuperar-codigo',
    component: CodigoRecuperacao,
    name: 'recuperacao.codigo'
  },
  {
    path: '/nova-senha',
    component: NovaSenhaRecuperacao,
    name: 'nova.senha'
  },

  { path: '/recruiter/activate-user', component: ActivateUserRecruiter, name: 'recruiter.activate_user', props: (route) => ({ id: route.query.id, email: route.query.email }) },
  { path: '/recruiter/verifyEmail', component: VerifyEmailRecruiter, name: 'recruiter.activate_user', props: (route) => ({ id: route.query.id, email: route.query.email }) },
  {
    path: '/recruiter/login',
    component: LoginCandidateComponent,
    name: 'candidate.login'
  },
  {
    path: '/recruiter/:userData/password/',
    component: PasswordRecruiterComponent,
    name: 'recruiter.password',
    props: true
  },
  {
    path: '/recruiter/register',
    component: RegisterCandidateComponent,
    name: 'candidate.register'
  },
  {
    path: '/recruiter/:telefone/register-otp',
    component: OtpRecruiterComponent,
    name: 'recruiter.register.otp',
    props: true
  },
  {
    path: '/recruiter/:telefonerecovery/otp-recoverypassword',
    component: OtpRecruiterPasswordComponent,
    name: 'recruiter.recoverypassword.otp',
    props: true
  },
  {
    path: '/recruiter/:email/register-email-otp',
    component: OtpRecruiterWithEmail,
    name: 'recruiter.register.email.otp',
    props: true
  },
  {
    path: '/recruiter/:emailverify/verify-email-otp',
    component: OtpRecruiterVerify,
    name: 'recruiter.verify.email.otp',
    props: true
  },
  {
    path: '/recruiter/register-name',
    component: RegisterRecruiterComponent,
    name: 'recruiter.register.name',
    props: true
  },
  {
    path: '/otp',
    component: otpteste,
  },
  {
    path: '/recruiter/activate-user',
    component: ActivateUserCandidate,
    name: 'candidate.activate_user',
    props: (route) => ({ id: route.query.id, email: route.query.email })
  },
  // { path: '/recruiter/recovery-password/:token', component: RecoveryPasswordRecruiter, name: 'recruiter.login', props: true },

  {
    path: '/cancel_newsletter',
    component: CancelNewsletter,
    name: 'candidate.cancel_newsletter',
    props: (route) => ({ id: route.query.id, email: route.query.email })
  },
  {
    path: '/cancel_account',
    component: CancelAccount,
    name: 'candidate.cancel_account',
    props: (route) => ({ id: route.query.id, email: route.query.email })
  },

  {
    path: '/recruiter/changepassword',
    component: ChangePassword,
    name: 'candidate.chagepassword',
    // meta: { auth: true }
  },
  {
    path: '/recruiter/new-password',
    component: NewPassword,
    name: 'candidate.define.chagepassword',
    // meta: { auth: true }
  },

  {
    path: '/recruiter/register-invite/:id',
    component: RegisterRecruiterInvite,
    name: 'candidate.recruiter.invite',
    props: true
  },
  {
    path: '/recruiter/recovery-password/:token',
    component: recoveryPassword,
    name: 'candidate.recovery_password',
    props: true
  },

  {
    path: "*",
    name: 'site.error404',
    component: error404,
  },
  /* {
    path: '/faqs',
    
    component: MainFaqs,
  } */
];
const router = new Router({
  mode: 'history',
  fallback: true,
  routes,
  scrollBehavior() { return { x: 0, y: 0 } }


});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.auth)) {
//     if (store.getters.isLoggedIn) {
//       next()
//       return
//     }
//     next('/recruiter/login')
//   } else {
//     next()
//   }
// })
export default router;