var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('v-row',{attrs:{"justify":"space-betwen"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-btn',{staticClass:"ml-0",attrs:{"to":{ name: 'recruiter.contents' },"tile":"","elevation":"4","dark":"","color":"red acent-3","small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v("Voltar")],1)],1),_c('v-col',{staticClass:"pl-0 mb-6 md-4 text-start",attrs:{"cols":"12","md":"4"}},[_c('span',[_vm._v("Está enfrentando alguma dificuldade?")]),_c('br'),_c('v-btn',{staticClass:"ml-0",attrs:{"href":"mailto:suporte@marrar.co.mz?subject=Publicar um Concurso","tile":"","elevation":"4","dark":"","color":"#FF5414","small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-message-settings ")]),_vm._v("Suporte")],1)],1)],1),_c('v-card',{staticStyle:{"margin-bottom":"60px"}},[_c('v-card-title',[_c('v-chip',{attrs:{"small":"","label":"","dark":"","color":'green darken-1'}},[_vm._v("Publicar Concurso ou Pedido de cotação")])],1),_c('v-card-text',[(_vm.imageUploadPreview)?_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"12"}},[_c('v-avatar',{attrs:{"size":"100"}},[_c('img',{attrs:{"src":_vm.imageUploadPreview,"alt":""}})])],1)],1):_vm._e(),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"8","md":"12"}},[_c('validation-provider',{attrs:{"name":"Título","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"titulo",attrs:{"dense":"","label":"Título","placeholder":"Título do anúncio","error-messages":errors,"required":""},model:{value:(_vm.content.titulo),callback:function ($$v) {_vm.$set(_vm.content, "titulo", $$v)},expression:"content.titulo"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{staticStyle:{"margin-top":"15px"},attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":"Validade","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.content.validade),callback:function ($$v) {_vm.$set(_vm.content, "validade", $$v)},expression:"content.validade"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"dense":"","no-title":"","scrollable":""},model:{value:(_vm.content.validade),callback:function ($$v) {_vm.$set(_vm.content, "validade", $$v)},expression:"content.validade"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticStyle:{"margin-top":"15px"},attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"contacto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"contacto",attrs:{"dense":"","label":"Contacto","placeholder":"Coloque o seu contacto","error-messages":errors,"required":""},model:{value:(_vm.contacto),callback:function ($$v) {_vm.contacto=$$v},expression:"contacto"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"wrap":""}}),(!_vm.anexoConteudoUploadPreview)?_c('v-row',{staticStyle:{"margin-bottom":"50px"},attrs:{"wrap":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('vue-editor',{ref:"conteudo",staticStyle:{"height":"300px","margin-top":"20px"},attrs:{"placeholder":"Por favor descreva o producto/serviço que procura ou descreva o seu concurso/pedido de cotação","editor-toolbar":_vm.customToolbar},model:{value:(_vm.content.conteudo_formatado),callback:function ($$v) {_vm.$set(_vm.content, "conteudo_formatado", $$v)},expression:"content.conteudo_formatado"}})],1)],1)],1):_vm._e(),_c('v-spacer'),_c('v-spacer')],1),_c('v-card-actions',[_c('v-btn',{attrs:{"type":"submit","small":"","elevation":"4","color":"red accent-3","dark":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-check ")]),_vm._v(" "+_vm._s(_vm.actionSubmit)+" ")],1),_c('v-btn',{staticClass:"mx-4",attrs:{"to":{ name: 'recruiter.contents' },"exact":"","outlined":"","small":"","elevation":"4","color":"red accent-3","dark":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-cancel ")]),_vm._v(" Cancelar ")],1),_c('v-btn',{staticClass:"ml-0",attrs:{"outlined":"","small":"","elevation":"4","color":"red accent-3","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.clean($event)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-eraser ")]),_vm._v(" Limpar ")],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }