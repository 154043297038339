<template>
  <div style="background-color: #f0f2f5">
    <!-- <loading /> -->
    <!--<loading-2 />-->
    <main-nav-candidate />
    <v-main>
      <v-container class="mt-6">
        <router-view></router-view>
      </v-container>
    </v-main>
    <generic-footer v-if="isMobile" />
    <v-bottom-navigation
      v-model="value"
      :background-color="color"
      dark
      fixed
      v-if="!isMobile"
    >
      <v-btn :to="{ name: 'site.contents' }">
        <span>Concursos</span>

        <v-icon>mdi-table-of-contents</v-icon>
      </v-btn>

      <v-btn :to="{ name: 'empresa' }">
        <span>Empresas</span>

        <v-icon>mdi-domain</v-icon>
      </v-btn>

      <!-- <v-btn>
        <span>Book</span>

        <v-icon>mdi-database</v-icon>
      </v-btn> -->

      <v-btn @click="goToPerfil()" active-class="">
        <span>Perfil</span>

        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import MainNavCandidate from "../header/MainNavSite.vue";
// import Footer from "../layouts/footer/Footer";
import GenericFooter from "../footer/footer.vue";

export default {
  components: {
    MainNavCandidate,
    // Footer,
    GenericFooter,
  },
  data() {
    return {
      value: 1,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
    color() {
      switch (this.value) {
        case 0:
          return "#FF4500";
        case 1:
          return "#FF4500";
        case 2:
          return "#FF4500";
        case 3:
          return "#FF4500";
        default:
          return "#FF4500";
      }
    },
  },
  methods: {
    goToPerfil() {
      let token = localStorage.getItem("TOKEN_AUTH");
      if (token) {
        this.$router.push({ name: "perfil.dash" });
      } else {
        this.$router.push({ name: "site.oportunidades" });
      }
    },
  },
};
</script>

<style scoped>
</style>