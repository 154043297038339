<template>
  <v-row wrap>
    <div :class="!isMobile ? 'home-mobile' : 'home'">
      <v-btn v-if="!isMobile" tile color="#FF4500" text dark @click="home">
        <v-icon v-if="!isMobile" size="27px" left> mdi-home </v-icon>
        voltar
      </v-btn>
      <v-btn v-else tile color="#FF4500" text dark @click="home">
        <v-icon large left> mdi-home </v-icon>
        Voltar ao gugla
      </v-btn>
    </div>

    <v-col :cols="isMobile ? '12' : '12'" style="background-color: #f0f2f5">

      <v-container fill-height style="height: 100vh">
        <v-row wrap justify="center" align="center">
          <v-col cols="12" :md="isMobile ? '6' : '6'" xl="4" sm="6">
            <v-container fill-height fluid>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <v-card elevation="2" :disabled="disabled" max-width="470">
                    <v-progress-linear height="4" v-show="loading" indeterminate color="#FF5414"></v-progress-linear>
                    <v-card-title>
                      <v-img :src="require('../../assets/img/Logo-Empresas-830x354.png')
                        " max-width="180" max-height="180" alt="Gugla Empresas"
                        style="display: block; margin-left: auto; margin-right: auto" />
                    </v-card-title>
                    <v-card-subtitle style="margin-bottom: 10px; margin-top: 5px">
                      <!-- <h3 style="color: #111;"></h3> -->
                      <p>Um codigo foi enviado para <span style="font-weight: bold;">{{ this.email }}</span>, use para
                        verificar a conta. </p>
                      <p><span>Nao recebi nenhum código
                          <span class="register" @click="resubmit()" style="color: #FF5414;font-weight: bold">
                            Reenviar</span></span></p>
                    </v-card-subtitle>
                    <v-card-text>
                      <validation-provider v-slot="{ errors }" rules="required" name="Código de verificação">
                        <v-text-field outlined v-model="formData.code" label="Código de verificação"
                          :error-messages="errors" color="#FF4500" />
                      </validation-provider>
                      <!-- <validation-provider v-slot="{ errors }" rules="required" name="Senha">
                        <v-text-field v-model="formData.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="[rules.required]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Senha"
                          hint="Mínimo de 7 caracteres" counter :error-messages="errors" @click:append="show1 = !show1"
                          color="#FF4500"></v-text-field>
                      </validation-provider> -->
                      <v-card-actions>
                        <v-btn rounded text small :to="{ name: 'candidate.login' }"
                          style="padding-inline: 19px;margin-left: -30px;">
                          <v-icon left small> mdi-arrow-left </v-icon>
                          Voltar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="#FF4500" dark small @click="validadeOtp()"
                          style="padding-inline: 19px;margin-right: -7px;">Verificar</v-btn>
                      </v-card-actions>
                    </v-card-text>

                    <p class="text-center" style="margin-top: 60px;"><span>Ainda não tem conta?
                        <span class="register" @click="register" style="color: #FF5414;font-weight: bold">
                          Registe-se.</span></span></p>
                  </v-card>

                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <!-- <div id="recaptcha-container"></div> -->

        </v-row>
      </v-container>
    </v-col>
    <loader />
  </v-row>
</template>

<script>
import Loader from "../loader/Loader.vue";
import Footer from '../footer/footer.vue'
import Swal from "sweetalert2";
import 'firebase/auth';
import firebaseConfig from '../../services/firebase'
import firebase from 'firebase/app';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from "vee-validate";
import {
  required,
  digits,
  email,
  confirmed,
  max,
  regex,
} from "vee-validate/dist/rules";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} não pode estar vazio!",
});

extend("digits", {
  ...digits,
  message: "{_field_} deve conter {length} dígitos. ({_value_})!",
});

extend("email", {
  ...email,
  message: "O email deve ser válido!",
});
extend("confirmed", {
  ...confirmed,
  message: "O valor para o campo {_field_} não coincide!",
});

extend("strong_password", {
  message: (field) =>
    "A " +
    field +
    " deve conter pelo menos 1 caracter minúsculo, 1 caracter maiúsculo, 1 dígito e um cumprimento não inferior a 7!",
  validate: (value) => {
    // let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/); // Com caracteres especiais
    let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/);
    return pattern.test(value);
  },
});
export default {
  components: {
    Loader,
    ValidationProvider,
    ValidationObserver,
    Footer
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
    getCandidate() {
      return this.$store.getters.getCandidate;
    },
  },
  created() {
    var linkTag = document.createElement("link");
    linkTag.setAttribute("rel", "canonical");
    linkTag.setAttribute(
      "href",
      `https://empresas.gugla.co.mz/candidate/login`
    );
    document.head.appendChild(linkTag);
  },
  data() {
    return {
      tab: null,
      firebaseRes: null,
      loading: false,
      disabled: false,
      idToken: '',
      formData: {
        email: "",
        password: "",
        user: "",
        code: ""
      },

      candidate: {
        name: "",
        last_name: "",
        email: "",
        password: "",
      },
      confirm_password: "",

      show1: false,
      password: "Password",
      rules: {
        required: (value) => !!value || "Obrigatório.",
        min: (v) => v.length >= 7 || "Mínimo de 7 caracteres",
        emailMatch: () => `The email and password you entered don't match`,
      },

      //candidate: {},
    };
  },
  props: {
    email: {
      required: true
    }
  },

  mounted() {

    // this.sendOtp(this.telefone);
    this.sendOtpEmail(this.email)
  },
  methods: {
    sendOtpEmail(email_user) {
      // this.$store.dispatch("loaderChange", true);
      this.loading = true
      this.disabled = true
      let user = {
        email: email_user
      }
      this.$store.dispatch('sendEmailOtp', user).then((response) => {
        // this.$store.dispatch("loaderChange", false);
        this.loading = false
        this.disabled = false
      });
    },
    validadeOtp() {
      // this.$store.dispatch("loaderChange", true);
      this.loading = true
      this.disabled = true
      let user = {
        email: this.email,
        otp_code: this.formData.code
      }
      this.$store.dispatch('verifyEmailOtp', user).then((response) => {
        if (response.recruiter.first_login) {
          this.$router.push({ name: 'candidate.define.chagepassword' })
        }
        // this.$store.dispatch("loaderChange", false);

      }).catch((error) => {
        this.loading = false
        this.disabled = false
      });
    },
    sendOtp(contacto) {
      // this.$store.dispatch("loaderChange", true);
      this.loading = true
      this.disabled = true
      firebase.auth();
      const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': function (res) {

        }
      })
      firebase.auth().signInWithPhoneNumber('+258' + contacto, appVerifier).then(result => {
        console.log(result);
        // console.log('sms enviada');
        this.firebaseRes = result
        // this.$store.dispatch("loaderChange", false);
      })

      // this.$store.dispatch("loaderChange", false);
      this.loading = false
      this.disabled = false

    },
    validate() {
      // this.$store.dispatch("loaderChange", true);
      this.loading = true
      this.disabled = true
      this.firebaseRes.confirm(this.formData.code)
        .then(res => {
          // console.log(res);
          // this.$store.dispatch("changeLoginDialog", false);
          firebase.auth().currentUser.getIdToken(true).then(token => {
            // // console.log("As",token)
            // axios.get(`https://backend.gugla.co.mz/api/recruiters/recruiter/verifytoken/${token}`).then(res => {
            //   console.log(res)
            // })
            this.$store.dispatch('loginWithFirebaseWithouPassword', token).then((response) => {
              this.$store.dispatch("me");
              this.candidate = this.getCandidate;

              console.log(response);
              this.$store.dispatch("changeLoginDialog", false);
              if (response.recruiter.name == "empty" || response.recruiter.name == "") {
                this.$router.push({ name: 'recruiter.register.name' })
              } else {
                if (response.recruiter.first_login == true) {
                  // Já fez o primeiro login
                  this.$router.push({ name: "site.contents" });
                } else if (response.recruiter.first_login == false) {
                  // Ainda não fez o primeiro login/obriga a trocar a senha
                  this.$router.push({ name: "candidate.chagepassword" });
                }
              }
              //this.$router.push('/')

              // this.$store.dispatch("loaderChange", false);
              this.loading = false
              this.disabled = false
            })
              .catch((error) => {
                // this.$store.dispatch("loaderChange", false);
                this.loading = false
                this.disabled = false
                console.log(error);
              });
          })

          // console.log(this.idToken);
        })
        .catch((erro) => {
          // console.log("erroooo",erro.message)
          this.loading = false
          this.disabled = false

          Swal.fire({
            title: 'Código de verificação Invalido!',
            text: erro.message,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#FF1744'
          });
        })
    },

    isNumber(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
    register() {
      this.$router.push({ name: "candidate.register" });
    },
    resubmit() {
      this.sendOtp(this.telefone)
    },
    async login() {
      // console.log(this.isNumber(this.formData.email))
      // const isValidLoginForm = await this.$refs.loginForm.validate();
      if (this.formData.user == '') {
        Swal.fire({
          title: "Alerta de Validação!",
          html: `Verifque o preenchimento dos campos antes de enviar o formulário.`,
          icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#FF4500",
        });

        return;
      }
      let user;
      if (this.isNumber(this.formData.user)) {
        user = {
          'contacto': '+258' + this.formData.user
        }
      } else {
        user = {
          'email': this.formData.user
        }
      }

      this.$store.dispatch("loaderChange", true);
      this.$store
        .dispatch("verifyuser", user)
        .then((response) => {
          // this.candidate = this.getCandidate;
          // console.log(response.data)
          this.$store.dispatch("changeLoginDialog", false);
          if (response.data.user_exist == true && response.data.passwd_created == true) {
            // o user existe e tem a senha
            if (this.isNumber(this.formData.user) == true) {
              this.$router.push({ name: "recruiter.password", params: { userData: this.formData.user } });
            } else {

              this.$router.push({ name: "recruiter.password", params: { userData: this.formData.user } });
            }

          }
          else if (this.isNumber(this.formData.user) == false && response.data.user_exist == false && response.data.passwd_created == false) {
            //  o user nao existe e nao tem a senha
            Swal.fire({
              // title: 'Sweet!',
              text: 'Desculpe, o email não foi encontrado. Por favor, crie uma conta na aplicação Gugla Empresas para ter acesso aos nossos serviços.',
              imageUrl: 'https://empresas.gugla.co.mz/img/lupa.png',
              // imageWidth: 100,
              imageHeight: 200,
              imageAlt: 'Custom image',
              confirmButtonText: 'Registar-se',
              confirmButtonColor: '#FF1744'
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "candidate.register" });
              }
            })
          }
          this.$store.dispatch("loaderChange", false);
        })
        .catch((error) => {
          this.$store.dispatch("loaderChange", false);
          console.log(error);
        });
    },

    async autoRegister() {
      const isValidAutoRegisterForm =
        await this.$refs.autoRegisterForm.validate();

      if (!isValidAutoRegisterForm) {
        Swal.fire({
          title: "Alerta de Validação!",
          html: `Verifque o preenchimento dos campos antes de enviar o formulário.`,
          icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#FF4500",
        });

        return;
      }

      this.$store.dispatch("loaderChange", true);
      this.$store
        .dispatch("register", this.candidate)
        .then((response) => {
          // this.candidate = response.candidate;
          // Swal.fire({
          // 	title: "Sucesso!",
          // 	html: `A sua conta foi registada!<br> Aceda ao seu email para activar a sua conta e extrair os dados de acesso.`,
          // 	icon: "success",
          // 	confirmButtonText: "Ok",
          // 	confirmButtonColor: "#FF1744",
          // }).then(() => {
          // 	this.$router.push({ name: "candidate.login" });
          // });
          this.$router.push({
            name: "site.steps_after_register",
            params: { success: 1, email: this.candidate.email },
          });
          this.$store.dispatch("loaderChange", false);
        })
        .catch(() => this.$store.dispatch("loaderChange", false));
    },
    forgotPassword() {
      this.$router.push({ name: "candidate.forgotpassword" });
    },
    home() {
      this.$router.push({ name: "site.contents" });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

.red-gugla {
  color: #ff1744;
}

.basil {
  background-color: #fffbe6 !important;
}

.basil--text {
  color: #356859 !important;
}

.shadow-left {
  box-shadow: 20px 20px 20px 20px #888888;
  /* for all browsers */
  -moz-box-shadow: 20px 20px 20px 20px #888888;
  /* for old firefox */
  -webkit-box-shadow: 20px 20px 20px 20px #888888;
  /* override for Chrome & Safari */
}

.text1 {
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  padding: 20px;
}

.register {
  color: cornflowerblue;
}

.register:hover {
  cursor: pointer;
}

.home {
  position: absolute;
  top: 30px;
  left: 30px;
}

.home-mobile {
  position: absolute;
  top: 15px;
  left: 15px;
}

.mobile {
  /* padding-top: 60px; */
  /* background-color: red; */
  /* style="background-color: #F0F2F5; padding-top: 20%;" */
  padding-top: 50%;
  margin-top: 40%;
  background-color: #F0F2F5;
}

.no-uppercase {
  text-transform: unset !important;
  background-color: #ff4500 !important;
  color: white;
  font-size: small;
}
</style>