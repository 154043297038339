import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import { BASE_URL } from '../services/config'
import { API } from '../services/api'

const NAME_TOKEN = 'TOKEN_AUTH'


const ENTITY_NAME = 'ENTITY'

Vue.use(Vuex)


export default new Vuex.Store({
    state: {
        email: '',
        status: '',
        publicities: [],
        provinces: [],
        hasPassword: false,
        hasEmail: false,
        hasContact: false,
        loader: false,
        changeValue: false,
        loginDialog: false,
        applyVagaDialog: false,
        vaga: null,
        cadidaturas: [],
        completeProfile: false,
        popupCount: 0,
        popupLoginCount: 0,
        token: localStorage.getItem('TOKEN_AUTH') || '',
        candidate: {
            id: '',
            first_login: '',
            name: '',
            last_name: '',
            receive_newsletter: '',
            whatsapp_contact: '',
            notify_whatsapp: '',
            

        },
        items: [],
        minhasempresas: [],
        locals: [],
        candidates: [],
        // createContents: {
        //     locals: [],
        //     empresas: []
        // }



    },
    mutations: {
        set_candidate(state, params) {
            state.candidate = params
        },
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token, candidate) {
            state.status = 'success'
            state.token = token
            state.candidate = candidate
        },
        auth_register(state, candidate) {
            state.candidate = candidate
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
            state.candidate = {
                id: '',
                first_login: '',
                name: '',
                last_name: '',
                receive_newsletter: '',

            }
        },
        forgotValue(state, value) {
            state.forgotValue = value
        },
        loaderChange(state, value) {
            state.loader = value;
        },
        changeValue(state, value) {
            state.changeValue = value
        },
        SET_EMAIL(state, value) {
            state.email = value
        },
        loginDialogActive(state, value) {
            state.loginDialog = value
        },
        completeProfileActive(state, value) {
            state.completeProfile = value
            /*if(!console.log(VueCookies.get('showPopUp')))
                VueCookies.set('showPopUp', true, '5s')
            console.log(VueCookies.get('showPopUp'))*/
        },
        applyVagaDialogActive(state, params) {
            state.applyVagaDialog = params.value
            state.vaga = params.id
        },
        candidaturas(state, value) {
            state.cadidaturas = value
        },
        publicities(state, value) {
            state.publicities = value
        },
        GET_PROVINCES(state, value) {
            state.provinces = value.data
        },
        GET_CONTENTS(state, value) {
            state.items = value.data
        },
        GET_MINHAS_EMPRESAS(state, value) {
            state.minhasempresas = value.data.minhas_empresas.data
        },
        GET_LOCALS(state, locals) {
            state.locals = locals
        },
        GET_CANDIDATE(state, value){
            state.items = value.data
        },
        GET_CANDIDATES(state, payload){
            state.candidates = payload
        },
        GET_CREATECONTENT(state, locals, empresas) {
            state.createContents.locals = locals;
            state.createContents.empresas = empresas;
        }

    },
    actions: {
        getProvince({ commit }, params) {

            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/provinces`)
                    .then(res => {
                        // commit('auth_request')
                        commit('GET_PROVINCES', res.data)
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        getDistricts({ commit }, id) {

            commit('loaderChange', true, { root: true })

            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/provinces/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        getContent({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/contents/${params.id}`)
                    .then(res => {
                        // commit('auth_request')

                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getContentWithAuth({ commit }, params) {

            
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/showcontents/${params.id}`)
                    .then(res => {
                        // commit('auth_request')

                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getCandidateVaga({ commit }, {id, content}) {
            
            commit('loaderChange', true, { root: true })
    
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/recruiter/candidates/${id}/content/${content}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        getVaga({ commit }, id) {

            
            
            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/recruiter/vagas/${id}`)
                    .then(res => {
                        // commit('auth_request')
                        resolve(res)
                        const ids = res.data.data.candidates.map(user => {
                            return user.id
                        })
                        commit('GET_CANDIDATES', ids)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() =>{
                        commit('loaderChange', false, { root: true })
                    })
            })
            /*
            commit('loading/CHANGE_LOADING', true, { root: true })
    
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${VAGAS}/${id}`)
                    .then(response => {
                        resolve(response.data)
                        const ids = response.data.data.candidates.map(user => {
                            return user.id
                        })
                        commit('GET_CANDIDATES', ids)
                    })
                    .catch(error => reject(error))
                    .finally(() => {
                        commit('loading/CHANGE_LOADING', false, { root: true })
                    })
    
            })
            */
        },
        getEmpresa({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/empresa/${params.id}`)
                    .then(res => {
                        // commit('auth_request')
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        contarPublicidade({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/publicidade/${params.id}`)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        contarPublicidadeAuth({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/publicidadeauth/${params.id}`)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        subscribePageLogs({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/recruiter/subscription_page_logs`, params)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updatesubscribePageLogs({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/recruiter/subscription_page_logs/${params.id}`, params)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getEmpresaWithAuth({ commit }, params) {
            commit('loaderChange', true, { root: true })
            
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/empresashow/${params.id}`)
                    .then(res => {
                        // commit('auth_request')

                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        storeEmpresa({ commit }, params) {
            
            commit('loaderChange', true, { root: true })

            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/empresa`, params)
                    .then(res => {
                        // commit('auth_request')

                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        updateEmpresa({commit}, params) {
        
            commit('loaderChange', true, { root: true })
    
            return new Promise((resolve, reject) => {
    
                axios.post(`${API}/recruiters/empresa/${params.get('id')}`, params )
                    .then(response => {
                        // commit('STORE_RETURN_TO_PAGE', params.page)
                        // commit('GET_CONTENTS', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
    
            })
    
        },
        getPromo({commit}, params) {
            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/recruiter/promocoes/${params.price}`)
                    .then(res => {
                        resolve(res.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        updateService({commit}, params) {
            commit('loaderChange', true, { root: true })
    
            return new Promise((resolve, reject) => {
    
                axios.post(`${API}/recruiters/empresa/edit/service`, params )
                    .then(response => {
                        // commit('STORE_RETURN_TO_PAGE', params.page)
                        // commit('GET_CONTENTS', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
    
            })
        },
        addService({commit}, params) {
        
            commit('loaderChange', true, { root: true })
    
            return new Promise((resolve, reject) => {
    
                axios.post(`${API}/recruiters/empresa/addservices/${params.get('id')}`, params )
                    .then(response => {
                        // commit('STORE_RETURN_TO_PAGE', params.page)
                        // commit('GET_CONTENTS', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
    
            })
    
        },
        // getEmpresaWithAuth({ commit }, params) {
            
        //     return new Promise((resolve, reject) => {
        //         axios.get(`${API}/recruiters/showempresa/${params.id}`)
        //             .then(res => {
        //                 // commit('auth_request')

        //                 resolve(res)
        //             })
        //             .catch(err => {
        //                 reject(err)
        //             })
        //     })
        // },
        storeDetalhesEmpresa({ commit }, params) {

            // commit('loading/CHANGE_LOADING', true, { root: true })

            return new Promise((resolve, reject) => {

                axios.post(`${API}/recruiters/detalhesempresa`, params)
                    .then(response => {
                        // commit('STORE_RETURN_TO_PAGE', params.page)
                        // commit('GET_CONTENTS', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        // commit('loading/CHANGE_LOADING', false, { root: true })
                    })

            })

        },
        storeLeadEmpresa({ commit }, params) {

            commit('loaderChange', true, { root: true })

            return new Promise((resolve, reject) => {

                axios.post(`${API}/recruiters/leadempresa`, params)
                    .then(response => {
                        // commit('STORE_RETURN_TO_PAGE', params.page)
                        // commit('GET_CONTENTS', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })

            })

        },
        
        getEmpresaInteresse({ commit }, params) {

            commit('loaderChange', true, { root: true })

            return new Promise((resolve, reject) => {

                axios.get(`${API}/recruiters/empresas-interesse`)
                    .then(response => {
                        // commit('STORE_RETURN_TO_PAGE', params.page)
                        // commit('GET_CONTENTS', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })

            })

        },

        addFavoriteContents({ commit }, params) {

            commit('loaderChange', true, { root: true })
    
            return new Promise((resolve, reject) => {
    
                axios.post(`${API}/recruiters/add-favorite-content`, params)
                    .then(response => {
                        // commit('STORE_RETURN_TO_PAGE', params.page)
                        // commit('GET_CONTENTS', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
    
            })
    
        },
        removeFavoriteContents({ commit }, params) {

            commit('loaderChange', true, { root: true })
    
            return new Promise((resolve, reject) => {
    
                axios.delete(`${API}/recruiters/delete-favorite-content`, params)
                    .then(response => {
                        // commit('STORE_RETURN_TO_PAGE', params.page)
                        // commit('GET_CONTENTS', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
    
            })
    
        },
        
        activateEmail({ commit }, formData) {
            commit('loading/CHANGE_LOADING', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/recruiter/activate-email`, formData)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
            })
       },
        activateUser({ commit }, formData) {
            // console.log(formData);
            commit('loading/CHANGE_LOADING', true, { root: true })

            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/recruiter/activate-user`, formData)
                    .then((response) => {
                        const token = response.data.token
                        const entity = 'recruiter'
                        let recruiter = response.data.recruiter
                        let payload = {
                            user: {
                                id: recruiter.id,
                                first_login: recruiter.first_login,
                                name: recruiter.name,
                                receive_newsletter: recruiter.receive_newsletter,
                                logo: recruiter.logo,
                                verified: recruiter.verified
                            }
                        }

                        commit('auth/AUTH_USER_OK', { ...payload, entity }, { root: true })

                        localStorage.setItem(NAME_TOKEN, token)
                        localStorage.setItem(ENTITY_NAME, entity)
                        window.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                        resolve(response.data)
                        localStorage.setItem('RECRUITER_ID', response.data.recruiter.id)
                        localStorage.setItem('RECRUITER_EMAIL', response.data.recruiter.email)
                        localStorage.setItem('TEM_EMPRESA', response.data.recruiter.tem_empresa)
                        sessionStorage.setItem('SUBSCRIBE', response.data.recruiter.subscribe)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                    .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
            })
        },
        pagarMpesa({ commit }, params) {

            // commit('loaderChange', true, { root: true })
            

            return new Promise((resolve, reject) => {

                axios.post(`${API}/recruiters/pagarmpesa`, params)
                    .then(response => {
                        // commit('STORE_RETURN_TO_PAGE', params.page)
                        // commit('GET_CONTENTS', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        // commit('loaderChange', false, { root: true })
                    })

            })

        },
        getFavoriteContents({ commit }, params) {
            

            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/content-favorite`)
                    .then(res => {
                        // commit('auth_request')
                        commit('GET_CONTENTS', res.data)
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        getMyContents({ commit }, params) {
            
            commit('loaderChange', true, { root: true })
    
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/recruiter/contents`)
                    .then(response => {
                        resolve(response)
                        commit('GET_CONTENTS', response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        getVagas({ commit }, params) {
            

            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/recruiter/vagas`)
                    .then(res => {
                        // commit('auth_request')
                        commit('GET_CONTENTS', res.data)
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => commit('loaderChange', false, { root: true }))
            })
        },
        storeConcurso({ commit }, params) {
            window.axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('TOKEN_AUTH')}`
            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/concurso`, params)
                    .then(res => {
                        // commit('auth_request')
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => commit('loaderChange', false, { root: true }))
            })
        },
        storeVaga({ commit }, params) {
            
            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/vaga`, params)
                    .then(res => {
                        // commit('auth_request')
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => commit('loaderChange', false, { root: true }))
            })
        },
        getMinhasEmpresas({ commit }, params) {
            
            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/minhasempresa`)
                    .then(res => {
                        // commit('auth_request')
                        commit('GET_MINHAS_EMPRESAS', res.data)
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        getLocals ({commit}) {
            window.axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('TOKEN_AUTH')}`
            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.get(`${BASE_URL}recruiter/contents/create`)
                    .then(res => {
                        commit('GET_LOCALS', res.data.data.locals)
                        resolve(res)
                    })
                    .catch(err => reject(err))
                    .finally(() => commit('loaderChange', false, { root: true }))
            })
        },
        receiveNewsletter({ commit }, formData) {
            // console.log(formData);
            
            commit('loaderChange', true, { root: true })

            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/recruiter/receive-newsletter`, formData)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                    .finally(() => commit('loaderChange', false, { root: true }))
            })
        },
        receiveWhatasppNewsletter({ commit }, formData) {
            // console.log(formData);
            
            commit('loaderChange', true, { root: true })

            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/recruiter/receive-whatsapp-newsletter`, formData)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                    .finally(() => commit('loaderChange', false, { root: true }))
            })
        },
        updateRecruiter({ commit }, formData) {
            // console.log(formData);
            
            commit('loaderChange', true, { root: true })

            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/recruiter/update`, formData)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                    .finally(() => commit('loaderChange', false, { root: true }))
            })
        },
        verifyEmail({ commit }, formData) {
            // console.log(formData);
            
            commit('loaderChange', true, { root: true })

            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/recruiter/updateLogin`, formData)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                    .finally(() => commit('loaderChange', false, { root: true }))
            })
        },
        getCandidatesFavorites({ commit }, params) {
            
            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/candidates/candidates-favorites`)
                    .then(res => {
                        // commit('auth_request')
                        commit('GET_CANDIDATE', res.data)
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        getCandidates({ commit }, params) {
            
            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/candidates`, { params })
                    .then(res => {
                        // commit('auth_request')
                        commit('GET_CANDIDATE', res.data)
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        getCandidate({ commit }, id) {
            
            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/candidates/${id}`)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        recordContent({ commit }, params) {
            // 
            // commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/contents/record`, params)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        // commit('loaderChange', false, { root: true })
                    })
            })
        },
        addCandidatesFavorites({ commit }, params) {
            
            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.post(`${API}/recruiters/candidates/add-favorite`, params)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        removeCandidatesFavorites({ commit }, params) {
            
            commit('loaderChange', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.delete(`${API}/recruiters/candidates/delete-favorite`, params)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        commit('loaderChange', false, { root: true })
                    })
            })
        },
        
        login({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({
                    url: `${BASE_URL}recruiter/login`,
                    data: params,
                    method: 'POST'
                })
                    .then(response => {

                        const token = response.data.token
                        const entity = 'recruiter'
                        let recruiter = response.data.recruiter
                        let payload = {
                            user: {
                                id: recruiter.id,
                                first_login: recruiter.first_login,
                                name: recruiter.name,
                                receive_newsletter: recruiter.receive_newsletter,
                                logo: recruiter.logo,
                                verified: recruiter.verified
                            }
                        }

                        commit('auth/AUTH_USER_OK', { ...payload, entity }, { root: true })

                        localStorage.setItem(NAME_TOKEN, token)
                        localStorage.setItem(ENTITY_NAME, entity)
                        window.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                        resolve(response.data)
                        localStorage.setItem('RECRUITER_ID', response.data.recruiter.id)
                        localStorage.setItem('TEM_EMPRESA', response.data.recruiter.tem_empresa)
                        sessionStorage.setItem('SUBSCRIBE', response.data.recruiter.subscribe)
                        localStorage.setItem('RECRUITER_EMAIL', response.data.recruiter.email)
                    })
                    .catch(err => {
                        commit('auth_error')
                        //console.log(err)
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        newlogin({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({
                    url: `${BASE_URL}recruiter/new_login`,
                    data: params,
                    method: 'POST'
                })
                    .then(response => {

                        const token = response.data.token
                        const entity = 'recruiter'
                        let recruiter = response.data.recruiter
                        let payload = {
                            user: {
                                id: recruiter.id,
                                first_login: recruiter.first_login,
                                name: recruiter.name,
                                receive_newsletter: recruiter.receive_newsletter,
                                logo: recruiter.logo,
                                verified: recruiter.verified
                            }
                        }

                        commit('auth/AUTH_USER_OK', { ...payload, entity }, { root: true })

                        localStorage.setItem(NAME_TOKEN, token)
                        localStorage.setItem(ENTITY_NAME, entity)
                        window.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                        resolve(response.data)
                        localStorage.setItem('RECRUITER_ID', response.data.recruiter.id)
                        localStorage.setItem('TEM_EMPRESA', response.data.recruiter.tem_empresa)
                        sessionStorage.setItem('SUBSCRIBE', response.data.recruiter.subscribe)
                        localStorage.setItem('RECRUITER_EMAIL', response.data.recruiter.email)
                    })
                    .catch(err => {
                        commit('auth_error')
                        //console.log(err)
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        verifyContactLogin({ commit }, token) {
            return new Promise((resolve, reject) => {
                axios.get(`${API}/recruiters/recruiter/setcontact/${token}`)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => reject(err))
            })
        },
        loginWithFirebaseWithouPassword({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({
                    url: `${BASE_URL}recruiter/verifytoken/${params}`,
                    data: params,
                    method: 'GET'
                })
                    .then(response => {

                        const token = response.data.token
                        const entity = 'recruiter'
                        let recruiter = response.data.recruiter
                        let payload = {
                            user: {
                                id: recruiter.id,
                                first_login: recruiter.first_login,
                                name: recruiter.name,
                                receive_newsletter: recruiter.receive_newsletter,
                                logo: recruiter.logo,
                                verified: recruiter.verified
                            }
                        }

                        commit('auth/AUTH_USER_OK', { ...payload, entity }, { root: true })

                        localStorage.setItem(NAME_TOKEN, token)
                        localStorage.setItem(ENTITY_NAME, entity)
                        window.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                        resolve(response.data)
                        localStorage.setItem('RECRUITER_ID', response.data.recruiter.id)
                        localStorage.setItem('TEM_EMPRESA', response.data.recruiter.tem_empresa)
                        sessionStorage.setItem('SUBSCRIBE', response.data.recruiter.subscribe)
                        localStorage.setItem('RECRUITER_EMAIL', response.data.recruiter.email)
                    })
                    .catch(err => {
                        commit('auth_error')
                        //console.log(err)
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        verifyEmailOtp({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({
                    url: `${BASE_URL}recruiter/verifyotp`,
                    data: params,
                    method: 'POST'
                })
                    .then(response => {

                        const token = response.data.token
                        const entity = 'recruiter'
                        let recruiter = response.data.recruiter
                        let payload = {
                            user: {
                                id: recruiter.id,
                                first_login: recruiter.first_login,
                                name: recruiter.name,
                                receive_newsletter: recruiter.receive_newsletter,
                                logo: recruiter.logo,
                                verified: recruiter.verified
                            }
                        }
                        console.log("sasasasa", response.data)

                        commit('auth/AUTH_USER_OK', { ...payload, entity }, { root: true })

                        localStorage.setItem(NAME_TOKEN, token)
                        localStorage.setItem(ENTITY_NAME, entity)
                        window.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                        resolve(response.data)
                        localStorage.setItem('RECRUITER_ID', response.data.recruiter.id)
                        localStorage.setItem('TEM_EMPRESA', response.data.recruiter.tem_empresa)
                        sessionStorage.setItem('SUBSCRIBE', response.data.recruiter.subscribe)
                        localStorage.setItem('RECRUITER_EMAIL', response.data.recruiter.email)
                    })
                    .catch(err => {
                        commit('auth_error')
                        //console.log(err)
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        verifyuser({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({
                    url: `${BASE_URL}recruiter/verifyuser`,
                    data: params,
                    method: 'POST'
                })
                    .then(res => {
                        const candidate = res.data.data
                        //console.log(candidate)
                        commit('auth_register', candidate)
                        resolve(res)
                    })
                    .catch(err => {
                        commit('auth_error', err)
                        reject(err)
                    })
            })
        },
        sendEmailOtp({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({
                    url: `${BASE_URL}recruiter/new_forgot_password`,
                    data: params,
                    method: 'POST'
                })
                    .then(res => {
                        // const candidate = res.data.data
                        //console.log(candidate)
                        // commit('auth_register', candidate)
                        resolve(res)
                    })
                    .catch(err => {
                        commit('auth_error', err)
                        reject(err)
                    })
            })
        },
        /*
        verifyEmailOtp({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({
                    url: `${BASE_URL}recruiter/verifyotp`,
                    data: params,
                    method: 'POST'
                })
                    .then(res => {
                        // const candidate = res.data.data
                        //console.log(candidate)
                        // commit('auth_register', candidate)
                        resolve(res)
                    })
                    .catch(err => {
                        commit('auth_error', err)
                        reject(err)
                    })
            })
        },
        */
        register({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({
                    url: `${BASE_URL}recruiter/auto-register`,
                    data: params,
                    method: 'POST'
                })
                    .then(res => {
                        const candidate = res.data.data
                        //console.log(candidate)
                        commit('auth_register', candidate)
                        resolve(res)
                    })
                    .catch(err => {
                        commit('auth_error', err)
                        reject(err)
                    })
            })
        },
        logout({ commit }) {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('TOKEN_AUTH')}`,
                }
            }
            return new Promise((resolve) => {
                axios.post(`${BASE_URL}recruiter/logout`, config)
                    .then(() => {
                        commit('logout')
                        // localStorage.removeItem('token')
                        localStorage.removeItem('TOKEN_AUTH')
                        localStorage.removeItem('RECRUITER_ID')
                        localStorage.removeItem('RECRUITER_EMAIL')
                        localStorage.removeItem('TEM_EMPRESA')
                        localStorage.removeItem('quantidade_meses')
                        localStorage.removeItem('ENTITY')
                        localStorage.removeItem('email_recruiter')
                        resolve()
                    })
                    .catch(() => {
                        commit('logout')
                        // localStorage.removeItem('token')
                        localStorage.removeItem('TOKEN_AUTH')
                        localStorage.removeItem('RECRUITER_ID')
                        localStorage.removeItem('RECRUITER_EMAIL')
                        localStorage.removeItem('TEM_EMPRESA')
                        localStorage.removeItem('quantidade_meses')
                        localStorage.removeItem('ENTITY')
                        localStorage.removeItem('email_recruiter')
                        resolve()
                    })


            })
        },
        forgotPassword({ commit }, formData) {



            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}recruiter/forgot_password`, formData)
                    .then((res) => {
                        commit('')
                        resolve(res)
                    })
                    .catch(error => {
                        //console.log(error);
                        reject(error)
                    })

            })
        },
        changePassword({ commit }, params) {
            commit('loaderChange', true, { root: true })
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('TOKEN_AUTH')}`,
                    // Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}recruiter/change_password`, params, config)
                    .then((res) => {
                        commit('auth_request')
                        resolve(res)

                    })
                    .catch(error => {
                        commit('loaderChange', false, { root: true })
                        reject(error)
                    })
            })
        },
        activeCandidate({ commit }, params) {

            return new Promise((resolve, reject) => {
                axios.get(`${BASE_URL}active?id=${params.id}&email=${params.email}`)
                    .then(res => {
                        commit('auth_request')

                        resolve(res)
                    })
                    .catch(error => {

                        reject(error)
                    })
            })
        },
        cancelAccount({ commit }, params) {
            return new Promise((resolve, reject) => {
                // axios.get(`${BASE_URL}newsletter/cancel?id=${params.id}&email=${params.email}`)
                axios.post(`${BASE_URL}recruiter/account/cancel`, {...params})
                    .then(res => {
                        resolve(res)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        me({ commit }) {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("TOKEN_AUTH")}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.get(`${BASE_URL}recruiter/me`, config)
                    .then(res => {
                        commit('set_candidate', res.data.recruiter)
                        sessionStorage.setItem('active_subscription', res.data.recruiter.active_subscription)
                        // const candidaturas_array = res.data[0].contents.map(vaga => {
                        //     return vaga.id
                        // })
                        // commit('candidaturas', candidaturas_array)
                        resolve(res)
                    })
                    .catch(err => {
                        // localStorage.removeItem('token')
                        sessionStorage.setItem('active_subscription', 0)
                        reject(err)
                    })
            })
        },
        activeNewsletter({ commit }, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}receive-newsletter`, params, config)
                    .then(res => {
                        commit('auth_request')
                        resolve(res)
                    })
                    .catch(
                        err => reject(err)
                    )
            })
        },
        personalData({ commit }, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}personal-data/create`, params, config)
                    .then(res => {
                        commit('auth_request')
                        resolve(res)
                    })
                    .catch(
                        err => reject(err)
                    )
            })
        }
        ,
        experience({ commit }, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}profissional-experience/create`, params, config)
                    .then(res => {
                        commit('auth_request')
                        resolve(res)
                    })
                    .catch(
                        err => reject(err)
                    )
            })
        },
        updateExperience({ commit }, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            console.log(params.id)
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}profissional-experience/update/${params.id}`, params.data, config)
                    .then(res => {
                        commit('auth_request')
                        resolve(res)
                    })
                    .catch(
                        err => reject(err)
                    )
            })
        },
        education({ commit }, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}education/create`, params, config)
                    .then(res => {
                        commit('auth_request')
                        resolve(res)
                    })
                    .catch(
                        err => reject(err)
                    )
            })
        },
        updateEducation({ commit }, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            console.log(params.id)
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}education/update/${params.id}`, params.data, config)
                    .then(res => {
                        commit('auth_request')
                        resolve(res)
                    })
                    .catch(
                        err => reject(err)
                    )
            })
        },
        language({ commit }, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}language/create`, params, config)
                    .then(res => {
                        commit('auth_request')
                        resolve(res)
                    })
                    .catch(
                        err => reject(err)
                    )
            })
        },
        updateLanguage({ commit }, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            console.log(params.id)
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}language/update/${params.id}`, params.data, config)
                    .then(res => {
                        commit('auth_request')
                        resolve(res)
                    })
                    .catch(
                        err => reject(err)
                    )
            })
        },
        anexo({ commit }, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}anexo/create`, params, config)
                    .then(res => {
                        commit('auth_request')
                        resolve(res)
                    })
                    .catch(
                        err => reject(err)
                    )
            })
        },
        updateAnexo({ commit }, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            // console.log(params.id)
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}anexo/update/${params.id}`, params.data, config)
                    .then(res => {
                        commit('auth_request')
                        resolve(res)
                    })
                    .catch(
                        err => reject(err)
                    )
            })
        },
        deleteData({ commit }, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.delete(`${BASE_URL}${params.table}/delete/${params.id}`, config)
                    .then(res => {
                        commit('auth_request')
                        resolve(res)
                    })
                    .catch(
                        err => reject(err)
                    )
            })
        },
        getCreateContent({ commit }, params) {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('TOKEN_AUTH')}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.get(`${BASE_URL}recruiter/contents/create`, config)
                    .then(res => {
                        commit('GET_CREATECONTENT', res.data.data.locals, res.data.data.empresas);
                        console.log(this.state.createContents.locals);
                        resolve(res)
                    })
                    .catch(err => reject(err))
            })
        },
        dialogValue({ commit }, value) {
            commit('forgotValue', value)
        },
        loaderChange({ commit }, value) {
            commit('loaderChange', value)
        },
        changeValue({ commit }, value) {
            commit('changeValue', value)
        },
        setEmail({ commit }, value) {
            commit('SET_EMAIL', value)
        },
        changeLoginDialog({ commit }, value) {
            commit('loginDialogActive', value)
        },
        changeCompleteProfile({ commit }, value) {
            //console.log(this.state)
            if (value == false) {
                commit('completeProfileActive', value)
            }
            commit('completeProfileActive', value)
        },
        changeApplyVagaDialog({ commit }, params) {
            commit('applyVagaDialogActive', params)
        },
        getPublicity({ commit }, params) {
            commit('publicities', params)
        }

    },
    getters: {
        getCandidate: state => state.candidate,
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        loader: state => state.loader,
        changeValue: state => state.changeValue,
        email: state => state.email,
        loginDialog: state => state.loginDialog,
        completeProfile: state => state.completeProfile,
        applyVaga: state => state.applyVagaDialog,
        publicities: state => state.publicities,
        locals: state => state.createContents.locals,
        empresas: state => state.createContents.empresas,

    }
})