<template>

    
    <v-container class="my-auto"   style="align-items: center; ">   
            <div style="margin:2%">
                <v-card max-width="470" class="mx-auto" >
                    <v-card-title style="justify-content: center;margin-top: 2%;">
                        <img
                            :src="require('../../assets/img/Logo-Empresas-830x354.png')"
                            height="72"
                            width="180"
                            alt="Gugla Empresas"
                        />
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-subtitle style="text-align: center;color: #F66459">
                        <h2>
                            Recuperação da senha
                    </h2></v-card-subtitle>
                    <v-spacer></v-spacer>
                    <v-card-subtitle  > Escreva o seu email ou número de telemóvel para recuperar a senha </v-card-subtitle>
                    <v-card-text>
                   
                        <validation-provider>

                            <v-text-field outlined placeholder="example@example.com/849999999"></v-text-field>
                        </validation-provider>
                        <v-card-actions>
                            <v-btn outlined class="no-backButton" style="margin-left: -7px;" :to="{name: 'candidate.login'}" >
                                
                                Cancelar
                            </v-btn>
                             <v-spacer></v-spacer>
                            <v-btn :to="{name: 'recuperacao.escolha'}" class="no-uppercase" style="padding-inline: 39px;margin-right: -7px;">
                              Seguinte
                            </v-btn>
                        </v-card-actions>
                        
                    
                    </v-card-text>
                    <!-- <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="no-uppercase" style="padding-inline: 39px;">
                            Seguinte
                        </v-btn>
                    </v-card-actions> -->
                </v-card>

                 
            </div>
           

            
            
    </v-container>
 
</template>
<script>
export default{
    name:'DadosRecuperacao',
}
</script>
<style scoped>



.no-uppercase {
  text-transform: unset !important;
  background-color: #ff4500 !important ;
  color: white;
  font-size: small;
}
.no-backButton{
    
    text-transform: unset !important;
    color: #ff4500 !important;
}
</style>