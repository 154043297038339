<template >
  <div class="containe" :class="isMobile ? 'ml-16' : 'ma-3'">
    <div
      :class="open ? 'pergunta open' : 'contain'"
      @click="$emit('abrir', posicao)"
    >
      <div class="pergunta" :class="isMobile ? '' : 'pb-4'">
        <div
          class="teste"
          :class="{
            'mr-3': $vuetify.breakpoint.smAndDown,
            'mt-3': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <p
            :class="{
              'mr-4': $vuetify.breakpoint.smAndDown,
              ' desabilitar': $vuetify.breakpoint.smAndDown,
            }"
          >
            {{ pergunta.question }}
          </p>
          <v-icon class="icones"> mdi-chevron-down </v-icon>
        </div>
      </div>
      <div
        class="resposta"
        :class="{
          'mr-15': $vuetify.breakpoint.smAndDown,
          'ml-8': $vuetify.breakpoint.smAndDown,
        }"
      >
        <span v-if="pergunta.code != 3">{{ pergunta.answer }}</span>
        <span v-else
          ><b>Mpesa / Paypal</b> <br> Ao efetuar a subscrição no gugla empresas através do
          Mpesa ou paypal, o sistema de pagamento irá de forma automática
          habilitar a visualização dos concursos, de acordo com o pacote por si
          selecionado. <br><b> Transferência bancaria</b> <br> Em caso de efetuar a subscrição
          por meio de <b>transferência bancaria ou paypal</b>, terá de Enviar-nos o
          comprovativo de pagamento através do WhatsApp clicando aqui
          <a target="_blank" href="https://wa.me/+258848918441">(https://wa.me/+258848918441)</a> , comprovada o pagamento será efetuada a
          subscrição.
        </span>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: "Main1",
  props: ["pergunta", "posicao", "open"],

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
};
</script>

<style scoped>
.containe {
  max-width: 45.9375rem;
}
hr {
  color: #dcd8d8;
}
.contain {
  display: flex;
  flex-direction: column;
  gap: 0.4375rem;
  justify-content: space-around;
  max-width: 45.9375rem;
}

p {
  padding-left: 1.0625rem;
  font-weight: 600;
  margin-bottom: -16px;
  font-size: 18px;
}
p:hover {
  color: #ff4500;
}
.desabilitar {
  pointer-events: none;
}

.pergunta.open p {
  color: #ff4500;
}
.perguta.open pVMobile {
  margin-top: 20px;
}

.pergunta {
  position: relative;
  color: black;
  margin-bottom: 10px;
}
.pergunta.open .icones {
  position: relative;
  transform: rotate(180deg);
}
.teste {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 20px;
}

.pergunta.open .pergunta {
  margin-bottom: 10px;
}

.resposta {
  margin-left: 2.5rem;
  margin-right: 5.625rem;

  overflow-y: hidden;
  opacity: 0;
  max-height: 0px;
}
.pergunta.open .resposta {
  opacity: 1;
  max-height: 1000px;
  text-align: justify;
  margin-top: 8px;
  padding-top: 8px;
  margin-bottom: 15px;
  font-size: 16px;
}
</style>