<template>
  <v-container class="mt-8">
    <!-- popup sobre login e subscricao  -->
    <v-dialog persistent v-model="dialog" max-width="500">
      <v-card class="mx-auto d-flex flex-column" style="padding-bottom: 0px; padding-top: 50px">
        <div class="meuCard" style="
                margin: 0;
                padding-bottom: 0px;
                overflow-x: hidden;
                overflow-y: hidden;
              ">
          <v-row wrap>
            <v-col cols="12" md="12">
              <div class="text-center">
                <img src="../../assets/key-content.png" alt="" height="130" width="130"
                  style="display: block; margin-left: auto; margin-right: auto" />
              </div>
              <div class="textoCabelho">
                <h1 style="font-weight: bold; color: #111" class="text-center">
                  Apenas para membros subscritos!
                </h1>
                <br />
                <p class="text-center text-body-2 text-md-body-1">
                  O concurso que tentou aceder está disponível apenas para
                  membros subscritos.
                </p>
              </div>

              <br />

              <v-card-actions v-if="buttonCard == false">
                <v-row wrap justify="center">
                  <v-col cols="12" md="4">
                    <v-btn block color="#FF4500" dark rounded @click="goToLogin">Login</v-btn>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn block color="#EEEEEE" rounded @click="goToRegisto">Registo</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
              <v-card-actions v-if="buttonCard == true">
                <v-row wrap justify="center">
                  <v-col cols="12" md="6">
                    <v-btn block color="#D8293B" dark rounded @click="goToSubscricao">Efectuar a Subscrição</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog2" max-width="500">
      <v-card class="mx-auto d-flex flex-column" style="padding-bottom: 0px; padding-top: 10px">
        <div class="meuCard" style="
                margin: 0;
                padding-bottom: 0px;
                overflow-x: hidden;
                overflow-y: hidden;
              ">
          <v-row wrap>
            <v-col cols="12" md="12">
              <v-card-title>
                <v-img :src="
                  require('../../assets/img/Logo-Empresas-830x354.png')
                " max-width="150" max-height="150" alt="Gugla Empresas"
                  style="display: block; margin-left: auto; margin-right: auto" />
              </v-card-title>
              <v-card-subtitle>Login</v-card-subtitle>
              <v-card-text>
                <v-text-field v-model="formData.email" label="Email" />
                <v-text-field v-model="formData.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1"
                  label="Password" hint="Mínimo de 7 caracteres" counter @click:append="show1 = !show1"></v-text-field>
              </v-card-text>

              <br />

              <v-card-actions>
                <v-btn color="#FF4500" block dark rounded @click="goToLogin2">Entrar</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <div>
      <v-row wrap align="center" justify="center">
        <v-col md="12">
          <template>
            <v-chip x-small label href="https://gugla.co.mz/publicidade.html" dark :color="'green darken-1'">anuncie neste
              espaço</v-chip>
          </template>
          <publicity />
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="6" md="6" class="pl-0 mb-6">
          <v-btn :to="{ name: 'site.contents' }" class="ml-0" tile elevation="4" dark color="red acent-3" small>
            <v-icon left small> mdi-arrow-left </v-icon>Concursos</v-btn>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.smAndDown" cols="6" md="6" class="text-right">
          <!--<v-avatar size="80" height="auto" v-if="content.image" rounded>
							<img :src="content.image" alt=""/>
						</v-avatar>-->
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11">
          <content-details-component :content="content"
            :recomended_content="recomended_content"></content-details-component>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12" cols="12" v-if="recomended_content">
          <div>Empresas recomendadas</div>
          <v-divider class="my-2"></v-divider>
        </v-col>
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="3" v-for="empresa in recomended_content" :key="empresa.id">
            <v-card elevation="1" class="mx-auto" max-width="344" max-height="90" outlined>
              <v-list-item three-line @click="getEmpresa(empresa.id)">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ empresa.nome }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    empresa.industria ? empresa.industria : ""
                  }}</v-list-item-subtitle>
                  <!-- <v-list-item-subtitle v-if="isMobile">{{empresa.local}}</v-list-item-subtitle> -->
                </v-list-item-content>
                <v-list-item-avatar v-if="empresa.image" size="60" height="45" rounded color="">
                  <img :src="empresa.image" alt="" />
                </v-list-item-avatar>
                <v-list-item-avatar v-else size="60" height="auto" rounded color="">
                  <img :src="require('../../assets/empresa-logo.png')" alt="" />
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="3">
            <v-card elevation="1" class="mx-auto" id="cardEmpresas" max-width="344" max-height="90" @click="cardEmpresa">
              <h5 class="pa-4">Registe a sua empresa nessa plataforma</h5>
              <p class="pa-4" style="color: #fff">
                Exponha os seus productos e serviços
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
      <v-row wrap align="center" justify="center">
        <v-col md="12" style="margin-bottom: 40px">
          <template>
            <v-chip x-small label href="https://gugla.co.mz/publicidade.html" dark :color="'green darken-1'">anuncie neste
              espaço</v-chip>
          </template>
          <publicity />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>


<script>
import api from "../../services/index";
import contentDetailsComponent from "./contentDetailsComponent.vue";
import Publicity from "../publicity/publicity.vue";

export default {
  components: { contentDetailsComponent, Publicity },
  data() {
    return {
      id: this.$route.params.id,
      content: {},
      recomended_content: [],
      dialog: false,
      dialog2: false,
      buttonCard: false,
      formData: {
        email: "",
        password: "",
      },
      show1: false,
      password: "Password",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 7 || "Mínimo de 7 caracteres",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  computed: {
    candidate() {
      let candidate = this.$store.state.candidate;
      return candidate;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
  created() {
    this.getEmpresaInteresse();
    sessionStorage.setItem("id", this.id)
    let token = localStorage.getItem("TOKEN_AUTH");
    if (token) {
      this.getContentWithAuth(this.id);
      this.buttonCard = true;
    } else {
      this.getContent(this.id);
      this.buttonCard = false;
    }
  },
  methods: {
    goToRegisto() {
      this.$router.push({ name: "candidate.register" });
    },
    goToLogin() {
      this.dialog = false
      var urlAtual = window.location.href;
      sessionStorage.setItem("url", urlAtual);
      // sessionStorage.setItem("previous_router", "concurso");
      this.$router.push({ name: 'candidate.login' })
      // this.dialog2 = true
    },
    goToLogin2() {
      this.$store.dispatch("loaderChange", true);
      this.$store
        .dispatch("login", this.formData)
        .then(() => {
          console.log("Logou");
          this.dialog2 = false;
          // this.$router.push({ name: "recruiter.contents" });
          let token = localStorage.getItem("TOKEN_AUTH");
          this.$store.dispatch('me')
          location.reload()

          if (token) {
            this.getContentWithAuth(this.id);
          } else {
            this.getContent(this.id);
          }
          this.$store.dispatch("loaderChange", false);
        })
        .catch(() => {
          this.$store.dispatch("loaderChange", false);
          console.log("erro ao logar");
        })
        .finally("Finalizou no componet");
    },
    cardEmpresa() {
      let token = localStorage.getItem("TOKEN_AUTH");
      if (!token) {
        this.$router.push({ name: "site.concursos" });
      } else {
        this.$router.push({ name: "empresa.create" });
      }
    },
    checkUserProfile() {
      //console.log('ok')
      if (
        this.candidate?.personal_data &&
        this.candidate.personal_data == null
      ) {
        this.$store.dispatch("changeCompleteProfile", true);
      } else if (this.candidate?.anexos && this.candidate.anexos.length == 0) {
        this.$store.dispatch("changeCompleteProfile", true);
      } else if (
        this.candidate?.education &&
        this.candidate.education.length == 0
      ) {
        this.$store.dispatch("changeCompleteProfile", true);
      } else if (
        this.candidate?.language &&
        this.candidate.language.length == 0
      ) {
        this.$store.dispatch("changeCompleteProfile", true);
      }
    },
    getContent(id) {
      this.$store.dispatch("loaderChange", true);
      this.$store
        .dispatch("getContent", { id })
        .then((res) => {
          console.log(res.data.data);
          this.content = res.data.data;
          // this.recomended_content = res.data.data.recomended_content.data

          this.$store.dispatch("loaderChange", false);
          scroll(0, 0);
        })
        .catch((err) => {
          this.$store.dispatch("loaderChange", false);
          this.dialog = true;
          // alert('ansjasjasjas')
          // this.$router.push({ name: "site.contents" });
          // return err;
        });
    },

    getEmpresa(item) {
      // alert(item)
      this.$router.push({ name: "empresa.show", params: { id: item } });
    },
    getEmpresaInteresse() {
      this.$store.dispatch("loaderChange", true);
      this.$store
        .dispatch("getEmpresaInteresse")
        .then((res) => {
          console.log(res.data.data);
          let novo_array = res.data.data
          // this.recomended_content = res.data.data;
          this.recomended_content =  novo_array.sort(() => Math.random() - 0.5)
          this.$store.dispatch("loaderChange", false);
          scroll(0, 0);
        })
        .catch((err) => {
          this.$store.dispatch("loaderChange", false);
          this.$router.push({ name: "site.contents" });
          return err;
        });
    },
    getContentWithAuth(id) {
      this.$store.dispatch("loaderChange", true);
      this.$store
        .dispatch("getContentWithAuth", { id })
        .then((res) => {
          console.log(res.data.data);
          this.content = res.data.data;
          // this.recomended_content = res.data.data.recomended_content.data

          this.$store.dispatch("loaderChange", false);
          scroll(0, 0);
        })
        .catch((err) => {
          this.$store.dispatch("loaderChange", false);
          this.$router.push({ name: "site.contents" });
          return err;
        });
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
}

#cardEmpresas {
  background-image: url("../../assets/empresas-card.png");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

h5 {
  color: #fff;
  font-size: 18px;
}


.textoCabelho {
  /* background-color: red; */
  padding: 0;
}

p {
  padding: 0;
}
</style>