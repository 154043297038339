<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-form @submit.prevent="onSubmit">
      <v-row justify="space-betwen">
        <v-col cols="8"></v-col>
        <v-col cols="12" md="4" class="pl-0 mb-6 md-4 text-start">
          <span>Está enfrentando alguma dificuldade?</span>
          <br />
          <v-btn
            :href="`mailto:suporte@marrar.co.mz?subject=Publicar uma Vaga`"
            class="ml-0"
            tile
            elevation="4"
            dark
            color="#FF5414"
            small
          >
            <v-icon left small> mdi-message-settings </v-icon>Suporte</v-btn
          >
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          <!-- <h4 class="grey--text font-weight-regular">Concurso</h4> -->
          <v-chip small label dark :color="'green darken-1'"
            >Publicar Vaga</v-chip
          >
        </v-card-title>
        <v-card-text>
          <v-row wrap v-if="imageUploadPreview">
            <v-col cols="12" md="12" class="text-center">
              <v-avatar size="100">
                <img :src="imageUploadPreview" alt="" />
              </v-avatar>
            </v-col>
          </v-row>
          <!-- <v-row wrap>
            <v-col cols="12" md="12">
              <v-file-input
                ref="image_upload"
                dense
                truncate-length="1"
                label="Carregar Imagem"
                accept="image"
                @change="onFileChange"
              ></v-file-input>
            </v-col>
          </v-row> -->
          <v-row wrap>
            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Título"
                rules="required"
              >
                <v-text-field
                  ref="titulo"
                  dense
                  v-model="vaga.titulo"
                  label="Título"
                  placeholder="Título do anúncio"
                  :error-messages="errors"
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Empresa"
                rules="required"
              >
                <v-select
                  v-model="vaga.empresa_id"
                  ref="empresas"
                  dense
                  :error-messages="errors"
                  :items="empresas"
                  item-text="nome"
                  item-value="id"
                  chips
                  label="Empresa"
                  class="mt-0"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    v-model="vaga.validade"
                    label="Validade"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  dense
                  v-model="vaga.validade"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Local"
                rules="required"
              >
                <v-select
                  ref="local"
                  dense
                  :error-messages="errors"
                  v-model="vaga.local"
                  :items="locals"
                  item-text="name"
                  item-value="id"
                  chips
                  label="Local"
                  class="mt-0"
                ></v-select>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Industria"
                rules="required"
              >
                <v-select
                  ref="Industria"
                  dense
                  :error-messages="errors"
                  v-model="vaga.industria"
                  :items="industrias"
                  item-text="name"
                  item-value="id"
                  chips
                  label="Industria"
                  class="mt-0"
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row
            wrap
            v-if="!anexoConteudoUploadPreview"
            style="margin-bottom: 50px"
          >
            <v-col md="12">
              <vue-editor
                ref="conteudo"
                placeholder="Descrição do concurso..."
                style="height: 300px; margin-top: 20px"
                :editor-toolbar="customToolbar"
                v-model="vaga.conteudo_formatado"
              >
              </vue-editor>
            </v-col>
          </v-row>

          <!-- <v-row wrap v-if="anexoConteudoUploadPreview">
            <v-col cols="12" md="12" class="text-center">
              <v-img>
                <img
                  placeholder="Anexo"
                  :src="anexoConteudoUploadPreview"
                  class="anexo"
                  alt=""
                />
              </v-img>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" md="12">
              <v-file-input
                ref="anexo_conteudo"
                dense
                show-size
                small-chips
                truncate-length="20"
                label="Anexo"
                accept="image/*"
                @change="onAnexoConteudoFileChange"
              ></v-file-input>
            </v-col>
          </v-row> -->

          <v-row wrap>
            <v-col cols="12" md="8">
              <validation-provider
                v-slot="{ errors }"
                name="Como candidatar-se"
                rules="required"
              >
                <v-select
                  v-model="method"
                  :items="formaList"
                  :error-messages="errors"
                  prepend-icon="mdi-none"
                  label="Como candidatar-se"
                  data-vv-name="select"
                  required
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="Este campo"
                :rules="rule"
              >
                <v-text-field
                  v-show="method != formaList[3]"
                  v-model="desc"
                  :error-messages="errors"
                  :prepend-icon="
                    method == formaList[0]
                      ? 'mdi-map-marker-outline'
                      : 'mdi-link-variant'
                  "
                  :label="candidatar_label"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider v-slot="{ errors }" name="Dica">
                <v-textarea
                  v-show="method != formaList[3]"
                  v-model="dica"
                  :error-messages="errors"
                  prepend-icon="mdi-tag-multiple-outline"
                  label="Dica"
                  style="height: 200px"
                />
              </validation-provider>
            </v-col>

            <!-- <v-col cols="12">
              <v-checkbox
                label="Pode receber candidaturas directamente da plataforma?"
                v-model="vaga.candidaturas"
                color="#FF6126"
              ></v-checkbox>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn type="submit" small elevation="4" color="red accent-3" dark>
            <v-icon small class="mr-2"> mdi-check </v-icon>
            {{ actionSubmit }}
          </v-btn>
          <v-btn
            :to="{ name: 'recruiter.vagas' }"
            exact
            outlined
            small
            elevation="4"
            color="red accent-3"
            dark
            class="mx-4"
          >
            <v-icon small class="mr-2"> mdi-cancel </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            outlined
            small
            elevation="4"
            color="red accent-3"
            dark
            @click.prevent="clean"
            class="ml-0"
          >
            <v-icon small class="mr-2"> mdi-eraser </v-icon>
            Limpar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <!-- <v-divider class="my-5"></v-divider>
    <v-row justify="start">
      <v-col cols="12" class="pl-0 mb-6 md-4 text-start">
        <span>Está enfrentando alguma dificuldade?</span>
        <br />
        <v-btn
          :href="`mailto:suporte@marrar.co.mz?subject=Publicar Uma Vaga`"
          class="ml-0"
          tile
          elevation="4"
          dark
          color="#FF5414"
          small
        >
          <v-icon left small> mdi-message-settings </v-icon>Suporte</v-btn
        >
      </v-col>
    </v-row> -->
  </validation-observer>
</template>
<script>
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from "vee-validate";
import { VueEditor } from "vue2-editor";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import Swal from "sweetalert2";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} não pode estar vazio",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});
extend("url", {
  validate(value) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(value);
  },
  message: "Introduz um link valido.",
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueEditor,
  },
  props: {
    vaga: {
      required: false,
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object | Array,
      default: () => {
        return {
          id: "",
          image: "",
          titulo: "",
          instituicao: "",
          is_recomended: false,
          industria: "",
          validade: "",
          categoria_id: 1,
          local: "",
          fonte: "",
          conteudo: "",
          conteudo_formatado: "",
          anexo_conteudo: "",
          empresa_id: "",
          candidaturas: ""
        };
      },
    },
    updating: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      tab: null,
      image_upload: null,
      anexo_conteudo_upload: null,
      hidden: false,
      method: "",
      desc: "",
      dica: "",
      rule: "",
      candidatar_label: "",
      formaList: [
        "Entregar candidatura no endereço físico",
        "Aceder o link",
        "Enviar candidatura por email",
        "Receber através do Gugla"
      ],
      locals: [
        "Cabo Delgado",
        "Gaza",
        "Inhambane",
        "Manica",
        "Maputo",
        "Nampula",
        "Niassa",
        "Sofala",
        "Tete",
        "Zambézia",
      ],
      industrias: [
        "Administração e Secretariado Cliente",
        "Aquisições e Procurement",
        "Assistente",
        "Auditoria e Consultoria",
        "Comunicação Social",
        "Construção Civil",
        "Contabilidade e Finanças",
        "Design",
        "Direito e Justiça",
        "Electricidade",
        "Gestão de Projectos",
        "Gestão e Assessoria",
        "Gestão Financeira",
        "Gestor Provincial",
        "Higiene e Segurança",
        "Hotelaria e Turismo",
        "informática e Programação",
        "Manutenção e Técnica",
        "Marketing e Publicidade",
        "Mecânica",
        "Meio Ambiente",
        "Monitoria e Avaliação",
        "Oficial Técnico",
        "Operário",
        "Recursos",
        "Humanos",
        "Relações Públicas",
        "Saúde",
        "Seguros",
        "Serviços Gerais",
        "Serviços Sociais",
        "Supervisão e Coordenação",
        "Topografia e Geologia",
        "Transportes e Logística",
        "Vendas/Comércio",
        "Outro",
      ],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "bullet" }, { list: "ordered" }, { list: "check" }],
        [{ color: [] }],
        ["link"],
      ],
    };
  },
  created() {
    this.getEmpresas();
    this.getLocals();
  },
  methods: {
    getEmpresas() {
      this.$store.dispatch('getMinhasEmpresas')
    },
    onSubmit() {
      console.log("ddd")
      // this.$refs.observer.validate();

      const como_candidatar = {
        method: this.method,
        desc: this.desc,
        dica: this.dica,
      };

      let conteudo = "";
      if (this.method == this.formaList[0]) {
        conteudo =
          this.vaga.conteudo +
          "\n" +
          "Processo de candidatura" +
          "\n" +
          "Endereço: " +
          como_candidatar.desc;
      }
      if (this.method == this.formaList[1]) {
        conteudo =
          this.vaga.conteudo +
          "\n" +
          "Processo de candidatura" +
          "\n" +
          "Através do website " +
          como_candidatar.desc;
      }
      if (this.method == this.formaList[2]) {
        conteudo =
          this.vaga.conteudo +
          "\n" +
          "Processo de candidatura" +
          "\n" +
          "Envie um email para " +
          como_candidatar.desc;
      }
      if (como_candidatar.dica) {
        conteudo = conteudo + "\n" + "Dica" + "\n" + como_candidatar.dica;
      }

      // console.log(JSON.stringify(como_candidatar));
      // console.log(JSON.parse(JSON.stringify(como_candidatar)))
      //return

      // eslint-disable-next-line no-unreachable
      // let action = this.updating ? "updateVaga" : "storeVaga";

      const formData = new FormData();

      formData.append("id", this.vaga.id);
      formData.append("titulo", this.vaga.titulo);
      formData.append("empresa_id", this.vaga.empresa_id);
      //this.vaga.conteudo
      formData.append("conteudo", conteudo);
      formData.append("conteudo_formatado", this.vaga.conteudo_formatado);
      formData.append("validade", this.vaga.validade);
      formData.append("categoria_id", this.vaga.categoria_id);
      formData.append("local", this.vaga.local);
      formData.append("fonte", this.vaga.fonte);
      formData.append("como_candidatar", JSON.stringify(como_candidatar));
      formData.append("is_recomended", this.vaga.is_recomended);
      formData.append("industria", this.vaga.industria);
      formData.append("candidaturas", this.vaga.candidaturas);

      if (this.image_upload != null) {
        formData.append("image", this.image_upload);
      } else {
        formData.append("image", this.vaga.image);
      }

      if (this.anexo_conteudo_upload != null) {
        formData.append("anexo_conteudo", this.anexo_conteudo_upload);
      } else {
        formData.append("anexo_conteudo", this.vaga.anexo_conteudo);
      }

      this.$store
        .dispatch("storeVaga", formData)
        .then(() => {
          Swal.fire({
            title: "Sucesso!",
            text: "Submetido com sucesso.",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#FF1744",
          }).then(() => {
            this.$router.push({ name: "recruiter.vagas" });
          });
        });
    },
    onFileChange(e) {
      this.image_upload = e;
    },

    onAnexoConteudoFileChange(e) {
      this.anexo_conteudo_upload = e;
    },
    clean() {
      this.vaga.titulo = "";
      this.vaga.instituicao = "";
      this.vaga.validade = "";
      this.vaga.local = "";
      this.vaga.fonte = "";
      this.vaga.conteudo = "";
      this.$refs.observer.reset();
    },
  },
  computed: {
    actionSubmit() {
      return this.updating ? "Actualizar" : "Salvar";
    },
    empresas() {
      return this.$store.state.minhasempresas;
    },

    // eslint-disable-next-line vue/return-in-computed-property
    imageUploadPreview() {
      if (this.image_upload) {
        return URL.createObjectURL(this.image_upload);
      } else if (this.vaga.image) {
        return this.vaga.image;
      }
      return;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    anexoConteudoUploadPreview() {
      if (this.anexo_conteudo_upload) {
        return URL.createObjectURL(this.anexo_conteudo_upload);
      } else if (this.vaga.anexo_conteudo) {
        return this.vaga.anexo_conteudo;
      }
      return;
    },
  },
  watch: {
    method(method) {
      //'Entregar candidatura no endereço físico',
      //	'Aceder o link',
      //	'Enviar candidatura por email'
      if (method == this.formaList[0]) {
        this.vaga.candidaturas = false
        this.candidatar_label = "Endereço físico";
        this.rule = "required";
      }
      if (method == this.formaList[1]) {
        this.vaga.candidaturas = false
        this.candidatar_label = "Link da candidatura";
        this.rule = "required|url";
      }
      if (method == this.formaList[2]) {
        this.vaga.candidaturas = false
        this.candidatar_label = "Email para enviar a candidatura";
        this.rule = "required|email";
      }
      if (method == this.formaList[3]) {
        this.vaga.candidaturas = true
      }
      
    },
    vaga(vaga) {
      if (vaga.como_candidatar) {
        console.log(vaga.como_candidatar);
        this.method = vaga.como_candidatar.method;
        this.desc = vaga.como_candidatar.desc;
        this.dica = vaga.como_candidatar.dica;
      }
    },
  },
};
</script>

<style>
.inputFile {
  cursor: pointer;
}
</style>