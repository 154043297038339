<template>
  <v-container class="mt-8 " :class="isMobile ? 'mb-0' : 'mb-8'">
    <v-container :class="isMobile ? 'pl-12' : 'pl-0'">
      <v-btn
        elevation="2"
        class="no-uppercase"
        small
        dark
        @click="goToperfiDash()"
      >
        <v-icon style="padding-right: 14px" small> mdi-arrow-left </v-icon>
        Voltar
      </v-btn>
    </v-container>
    <div style="text-align: center; margin-top: 20px">
      <div>
        <p class="text-center">
          Clique no icone/imagem abaixo para actualizar a foto de perfil
        </p>
        <v-row wrap v-if="imageUploadPreview">
          <v-col cols="12" md="12" class="text-center">
            <label for="file" class="inputFile">
              <v-avatar size="150">
                <img :src="imageUploadPreview" alt="" />
              </v-avatar>
            </label>
          </v-col>
        </v-row>
        <v-row wrap v-else>
          <v-col cols="12" md="12" class="text-center">
            <label for="file" class="inputFile">
              <v-avatar size="150">
                <img src="../../assets/img/user-png.png" alt="" />
              </v-avatar>
            </label>
          </v-col>
        </v-row>

        <v-row wrap>
          <v-col cols="12" md="12">
            <v-file-input
              ref="image_upload"
              dense
              truncate-length="1"
              label="Carregar Imagem"
              accept="image"
              @change="onFileChange"
              id="file"
              v-show="hidden"
            ></v-file-input>
          </v-col>
        </v-row>
        <!-- <v-list-item-avatar size="150">
          <img v-if="getCandidate.logo" :src="getCandidate.logo" />
          <v-icon v-else size="141"> mdi-account-edit-outline </v-icon>
        </v-list-item-avatar> -->
      </div>
      <!-- Formulario -->
      <div wrap style="margin-top: 20px">
        <v-form>
          <v-container>
            <!-- Nome -->
            <v-text-field color="black" type="text" v-model="recruiter_name">
              <template v-slot:label style="padding-left: 50px">
                <v-icon style="vertical-align: middle; margin-right: 6px">
                  mdi-account-outline
                </v-icon>
                <strong>Nome </strong>
              </template>
            </v-text-field>
            <!-- Numero -->
            <!-- <v-text-field
              color="black"
              readonly
              maxlength="10"
              :value="getCandidate.number"
            >
              <template v-slot:label style="padding-left: 50px">
                <v-icon style="vertical-align: middle; margin-right: 6px">
                  mdi-phone
                </v-icon>
                <strong>Numero </strong>
              </template>
            </v-text-field> -->

            <!-- Email -->

            <v-text-field v-slot:label :readonly="getCandidate.email !== 'empty' " type="email" :value="getCandidate.email !== 'empty' ? getCandidate.email : ''">
              <template style="padding-left: 50px">
                <v-icon style="vertical-align: middle; margin-right: 6px">
                  mdi-email-outline
                </v-icon>
                <strong>Email </strong>
              </template>
            </v-text-field>
            <v-text-field v-slot:label :readonly="getCandidate.contacto" type="email" :value="getCandidate.contacto">
              <template style="padding-left: 50px">
                <v-icon style="vertical-align: middle; margin-right: 6px">
                  mdi-phone-outline
                </v-icon>
                <strong>Contacto </strong>
              </template>
            </v-text-field>
          </v-container>
          <!-- Botao -->

          <v-container
            class="mx-auto"
            style="text-align: right; margin-top: 20px"
          >
            <v-btn
              elevation="2"
              class="no-uppercase"
              style="margin: 4px; color: white"
              @click="updateRecruiter()"
            >
              <v-icon style="padding-right: 14px"> mdi-refresh </v-icon>
              Actualizar
            </v-btn>
          </v-container>
        </v-form>
      </div>
    </div>
  </v-container>
</template>
<script>
import Swal from "sweetalert2";
export default {
  name: "FormPerfil",
  data() {
    return {
      recruiter_name: "",
      recruiter_email: "",
      image_upload: null,
      recruiter_logo: "",
      hidden: false,
    };
  },
  methods: {
    onFileChange(e) {
      this.image_upload = e;
    },
    goToperfiDash() {
      this.$router.push({ name: "perfil.dash" });
    },
    updateRecruiter() {
      const formData = new FormData();

      formData.append("name", this.recruiter_name);
      formData.append("email", this.recruiter_email);
      if (this.image_upload !== null) {
        formData.append("logo", this.image_upload);
      }

      this.$store
        .dispatch("updateRecruiter", formData)
        .then(() => {
          Swal.fire({
            title: "Sucesso!",
            text: "Informações pessoais actualizado!",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#FF4500",
          });
        })
        .catch((error) => {
          console.log("erro ao logar");
        })
        .finally("Finalizou no componet");
    },
  },
  computed: {
    getCandidate() {
      let candidate = this.$store.state.candidate;
      this.recruiter_name = candidate.name;
      this.recruiter_email = candidate.email;
      this.recruiter_logo = candidate.logo;
      return candidate;
    },
    imageUploadPreview() {
      if (this.image_upload) {
        return URL.createObjectURL(this.image_upload);
      } else if (this.recruiter_logo) {
        return this.recruiter_logo;
      }
      return;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
};
</script>

<style>
.no-uppercase {
  text-transform: unset !important;
  background-color: #f66459 !important ;
}
</style>