const firebaseConfig = {
  apiKey: "AIzaSyCZ7vQkBopnKYpqTCudLIatbJV1rcT9P0k",
  authDomain: "guglamz.firebaseapp.com",
  databaseURL: "https://guglamz.firebaseio.com",
  projectId: "guglamz",
  storageBucket: "guglamz.appspot.com",
  messagingSenderId: "752126581328",
  appId: "1:752126581328:web:d3116dbf2ab1aa17388f55",
  measurementId: "G-PFL2KZB38B"
};


export default firebaseConfig;