<template>
    <v-container class="mt-8" style="margin-bottom: 50px;">
        <h1 class="text-center">Convidar amigos</h1>
        <v-container>
            <p class="text-center">Convide amigos ou outras empresas para usar o Gugla Empresas e ganhe uma subscrição
                grátis! É fácil e simples.</p>
            <h2 style="color: #363232" :style="isMobile ? { 'margin-bottom': '-30px' } : {}">Como funciona?</h2>
            <v-container class="mx-auto"
                :style="isMobile ? { 'display': 'flex', 'flex-direction': 'row' } : { 'display': 'flex', 'flex-direction': 'column' }">
                <v-card v-for="(Passo, i) in Passos" :key="i" max-width="300" outlined dark
                    style="background-color: #FF450033; margin: 2%;">
                    <v-card-title style="color: #FF4500;">
                        {{ Passo.numero }}º Passo
                    </v-card-title>
                    <v-card-subtitle style="color: #363232;">
                        {{ Passo.descricao }}
                    </v-card-subtitle>
                </v-card>
            </v-container>

        </v-container>

        <v-container
            :style="isMobile ? { 'display': 'flex', 'flex-direction': 'row' } : { 'display': 'flex', 'flex-direction': 'column' }">
            <h4 class="mr-4">O Seu Link Exclusivo</h4>
            <!-- <v-col  cols="12" >
                        <v-text-field
                            value="https://empresas.gugla.co.mz/contents/18102/show"
                            outlined
                            readonly
                            md="6"
                            :style="isMobile ? {'margin-top': '-30px',}:{}"
                          >
                        
                        </v-text-field>
                    </v-col> -->
            <v-text-field :value="`https://empresas.gugla.co.mz/recruiter/register-invite/${user.id}`" outlined readonly
                :style="isMobile ? { 'margin-top': '-15px', 'max-width': '800px' } : {}">
                <template #append>
                    <v-btn style="text-transform: unset !important; margin-top: -6px;" color="secondary" type=""
                        value="copiar link" @click="
                            copyUrl(
                                `https://empresas.gugla.co.mz/recruiter/register-invite/${user.id}`
                            )
                        ">
                        Copiar Link
                    </v-btn>
                </template>

            </v-text-field>

            <div class="text-center" :style="isMobile ? { 'margin-top': '-30px' } : {}">
                <v-container style="color: #FF4500;"
                    :style="isMobile ? { 'display': 'flex', 'flex-direction': 'row', 'margin-left': '20px' } : { 'display': 'flex', 'flex-direction': 'row', 'margin-left': '12px' }">
                    <div>
                        <ShareNetwork network="whatsapp"
                            :url="`https://empresas.gugla.co.mz/recruiter/register-invite/${user.id}`"
                            :title="`Link de Convite`" hashtags="empresas,concursos,gugla,guglaempresas">

                            <v-btn icon>
                                <v-icon large dark color="green">
                                    mdi-whatsapp
                                </v-icon></v-btn>
                        </ShareNetwork>
                        <p>Whatsapp</p>
                    </div>
                    <v-divider class="mx-4" vertical>
                    </v-divider>
                    <div>
                        <ShareNetwork network="facebook"
                            :url="`https://empresas.gugla.co.mz/recruiter/register-invite/${user.id}`"
                            :title="`Link de Convite`" hashtags="empresas,concursos,gugla,guglaempresas">

                            <v-btn icon>
                                <v-icon large dark color="#4267B2">
                                    mdi-facebook
                                </v-icon></v-btn>
                        </ShareNetwork>
                        <p>Facebook</p>
                    </div>
                    <v-divider class="mx-4" vertical>
                    </v-divider>

                    <div>
                        <ShareNetwork network="linkedin"
                            :url="`https://empresas.gugla.co.mz/recruiter/register-invite/${user.id}`"
                            :title="`Link de Convite`" hashtags="empresas,concursos,gugla,guglaempresas">

                            <v-btn icon>
                                <v-icon large dark color="#0072B1">
                                    mdi-linkedin
                                </v-icon></v-btn>
                        </ShareNetwork>
                        <p>Linkedin</p>
                    </div>



                </v-container>
            </div>
        </v-container>

        <!--    <div class="text-center">
                  
                    <v-container elevation="2" style="color: #FF4500;" >
                    <v-icon :elevation="2"  style="margin-inline: 1%;color: #FF4500;" >mdi-whatsapp</v-icon>
                    <v-divider
                      class="mx-4"
                      vertical
                      style="color: black;"
                    ></v-divider>
                    <v-icon :elevation="2"  style="margin-inline: 1%;color: #FF4500;">mdi-facebook</v-icon>
                    <v-icon :elevation="2"  style="margin-inline: 1%;color: #FF4500;">mdi-linkedin</v-icon>
                    </v-container>
                   </div>
                    -->



    </v-container>
</template>
<script>

import Swal from "sweetalert2";
export default {
    data() {
        return {
            Passos: [
                {
                    numero: '1',
                    descricao: 'Partilhe o seu link exclusivo com amigos ou outras empresas, clicando os ícones abaixo'
                },
                {
                    numero: '2',
                    descricao: 'Os seus convidados poderão subscrever-se ao Gugla Empresas. Automaticamente você irá ganhar uma subscrição grátis'
                },
                {
                    numero: '3',
                    descricao: 'Pode partilhar o seu link exclusivo com quantas pessoas quiser. Quanto mais convidados tiver maior será a sua recompensa'
                },


            ]
        }
    },
    created() {
        var urlAtual = window.location.href;
        let token = localStorage.getItem("TOKEN_AUTH")

        if(!token){
            sessionStorage.setItem("url", urlAtual)
            this.$router.push({name: 'candidate.login'})
        }
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndUp ? true : false;
        },
        user() {
            let recruiter = this.$store.state.candidate;
            return recruiter;
        }

    },
    methods: {
        async copyUrl(url) {
            try {
                await navigator.clipboard.writeText(url);
                Swal.fire({
                    title: "Link copiado!",
                    showConfirmButton: false,
                    timer: 1000,
                });
            } catch (error) {
                console.log("cannot copy", error);
            }
        },


    },
}
</script>

<style scoped>
p {
    color: black;
}
</style>