/* eslint-disable vue/no-unused-components */
<template>
  <v-row wrap>
  <!-- <div :class="!isMobile ? 'home-mobile' : 'home'">
      <v-btn v-if="!isMobile" tile color="#FF4500" text dark @click="home">
        <v-icon v-if="!isMobile" size="27px" left> mdi-home </v-icon>
        voltar
      </v-btn>
      <v-btn v-else tile color="#FF4500" text dark @click="home">
        <v-icon large left> mdi-home </v-icon>
        Voltar ao gugla
      </v-btn>
          </div> -->
    <v-col cols="6" v-if="$vuetify.breakpoint.smAndUp" class="register-page">
      <v-container fill-height style="heigth: 100vh">
        <v-row wrap justify="center" align="center">
          <v-col cols="6">
            <v-row wrap>
            <!-- <v-col cols="12">
                <v-img
                  :src="require('../../assets/img/Logo-Empresas-830x354.png')"
                  max-width="150"
                  max-height="150"
                  alt="Gugla Empresas"
                >
                </v-img>
                <p>
                  Bem Vindo a plataforma das empresas de Moçambique Aqui poderá
                  encontrar:
                </p>
				<v-list-item>
                    <v-list-item-icon>
                      <v-icon color="accent"> mdi-check-circle-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        Concursos para prestadores de Serviços e Fornecedores de
                        Bens anunciados por empresas publicas e privadas;
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="accent"> mdi-check-circle-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        A lista de Prestadores de Serviços e Fornecedores de
                        Bens;
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="accent"> mdi-check-circle-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        Um espaço para registar a sua empresa e promover os seus
                        produtos e serviços;
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="accent"> mdi-check-circle-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        Soluções digitais para a procura e seleção de Recursos
                        Humanos.
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                    </v-col> -->
            </v-row>

          <!-- <v-row wrap>
              <v-col cols="12">
                <p class="font-weight-bold" style="padding: 10px">
                  Em casos de dificuldade contacte:
                </p>
                <p style="padding: 10px">
                  <v-icon color="#FF4500"> mdi-email </v-icon>
                  comercial@marrar.co.mz
                </p>
                <p style="padding: 10px">
                  <v-icon color="#FF4500"> mdi-email </v-icon>
                  suporte@marrar.co.mz
                </p>
              </v-col>
                  </v-row> -->
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <v-col :cols="isMobile ? '6' : '12'" style="background-color: #f0f2f5">
      <v-container fill-height style="height: 100vh">
        <v-row wrap justify="center" align="center">
          <v-col cols="12" md="6">
            <v-container fill-height fluid>
              <v-row wrap justify="center" align="center">
                <v-col>
                <!-- <validation-observer ref="autoRegisterForm" v-slot="{}">
                    <v-form @submit.prevent="autoRegister">
                      <v-card>
                        <v-card-title style="background-color: #ff4500">
                          <v-row justify="center">
                            <span class="text1">Registe-se</span>
                          </v-row>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Nome"
                            rules="required"
                          >
                            <v-text-field
                              v-model="candidate.name"
                              label="Nome"
                              :error-messages="errors"
                            />
                          </validation-provider>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Sobrenome"
                            rules="required"
                          >
                            <v-text-field
                              v-model="candidate.last_name"
                              label="Sobrenome"
                              :error-messages="errors"
                            />
                          </validation-provider>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Email"
                            rules="required|email"
                          >
                            <v-text-field
                              v-model="candidate.email"
                              label="Email"
                              :error-messages="errors"
                            />
                          </validation-provider>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Senha"
                            rules="required|strong_password"
                          >
                            <v-text-field
                              v-model="candidate.password"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="[rules.required, rules.min]"
                              :type="show1 ? 'text' : 'password'"
                              name="input-10-1"
                              label="Password"
                              hint="Mínimo de 7 caracteres"
                              counter
                              :error-messages="errors"
                              @click:append="show1 = !show1"
                            ></v-text-field>
                          </validation-provider>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Confirmar Senha"
                            rules="required|confirmed:Senha|strong_password"
                          >
                            <v-text-field
                              v-model="candidate.password_confirmation"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="[rules.required, rules.min]"
                              :type="show1 ? 'text' : 'password'"
                              name="confirm-new-password"
                              label="Confirmar Senha"
                              hint="Mínimo de 7 characters"
                              counter
                              :error-messages="errors"
                              @click:append="show1 = !show1"
                            ></v-text-field>
                          </validation-provider>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            type="submit"
                            small
                            elevation="4"
                            dark
                            color="#FF4500"
                            >Registar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                        </validation-observer> -->
                  <validation-observer ref="autoRegisterForm" v-slot="{}">
                    <v-form @submit.prevent="autoRegister">
                      <v-card elevation="2" max-width="470" :disabled="disabled">
                        <v-progress-linear v-show="loading" height="4" indeterminate color="#FF5414"></v-progress-linear>
                        <v-card-title>
                          <v-img :src="
                            require('../../assets/img/Logo-Empresas-830x354.png')
                          " max-width="180" max-height="180" alt="Gugla Empresas"
                            style="display: block; margin-left: auto; margin-right: auto" />
                        </v-card-title>
                        <v-card-subtitle style="margin-bottom: 0px; margin-top: 0px">
                          <h2 class="text-center" style="color: #FF5414;">Crie a sua Conta</h2>
                        </v-card-subtitle>
                        <v-card-text>
                          <validation-provider v-slot="{ errors }" name="Nome" rules="required">
                            <v-text-field outlined v-model="candidate.name" label="Nome" :error-messages="errors"
                              color="#FF4500" />
                          </validation-provider>
                          <validation-provider v-slot="{ errors }" name="Sobrenome" rules="required">
                            <v-text-field outlined v-model="candidate.last_name" label="Sobrenome"
                              :error-messages="errors" color="#FF4500" />
                          </validation-provider>
                          <validation-provider v-slot="{ errors }" name="E-mail ou Telemovel" rules="required">
                            <v-text-field outlined v-model="candidate.email_telefone" label="E-mail ou Telemovel"
                              :error-messages="errors" color="#FF4500" />
                          </validation-provider>
                          <validation-provider v-slot="{ errors }" name="Senha" rules="required|strong_password">
                            <v-text-field outlined v-model="candidate.password"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]"
                              :type="show1 ? 'text' : 'password'" name="input-10-1" label="Senha"
                              hint="Mínimo de 7 caracteres" counter :error-messages="errors"
                              @click:append="show1 = !show1" color="#FF4500"></v-text-field>
                          </validation-provider>
                          <validation-provider v-slot="{ errors }" name="Confirmar Senha"
                            rules="required|confirmed:Senha|strong_password">
                            <v-text-field outlined v-model="candidate.password_confirmation"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]"
                              :type="show1 ? 'text' : 'password'" name="confirm-new-password" label="Confirmar Senha"
                              hint="Mínimo de 7 characters" counter :error-messages="errors"
                              @click:append="show1 = !show1" color="#FF4500"></v-text-field>
                          </validation-provider>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn type="submit" small elevation="4" dark color="#FF4500">Registar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </validation-observer>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <loader />
  </v-row>
</template>

<script>
import Loader from "../loader/Loader.vue";
import Swal from "sweetalert2";
import Footer from '../footer/footer.vue'
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from "vee-validate";
import {
  required,
  digits,
  email,
  confirmed,
  max,
  regex,
} from "vee-validate/dist/rules";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} não pode estar vazio!",
});

extend("digits", {
  ...digits,
  message: "{_field_} deve conter {length} dígitos. ({_value_})!",
});

extend("email", {
  ...email,
  message: "O email deve ser válido!",
});
extend("confirmed", {
  ...confirmed,
  message: "O valor para o campo {_field_} não coincide!",
});

extend("strong_password", {
  message: (field) =>
    "A " +
    field +
    " deve conter pelo menos 1 caracter minúsculo, 1 caracter maiúsculo, 1 dígito e um cumprimento não inferior a 7!",
  validate: (value) => {
    // let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/); // Com caracteres especiais
    let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/);
    return pattern.test(value);
  },
});
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Loader,
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    Footer
  },
  data() {
    return {
      disabled: false,
      loading: false,
      candidate: {
        name: "",
        last_name: "",
        email: "",
        password: "",
        user_id: this.$route.params.id
      },
      password_confirmation: "",
      confirm_password: "",
      show1: false,
      rules: {
        required: (value) => !!value || "Obrigatório.",
        min: (v) => v.length >= 7 || "Mínimo de 7 caracteres",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  created() {
    var linkTag = document.createElement("link");
    linkTag.setAttribute("rel", "canonical");
    linkTag.setAttribute(
      "href",
      `https://empresas.gugla.co.mz/candidate/register`
    );
    document.head.appendChild(linkTag);
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
  methods: {
    isNumber(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
    login() {
      this.$router.push({ name: "candidate.login" });
    },
    async autoRegister() {
      const isValidAutoRegisterForm =
        await this.$refs.autoRegisterForm.validate();

      if (!isValidAutoRegisterForm) {
        Swal.fire({
          title: "Alerta de Validação!",
          html: `Verifque o preenchimento dos campos antes de enviar o formulário.`,
          icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#FF4500",
        });

        return;
      }
      let recruiter = {
        name: this.candidate.name + this.candidate.last_name,
        password: this.candidate.password,
        password_confirmation: this.candidate.password_confirmation,
        user_id: this.$route.params.id
      }

      if (this.isNumber(this.candidate.email_telefone) == true) {
        recruiter['contacto'] = '+258' + this.candidate.email_telefone
      } else {
        recruiter['email'] = this.candidate.email_telefone
      }

      // this.$store.dispatch("loaderChange", true);
      this.disabled = true
      this.loading = true
      this.$store
        .dispatch("register", recruiter)
        .then((response) => {
          // this.$router.push({
          //   name: "site.steps_after_register",
          //   params: { success: 1, email: this.candidate.email },
          // });
          if (this.isNumber(this.candidate.email_telefone) == true) {
            this.$router.push({ name: 'recruiter.register.otp', params: { telefone: this.candidate.email_telefone } })
          } else {
            this.$router.push({ name: 'recruiter.verify.email.otp', params: { emailverify: this.candidate.email_telefone } })
          }
          // this.$store.dispatch("loaderChange", false);
          this.disabled = false
          this.loading = false
        })
        .catch(() => {
          this.disabled = false
          this.loading = false
        });
    },
    home() {
      this.$router.push({ name: "site.contents" });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

.login {
  color: cornflowerblue;
}

.login:hover {
  cursor: pointer;
}

.red-gugla {
  color: #ff1744;
}

.basil {
  background-color: #fffbe6 !important;
}

.basil--text {
  color: #356859 !important;
}

.shadow-left {
  box-shadow: 20px 20px 20px 20px #888888;
  /* for all browsers */
  -moz-box-shadow: 20px 20px 20px 20px #888888;
  /* for old firefox */
  -webkit-box-shadow: 20px 20px 20px 20px #888888;
  /* override for Chrome & Safari */
}

.text1 {
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  padding: 20px;
}

.home {
  position: absolute;
  top: 30px;
  left: 30px;
}

.home-mobile {
  position: absolute;
  top: 15px;
  left: 15px;
}

.mobile {
  /* padding-top: 60px; */
  /* background-color: red; */
  /* style="background-color: #F0F2F5; padding-top: 20%;" */
  /* padding-top: 50%; */
  margin-top: 70%;
  background-color: #F0F2F5;
}

.register-page {
  background-image: url("../../assets/invite2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #E8B4A2;
}
</style>