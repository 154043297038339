<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-row wrap class="mb-5">
        <v-col cols="12" md="10">
          <p class="text-subtitle-1 text-md-h3">
            {{ content.titulo }}
          </p>
          <v-divider class="my-3"></v-divider>
          <div
            v-if="$vuetify.breakpoint.smAndDown && showdescription"
            class="space-betwen"
          >
            <p class="text-subtitle-2 text-md-h6">
              <b>Detalhes:</b>
            </p>
            <table>
              <tr>
                <td>Instituição:</td>
                <td>
                  {{ content.instituicao }}
                </td>
              </tr>
              <tr>
                <td>Local:</td>
                <td v-if="content.local">
                  <span>
                    {{ content.local }}
                  </span>
                </td>
                <td v-else>
                  <span v-for="local in content.locals" :key="local.id">
                    {{ local.name }}
                  </span>
                </td>
              </tr>
              <tr v-if="content.industria">
                <td>Categoria:</td>
                <td>
                  {{ content.industria }}
                </td>
              </tr>
              <tr>
                <td>Publicado:</td>
                <td>
                  {{ content.created_at }}
                </td>
              </tr>
              <tr
                :class="[
                  expired ? 'left-border-expired' : 'left-border-active',
                ]"
              >
                <td>Validade:</td>
                <td>
                  {{ content.validade | formatDate }}
                </td>
              </tr>
            </table>
            <v-divider class="my-3"></v-divider>
            <!-- <div>
              <v-btn
                v-if="content.candidaturas == true"
                color="#FF5252"
                class="white--text"
                small
                rounded
                @click="goToCandidaturas(content)"
                block
              >
                Ver Candidaturas
                <v-icon right dark> mdi-account-group </v-icon>
              </v-btn>
            </div> -->
          </div>
        </v-col>

        <v-col cols="12" md="2">
          <div>
            <v-btn
              v-if="!showdescription"
              color="#FF5252"
              class="white--text"
              small
              block
              @click="showdescription = true"
            >
              Ver Descrição
            </v-btn>
          </div>
          <div>
            <v-btn
              v-if="showdescription"
              color="#FF5252"
              class="white--text"
              small
              block
              @click="showdescription = false"
            >
              Ocultar Descrição
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row wrap v-if="showdescription">
        <v-col md="9" cols="12">
          <v-img v-if="content.anexo_conteudo">
            <img :src="content.anexo_conteudo" class="anexo" alt="" />
          </v-img>
          <div
            v-else
            class="text-pre-wrap"
            v-html="
              content.conteudo_formatado
                ? content.conteudo_formatado
                : content.conteudo
            "
          ></div>
        </v-col>

        <v-col md="3" cols="12" class="hidden-xs-only">
          <div class="sticky-top">
            <p class="text-subtitle-2 text-md-h6">Detalhes</p>
            <v-divider class="my-3"></v-divider>
            <div class="" style="margin-left: 15px">
              <v-avatar size="80" height="auto" v-if="content.image" rounded>
                <img :src="content.image" alt="" />
              </v-avatar>
            </div>
            <table>
              <tr>
                <td>Instituição:</td>
                <td>
                  {{ content.instituicao }}
                </td>
              </tr>
              <tr>
                <td>Local:</td>
                <td v-if="content.local">
                  <span>
                    {{ content.local }}
                  </span>
                </td>
                <td v-else>
                  <span v-for="local in content.locals" :key="local.id">
                    {{ local.name }}
                  </span>
                </td>
              </tr>
              <tr v-if="content.industria">
                <td>Categoria:</td>
                <td>
                  {{ content.industria }}
                </td>
              </tr>
              <tr>
                <td>Publicado:</td>
                <td>
                  {{ content.created_at }}
                </td>
              </tr>
              <tr
                :class="[
                  expired ? 'left-border-expired' : 'left-border-active',
                ]"
              >
                <td>Validade:</td>
                <td>
                  {{ content.validade | formatDate }}
                </td>
              </tr>
            </table>
            <v-divider class="my-3"></v-divider>
            <v-row justify="center"> </v-row>
          </div>
        </v-col>
      </v-row>

      <v-row wrap justify="space-between">
        <v-col md="12" cols="12" v-show="content.candidaturas == true">
          <h4
            style="text-transform: uppercase; font-size: 20px; color: #FF5252"
          >
            Candidaturas da Vaga
          </h4>
          <v-divider class="my-3"></v-divider>
        </v-col>
        <v-col
          cols="12"
          md="12"
          class="text-center"
          v-if="content.candidates.length == 0"
        >
          <v-row wrap justify="center">
            <v-col cols="12" sm="6" md="6">
              <div>
                <img
                  height="200x"
                  width="200x"
                  src="../../../assets/ui/cd.png"
                />
              </div>
            </v-col>
          </v-row>
          <p
            style="
              font-weight: 900;
              text-transform: capitalize;
              font-size: 20px;
            "
          >
            Ainda não possuí candidados nessa vaga
          </p>
          <v-row wrap justify="center">
            <v-col cols="12" sm="6" md="6">
              <div style="background-color: #fdece5; border: 1px solid #ff4500">
                <p style="padding: 10px">
                  <!-- <span style="font-weight: 900; width: 300px"
                    >Marque Candidatos</span
                  > -->
                  Veja nessa secção os candidatos da sua vaga, assim que
                  candidatarem-se.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          md="6"
          cols="12"
          v-for="candidate in content.candidates"
          :key="candidate.id"
        >
          <v-card
            class="mx-auto d-flex flex-column"
            outlined
            elevation="1"
            min-height="100"
            @click="goToProfile(candidate)"
          >
            <v-list-item
              :to="detailsRoute"
              class="left-border-expired"
              v-if="candidate.pivot.avaliation == 0"
            >
              <v-list-item-content three-line>
                <p class="text-subtitle-2 text-sm-h6 mb-1">
                  {{ candidate.name }} {{ candidate.last_name }}
                </p>
                <v-list-item-subtitle v-if="candidate.personal_data != null">
                  {{ candidate.personal_data.profissao }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span v-if="candidate.profissional_experience.length == 0">
                    Sem Experiência</span
                  >
                  <span v-if="candidate.profissional_experience.length == 1">
                    Possui Experiência</span
                  >
                  <span v-if="candidate.profissional_experience.length > 1">
                    Possui mais de 1 Experiência</span
                  >
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="candidate.endereco">
                  {{ candidate.endereco }}, Moçambique
                </v-list-item-subtitle>
                <v-list-item-subtitle> </v-list-item-subtitle>
              </v-list-item-content>
              <v-avatar size="60" color="white">
                <img
                  v-if="candidate.personal_data != null"
                  :src="candidate.personal_data.image"
                  alt=""
                />
                <img
                  v-else
                  :src="require('../../../assets/user-png.png')"
                  alt=""
                />
              </v-avatar>
            </v-list-item>
            <v-list-item
              :to="detailsRoute"
              class="left-border-active"
              v-if="candidate.pivot.avaliation == 1"
            >
              <v-list-item-content three-line>
                <p class="text-subtitle-2 text-sm-h6 mb-1">
                  {{ candidate.name }} {{ candidate.last_name }}
                </p>
                <v-list-item-subtitle v-if="candidate.personal_data != null">
                  {{ candidate.personal_data.profissao }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span v-if="candidate.profissional_experience.length == 0">
                    Sem Experiência</span
                  >
                  <span v-if="candidate.profissional_experience.length == 1">
                    Possui Experiência</span
                  >
                  <span v-if="candidate.profissional_experience.length > 1">
                    Possui mais de 1 Experiência</span
                  >
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="candidate.endereco">
                  {{ candidate.endereco }}, Moçambique
                </v-list-item-subtitle>
                <v-list-item-subtitle> </v-list-item-subtitle>
              </v-list-item-content>
              <v-avatar size="60" color="white">
                <img
                  v-if="candidate.personal_data != null"
                  :src="candidate.personal_data.image"
                  alt=""
                />
                <img
                  v-else
                  :src="require('../../../assets/user-png.png')"
                  alt=""
                />
              </v-avatar>
            </v-list-item>
            <v-list-item
              :to="detailsRoute"
              v-if="candidate.pivot.avaliation == null"
            >
              <v-list-item-content three-line>
                <p class="text-subtitle-2 text-sm-h6 mb-1">
                  {{ candidate.name }} {{ candidate.last_name }}
                </p>
                <v-list-item-subtitle v-if="candidate.personal_data != null">
                  {{ candidate.personal_data.profissao }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span v-if="candidate.profissional_experience.length == 0">
                    Sem Experiência</span
                  >
                  <span v-if="candidate.profissional_experience.length == 1">
                    Possui Experiência</span
                  >
                  <span v-if="candidate.profissional_experience.length > 1">
                    Possui mais de 1 Experiência</span
                  >
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="candidate.endereco">
                  {{ candidate.endereco }}, Moçambique
                </v-list-item-subtitle>
                <v-list-item-subtitle> </v-list-item-subtitle>
              </v-list-item-content>
              <v-avatar size="60" color="white">
                <img
                  v-if="candidate.personal_data != null"
                  :src="candidate.personal_data.image"
                  alt=""
                />
                <img
                  v-else
                  :src="require('../../../assets/user-png.png')"
                  alt=""
                />
              </v-avatar>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      dialog: false,
      marcar_btn: false,
      showdescription: false,
    };
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },

  created() {
    let token = localStorage.getItem("TOKEN_AUTH");
    if (!token) {
      this.marcar_btn = true;
    }
  },

  computed: {
    expired() {
      let today = new Date();
      let deadlineDate = new Date(this.content.validade);

      return today > deadlineDate ? true : false;
    },
    user() {
      let user = this.$store.state.auth.user;
      this.newsletter.receive_newsletter = user.receive_newsletter;
      return user;
    },
    loginDialog() {
      return this.$store.getters.loginDialog;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },

  methods: {
    goToProfile(candidate) {
      localStorage.setItem("id_content", this.content.id);
      localStorage.setItem("titulo", this.content.titulo);
      this.$router.push({
        name: "recruiter.minhasCandidaturas.show",
        params: { id: candidate.id },
      });
    },
    getContent() {
      this.$store
        .dispatch("content_site/getContentWithAuth", this.content.id)
        .then((response) => {
          this.content = response.data;
        });
    },
    goToCandidaturas(content) {
      this.$router.push({
        name: "recruiter.minhasCandidaturas",
        params: { id: content.id },
      });
      // console.log(content.id)
    },
  },

  filters: {
    formatDate: (date) => {
      if (date) {
        let newDate = new Date(date);
        let newFormatedDate =
          newDate.getDate() +
          "-" +
          (newDate.getMonth() + 1) +
          "-" +
          newDate.getFullYear();
        return newFormatedDate;
      }

      return "";
    },
  },
};
</script>

<style scoped>
.anexo {
  width: 100%;
}
.sticky-top {
  position: sticky;
  top: 70px;
}
table,
tr,
td {
  border: none;
  font-size: 13px;
}
td {
  padding: 0 10px 0 10px;
}

.left-border-expired {
  /* red accent-3 */
  border-left: 4px solid #ff1744 !important;
}

.bottom-border-active {
  /* red accent-3 */
  border-bottom: 4px solid #ff1744 !important;
}

.left-border-active {
  /* light-green accent-4 */
  border-left: 4px solid #64dd17 !important;
  /*  */
}
.left-border-expired {
  /* red accent-3 */
  border-left: 4px solid #ff1744 !important;
}

.bottom-border-active {
  /* red accent-3 */
  border-bottom: 4px solid #ff1744 !important;
}

.left-border-active {
  /* light-green accent-4 */
  border-left: 4px solid #64dd17 !important;
  /*  */
}

.bottom-border-active {
  border-bottom: 4px solid #64dd17 !important;
}

.login {
  color: cornflowerblue;
}
.login:hover {
  cursor: pointer;
}
.span-style {
  font-size: 16px;
}
.img-pop {
  height: 160px;
}
.text {
  font-size: 21px;
}
.text2 {
  font-size: 16px;
}
.text-mob {
  font-size: 17px;
}
.sub-text {
  font-size: 15px;
}
.sub-text-mob {
  font-size: 14px;
}
.img-mob {
  position: relative;
  right: 40px;
  top: 30px;
  height: 120px;
}
.close {
  position: absolute;
  right: 0;
}
</style>