<template>
    <div style="width: 600px;">
        <v-form>
            <v-text-field
            v-model="contacto"
            label="contacto"
            v-if="cont_view">
            </v-text-field>
    
            <v-text-field
            v-model="code"
            label="otp"
            v-if="otp_view">
            </v-text-field>
    
            <v-btn
                color="success"
                class="mr-4"
                @click="sendOtp">
                send sms
            </v-btn>
            <v-btn
                color="success"
                class="mr-4"
                @click="validate"
                v-if="otp_view">
                verifyotp
            </v-btn>
        </v-form>
        <div id="recaptcha-container"></div>
     
    </div>
    </template>
    
    <script>
    import firebase from 'firebase/app';
    import 'firebase/auth';
    import firebaseConfig from '../../services/firebase'
    import axios from 'axios'
    export default {
        data() {
            return {
                contacto: '',
                cont_view: true,
                code: null,
                otp_view: false,
                firebaseRes: null,
                idToken: ''
            }
        },
        methods: {
            sendOtp() {
                firebase.auth();
                const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible',
                    'callback': function (res) {
                        
                    }
                })
                firebase.auth().signInWithPhoneNumber(this.contacto, appVerifier).then(result => {
                    this.otp_view = true
                    console.log(result);
                    // console.log('sms enviada');
                    this.firebaseRes = result
                })
    
            },
            validate() {
                this.firebaseRes.confirm(this.code).then(res => {
                    // console.log(res);
                    firebase.auth().currentUser.getIdToken(true).then(token => {
                        axios.get(`https://backend.gugla.co.mz/api/recruiters/recruiter/loginFirebase/${token}`).then(res => {
                            console.log(res)
                        })
                    })
                    console.log(this.idToken);
                })
    
            },
            sendMessage() {
              console.log('here....');
                firebase.auth();
                window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                  'size': 'invisible',
                  'callback': function(response) {
                  }
                
                })
                const phoneNumber = "+258845948844"
                const appVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
                    'size': 'invisible',
                    'callback': res => {
                        //
                    }
                });
                firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier).then(confirmationResult => {
                    console.log("ok");
                    console.log(confirmationResult);
                }).catch(err => {
                    console.log(err);
                });
    
                
            }
        }
    }
    </script>
    
    <style>
    
    </style>