<template>
  <!--<v-row wrap align="center" justify="center">
       <v-col sm="11">-->
  <v-container class="mt-8">
    <v-row wrap align="center" justify="end">
      <v-col cols="12" md="6">
        <h4 style="text-transform: uppercase; font-size: 20px; color: #ff5252">
          Base de Dados de Candidatos Buscando Emprego
        </h4>
      </v-col>
      <v-col cols="12" md="6">
        <form class="" @submit.prevent="searchContent">
          <v-form>
            <v-text-field
              ref="search"
              v-model="filter"
              label="Pesquisar candidados por Profissão"
              placeholder="Pesquisar candidados por Profissão"
              append-icon="mdi-briefcase-search"
              @click:append.prevent="searchContent"
              @keypress.enter.prevent="searchContent"
            ></v-text-field>
          </v-form>
        </form>
      </v-col>
    </v-row>
    <v-row v-if="search_alert">
      <v-col cols="12">
        <v-alert
          dense
          text
          type="success"
          border="left"
          v-if="candidates.total > 0"
        >
          <v-row justify="space-between">
            <v-col>
              <span class="caption"
                >{{ candidates.total }} resultados de {{ filter }}.
              </span>
            </v-col>
            <v-col class="text-right">
              <v-btn color="success" small text @click="resetFilteredResults"
                >Limpar <v-icon small>mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-alert>

        <v-alert v-else dense text type="error" border="left">
          <v-row justify="space-between">
            <v-col>
              <span class="caption"
                >{{ candidates.total }} resultados de {{ filter }}.
              </span>
            </v-col>
            <v-col class="text-right">
              <v-btn @click="resetFilteredResults" color="error" small text
                >Limpar <v-icon small>mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <!-- <v-row>

      </v-row> -->
    </v-row>
    <v-row wrap justify="space-between">
      <v-col
        sm="12"
        md="6"
        class="mb-3"
        v-for="candidate in candidates.data"
        :key="candidate.id"
      >
        <candidate-card-component
          :candidate="candidate"
          :detailsRoute="{
            name: 'recruiter.candidate.show',
            params: { id: candidate.id },
          }"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12">
        <v-pagination
          v-model="candidates.current_page"
          :length="candidates.last_page"
          @input="handleChangePage"
          color="error"
          total-visible="7"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
  <!--</v-col>
    </v-row>-->
</template>

<script>
import CandidateCardComponent from "./CandidateCardComponent";
import api from "../../../services/index.js";

export default {
  components: { CandidateCardComponent },
  data() {
    return {
      filter: "",
      search_alert: false,
    };
  },
  computed: {
    candidates() {
      //console.log(this.$store.state.candidate.items)
      return this.$store.state.items;
    },
    params() {
      return {
        profissao: this.filter,
      };
    },
  },
  created() {
    let page = Number(sessionStorage.getItem("page_candidate"));
    let profissao = sessionStorage.getItem("profissao");
    // if (profissao) {
    //   this.$store.dispatch("getCandidates", {
    //     profissao: profissao,
    //     page: page,
    //   });
    // }
    if (profissao) {
      this.search_alert = true;
      this.$store.dispatch("getCandidates", {
        profissao: profissao,
        page: page,
      });
    } else if (page) {
      this.getCandidates(page);
    } else {
      this.getCandidates(1);
    }
  },
  methods: {
    getCandidates(page) {
      this.$store.dispatch("getCandidates", { page }).then((res) => {});
    },
    handleChangePage(page) {
      sessionStorage.setItem("page_candidate", page);
      let profissao = sessionStorage.getItem("profissao");
      // if (profissao) {
      //   this.$store.dispatch("getCandidates", {
      //     profissao: profissao,
      //     page: page,
      //   });
      // }
      if (profissao) {
        this.search_alert = true;
        this.$store.dispatch("getCandidates", {
          profissao: profissao,
          page: page,
        });
      }
      else if (this.filter == "") {
        sessionStorage.setItem("page_candidate", page);
        this.getCandidates(page);
      } else {
        sessionStorage.setItem("profissao", this.filter);
        this.$store.dispatch("getCandidates", {
          profissao: this.filter,
          page: page,
        });
      }
    },
    searchContent() {
      // console.log("ok")
      this.search_alert = true;
      let page = Number(sessionStorage.getItem(page));
      if (page) {
      } else {
        sessionStorage.setItem("profissao", this.filter);
        sessionStorage.setItem("page", 1);
      }
      this.$store.dispatch("getCandidates", {
        profissao: this.filter,
      });
      if (this.filter == "") {
        // sessionStorage.clear();
        sessionStorage.removeItem("profissao");
      }
    },

    resetFilteredResults() {
      this.filter = "";
      this.getCandidates(1);
      this.search_alert = false;
      sessionStorage.clear();
      sessionStorage.setItem("page", 1);
    },
  },
};
</script>

<style>
</style>
