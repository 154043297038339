<template>
  <v-container>
    <v-dialog v-model="dialog" max-width="500">
      <v-card class="mx-auto d-flex flex-column" style="padding-bottom: 0px; padding-top: 50px">
        <div class="meuCard" style="
                      margin: 0;
                      padding-bottom: 0px;
                      overflow-x: hidden;
                      overflow-y: hidden;
                    ">
          <v-row wrap>
            <v-col cols="12" md="12">
              <div class="text-center">
                <img src="../../assets/key-content.png" alt="" height="130" width="130"
                  style="display: block; margin-left: auto; margin-right: auto" />
              </div>
              <div class="textoCabelho">
                <h1 style="font-weight: bold; color: #111" class="text-center">
                  Precisa fazer Login!
                </h1>
                <br />
                <p class="text-center text-body-2 text-md-body-1">
                  Por favor faça o login com a sua conta para <strong>marcar um concurso ou pedido de cotação como
                    favorito</strong>. Caso ainda não tenha uma conta
                  por favor registe-se.
                </p>
              </div>
              <br />
              <v-card-actions>
                <v-row wrap justify="center">
                  <v-col cols="12" md="4">
                    <v-btn block color="#FF4500" dark rounded @click="goToLogin">Login</v-btn>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn block color="#EEEEEE" rounded @click="goToRegisto">Registo</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
              <v-card-actions v-if="buttonCard == true">
                <v-row wrap justify="center">
                  <v-col cols="12" md="6">
                    <v-btn block color="#D8293B" dark rounded @click="goToSubscricao">Efectuar a Subscrição</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-col cols="12">
        <v-row wrap class="mb-5">
          <v-col cols="12" md="10">
            <p class="text-subtitle-1 text-md-h3">
              {{ content.titulo }}
            </p>
            <v-divider class="my-3"></v-divider>
            <div v-if="$vuetify.breakpoint.smAndDown" class="space-betwen">
              <!--<v-avatar size="80" height="auto" v-if="content.image" rounded>
								<img :src="content.image" alt=""/>
							</v-avatar>-->
              <p class="text-subtitle-2 text-md-h6">
                <b>Detalhes:</b>
              </p>
              <table>
                <tr>
                  <td>Instituição:</td>
                  <td>
                    {{ content.instituicao }}
                  </td>
                </tr>
                <tr>
                  <td>Local:</td>
                  <td>
                    <span v-if="content.local">
                      {{ content.local }}
                    </span>
                    <span v-else>
                      <span v-for="local in content.locals" :key="local.id" class="mr-1">
                        {{ local.name }}
                      </span>
                    </span>
                  </td>
                </tr>
                <tr v-if="content.industria">
                  <td>Categoria:</td>
                  <td>
                    {{ content.industria }}
                  </td>
                </tr>
                <tr>
                  <td>Publicado:</td>
                  <td>
                    {{ content.created_at }}
                  </td>
                </tr>
                <tr :class="[
                  expired ? 'left-border-expired' : 'left-border-active',
                ]">
                  <td>Validade:</td>
                  <td>
                    {{ content.validade | formatDate }}
                  </td>
                </tr>
              </table>
              <div v-if="content.candidaturas">
                <p>
                  <v-btn @click="openDialog" v-if="!candidaturas" class="ml-0" tile elevation="4" dark color="red acent-3"
                    small>
                    Enviar candidatura</v-btn>
                  <v-btn v-else class="ml-0" tile elevation="4" dark color="red acent-3" small>Candidatura enviada</v-btn>
                </p>
              </div>
              <v-divider class="my-3"></v-divider>
            </div>
          </v-col>
        </v-row>

        <v-row wrap class="mb-5">
          <v-col md="8" cols="12">
            <v-img v-if="content.anexo_conteudo">
              <img :src="content.anexo_conteudo" class="anexo" alt="" />
            </v-img>
            <div v-else class="text-pre-wrap" v-html="
              content.conteudo_formatado
                ? content.conteudo_formatado
                : content.conteudo
            "></div>
            <div v-if="isLoggedIn">
              <div v-if="active_candidatar" v-html="candidatura"></div>
              <div v-if="active_candidatar" v-html="dica"></div>
              <div v-if="content.candidaturas">
                <p>
                  <v-btn @click="openDialog" v-if="!candidaturas" class="ml-0" tile elevation="4" dark color="red acent-3"
                    small>
                    Enviar candidatura</v-btn>
                  <v-btn v-else class="ml-0" tile elevation="4" dark color="red acent-3" small>Candidatura enviada</v-btn>
                </p>
              </div>
            </div>

            <div>
              <div v-if="$vuetify.breakpoint.smAndDown">
                <div class="share-btn">
                  <v-btn icon>
                    <br />
                    Compartilhar
                  </v-btn>
                </div>
                <v-row style="margin-left: 10px">
                  <v-row wrap>
                    <v-col cols="2">
                      <ShareNetwork network="facebook" :url="`https://empresas.gugla.co.mz/contents/${content.id}/show`"
                        :title="`Vaga - ${content.titulo}`"
                        quote="The hot reload is so fast it\'s near instant. - Evan You"
                        hashtags="empregos,jobs,gugla,guglaempregos">
                        <br />
                        <v-btn icon>
                          <v-icon large dark color="#4267B2">
                            mdi-facebook
                          </v-icon></v-btn>
                      </ShareNetwork>
                    </v-col>
                    <v-col cols="2">
                      <ShareNetwork network="whatsapp" :url="`https://empresas.gugla.co.mz/contents/${content.id}/show`"
                        :title="`Vaga - ${content.titulo}`"
                        quote="The hot reload is so fast it\'s near instant. - Evan You"
                        hashtags="empregos,jobs,gugla,guglaempregos">
                        <br />
                        <v-btn icon>
                          <v-icon large dark color="green">
                            mdi-whatsapp
                          </v-icon></v-btn>
                      </ShareNetwork>
                    </v-col>
                    <v-col cols="2">
                      <ShareNetwork network="linkedin" :url="`https://empresas.gugla.co.mz/contents/${content.id}/show`"
                        :title="`Vaga - ${content.titulo}`"
                        quote="The hot reload is so fast it\'s near instant. - Evan You"
                        hashtags="empregos,jobs,gugla,guglaempregos">
                        <br />
                        <v-btn icon>
                          <v-icon large dark color="#0072B1">
                            mdi-linkedin
                          </v-icon></v-btn>
                      </ShareNetwork>
                    </v-col>
                    <v-col cols="2">
                      <br />
                      <v-btn @click="
                        copyUrl(
                          `https://empresas.gugla.co.mz/contents/${content.id}/show`
                        )
                      " icon>
                        <v-icon dark large> mdi-link-variant </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-row>
                <div>
                  <!-- <div
                    class="share-btn"
                    style="margin-left: 0px; margin-top: 20px"
                  >
                    <v-btn color="#FF5252" class="white--text" small>
                      adicionar aos favoritos
                    </v-btn>
                  </div> -->
                  <div class="share-btn" style="margin-left: 0px; margin-top: 20px">
                    <!-- <p>{{add_favorito  }}</p> -->
                    <div class="" v-if="!favorito_show">
                      <v-btn color="#FF5252" class="white--text" small block @click="addFavoriteWithoutLogin()">
                        adicionar aos favoritos
                      </v-btn>
                    </div>
                    <div class="" v-if="add_favorito == false">
                      <v-btn color="#FF5252" class="white--text" small block @click="addFavorite()" v-show="
                        concurso.content_favorite == 0 ||
                        content.content_favorite == 0
                      ">
                        adicionar aos favoritos
                      </v-btn>
                    </div>
                    <div class="" v-if="add_favorito == true">
                      <v-btn color="#FF5252" class="white--text" small block v-show="
                        concurso.content_favorite == 1 ||
                        content.content_favorite == 1
                      " @click="removeFavorite()">
                        remover dos favoritos
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="4" cols="12" class="hidden-xs-only">
            <div class="sticky-top">
              <p class="text-subtitle-2 text-md-h6">Detalhes</p>
              <v-divider class="my-3"></v-divider>
              <div class="" style="margin-left: 15px">
                <v-avatar size="80" height="auto" v-if="content.image" rounded>
                  <img :src="content.image" alt="" />
                </v-avatar>
              </div>
              <table>
                <tr>
                  <td>Instituição:</td>
                  <td>
                    {{ content.instituicao }}
                  </td>
                </tr>
                <tr>
                  <td>Local:</td>
                  <td>
                    <span v-if="content.local">
                      {{ content.local }}
                    </span>
                    <span v-else>
                      <span v-for="local in content.locals" :key="local.id" class="mr-1">
                        {{ local.name }}
                      </span>
                    </span>
                  </td>
                </tr>
                <tr v-if="content.industria">
                  <td>Categoria:</td>
                  <td>
                    {{ content.industria }}
                  </td>
                </tr>
                <tr>
                  <td>Publicado:</td>
                  <td>
                    {{ content.created_at }}
                  </td>
                </tr>
                <tr :class="[
                  expired ? 'left-border-expired' : 'left-border-active',
                ]">
                  <td>Validade:</td>
                  <td>
                    {{ content.validade | formatDate }}
                  </td>
                </tr>
              </table>
              <div v-if="content.candidaturas">
                <p>
                  <v-btn @click="openDialog" v-if="!candidaturas" class="ml-0" tile elevation="4" dark color="red acent-3"
                    small>
                    Enviar candidatura</v-btn>
                  <v-btn v-else class="ml-0" tile elevation="4" dark color="red acent-3" small>Candidatura enviada</v-btn>
                </p>
              </div>
              <div>
                <div class="share-btn" style="margin-left: 110px">
                  <!-- <p>{{add_favorito  }}</p> -->
                  <div class="" v-if="!favorito_show">
                    <v-btn color="#FF5252" class="white--text" small block @click="addFavoriteWithoutLogin()">
                      adicionar aos favoritos
                    </v-btn>
                  </div>
                  <div class="" v-if="add_favorito == false">
                    <v-btn color="#FF5252" class="white--text" small block @click="addFavorite()" v-show="
                      concurso.content_favorite == 0 ||
                      content.content_favorite == 0
                    ">
                      adicionar aos favoritos
                    </v-btn>
                  </div>
                  <div class="" v-if="add_favorito == true">
                    <v-btn color="#FF5252" class="white--text" small block v-show="
                      concurso.content_favorite == 1 ||
                      content.content_favorite == 1
                    " @click="removeFavorite()">
                      remover dos favoritos
                    </v-btn>
                  </div>
                </div>
              </div>
              <div>
                <div class="share-btn">
                  <v-btn icon>
                    <br />
                    Compartilhar
                  </v-btn>
                </div>
                <v-row style="margin-left: 10px">
                  <v-row wrap>
                    <v-col cols="2">
                      <ShareNetwork network="facebook" :url="`https://empresas.gugla.co.mz/contents/${content.id}/show`"
                        :title="`Vaga - ${content.titulo}`"
                        quote="The hot reload is so fast it\'s near instant. - Evan You"
                        hashtags="empregos,jobs,gugla,guglaempregos">
                        <br />
                        <v-btn icon>
                          <v-icon large dark color="#4267B2">
                            mdi-facebook
                          </v-icon></v-btn>
                      </ShareNetwork>
                    </v-col>
                    <v-col cols="2">
                      <ShareNetwork network="whatsapp" :url="`https://empresas.gugla.co.mz/contents/${content.id}/show`"
                        :title="`Vaga - ${content.titulo}`"
                        quote="The hot reload is so fast it\'s near instant. - Evan You"
                        hashtags="empregos,jobs,gugla,guglaempregos">
                        <br />
                        <v-btn icon>
                          <v-icon large dark color="green">
                            mdi-whatsapp
                          </v-icon></v-btn>
                      </ShareNetwork>
                    </v-col>
                    <v-col cols="2">
                      <ShareNetwork network="linkedin" :url="`https://empresas.gugla.co.mz/contents/${content.id}/show`"
                        :title="`Vaga - ${content.titulo}`"
                        quote="The hot reload is so fast it\'s near instant. - Evan You"
                        hashtags="empregos,jobs,gugla,guglaempregos">
                        <br />
                        <v-btn icon>
                          <v-icon large dark color="#0072B1">
                            mdi-linkedin
                          </v-icon></v-btn>
                      </ShareNetwork>
                    </v-col>
                    <v-col cols="2">
                      <br />
                      <v-btn @click="
                        copyUrl(
                          `https://empresas.gugla.co.mz/contents/${content.id}/show`
                        )
                      " icon>
                        <v-icon dark large> mdi-link-variant </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-useless-escape */

import Swal from "sweetalert2";
export default {
  props: {
    content: {
      required: true,
      type: Object,
      default() {
        return {
          id: null,
          titulo: "",
          conteudo_formatado: "",
        };
      },
    },
    recomended_content: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      favorito_show: false,
      concurso: {},
      active_candidatar: false,
      candidatura: "",
      dica: "",
      googleStructure: "",
      candidatura_show: false,
      add_favorito: false,
      dialog: false
    };
  },
  created() {
    let token = localStorage.getItem("TOKEN_AUTH");
    if (token) {
      this.favorito_show = true
    } else {
      this.favorito_show = false
    }
    // if(this.content.content_favorite == 0){
    //   this.add_favorito = false
    // }else{
    //   this.add_favorito = true
    // }
    // this.getContentWithAuth(this.content.id)
    // let token = localStorage.getItem("TOKEN_AUTH");
    // if (token) {
    //   this.getContentWithAuth(this.content.id);
    // } else {
    //   this.getContent(this.content.id);
    // }
  },
  /*
  metaInfo() {
    return {
      title: this.content.titulo,
      meta: [
        { property: "og:locale", content: "pt_PT" },
        { property: "og:type", content: "article" },
        { property: "og:site_name", content: "Gugla Empresas" },
        { property: "og:title", content: this.content.titulo },
        {
          property: "og:image",
          content:
            "https://gugla.s3.us-east-2.amazonaws.com/public/publicity/45/626503005.gif",
        },
        {
          property: "og:url",
          content: `https://empresas.gugla.co.mz/contents/${this.content.id}/show`,
        },
      ],
    };
  },
  */
  methods: {
    getContent(id) {
      this.$store.dispatch("loaderChange", true);
      this.$store
        .dispatch("getContent", { id })
        .then((res) => {
          console.log(res.data.data);
          this.concurso = res.data.data;
          // this.recomended_content = res.data.data.recomended_content.data

          this.$store.dispatch("loaderChange", false);
          scroll(0, 0);
        })
        .catch((err) => {
          this.$store.dispatch("loaderChange", false);
          this.$router.push({ name: "site.contents" });
          return err;
        });
    },
    getContentWithAuth(id) {
      this.$store.dispatch("loaderChange", true);
      this.$store
        .dispatch("getContentWithAuth", { id })
        .then((res) => {
          this.concurso = res.data.data;

          this.$store.dispatch("loaderChange", false);
          scroll(0, 0);
        })
        .catch((err) => {
          this.$store.dispatch("loaderChange", false);
          this.$router.push({ name: "site.contents" });
          return err;
        });
    },
    /*
    applyVacancy() {
      console.log(this.content.id);
    },
    openDialog() {
      if (this.isLoggedIn) {
        this.$store.dispatch("changeApplyVagaDialog", {
          value: true,
          id: this.content.id,
        });
      } else {
        this.$router.push({ name: "candidate.dashboard" });
      }
    },
   
    */
    async copyUrl(url) {
      try {
        await navigator.clipboard.writeText(url);
        Swal.fire({
          title: "Link copiado!",
          showConfirmButton: false,
          timer: 1000,
        });
      } catch (error) {
        console.log("cannot copy");
      }
    },
    addFavorite() {
      // document.getElementById("favorite-button").click();
      // console.log(this.content_id)
      this.add_favorito = true;

      let token = localStorage.getItem("TOKEN_AUTH");
      if (token) {
        let params = {
          content_id: this.content.id,
        };

        this.$store.dispatch("addFavoriteContents", params).then((res) => {
          this.getContentWithAuth(this.content.id);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Concurso marcado como Favorito.",
          });
          this.add_favorito = true;
        });
      } else {
        // document.getElementById("favorite-button").click();
        this.$router.push({ name: "site.concursos" });
      }
    },
    removeFavorite() {
      let params = {
        content_id: this.content.id,
      };
      this.$store.dispatch("removeFavoriteContents", { params }).then((res) => {
        this.getContentWithAuth(this.content.id);
        this.add_favorito = false;

        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Concurso removido dos Favoritos.",
        });
      });
    },
    addFavoriteWithoutLogin() {
      this.dialog = true
    },
    goToRegisto() {
      this.$router.push({ name: "candidate.register" });
    },
    goToLogin() {
      this.dialog = false;
      var urlAtual = window.location.href;
      sessionStorage.setItem("url", urlAtual);
      // sessionStorage.setItem("previous_router", "subscricao");
      this.$router.push({ name: 'candidate.login' })
      // document.getElementById("openPop2").click();
    },
  },
  computed: {
    expired() {
      let today = new Date();
      let deadlineDate = new Date(this.content.validade);

      return today > deadlineDate ? true : false;
    },
    
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
    candidaturas() {
      const cand_array = this.$store.state.cadidaturas;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let cand_show = cand_array.includes(this.content.id) ? true : false;
      return cand_show;
    },
  },

  filters: {
    formatDate: (date) => {
      if (date) {
        let newDate = new Date(date);
        let newFormatedDate =
          newDate.getDate() +
          "-" +
          (newDate.getMonth() + 1) +
          "-" +
          newDate.getFullYear();
        return newFormatedDate;
      }

      return "";
    },
  },
  watch: {
    content(content) {
      if (content?.como_candidatar) {
        const como_candidatar = JSON.parse(content.como_candidatar);
        //console.log(como_candidatar)
        this.active_candidatar = true;
        if (
          como_candidatar.method == "Entregar candidatura no endereço físico"
        ) {
          //console.log(1)
          this.candidatura = `<h4>Processo de candidatra</h4><p><ul><li>Endereço: ${como_candidatar.desc}</li></ul></p>`;
          if (como_candidatar.dica) {
            this.dica = `<h4>Dica</h4><p><ul><li>${como_candidatar.dica}</li></ul></p>`;
          }
        }
        if (como_candidatar.method == "Aceder o link") {
          this.candidatura = `<h4>Processo de candidatra</h4><p><ul><li>Através do website <a href="${como_candidatar.desc}" rel="noopener noreferrer" target="_blank">${como_candidatar.desc}</a></li></ul></p>`;
          if (como_candidatar.dica) {
            this.dica = `<h4>Dica</h4><p><ul><li>${como_candidatar.dica}</li></ul></p>`;
          }
        }
        if (como_candidatar.method == "Enviar candidatura por email") {
          this.candidatura = `<h4>Processo de candidatra</h4><p><ul><li>Envie um email para <a href="mailto:${como_candidatar.desc}" rel="noopener noreferrer" target="_blank">${como_candidatar.desc}</a></li></ul></p>`;
          if (como_candidatar.dica) {
            this.dica = `<h4>Dica</h4><p><ul><li>${como_candidatar.dica}</li></ul></p>`;
          }
        }
        if (content.candidaturas) {
          this.candidatura = `<h4>Processo de candidatra</h4>`;
          if (como_candidatar.dica) {
            this.dica = `<h4>Dica</h4><p><ul><li>${como_candidatar.dica}</li></ul></p>`;
          }
        }
      }
      const locals =
        content.locals.length > 0 ? content.locals[0].name : content.local;
      // const locals = content.locals.length > 0 ? content.locals.map(loc => loc.name).toString() : content.local
      this.googleStructure = `
				{
					"@context": "http://schema.org",
					"@type": "JobPosting",
					"title": "${content.titulo}",
					"description": "${content.conteudo_formatado}",
					"datePosted": "${content.created_at}",
					"validThrough": "${content.validade}",
					"employmentType" : "CONTRACTOR",
					"hiringOrganization": {
						"@type": "Organization",
						"name": "${content.instituicao}",
						"sameAs": "https://empresas.gugla.co.mz",
						"logo": "${content.image}"
					},
					"industry": "${content.industria ? content.industria : "N/A"}",
					"jobBenefits": "",
					"jobLocation": {
						"@type": "Place",
						"address": {
							"@type": "PostalAddress",
							"streetAddress": "N/A",
							"addressCountry": "MZ",
							"addressLocality": "${locals}",
							"addressRegion": "${locals}"
							"postalCode": "N/A",
						}
					},
					"baseSalary": {
						"@type": "MonetaryAmount",
						"currency": "MZN",
						"value": {
						"@type": "QuantitativeValue",
						"value": "N/A",
						"unitText": "N/A"
					},
					"occupationalCategory": "${content.industria ? content.industria : "N/A"}",
					"qualifications": "",
					"responsibilities": "",
					"skills": ""
				}
			`;
      // ${content.conteudo.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t").replace(/\"/g, `\\"`)}
      // console.log(content.conteudo.replace(/\n/g, "\\n").replace(/\r/g, "\\r"));
      //var pre = document.createElement('pre')
      //pre.textContent = googleStructure
      //document.body.append(pre)
      //document.body.insertAdjacentElement('beforeend', this.googleStructure)

      var title = document.getElementById("title");
      var newScript = document.createElement("script");
      newScript.setAttribute("type", "application/ld+json");
      var googleJson = document.createTextNode(this.googleStructure);
      newScript.appendChild(googleJson);
      document.body.appendChild(newScript);

      var linkTag = document.createElement("link");
      linkTag.setAttribute("rel", "canonical");
      linkTag.setAttribute(
        "href",
        `https://empresas.gugla.co.mz/contents/${content.id}/show`
      );
      title.parentElement.insertBefore(linkTag, title.nextSibling);
      // document.head.appendChild(linkTag)

      // let newMeta = document.createElement('meta')
      // newMeta.setAttribute('property', 'og:title')
      // newMeta.setAttribute('content', `${content.titulo}`)
      // document.head.appendChild(newMeta)
      // title.parentElement.insertBefore(newMeta, title.nextSibling)
    },
  },
};
</script>

<style scoped>
.textoCabelho {
  margin-top: 40px;
}

.anexo {
  width: 100%;
}

.sticky-top {
  position: sticky;
  top: 70px;
}

table,
tr,
td {
  border: none;
  font-size: 13px;
}

td {
  padding: 0 10px 0 10px;
}

.left-border-expired {
  /* red accent-3 */
  border-left: 4px solid #ff1744 !important;
}

.bottom-border-active {
  /* red accent-3 */
  border-bottom: 4px solid #ff1744 !important;
}

.left-border-active {
  /* light-green accent-4 */
  border-left: 4px solid #64dd17 !important;
  /*  */
}

.bottom-border-active {
  border-bottom: 4px solid #64dd17 !important;
}

.share-btn {
  margin: 0 0 0 45px;
}
</style>