<template>
  <v-container class="mt-8">
    <v-row justify="center">
      <v-col cols="12" sm="12" md="12">
        <content-form :updating="false" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ContentForm from "./partials/ContentForm.vue";

export default {
  components: {
    ContentForm,
  },

  created() {
    // this.geteCategories(2);
  },

  methods: {
    // geteCategories(category_code = 2) {
    // 	this.$store.dispatch("category_on_recruiter/getCategories", {
    // 		category_code,
    // 	});
    // },
  },
};
</script>
<style scoped>
</style>