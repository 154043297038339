<template>
  <!--<v-row wrap align="center" justify="center">
       <v-col sm="11">-->
  <v-container class="mt-8">
    <v-row>
      <v-col cols="12" class="mb-6">
        <v-btn
          :to="{ name: 'recruiter.vagas.show', params: { id: this.id } }"
          class="ml-0"
          tile
          elevation="4"
          dark
          color="red acent-3"
          small
        >
          <v-icon left small> mdi-arrow-left </v-icon>Voltar</v-btn
        >
      </v-col>
    </v-row>
    <v-row wrap align="center" justify="end">
      <v-col cols="12" md="6">
        <h4 style="text-transform: uppercase; font-size: 20px; color: #FF5252">
          Candidaturas da Vaga
        </h4>
      </v-col>
      <v-col cols="12" md="6">
      </v-col>
    </v-row>
    <v-row v-if="search_alert"> </v-row>
    <v-row wrap justify="space-between">
      <v-col
        sm="12"
        md="6"
        class="mb-3"
        v-for="candidate in content.candidates"
        :key="candidate.id"
      >
        <candidate-card-component
          :candidate="candidate"
          :detailsRoute="{
            name: 'recruiter.minhasCandidaturas.show',
            params: { id: candidate.id },
          }"
        />
      </v-col>
    </v-row>
  </v-container>
  <!--</v-col>
    </v-row>-->
</template>

<script>
import CandidateCardComponent from "./CandidateCardComponent";

export default {
  components: { CandidateCardComponent },

  data() {
    return {
      content: {
        image: "",
        titulo: "",
        instituicao: "",
        validade: "",
        categoria_id: "",
        local: "",
        fonte: "",
        conteudo: "",
      },
    };
  },

  props: {
    id: {
      required: true,
    },
  },
  computed: {
    candidates() {
      //console.log(this.$store.state.candidate.items)
      return this.$store.state.candidate.items;
    },
    params() {
      return {
        profissao: this.filter,
      };
    },
  },
  created() {
    this.getContent(this.id);
    // console.log("casn1")
    // sessionStorage.clear()
    let page = Number(sessionStorage.getItem("page"));
    this.filter = sessionStorage.getItem("profissao");
    if (sessionStorage.getItem("profissao")) {
      this.search_alert = true;
      this.$store.dispatch("getCandidates", {
        profissao: this.filter,
        page: page,
      });
    } else {
      this.getCandidates(1);
    }
  },
  methods: {
    getContent(id) {
      this.$store.dispatch("getVaga", id).then((response) => {
        this.content = response.data;
      });
    },
    getCandidates(page) {
      this.$store.dispatch("getCandidates", { page }).then((res) => {
        console.log(res);
      });
    },
    handleChangePage(page) {
      // console.log(page);

      sessionStorage.setItem("page", page);
      if (this.filter == "") {
        sessionStorage.setItem("page", page);
        this.getCandidates(page);
      } else {
        sessionStorage.setItem("profissao", this.filter);
        this.$store.dispatch("getCandidates", {
          profissao: this.filter,
          page: page,
        });
      }
    },
    searchContent() {
      // console.log("ok")
      this.search_alert = true;
      let page = Number(sessionStorage.getItem(page));
      if (page) {
      } else {
        sessionStorage.setItem("profissao", this.filter);
        sessionStorage.setItem("page", 1);
      }
      this.$store.dispatch("candidate/getCandidates", {
        profissao: this.filter,
      });
      if (this.filter == "") {
        sessionStorage.clear();
      }
    },

    resetFilteredResults() {
      this.filter = "";
      this.getCandidates(1);
      this.search_alert = false;
      sessionStorage.clear();
      sessionStorage.setItem("page", 1);
    },
  },
};
</script>

<style>
</style>
