<template>
  <form>
      <v-text-field
			ref="search"
			v-model.lazy.trim="filter"
			label="Pesquisar"
			placeholder="pesquise"
            aria-autocomplete="off"
			append-icon="mdi-briefcase-search"
			@click:append.prevent="searchContent"
			@keypress.enter.prevent="searchContent"
		></v-text-field>
  </form>
</template>

<script>
export default {
	data() {
		return {
			filter: "",
		}
	},
	methods: {
		searchContent(){
			this.$emit("searchContentEvent", this.filter);
			this.filter = "";
		}
	}
    
				
}
</script>

<style>

</style>