<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<template>
  <v-container class="mt-8">
    <div>
      <v-row wrap justify="center">
        <v-col cols="12" md="12">
          <div class="mb-auto">
            <div class="justify-center align-center">
              <h4 class="text-h6 text-md-h4 accent--text text-center" style="font-weight: bold; margin-bottom: 20px;">
                Plano de Subscrição
              </h4>
              <!-- <p>
                Fique a frente da concorrência visualizando todos os
                concursos de empresas públicas e privadas publicados na
                nossa plataforma. Não perca nenhuma oportunidade de negócio.
              </p> -->
            </div>
          </div>
          <div style="margin-bottom: 1px;" class="mb-auto">
            <div class="justify-center align-center">
              <h4 class="text-h6" style="font-weight: bold">
                Selecione abaixo o que deseja fazer na plataforma:
              </h4>
              <v-checkbox v-model="checkbox1" label="Visualização de concursos e pedidos de cotações" color="#E61F31"
                @change="onClickService(checkbox1)"></v-checkbox>
              <v-checkbox v-model="checkbox2" label="Promoção da minha empresa, seus serviços e produtos" color="#E61F31"
                @change="onClickService(checkbox2)"></v-checkbox>
              <v-row wrap>
                <v-col cols="12" md="6">
                  <v-select :items="minhasempresas" item-text="nome" item-value="id" v-model="empresa_nome"
                    label="Selecione uma Empresa" v-show="checkbox2 == true" color="#E61F31"
                    @change="changeEmpresa(empresa_nome)"></v-select>
                </v-col>
              </v-row>
              <!-- <v-checkbox v-model="checkbox3" label="Todos os Serviços" color="#E61F31"
                @change="onClickAllServices(checkbox3)"></v-checkbox> -->
            </div>
          </div>
          <div style="margin-bottom: 1px;" class="mb-auto" v-show="checkbox1 == true || checkbox2 == true">
            <div class="justify-center align-center">
              <h4 class="text-h6" style="font-weight: bold">
                Descubra o que o pacote selecionado tem a oferecer!
              </h4>
            </div>
            <!-- <p>Facilitamos a sua vida na procura de oportunidades de negócio.</p> -->
            <p v-if="checkbox1 || checkbox2">Custo Mensal: <b>{{ price }} Meticais</b></p>
            <!-- <p v-if="checkbox1 && checkbox2">Custo Mensal: <b>{{ price }} Meticais</b> </p> -->
          </div>

          <div style="margin-bottom: 10px;" class="mb-auto">
            <v-row wrap>
              <v-col cols="12" md="9">
                <v-list v-show="checkbox1 == true || checkbox2 == true">
                  <v-list-item v-for="(item, index) in primeiropacote" :key="index">
                    <v-list-item-icon>
                      <v-icon color="#68B51B" v-if="checkbox1 == true"> mdi-check-circle </v-icon>
                      <v-icon color="#F32A0C" v-if="checkbox1 == false && checkbox2 == true"> mdi-alpha-x-circle </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p>
                        {{ item.description }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="(item, index) in segundopacote" :key="index">
                    <v-list-item-icon>
                      <v-icon color="#68B51B" v-if="checkbox2 == true"> mdi-check-circle </v-icon>
                      <v-icon color="#F32A0C" v-if="checkbox1 == true && checkbox2 == false"> mdi-alpha-x-circle </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p>
                        {{ item.description }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </div>

          <!-- planos de Subscrição -->

          <div style="margin-bottom: 10px" class="pa-2 mb-auto" v-show="checkbox1 == true || checkbox2 == true">
            <v-row wrap justify="center" class="mt-6">
              <h4 class="text-h6 text-md-h4 accent--text text-center" style="font-weight: bold">
                Pacotes de Subscrição
              </h4>
            </v-row>
            <v-row wrap>
              <v-col cols="12" md="12">
                <p class="text-center">
                  Escolha quantos meses deseja pagar e veja quanto pagar
                </p>
              </v-col>
              <v-col cols="12" md="12">
                <PricingComponent @valor="getValor" :price="price" />
              </v-col>
            </v-row>
          </div>

        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import PricingComponent from "./PricingComponent";
export default {
  data() {
    return {
      empresas: [],
      empresa_nome: "",
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      price: 1160,
      mes: 1,
      valor: {
        valor: 580.00,
        mes: 1,
      },
      primeiropacote: [
        // {
        //   code: 1,
        //   description: "Visualização da Lista de Empresas, para descobrir parceiros, fornecedores e prestadores de serviços"
        // },
        {
          code: 2,
          description: "Visualização de Concursos e Pedidos de Cotações"
        },
        {
          code: 3,
          description: "Notificações sobre novos concursos publicados"
        },
        {
          code: 4,
          description: "Acesso aos concursos através do aplicativo móvel"
        },
        {
          code: 5,
          description: "Acesso aos concursos através do WhatsApp"
        },
        {
          code: 6,
          description: "Guardar Concursos como Favorito para consultar futuramente"
        },
      ],
      segundopacote: [
        {
          code: 1,
          description: "Visualização da Lista de Empresas, para descobrir parceiros, fornecedores e prestadores de serviços"
        },
        {
          code: 2,
          description: "Promoção da minha empresa, seus produtos e seus serviços"
        },
        {
          code: 3,
          description: "Visualizar lista de potenciais clientes interessados nos meus produtos e serviços"
        },
        {
          code: 4,
          description: "Receber Leads, potenciais clientes"
        },
        {
          code: 5,
          description: "Vender os seus produtos e Serviços"
        },
        {
          code: 6,
          description: "Website dedicado para a sua empresa"
        },
      ],

    };
  },
  created() {
    this.empresas.length = 0
    this.empresa_nome = this.empresa_id
    if(this.empresa_id){
      sessionStorage.setItem("empresa", this.empresa_nome)
    }
    this.$store.dispatch("getMinhasEmpresas");
    if (this.tipo_subscricao == 1) {
      sessionStorage.removeItem("tipo_subscricao")
      sessionStorage.setItem("tipo_subscricao", 1)
      this.checkbox1 = true
      this.price = 580
    }
    else if (this.tipo_subscricao == 2) {
      sessionStorage.removeItem("tipo_subscricao")
      sessionStorage.setItem("tipo_subscricao", 2)
      this.checkbox2 = true
      this.price = 580
    }
    let token = localStorage.getItem("TOKEN_AUTH")
    if(token){
      this.subscribeLogs()
    }
   
  },
  components: {
    PricingComponent,
  },
  props: {
    tipo_subscricao: {
      required: true,
      default: 0
    },
    empresa_id: {
      required: true
    }
  },
  computed: {
    isMobile() {
      console.log(this.$vuetify.breakpoint.name);
      return this.$vuetify.breakpoint.mdAndUp ? true : false;
    },
    minhasempresas() {
      this.empresas.length = 0
      let empresa = this.$store.state.minhasempresas;
      empresa.forEach(element => {
        // console.log(element.nome)
        this.empresas.push(element)

      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.empresas.push({ id: 0, nome: "Nova Empresa (Por registrar)" })

      return this.empresas;
    },
  },

  methods: {
    subscribeLogs() {
      let data = {
        "recruiter_email": localStorage.getItem("RECRUITER_EMAIL"),
        "subscribe_page_select": 1,
        "subscribe_plan_page": 1,
        "id": localStorage.getItem("id_subs")
      }
      this.$store.dispatch("updatesubscribePageLogs", data).then((res) => {
      })
    },
    getValor(item) {
      this.valor = item;
      console.log("item", item);
    },
    onClickAllServices(checkbox3) {
      this.price = 580 * 2
      if (checkbox3 == true) {
        this.checkbox1 = false
        this.checkbox2 = false
      }
    },
    changeEmpresa(empresa_nome) {
      sessionStorage.setItem("empresa", empresa_nome)
    },
    onClickService(item) {
      this.price = 580
      console.log(this.empresa_nome)

      sessionStorage.setItem("tipo_subscricao", 0)
      localStorage.setItem("tipo_subscricao", 0)
      if (this.checkbox1 == true) {
        sessionStorage.setItem("tipo_subscricao", 1)
        localStorage.setItem("tipo_subscricao", 1)
        localStorage.setItem("valor", this.price)
      }
      if (this.checkbox2 == true) {
        sessionStorage.setItem("tipo_subscricao", 2)
        localStorage.setItem("tipo_subscricao", 2)
        localStorage.setItem("valor", this.price)
      }
      if (this.checkbox1 == true && this.checkbox2 == true) {
        sessionStorage.setItem("tipo_subscricao", 3)
        localStorage.setItem("tipo_subscricao", 3)
        localStorage.setItem("valor", this.price * 2)
        this.price = this.price * 2
      }
    }
  },
};
</script>
  
<style scoped>
@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700italic,700,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);

.styleImg {
  height: auto;
  width: 300px;
}

.imgSmall {
  height: auto;
  width: 250px;
  position: relative;
  left: -10px;
}

.imgSmallMobile {
  height: auto;
  width: 200px;
  position: relative;
  left: -30px;
  top: 25px;
}

.alignTest1 {
  padding: 10px;
  margin: 50px 0 10px 0;
}

.passos {
  background-color: #f5f5f5;
  height: 100%;
  padding: 10px;
  margin: 5px;
}

.texto {
  height: 100%;
  padding-top: 50px;
}

img {
  /* height: 9px; */
  align-content: flex-end;
  display: flex;
}

h4 {
  font-family: "Raleway", sans-serif;
}

.v-messages {
  /* flex: 1 1 auto; */
  /* font-size: 12px; */
  /* min-height: 14px; */
  /* min-width: 1px; */
  /* position: relative; */
}

.v-list-item.theme--light {
  padding: 0px;
}

.v-messages.theme--light {
  height: 0;
  width: 0;
}
</style>