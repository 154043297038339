<template>
    <v-row justify="center">
      <!-- <v-btn
        color="primary"
        dark
        @click.stop="dialog = true"
      >
        Open Dialog
      </v-btn> -->
      <v-dialog
        v-model="hasContact"
        max-width="500"
      >
        <v-card
        v-if="ask"
        class="mx-auto d-flex flex-column"
        style="padding-bottom: 0px; padding-top: 50px"
        >
          <div
            class="meuCard"
            style="
              margin: 0;
              padding-bottom: 0px;
              overflow-x: hidden;
              overflow-y: hidden;
            "
          >
            <v-row wrap>
              <v-col cols="12" md="12">
                <div class="text-center">
                  <img
                    src="../../../assets/question-mark.png"
                    alt=""
                    height="130"
                    width="130"
                    style="display: block; margin-left: auto; margin-right: auto"
                  />
                </div>
                <div class="textoCabelho">
                  <h4 style="font-weight: bold; color: #111" class="text-center">
                    Garanta que sempre terás acesso a sua conta
                  </h4>
                  <br />
                  <p class="text-center text-body-2 text-md-body-1">
                    Adicione o seu contacto a sua conta para ter mais de uma forma de entrar na plataforma. isso irá permitir  que possa entrar sempre na plataforma sempre que não tiver acesso ao email.
                  </p>
                </div>
  
                <br />
  
                <v-card-actions v-if="true">
                  <v-row wrap justify="center">
                    <v-col cols="12" md="6">
                      <v-btn block color="#FF4500" dark rounded @click="() => ask = false"
                        >Adicionar contacto</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card
        v-else
        class="mx-auto d-flex flex-column"
        >
        <v-tabs v-model="tab" background-color="#FF4500" icons-and-text dark grow>
            <v-tabs-slider color="#2F4858"></v-tabs-slider>
                  <!-- <v-tabs-slider color="#E61F31"></v-tabs-slider> -->
            <v-tab key="forgot_password_tab">
              <v-icon>mdi-account</v-icon>
              <div class="caption">Adicionar contacto</div>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
        </v-tabs-items>
        <validation-observer ref="changePasswordForm" v-slot="{}">
              <v-card v-if="setContact" elevation="2" max-width="470" :disabled="disabled">
                <v-progress-linear height="4" v-show="loading" indeterminate color="#FF5414"></v-progress-linear>
                <v-card-text>
                  <validation-provider v-slot="{ errors }" rules="required" name="E-mail ou telemovel">
                    <v-text-field outlined v-model="formData.contacto" label="telemovel"
                      :error-messages="errors" color="#FF4500" />
                  </validation-provider>
                  <v-card-actions>
                      <!-- <v-btn depressed rounded text small @click="forgotPassword">Esqueceu a senha?</v-btn> -->
                      <v-spacer></v-spacer>
                      <v-btn color="#FF4500" dark small @click="sendOtp" class="btn-submit"
                        style="padding-inline: 19px;margin-right: -7px;">Submeter</v-btn>
                    </v-card-actions>
                </v-card-text>
              </v-card>
              <v-card v-else elevation="2" :disabled="disabled" max-width="470">
                  <v-progress-linear height="4" v-show="loading" indeterminate color="#FF5414"></v-progress-linear>
                  <v-card-title>
                  </v-card-title>
                  <v-card-subtitle style="margin-bottom: 10px; margin-top: 5px">
                    <!-- <h3 style="color: #111;"></h3> -->
                    <p>Um codigo foi enviado para <span style="font-weight: bold;">{{ formData.contacto }}</span>, use para
                      verificar a conta. </p>
                  </v-card-subtitle>
                  <v-card-text>
                    <validation-provider v-slot="{ errors }" rules="required" name="Código de verificação">
                      <v-text-field outlined v-model="formData.code" label="Código de verificação"
                        :error-messages="errors" color="#FF4500" />
                    </validation-provider>
                    <v-card-actions>
                      <v-btn rounded text small @click="() => {setContact = true}"
                        style="padding-inline: 19px;margin-left: -30px;">
                        <v-icon left small> mdi-arrow-left </v-icon>
                        Voltar
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="#FF4500" dark small @click="validate()"
                        style="padding-inline: 19px;margin-right: -7px;">Verificar</v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
            </validation-observer>
        </v-card>
      </v-dialog>
      <div id="recaptcha-container"></div>
    </v-row>
  </template>
  <script>
    import Swal from "sweetalert2";
    import firebase from 'firebase/app';
    import {
    ValidationProvider,
    ValidationObserver,
    setInteractionMode,
    extend,
    } from "vee-validate";
    import {
    required,
    digits,
    email,
    confirmed,
    max,
    regex,
    } from "vee-validate/dist/rules";
    setInteractionMode("eager");

    extend("required", {
    ...required,
    message: "{_field_} não pode estar vazio!",
    });

    extend("digits", {
    ...digits,
    message: "{_field_} deve conter {length} dígitos. ({_value_})!",
    });

    extend("email", {
    ...email,
    message: "O email deve ser válido!",
    });

    extend("confirmed", {
    ...confirmed,
    message: "O valor para o campo {_field_} não coincide!",
    });

    extend("strong_password", {
    message: (field) =>
        "A " +
        field +
        " deve conter pelo menos 1 caracter minúsculo, 1 caracter maiúsculo, 1 dígito e um cumprimento não inferior a 7!",
    validate: (value) => {
        // let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/); // Com caracteres especiais
        let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/);
        return pattern.test(value);
    },
    });
  export default {
    components: { ValidationProvider, ValidationObserver },
    computed: {
        passwordMatch() {
        return () =>
            this.confirm_password === this.formData.password ||
            "A senha deve ser a mesma";
        },
        validForm() {
        return this.confirm_password === this.formData.password ? true : false;
        },
        getCandidate() {
        return this.$store.getters.getCandidate;
        },
        auth() {
        return this.$store.state.candidate;
        },
        hasContact() {
          return this.$store.state.hasContact;
        }
    },
    data () {
      return {
        firebaseRes: null,
        dialog: true,
        ask: true,
        disabled: false,
        loading: false,
        setContact: true,
        // auth: {},
        formData: {
            password: "",
            password_confirmation: "",
            forced: false,
            email: "",
            contacto: "",
            code: ""
        },
        confirm_password: "",
        show1: false,
        password: "Password",
        rules: {
            required: (value) => !!value || "Required.",
            min: (v) => v.length >= 7 || "Min 7 characters",
            emailMatch: () => `The email and password you entered don't match`,
        },
        tab: null,
      }
    },
    methods: {
    getAuth() {
      // this.auth = this.$store.state.candidate;
    },
    setEmail() {
      console.log(this.formData.email);
      
      const formData = new FormData();
      formData.append("email", this.formData.email);

      this.$store
        .dispatch("verifyEmail", formData)
        .then(() => {
          Swal.fire({
            title: "Sucesso!",
            text: `Foi enviado um email para ${this.formData.email} para confirmar e ativar o metódo por email!`,
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#FF4500",
          });
          this.$store.state.hasEmail = false
        })
        .catch((error) => {
          console.log(error);
        })
        .finally("Finalizou no componet");
    },
    sendOtp() {
      console.log(this.formData);
      this.loading = true
      this.disabled = true
      this.$store.dispatch("loaderChange", true);
      firebase.auth();
      const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': function (res) {

        }
      })
      firebase.auth().signInWithPhoneNumber('+258' + this.formData.contacto, appVerifier).then(res => {
        this.firebaseRes = res
        this.$store.dispatch("loaderChange", false);
        this.loading = false
        this.disabled = false
        this.setContact = false

      })
      .catch(err => {
        Swal.fire({
            title: "Opss!",
            text: `Erro ao verificar o contacto`,
            icon: "warning",
            confirmButtonText: "OK",
            confirmButtonColor: "#FF4500",
          });
          // this.$store.state.hasEmail = false
      })
    },
    validate() {
      this.loading = true
      this.disabled = true
      this.firebaseRes.confirm(this.formData.code)
        .then(res => {
          firebase.auth().currentUser.getIdToken(true).then(token => {
            this.$store.dispatch('verifyContactLogin', token).then((response) => {
              this.loading = false
              this.disabled = false
              this.dialog = false
              this.$store.state.hasContact = false
              Swal.fire({
                title: "Opss!",
                text: `Já pode usar o teu contacto para acessar a sua conta.`,
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#FF4500",
              });
            })
          })
        }).catch(err => {
          this.loading = false
          this.disabled = false
          this.dialog = false
          this.$store.state.hasContact = false
          Swal.fire({
            title: "Opss!",
            text: `Erro ao verificar o contacto`,
            icon: "warning",
            confirmButtonText: "OK",
            confirmButtonColor: "#FF4500",
          });
        })
    }
  },
  }
</script>