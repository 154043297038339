<template>
  <!--<v-row wrap align="center" justify="center">
       <v-col sm="11">-->
  <v-container class="mt-8">
    <v-row wrap  justify="end" v-if="candidates.length != 0">
      <v-col cols="12" md="12">
        <h4 style="text-transform: uppercase; font-size: 20px; color: #FF5252">
          Meus Candidatos Favoritos
        </h4>
      </v-col>
      <!-- <v-col cols="12" md="6">
        <form class="" @submit.prevent="searchContent">
          <v-form>
            <v-text-field
              ref="search"
              v-model="filter"
              label="Pesquisar candidados por Profissão"
              placeholder="Pesquisar candidados por Profissão"
              append-icon="mdi-briefcase-search"
              @click:append.prevent="searchContent"
              @keypress.enter.prevent="searchContent"
            ></v-text-field>
          </v-form>
        </form>
      </v-col> -->
    </v-row>
    <v-row wrap justify="space-between">
      <!-- <v-alert
        border="bottom"
        color="#FF4500"
        colored-border
        type="warning"
        elevation="2"
        v-if="candidates.length == 0"
      >
        Ainda não possuí candidados favoritos, acesse a BASE DE DADOS DE
        CANDIDATOS para marcar os seus Candidatos.
      </v-alert> -->
      <v-col
        cols="12"
        md="12"
        class="text-center"
        v-if="candidates.length == 0"
      >
        <v-row wrap justify="center">
          <v-col cols="12" sm="6" md="6">
            <div>
              <img
                height="200x"
                width="200x"
                src="../../../assets/ui/cd.png"
              />
            </div>
          </v-col>
        </v-row>
        <p
          style="font-weight: 900; text-transform: capitalize; font-size: 20px"
        >
          Ainda não possuí candidados favoritos
        </p>
        <v-row wrap justify="center">
          <v-col cols="12" sm="6" md="6">
            <div style="background-color: #fdece5; border: 1px solid #ff4500">
              <p style="padding: 10px">
                <span style="font-weight: 900; width: 300px"
                  >Marque Candidatos</span
                >
                da base de Candidatos como favoritos, e volte para vê-los a
                qualquer momento que desejar.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        sm="12"
        md="6"
        class="mb-3"
        v-for="candidate in candidates"
        :key="candidate.id"
      >
        <candidate-card-component
          :candidate="candidate"
          :detailsRoute="{
            name: 'recruiter.candidate_favorites.show',
            params: { id: candidate.id },
          }"
        />
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col xs="12">
        <v-pagination
          v-model="candidates.current_page"
          :length="candidates.last_page"
          @input="handleChangePage"
          color="error"
          total-visible="7"
        ></v-pagination>
      </v-col>
    </v-row> -->
  </v-container>
  <!--</v-col>
    </v-row>-->
</template>

<script>
import CandidateCardComponent from "./CandidateCardComponent";

export default {
  components: { CandidateCardComponent },
  data() {
    return {};
  },
  computed: {
    candidates() {
      // console.log(this.$store.state.candidate.items)
      return this.$store.state.items;
    },
  },
  created() {
    // console.log("favorites", this.$store.state.candidate.items)
    this.getCandidates();
    // let page = Number(sessionStorage.getItem("page"));
    // if (page) {
    //   this.getCandidates(page);
    // } else {
    //   this.getCandidates(1);
    // }
  },
  methods: {
    getCandidates() {
      this.$store.dispatch("getCandidatesFavorites").then((res) => {
        // console.log(res);
      });
    },
    handleChangePage(page) {
      sessionStorage.setItem("page", page);
      this.getCandidates(page);
    },
  },
};
</script>

<style>
</style>
