<template>
  <v-container>
    <!--<v-card
    class=" my-12"
    flat
    max-width="1000px">-->
    <v-card width="auto" flat>
      <v-card-title
        >Estado da Subscrição para visualização de Concursos</v-card-title
      >
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Estado</th>
                <th class="text-left">Dias Restantes</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="getCandidate.subcribe_date == 0">Inactiva</td>
                <td v-else>Activa</td>
                <td>{{ getCandidate.subcribe_date }} dias</td>
                <td>
                  <v-btn
                    type="submit"
                    elevation="4"
                    color="secondary"
                    dark
                    small
                    class="mr-2"
                    :to="{name: 'site.subscricao'}"
                  >
                    <v-icon small class="mr-2"> mdi-update </v-icon>
                    Actualizar
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider style="100%"></v-divider>
      </v-card-text>
    </v-card>
  </v-container>
  <!--</v-card>-->
</template>

<script>
export default {
  components: {},
  computed: {
    getCandidate() {
      let candidate = this.$store.state.candidate;

      return candidate;
    },
  },
};
</script>

<style>
</style>