var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('v-row',{attrs:{"justify":"space-betwen"}},[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{staticClass:"pl-0 mb-6 md-4 text-start",attrs:{"cols":"12","md":"4"}},[_c('span',[_vm._v("Está enfrentando alguma dificuldade?")]),_c('br'),_c('v-btn',{staticClass:"ml-0",attrs:{"href":"mailto:suporte@marrar.co.mz?subject=Publicar uma Vaga","tile":"","elevation":"4","dark":"","color":"#FF5414","small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-message-settings ")]),_vm._v("Suporte")],1)],1)],1),_c('v-card',[_c('v-card-title',[_c('v-chip',{attrs:{"small":"","label":"","dark":"","color":'green darken-1'}},[_vm._v("Publicar Vaga")])],1),_c('v-card-text',[(_vm.imageUploadPreview)?_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"12"}},[_c('v-avatar',{attrs:{"size":"100"}},[_c('img',{attrs:{"src":_vm.imageUploadPreview,"alt":""}})])],1)],1):_vm._e(),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Título","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"titulo",attrs:{"dense":"","label":"Título","placeholder":"Título do anúncio","error-messages":errors,"required":""},model:{value:(_vm.vaga.titulo),callback:function ($$v) {_vm.$set(_vm.vaga, "titulo", $$v)},expression:"vaga.titulo"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"empresas",staticClass:"mt-0",attrs:{"dense":"","error-messages":errors,"items":_vm.empresas,"item-text":"nome","item-value":"id","chips":"","label":"Empresa"},model:{value:(_vm.vaga.empresa_id),callback:function ($$v) {_vm.$set(_vm.vaga, "empresa_id", $$v)},expression:"vaga.empresa_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":"Validade","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.vaga.validade),callback:function ($$v) {_vm.$set(_vm.vaga, "validade", $$v)},expression:"vaga.validade"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"dense":"","no-title":"","scrollable":""},model:{value:(_vm.vaga.validade),callback:function ($$v) {_vm.$set(_vm.vaga, "validade", $$v)},expression:"vaga.validade"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Local","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"local",staticClass:"mt-0",attrs:{"dense":"","error-messages":errors,"items":_vm.locals,"item-text":"name","item-value":"id","chips":"","label":"Local"},model:{value:(_vm.vaga.local),callback:function ($$v) {_vm.$set(_vm.vaga, "local", $$v)},expression:"vaga.local"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Industria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"Industria",staticClass:"mt-0",attrs:{"dense":"","error-messages":errors,"items":_vm.industrias,"item-text":"name","item-value":"id","chips":"","label":"Industria"},model:{value:(_vm.vaga.industria),callback:function ($$v) {_vm.$set(_vm.vaga, "industria", $$v)},expression:"vaga.industria"}})]}}],null,true)})],1)],1),(!_vm.anexoConteudoUploadPreview)?_c('v-row',{staticStyle:{"margin-bottom":"50px"},attrs:{"wrap":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('vue-editor',{ref:"conteudo",staticStyle:{"height":"300px","margin-top":"20px"},attrs:{"placeholder":"Descrição do concurso...","editor-toolbar":_vm.customToolbar},model:{value:(_vm.vaga.conteudo_formatado),callback:function ($$v) {_vm.$set(_vm.vaga, "conteudo_formatado", $$v)},expression:"vaga.conteudo_formatado"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"name":"Como candidatar-se","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.formaList,"error-messages":errors,"prepend-icon":"mdi-none","label":"Como candidatar-se","data-vv-name":"select","required":""},model:{value:(_vm.method),callback:function ($$v) {_vm.method=$$v},expression:"method"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Este campo","rules":_vm.rule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.method != _vm.formaList[3]),expression:"method != formaList[3]"}],attrs:{"error-messages":errors,"prepend-icon":_vm.method == _vm.formaList[0]
                    ? 'mdi-map-marker-outline'
                    : 'mdi-link-variant',"label":_vm.candidatar_label},model:{value:(_vm.desc),callback:function ($$v) {_vm.desc=$$v},expression:"desc"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Dica"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(_vm.method != _vm.formaList[3]),expression:"method != formaList[3]"}],staticStyle:{"height":"200px"},attrs:{"error-messages":errors,"prepend-icon":"mdi-tag-multiple-outline","label":"Dica"},model:{value:(_vm.dica),callback:function ($$v) {_vm.dica=$$v},expression:"dica"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"type":"submit","small":"","elevation":"4","color":"red accent-3","dark":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-check ")]),_vm._v(" "+_vm._s(_vm.actionSubmit)+" ")],1),_c('v-btn',{staticClass:"mx-4",attrs:{"to":{ name: 'recruiter.vagas' },"exact":"","outlined":"","small":"","elevation":"4","color":"red accent-3","dark":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-cancel ")]),_vm._v(" Cancelar ")],1),_c('v-btn',{staticClass:"ml-0",attrs:{"outlined":"","small":"","elevation":"4","color":"red accent-3","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.clean($event)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-eraser ")]),_vm._v(" Limpar ")],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }