<template>
	<form class="" @submit.prevent="searchContent">
		<!-- <mdb-input
				class="mt-0 mb-3"
				placeholder="pesquisar"
				ariaDescribedBy="button-addon2"
				v-model="filter"
			>
				<mdb-btn
					color="danger"
					size="md"
					group
					slot="append"
					id="button-addon2"
					type="submit"
					><i class="fa fa-search"></i
				></mdb-btn>
			</mdb-input> -->
		<template>
			<!-- <mdb-input
					v-model="filter"
					basic
					class="mb-3"
					placeholder="pesquisar"
					ariaDescribedBy="button-addon2"
				>
					<mdb-btn
						type="submit"
						outline="danger"
						size="md"
						group
						slot="append"
						id="button-addon2"
						><i class="fa fa-search"></i
					></mdb-btn>
				</mdb-input> -->
		</template>
		<v-form>
			<v-text-field
				ref="search"
				v-model="filter"
				label="Pesquisar"
				placeholder="pesquisar"
				append-icon="mdi-briefcase-search"
				@click:append.prevent="searchContent"
				@keypress.enter.prevent="searchContent"
			></v-text-field>
		</v-form>
	</form>
</template>

<script>
// import { mdbInput, mdbBtn } from "mdbvue";

export default {
	components: {
		// mdbInput,
		// mdbBtn,
	},

	data() {
		return {
			filter: "",
		};
	},

	methods: {
		searchContent() {
			this.$emit("searchContentEvent", this.filter);
			this.filter = ''
		},
	},
};
</script>

<style scoped>
/* section.preview {
	border: 1px solid #e0e0e0;
	padding: 15px;
} */
</style>