<template>
    <v-row justify="center">
      <!-- <v-btn
        color="primary"
        dark
        @click.stop="dialog = true"
      >
        Open Dialog
      </v-btn> -->
  
      <v-dialog
        v-model="hasPassword"
        max-width="500"
      >
        <v-card
        v-if="ask"
        class="mx-auto d-flex flex-column"
        style="padding-bottom: 0px; padding-top: 50px"
        >
          <div
            class="meuCard"
            style="
              margin: 0;
              padding-bottom: 0px;
              overflow-x: hidden;
              overflow-y: hidden;
            "
          >
            <v-row wrap>
              <v-col cols="12" md="12">
                <div class="text-center">
                  <img
                    src="../../../assets/question-mark.png"
                    alt=""
                    height="130"
                    width="130"
                    style="display: block; margin-left: auto; margin-right: auto"
                  />
                </div>
                <div class="textoCabelho">
                  <h4 style="font-weight: bold; color: #111" class="text-center">
                    Garanta que sempre terás acesso a sua conta
                  </h4>
                  <br />
                  <p class="text-center text-body-2 text-md-body-1">
                    Crie uma senha para a sua conta para ter mais de uma forma de entrar na plataforma. isso irá permitir  que possa entrar sempre na plataforma sempre que não tiver acesso ao seu numero de telefone.
                  </p>
                </div>
  
                <br />
  
                <v-card-actions v-if="true">
                  <v-row wrap justify="center">
                    <v-col cols="12" md="4">
                      <v-btn block color="#FF4500" dark rounded @click="() => ask = false"
                        >Definir senha</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card
        v-else
        class="mx-auto d-flex flex-column"
        >
        <v-tabs v-model="tab" background-color="#FF4500" icons-and-text dark grow>
            <v-tabs-slider color="#2F4858"></v-tabs-slider>
                  <!-- <v-tabs-slider color="#E61F31"></v-tabs-slider> -->
            <v-tab key="forgot_password_tab">
              <v-icon>mdi-account</v-icon>
              <div class="caption">Definir Senha</div>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
        </v-tabs-items>
            <validation-observer ref="changePasswordForm" v-slot="{}">
                <v-card>
                    <v-form @submit.prevent="changePassword">
                        <v-card-text>
                            <validation-provider v-slot="{ errors }" name="Nova Senha" rules="required|strong_password">
                              <v-text-field v-model="formData.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="new-password"
                                label="Nova Senha" hint="Mínimo de 7 characters" counter :error-messages="errors"
                                @click:append="show1 = !show1"></v-text-field>
                            </validation-provider>
                            <validation-provider v-slot="{ errors }" name="Senha"
                              rules="required|confirmed:Nova Senha|strong_password">
                              <v-text-field v-model="formData.password_confirmation"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]"
                                :type="show1 ? 'text' : 'password'" name="confirm-new-password" label="Confirmar Nova Senha"
                                hint="Mínimo de 7 characters" counter :error-messages="errors"
                                @click:append="show1 = !show1"></v-text-field>
                            </validation-provider>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn type="submit" small elevation="4" dark color="secondary">Submeter</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </validation-observer>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <script>
    import Swal from "sweetalert2";
    import {
    ValidationProvider,
    ValidationObserver,
    setInteractionMode,
    extend,
    } from "vee-validate";
    import {
    required,
    digits,
    email,
    confirmed,
    max,
    regex,
    } from "vee-validate/dist/rules";
    setInteractionMode("eager");

    extend("required", {
    ...required,
    message: "{_field_} não pode estar vazio!",
    });

    extend("digits", {
    ...digits,
    message: "{_field_} deve conter {length} dígitos. ({_value_})!",
    });

    extend("email", {
    ...email,
    message: "O email deve ser válido!",
    });

    extend("confirmed", {
    ...confirmed,
    message: "O valor para o campo {_field_} não coincide!",
    });

    extend("strong_password", {
    message: (field) =>
        "A " +
        field +
        " deve conter pelo menos 1 caracter minúsculo, 1 caracter maiúsculo, 1 dígito e um cumprimento não inferior a 7!",
    validate: (value) => {
        // let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/); // Com caracteres especiais
        let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/);
        return pattern.test(value);
    },
    });
  export default {
    components: { ValidationProvider, ValidationObserver },
    computed: {
        passwordMatch() {
        return () =>
            this.confirm_password === this.formData.password ||
            "A senha deve ser a mesma";
        },
        validForm() {
        return this.confirm_password === this.formData.password ? true : false;
        },
        getCandidate() {
        return this.$store.getters.getCandidate;
        },
        auth() {
        return this.$store.state.candidate;
        },
        hasPassword() {
        return this.$store.state.hasPassword;
        }
    },
    data () {
      return {
        dialog: false,
        ask: true,
        disabled: false,
        loading: false,
        // auth: {},
        formData: {
            password: "",
            password_confirmation: "",
            forced: false,
        },
        confirm_password: "",
        show1: false,
        password: "Password",
        rules: {
            required: (value) => !!value || "Required.",
            min: (v) => v.length >= 7 || "Min 7 characters",
            emailMatch: () => `The email and password you entered don't match`,
        },
        tab: null,
      }
    },
    methods: {
    getAuth() {
      // this.auth = this.$store.state.candidate;
    },
    changePassword() {
      // alert('sbdshbdhsdhs')
      this.loading = true;
      this.disabled = true;
      this.$store.dispatch("loaderChange", true);
      /*
      if (this.getCandidate.first_login == false) {
        this.formData.forced = 1;
      } else {
        this.formData.forced = 1;
      }
      */
      this.formData.forced = 1;
      const isValidLoginForm = this.$refs.changePasswordForm.validate();
      if (!isValidLoginForm) {
        Swal.fire({
          title: "Alerta de Validação!",
          html: `Verifque o preenchimento dos campos antes de enviar o formulário.`,
          icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#FF4500",
        });

        return;
      }

      this.$store.dispatch("changePassword", this.formData)
      .then(() => {
        Swal.fire({
          title: "Sucesso!",
          text: "Agora pode fazer o login utilizando a sua senha!",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#FF1744",
        });
        this.$store.state.hasPassword = false
        this.$store.dispatch("loaderChange", false);
        this.loading = false;
        this.disabled = false;

        this.$store.dispatch("me");

        this.$router.push({ name: "site.contents" });
      })
      .catch((error) => {
        this.loading = false;
        this.disabled = false;
      });
    },
  },
  }
</script>