<template>
  <v-container class="mt-8">
    <v-row wrap align="center" justify="center">
      <v-col sm="11">
        <v-row wrap align="center" justify="space-between">
          <v-col cols="12" md="12">
            <h4
              style="text-transform: uppercase; font-size: 20px; color: #ff5252"
            >
              Meus Concursos
            </h4>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              router
              :to="{ name: 'recruiter.contents.create' }"
              small
              elevation="4"
              dark
              color="red acent-3"
              tile
            >
              <v-icon small class="mr-2">mdi-plus</v-icon>
              Publicar Concurso
            </v-btn>
          </v-col>

          <v-col md="3">
            <search-content-component
              @searchContentEvent="searchContentEvent"
            ></search-content-component>
          </v-col>
        </v-row>

        <v-row v-if="search_alert">
          <v-col cols="12">
            <v-alert
              v-if="contents.total > 0"
              dense
              text
              type="success"
              border="left"
            >
              <v-row justify="space-between">
                <v-col>
                  <span class="caption"
                    >{{ contents.total }} resultados de {{ search }}.
                  </span>
                </v-col>
                <v-col class="text-right">
                  <v-btn @click="resetSearchResults" color="success" small text
                    >Limpar <v-icon small>mdi-close</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>

            <v-alert v-else dense text type="error" border="left">
              <v-row justify="space-between">
                <v-col>
                  <span class="caption"
                    >{{ contents.total }} resultados de {{ search }}.
                  </span>
                </v-col>
                <v-col class="text-right">
                  <v-btn @click="resetSearchResults" color="error" small text
                    >Limpar <v-icon small>mdi-close</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>

        <v-row wrap justify="space-between">
          <v-col
            sm="12"
            md="6"
            v-for="content in contents.data"
            :content="content"
            :key="content.id"
            class="mb-3"
          >
            <card-component
              :content="content"
              :detailsRoute="{
                name: 'recruiter.contents.show',
                params: { id: content.id },
              }"
            ></card-component>
          </v-col>
        </v-row>
        <v-row
          wrap
          align="center"
          justify="center"
          v-if="contents.data.length == 0"
        >
          <v-col cols="12" md="12" class="text-center">
            <v-row wrap justify="center">
              <v-col cols="12" sm="6" md="6">
                <div>
                  <img
                    height="200x"
                    width="200x"
                    src="../../../assets/ui/concurso.png"
                  />
                </div>
              </v-col>
            </v-row>
            <p
              style="
                font-weight: 900;
                text-transform: capitalize;
                font-size: 20px;
              "
            >
              Ainda não possuí Concursos
            </p>
            <v-row wrap justify="center">
              <v-col cols="12" sm="6" md="6">
                <div
                  style="background-color: #fdece5; border: 1px solid #ff4500"
                >
                  <p style="padding: 10px">
                    <span style="font-weight: 900; width: 300px"
                      >Crie os seus Concursos</span
                    >
                    e veja aqui.
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" v-if="contents.data.length != 0">
            <v-pagination
              v-model="contents.current_page"
              :length="contents.last_page"
              @input="handleChangePage"
              color="error"
              total-visible="7"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CardComponent from "../layouts/CardComponentContent";
import SearchContentComponent from "../layouts/SearchContentComponent";

export default {
  components: {
    CardComponent,
    SearchContentComponent,
  },

  data() {
    return {
      search: "",
      search_alert: false,
    };
  },

  created() {
    this.getContents(1);
    // this.getCategories(2);
  },

  computed: {
    contents() {
      return this.$store.state.items;
    },

    params() {
      return {
        titulo: this.search,
      };
    },
  },

  methods: {
    getContents(page) {
      this.$store.dispatch("getMyContents", {
        ...this.params,
        page,
      });
    },

    getCategories(category_code = 2) {
      this.$store.dispatch("category_on_recruiter/getCategories", {
        category_code,
      });
    },

    searchContentEvent(filter) {
      this.search = filter;
      this.getContents(1);
      this.search_alert = true;
    },

    resetSearchResults() {
      this.search = "";
      this.getContents(1);
      this.search_alert = false;
    },

    handleChangePage(page) {
      this.getContents(page);
    },
  },
};
</script>
<style scoped>
</style>