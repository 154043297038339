<template>
  <v-container class="mt-8">
    <v-container :class="isMobile ? 'pl-12' : 'pl-0'">
      <v-btn elevation="2" class="no-uppercase" small @click="goToperfiDash()">
        <v-icon style="padding-right: 14px" small> mdi-arrow-left </v-icon>
        Voltar
      </v-btn>
      <!-- <h3>Estado de Subscrição de Concursos e pedidos de cotações</h3> -->

      <!-- Estado e Dias -->

      <v-container style="">
        <div class="d-flex flex-wrap flex-column align-center justify-center">
          <h3>Estado de Subscrição de Concursos e pedidos de cotações</h3>
          <h4 style="margin-left: -35px" :class="isMobile ? 'ml-n8' : 'ml-n7'">
            <!-- <v-icon size="15" style="color: #ff4500">
              mdi-calendar-blank
            </v-icon>
            Estado de Subscrição de Concursos e pedidos de cotações -->
          </h4>

          <p :class="isMobile ? 'ml-n15' : 'ml-n7'" style="color: #111;">
            <!-- d{{ data_subscricao }} -->
            <!-- <span v-if="data_subscricao > 0">Expira</span> <span v-else>Expirou</span> no dia <strong>{{ dias }}</strong> -->
            <span v-if="data_subscricao < 0">Expirou no dia <strong>{{ dias }}</strong></span>
            <span v-if="data_subscricao == 0">Expirou no dia <strong>{{ dias }}</strong></span>
            <span v-if="data_subscricao > 0">Expira no dia <strong>{{ dias }}</strong></span>
          </p>
          <!-- <p :class="isMobile ? 'ml-n15' : 'ml-n7'">
            {{ getCandidate.subcribe_empresa }} dias (Empresas)
          </p> -->
          <v-btn elevation="2" class="no-uppercase" style="margin: 4px; color: white"
            :to="{ name: 'site.available.plans'}" color="green darken-1">
            <v-icon style="padding-right: 14px"> mdi-refresh </v-icon>
            Actualizar
          </v-btn>
        </div>
      </v-container>
    </v-container>
  </v-container>
</template>
<script>
var moment = require('moment');
export default {
  name: "EstadoSubscricaoPerfil",
  data() {
    return {
      data_subscricao: "",
      dias: ""
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
    getCandidate() {
      let candidate = this.$store.state.candidate;
      moment.locale('pt');
      let dias = moment(candidate.subcribe).format('DD [de] MMMM [de] YYYY')
      // this.data_subscricao = candidate.subcribe_date;
      return dias;
    },
  },
  methods: {
    goToperfiDash() {
      this.$router.push({ name: "perfil.dash" });
    },
  },
  created() {
    let candidate = this.$store.state.candidate;
    this.data_subscricao = candidate.subcribe_date;
    moment.locale('pt');
    this.dias = moment(this.$store.state.candidate.subscribe).format('DD [de] MMMM [de] YYYY')

  }
};
</script>
<style scoped>
.no-uppercase {
  text-transform: unset !important;
  background-color: #f66459 !important;
  color: white;
}

h3 {
  color: #ff4500;
  margin-left: 7px;
  margin-top: 40px;
  margin-bottom: -20px;
}

h4 {
  color: #ff4500;
  margin-left: 7px;
  margin-top: 30px;
}

p {
  /*  margin-left: 30px; */
  color: #c3c3c3;
}
</style>