<!-- eslint-disable no-unreachable -->
<!-- eslint-disable no-unreachable -->
<template>
  <v-container class="mt-8">
    <v-dialog v-model="dialog" persistent width="260">
      <v-card color="#B61827" dark>
        <v-card-title class="text-h5">
          Pagamento M-Pesa
        </v-card-title>
        <img src="../../../assets/pagarMpesa.png" width="250" alt="">

        <v-card-text>
          <p class="text-center" style="color: #fff;">Por favor termine o pagamento no teu telefone com M-Pesa {{ telefone
          }}</p>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div>
      <v-row wrap justify="center">
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" class="mb-6">
              <v-btn :to="{ name: 'site.subscricao' }" class="ml-0" tile elevation="4" dark color="red acent-3" small>
                <v-icon left small> mdi-arrow-left </v-icon>Voltar</v-btn>
            </v-col>
          </v-row>
          <v-row wrap justify="center">
            <v-col cols="12" md="9">
              <h2 class="title">Métodos de Pagamento</h2>
            </v-col>
          </v-row>
          <v-row wrap justify="center">
            <!-- <div class="payment-method"> -->
            <v-col cols="12" md="9">
              <v-select @change="visaClick($event)" :items="items" label="Escolha um método de Pagamento" item-text="meth"
                item-value="code"></v-select>
            </v-col>
            <!-- <v-col cols="12" md="4">
              <label for="card" class="method card">
                <div class="card-logos">
                  <img
                    src="../../../../assets/images/pagarMpesa.png"
                    height="80"
                    width="auto"
                  />
                </div>
  
                <div class="radio-input">
                  <input
                    type="radio"
                    name="payment"
                    v-model="metodoPagamento"
                    value="1"
                    @change="visaClick($event)"
                  />
                  Transferência Bancária
                </div>
              </label>
            </v-col>
  
            <v-col cols="12" md="4">
              <label for="paypal" class="method paypal">
                <img
                  src="../../../../assets/images/visapaypal.png"
                  height="80"
                  width="auto"
                />
                <div class="radio-input">
                  <input
                    id="paypal"
                    type="radio"
                    name="payment"
                    v-model="metodoPagamento"
                    value="2"
                    @change="visaClick($event)"
                  />
                  Paypal
                </div>
              </label>
            </v-col>
  
            <v-col cols="12" md="4">
              <label for="paypal" class="method paypal">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/M-pesa-logo.png/640px-M-pesa-logo.png"
                  height="60"
                  width="60"
                />
                <div class="radio-input">
                  <input
                    id="mpesa"
                    type="radio"
                    disabled
                    name="payment"
                    v-model="metodoPagamento"
                    value="3"
                    @change="visaClick($event)"
                  />
                  M-pesa (Brevemente)
                </div>
              </label>
            </v-col> -->
            <!-- </div> -->
          </v-row>

          <div style="margin-bottom: 4px" class="pa-2 mb-auto" v-if="metodoPagamento == 1">
            <v-row wrap justify="center" class="mt-6">
              <h4 class="text-h6 text-md-h4 accent--text" style="font-weight: bold">
                Como pagar a sua Subscrição
              </h4>
            </v-row>

            <v-row wrap justify="center">
              <v-col cols="12" md="12">
                <p class="text-center">
                  Para pagar {{ pacote.mes }}
                  <span v-if="pacote.mes == 1">mês</span>
                  <span v-else>meses</span> de subscrição por favor siga o passo
                  abaixo
                </p>
              </v-col>
              
              <v-col cols="12" md="9">
                <p>
                  Introduza o número M-pesa que usará para efectuar o pagamento.
                </p>
              </v-col> 


              <!-- <v-col cols="12" md="9">
                <p>
                  Para pagar {{ pacote.mes }}
                  <span v-if="pacote.mes == 1">mês</span>
                  <span v-else>meses</span> de subscrição por favor siga os passos
                  abaixo
                </p>
                <v-list>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="accent" style="font-weight: bold">
                        1
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        No seu telemóvel digita <strong>*150#</strong>;
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="accent" style="font-weight: bold">
                        2
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        Escolhe a opção <strong>6-Pagamentos;</strong>;
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="accent" style="font-weight: bold">
                        3
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        Selecione <strong>7-Digitar o Código de serviço</strong>;
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="accent" style="font-weight: bold">
                        4
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        Digita <strong>901277</strong> (código de serviço);
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="accent" style="font-weight: bold">
                        5
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        Digita a referência: <strong>{{ referencia }}</strong
                        >;
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="accent" style="font-weight: bold">
                        6
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        Digita o valor a pagar:
                        <strong>{{ pacote.valor }} Meticais</strong>;
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="accent" style="font-weight: bold">
                        7
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        Digita o teu <strong>PIN</strong>;
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="accent" style="font-weight: bold">
                        8
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        <strong>Confirma </strong> a transação.
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col> -->
              
            </v-row>

            <v-row wrap justify="center">
              
              <v-col cols="12" md="9">
                <v-text-field v-model="telefone" placeholder="84/85 XXX XXXX" prefix="+258"></v-text-field>
              </v-col>
              <v-col cols="12" md="9">
                <v-btn dark color="#E20613" @click="pagarMpesa" block>
                  <img src="../../../assets/pagarMpesa.png" height="auto" width="90" />
                  Pague {{ pacote.valor }}.00 Mts
                </v-btn>
              </v-col>
              <v-col cols="12" md="9">
                <h4>Em caso de dificuldades siga os passos abaixo.</h4>
              </v-col>
              <v-col cols="12" md="9">
                <ol>
                  <li>Digita*150#;</li>
                  <li>Escolhe a opção 6-Pagamentos;</li>
                  <li>Selecciona 7-Digitar o código de serviço;</li>
                  <li>Digita o código do serviço 901277;</li>
                  <li>Digita a referência NA;</li>
                  <li>Digita o valor a pagar {{ pacote.valor }};</li>
                  <li>Digita o teu PIN;</li>
                  <li>8)Confirma a transação.</li>
                </ol>
              </v-col>
              <v-col cols="12" md="12">
                <p class="text-center">
                  Após confirmar a transação envie o comprovativo através do WhatsApp 84 543 6085 ou por email para comercial@marrar.co.mz
                </p>
              </v-col>
            </v-row>
          </div>
          <div style="margin-bottom: 4px" class="pa-2 mb-auto" v-if="metodoPagamento == 2">
            <v-row wrap justify="center" class="mt-6">
              <h4 class="text-h6 text-md-h4 accent--text" style="font-weight: bold">
                Como pagar a sua Subscrição
              </h4>
            </v-row>

            <v-row wrap justify="center">
              <p class="text-center">
                <!-- Facilitamos a sua vida na procura de oportunidades de negócio. -->
              </p>
              <v-col cols="12" md="9">
                <v-list>
                  <v-list-item v-for="(validation_note, index_validation_note) in this
                    .validation_notes" :key="index_validation_note">
                    <v-list-item-icon>
                      <v-icon color="accent" style="font-weight: bold">
                        {{ validation_note.code }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        <span v-show="validation_note.code == 1">Para pagar {{ pacote.mes }}
                          <span v-if="pacote.mes == 1">mês</span>
                          <span v-else>meses</span> de subscrição envie
                          {{ pacote.valor }}MT para a conta descrita abaixo:
                          <p>
                            <span style="font-weight: bold">Banco:</span> Absa
                            Bank Moçambique Número
                          </p>
                          <p>
                            <span style="font-weight: bold">Número da Conta:</span>
                            0003102011227
                          </p>
                          <p>
                            <span style="font-weight: bold">NIB: </span>
                            000200030310201122720
                          </p>
                          <p>
                            <span style="font-weight: bold">Titular: </span>
                            Marrar Limitada
                          </p>
                        </span>
                        {{ validation_note.description }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </div>

          <!-- <div
            style="margin-bottom: 4px"
            class="pa-2 mb-auto"
            v-if="metodoPagamento == 2"
          >
            <v-row wrap justify="center" class="mt-6">
              <Paypal />
            </v-row>
          </div> -->
          <v-row justify="space-betwen" style="margin-top: 10%">
            <!-- <v-col cols="8"></v-col> -->
            <v-col cols="12" md="12" class="pl-0 mb-6 md-4 text-start">
              <span>Está enfrentando alguma dificuldade?</span>
              <br />
              <v-btn class="ml-0" tile elevation="4" dark color="#FF5414" small>
                E-mail: comercial@marrar.co.mz ou ligue 84 4181 881</v-btn>
                <ul>
                  <li><span>Email: comercial@marrar.co.mz</span></li>
                  <li>ligue: 84 5436 085</li>
                </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import PricingComponent from "./PricingComponent";
//   import Paypal from "./Paypal";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      dialog: false,
      referencia: "",
      metodoPagamento: 0,
      select: { state: "Florida", abbr: "FL" },
      items: [
        { meth: "M-pesa", code: 1 },
        { meth: "Transferência Bancária / Depósito ", code: 2 },
        { meth: "Paypal / Visa / Mastercard", code: 3 },
      ],
      telefone: "",
      pacote: {},
      mes: 1,
      valor: {
        valor: 579.99,
        mes: 1,
      },
      validation_notes: [
        {
          code: 1,
          description: ``,
        },
        {
          code: 2,
          description: `Envie o email que usa na plataforma e o comprovativo de pagamento para o número 844181881 (Whatsapp) ou para o email comercial@marrar.co.mz.`,
        },
        {
          code: 3,
          description: `Logo a seguir sua conta já estará activa para ver todos os concursos na plataforma Gugla Empresas.`,
        },
      ],
      passos_mpesa: [
        {
          code: 0,
          description: ``,
        },
        {
          code: 1,
          description: ` Digita *150#`,
        },
        {
          code: 2,
          description: `Escolha a opção 6. Pagamentos`,
        },
        {
          code: 3,
          description: `Escolha a opção 7. Digita o código do serviço`,
        },
        {
          code: 4,
          description: ` Digita 901277 (código de serviço)`,
        },
        {
          code: 5,
          description: `Digita a referência: ${localStorage.getItem("ref") + localStorage.getItem("RECRUITER_ID")
            }`,
        },
        {
          code: 6,
          description: `Digita o valor a pagar: ${this.valor} Meticais`,
        },
        {
          code: 7,
          description: `Digita o teu PIN`,
        },
        {
          code: 8,
          description: ` Confirma a transação.`,
        },
      ],
      passos: [
        {
          code: 1,
          description: ` para o M-Pesa (847198770).`,
        },
        {
          code: 2,
          description: `Envie o comprovativo para o numero 847198770 via SMS ou WhatsApp.`,
        },
        {
          code: 3,
          description: `Envie nos também o e-mail que usa para aceder ao Gugla empresas.`,
        },
        {
          code: 4,
          description: `Logo a seguir sua conta já estará activa para ver todos os concursos na plataforma Gugla Empresas.`,
        },
      ],
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    PricingComponent,
    //   Paypal,
  },

  created() {
    this.subscribeLogs()
    this.$store.dispatch('me')
    this.referencia =
      localStorage.getItem("ref") + localStorage.getItem("RECRUITER_ID");
    // window.scrollTop(0, 0);
    let email = localStorage.getItem("RECRUITER_EMAIL");
    localStorage.setItem("email_recruiter", email);
    localStorage.setItem("quantidade_meses", this.mes);
    this.pacote = {
      valor: this.valor,
      mes: this.mes,
    };

  },
  mounted() {
    scroll(0, 0);

  },
  computed: {
    isMobile() {
      console.log(this.$vuetify.breakpoint.name);
      return this.$vuetify.breakpoint.mdAndUp ? true : false;
    },
  },
  props: {
    valor: {
      required: true,
    },
    mes: {
      required: false,
    },
    dias: {
      required: false
    },
    promo: {
      required: false
    }
  },

  methods: {
    subscribeLogs() {
      let data = {
        "recruiter_email": localStorage.getItem("RECRUITER_EMAIL"),
        "subscribe_page_select": 1,
        "subscribe_plan_page": 1,
        "id": localStorage.getItem("id_subs")
      }
      this.$store.dispatch("updatesubscribePageLogs", data).then((res) => {
      })
    },
    getValor(item) {
      this.valor = item;
      console.log("item", item);
    },
    visaClick(event) {
      if (event == 1) {
        let data = {
          "recruiter_email": localStorage.getItem("RECRUITER_EMAIL"),
          "subscribe_plan_page": 1,
          "payment_method_page": 1,
          "id": localStorage.getItem("id_subs"),
          "selected_method": "M-pesa"
        }
        this.$store.dispatch("updatesubscribePageLogs", data).then((res) => {
        })
      }
      else if (event == 2) {
        let data = {
          "recruiter_email": localStorage.getItem("RECRUITER_EMAIL"),
          "subscribe_plan_page": 1,
          "payment_method_page": 1,
          "id": localStorage.getItem("id_subs"),
          "selected_method": "Transferência Bancária / Depósito"
        }
        this.$store.dispatch("updatesubscribePageLogs", data).then((res) => {
        })
      }
      else if (event == 3) {
        let data = {
          "recruiter_email": localStorage.getItem("RECRUITER_EMAIL"),
          "subscribe_plan_page": 1,
          "payment_method_page": 1,
          "id": localStorage.getItem("id_subs"),
          "selected_method": "Paypal"
        }
        this.$store.dispatch("updatesubscribePageLogs", data).then((res) => {
        })
      }
      this.metodoPagamento = event;
      if (this.metodoPagamento == 3) {
        location.replace("https://empresas.gugla.co.mz/paypal.html");
      }
    },
    pagarMpesa() {
      // console.log(this.dias);
      let tipo_pagamento;
      var valor;
      var data;
      if (this.dias === undefined && this.mes !== undefined){
        if (sessionStorage.getItem("tipo_subscricao") == 1) {
          tipo_pagamento = "Visualização de Concursos e pedidos de cotações"
          valor = 580 * this.mes
        }
        if (sessionStorage.getItem("tipo_subscricao") == 2) {
          tipo_pagamento = "Promoção da minha empresa, seus serviços e produtos"
          valor = 580 * this.mes
        }
        if (sessionStorage.getItem("tipo_subscricao") == 3) {
          tipo_pagamento = "Concursos + Registar Empresa"
          valor = 1160 * this.mes
        }
        data = {
          numero_telefone: this.telefone,
          valor: valor,
          quantidade_meses: this.mes,
          tipo_subscricao: tipo_pagamento,
          id_tipo_subscricao: sessionStorage.getItem("tipo_subscricao"),
          empresa_id: sessionStorage.getItem("empresa"),
        };
      } else if (this.dias !== undefined && this.mes === undefined) {
        data = {
          numero_telefone: this.telefone,
          valor: this.valor,
          quantidade_meses: this.mes,
          dias: this.dias,
          tipo_subscricao: "Visualização de Concursos e pedidos de cotações",
          id_tipo_subscricao: sessionStorage.getItem("tipo_subscricao"),
          empresa_id: sessionStorage.getItem("empresa"),
          promo: this.promo
        };
      } else if (this.promo !== undefined) {
        data = {
          numero_telefone: this.telefone,
          valor: this.valor,
          quantidade_meses: this.mes,
          dias: this.dias,
          tipo_subscricao: "Visualização de Concursos e pedidos de cotações",
          id_tipo_subscricao: sessionStorage.getItem("tipo_subscricao"),
          empresa_id: sessionStorage.getItem("empresa"),
          promo: this.promo
        }
      }

      // console.log("mpesa", data)
      // return
      this.dialog = true
      this.$store
        .dispatch("pagarMpesa", data)
        .then(() => {
          Swal.fire({
            title: "Sucesso!",
            text: "Pagamento Efectuado com Sucesso, subscrição actualizada.",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#FF4500",
          });
          this.dialog = false
          this.telefone = "";
          this.$store.dispatch('me')
          this.$router.push({ name: 'site.contents' })
        })
        .catch(() => {
          this.dialog = false
          this.$store.dispatch('me')
          console.log("erro ao efectuar o pagamento.");
        })
        .finally("Finalizou no componet");
      this.$store.dispatch('me')
    },
  },
};
</script>
  
<style scoped>
@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700italic,700,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);

.styleImg {
  height: auto;
  width: 300px;
}

.imgSmall {
  height: auto;
  width: 250px;
  position: relative;
  left: -10px;
}

.imgSmallMobile {
  height: auto;
  width: 200px;
  position: relative;
  left: -30px;
  top: 25px;
}

.alignTest1 {
  padding: 10px;
  margin: 50px 0 10px 0;
}

.passos {
  background-color: #f5f5f5;
  height: 100%;
  padding: 10px;
  margin: 5px;
}

.texto {
  height: 100%;
  padding-top: 50px;
}

img {
  /* height: 9px; */
  align-content: flex-end;
  display: flex;
}

h4 {
  font-family: "Raleway", sans-serif;
}

/* .payment-method {
    display: flex;
    margin-bottom: 60px;
    justify-content: space-between;
  } */

.method {
  display: flex;
  flex-direction: column;
  width: 382px;
  height: 170px;
  padding-top: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: rgb(249, 249, 249);
  justify-content: center;
  align-items: center;
}

.card-logos {
  display: flex;
  width: 150px;
  justify-content: space-between;
  align-items: center;
}

.radio-input {
  margin-top: 20px;
}

.title {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 40px;
  color: #2e2e2e;
}
</style>