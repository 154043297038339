<template>
   
   <v-container class="my-auto"   style="align-items: center; ">   
            <div style="margin:2%">
                <v-card max-width="470" class="mx-auto" >
                    <v-card-title style="justify-content: center;">
                        <img
                            :src="require('../../assets/img/Logo-Empresas-830x354.png')"
                            height="72"
                            width="180"
                            alt="Gugla Empresas"
                        />
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-subtitle style="text-align: center;color: #F66459">
                        <h2>
                            Recuperação da senha
                    </h2></v-card-subtitle>
                    <v-spacer></v-spacer>
                    <v-card-subtitle style="font-weight: 600;color: #000;" >Código de Verificação </v-card-subtitle>
                    <v-card-text>
                    
                        <validation-provider>
                            <v-text-field outlined placeholder="Codigo"></v-text-field>
                        </validation-provider>
                        <v-card-actions>
                            <v-btn text class="no-backButton" style="padding: 0;" :to="{name: 'recuperacao.escolha'}" >
                                <v-icon style="padding-right: 5px" dense>
                                    mdi-arrow-left
                                </v-icon>
                                Voltar
                            </v-btn>
                             <v-spacer></v-spacer>
                            <v-btn :to="{name: 'nova.senha'}"  class="no-uppercase" style="padding-inline: 39px;margin-right: -7px;">
                              Seguinte
                            </v-btn>
                        </v-card-actions>
                        
                   
                    </v-card-text>
                    <!-- <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="no-uppercase" style="padding-inline: 39px;">
                            Seguinte
                        </v-btn>
                    </v-card-actions> -->
                </v-card>

                 
            </div>
           

            
            
    </v-container>
</template>
<script>
export default{
    name:'CodigoRecuperacao',
}
</script>
<style scoped>
.no-uppercase {
  text-transform: unset !important;
  background-color: #ff4500 !important ;
  color: white;
  font-size: small;
}
.no-backButton{
    
    text-transform: unset !important;
    color: #ff4500 !important;
}


</style>