<template>
  <div>
    <!-- <v-row wrap style="margin: 2.5%; top: 0px">
      <v-col cols="12" class="text-center">
        <v-list-item>
          <v-list-item-content style="margin-top: 15px">
            <v-list-item-avatar size="200">
              <img v-if="getCandidate.logo" :src="getCandidate.logo" />
              <img v-else src="../../../assets/img/user-png.png" alt="" />
            </v-list-item-avatar>
            <v-list-item-title class="title" style="margin-top: 20px"
              >{{ getCandidate.name }}
              {{ getCandidate.last_name }}</v-list-item-title
            >
            <v-list-item-subtitle
              >{{ getCandidate.email }}
            </v-list-item-subtitle>
            <br />
            <v-list-item-subtitle>
              <v-chip
                small
                light
                outlined
                color="#FF5414"
                v-bind="attrs"
                v-on="on"
              >
                <span> informação pessoal</span>
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row> -->

    <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin: 2.5%; top: 0px">
        <v-col cols="12" class="text-center">
          <v-list-item>
            <v-list-item-content style="margin-top: 15px">
              <v-list-item-avatar size="200">
                <img v-if="getCandidate.logo" :src="getCandidate.logo" />
                <img v-else src="../../../assets/img/user-png.png" alt="" />
              </v-list-item-avatar>
              <v-list-item-title class="title" style="margin-top: 20px"
                >{{ getCandidate.name }}
                {{ getCandidate.last_name }}</v-list-item-title
              >
              <br>
              <v-list-item-subtitle
                >{{ getCandidate.email }}
              </v-list-item-subtitle>
              <br />
              <v-list-item-subtitle
                >Link para convite: <b><a :href="invite_link" style="color: #111" target="_blank">{{ invite_link }}</a></b>
              </v-list-item-subtitle>
              <br />
              <v-list-item-subtitle>
                <v-chip
                  small
                  light
                  outlined
                  color="#FF5414"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span> informação pessoal</span>
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card>

    <v-spacer></v-spacer>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin: 2.5%; top: 0px">
        <notification :newsletter="newsletter.receive_newsletter" />
      </v-row>
    </v-card>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin: 2.5%; top: 0px">
        <minhas-empresas />
      </v-row>
    </v-card>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin: 2.5%; top: 0px">
        <subscricao />
      </v-row>
    </v-card>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin: 2.5%; top: 0px">
        <meus-concursos-favoritos />
      </v-row>
    </v-card>
    <!-- <v-card class="mx-auto" max-width="1000" tile>
            <v-row wrap style="margin:2.5%; top: 0px">
                <anexo/>
            </v-row>
        </v-card> -->
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row style="margin: 2.5%; top: 0px">
        <v-container>
          <v-card flat width="auto">
            <v-card-text>
              <v-list>
                <v-list-item :to="{ name: 'candidate.chagepassword' }">
                  <v-list-item-icon>
                    <v-icon>mdi-form-textbox-password</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Alterar Senha</v-list-item-title>
                </v-list-item>
                <v-list-item @click.prevent="logout">
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-container>
      </v-row>
    </v-card>

    <loader />
  </div>
</template>

<script>
import Notification from "./notificationComponent.vue";
import MinhasEmpresas from "./MinhasEmpresasComponent.vue";
// import PersonalForm from './forms/PersonalInformationForm.vue'
//import PersonalInfo from './PersonalInfoComponent.vue'
import Subscricao from "./SubscricaoComponent.vue";
import MeusConcursosFavoritos from "./MeusConcursosFavoritosComponent.vue";
// import Anexo from './AnexoComponent.vue'
import Loader from "../../loader/Loader.vue";
import Swal from "sweetalert2";
import MinhasEmpresasComponent from "./MinhasEmpresasComponent.vue";
export default {
  components: {
    MinhasEmpresas,
    MeusConcursosFavoritos,
    Subscricao,
    Loader,
    Notification,
  },
  data() {
    return {
      invite_link: "",
      image: null,
      personal_data: {},
      data: false,
      dialog_newsletters_invite: false,
      dialog_newsletters: false,
      newsletter: {
        receive_newsletter: false,
      },
    };
  },
  computed: {
    getCandidate() {
      let candidate = this.$store.state.candidate;
      return candidate;
    },
    url() {
      if (!this.image) return;
      return URL.createObjectURL(this.image);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
  created() {
    this.$store.dispatch("me");
    this.invite_link = `https://empresas.devbackend.marrar.co.mz/recruiter/register-invite/${localStorage.getItem('RECRUITER_ID')}`
  },
  methods: {
    logout() {
      this.$store.dispatch("loaderChange", true);
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "candidate.login" });
        this.$store.dispatch("loaderChange", false);
      });
    },
    onSubmitReceiveNewsletter() {
      /*if(this.newsletter.receive_newsletter == true){
				this.newsletter.receive_newsletter = 1
			} else {
				this.newsletter.receive_newsletter = 0
			}*/
      this.$store
        .dispatch("activeNewsletter", this.newsletter)
        .then((response) => {
          if (this.newsletter.receive_newsletter) {
            Swal.fire({
              title: "Notificações",
              html: `Activou o serviço de notificação por email.<br/> Passará a Receber notificações sobre as novas vagas publicadas no Gugla Empregos.`,
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#FF4500",
            });
          } else {
            Swal.fire({
              title: "Notificações",
              html: `Desactivou o serviço de notificação por email.`,
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#FF4500",
            });
          }
          this.dialog_newsletters = false;
        });
    },
  },
};
</script>

<style scoped>
img {
  object-fit: cover;
}
</style>