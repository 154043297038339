<template>
  <v-app>
    <!--<header-component/>-->
    <router-view></router-view>
    <!--<change-password/>
    
    <Footer/>-->
    <!--<Loader/>-->
    <vue-fab :mainBtnColor="mainBtnColor" :scrollAutoHide="scrollAutoHide" :size="size"
      :class="isMobile ? 'fab-main-container' : 'fab-main-container-web'">
      <fab-item v-for="(item, idx) in menu" :idx="idx" :key="idx" :title="item.title" :color="item.color"
        :icon="item.icon" @clickItem="clickItem" />
    </vue-fab>
  </v-app>
</template>

<script>

//import loader from './components/loader/Loader.vue'
/*import ChangePassword from './components/candidate/ChangePassword.vue'
import Loader from './components/loader/Loader.vue'
import Footer from './components/footer/footer.vue';
import HeaderComponent from './components/header/HeaderComponent2.vue'
//import axios from 'axios';*/
import Swal from 'sweetalert2'



export default {

  name: 'App',
  component: {},
  /*components: { Footer, HeaderComponent, Loader, ChangePassword},*/
  created: function () {

    if (localStorage.getItem('TOKEN_AUTH')) {
      this.$store.dispatch('loaderChange', true)
      this.$store.dispatch('me')
        .then(() => {
          this.$store.dispatch('loaderChange', false)
        })
        .catch(err => {

          this.$store.dispatch('logout')
          this.$router.push({ name: 'candidate.login' })
          this.$store.dispatch('loaderChange', false)
        })
    }
  },
  data() {
    return {
      menu: [
        {
          icon: 'toc',
          title: 'Publicar concurso',
          color: '#ff9900'
        },
        {
          icon: 'apartment',
          title: 'Criar empresa',
          color: '#FF0000'
        },
        {
          icon: 'subscriptions',
          title: 'Actualizar subscrição',
          color: '#999'
        },
      ],
      mainBtnColor: '#52A755',
      scrollAutoHide: false,
      size: 'big'
    }
  },
  methods: {
    clickItem(item) {
      // console.log(item)
      if(item.idx == 0){
        this.$router.push({name: 'recruiter.contents.create'})
      }else if(item.idx == 1){
        this.$router.push({name: 'empresa.create'})
      }else if(item.idx == 2){
        this.$router.push({name: 'site.subscricao', params: {tipo_subscricao: 1}})
      }
      //  this.$router.push({name: 'recruiter.contents.create'})
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
}
</script>
<style scoped>
.fab-main-container {
  left: 85% !important;
}

.fab-main-container-web {
  left: 83% !important;
}
</style>
