<template>
  <v-container class="mt-8">
    <div>
      <v-row>
        <v-col cols="12" class="mb-6">
          <v-btn
            :to="{ name: 'recruiter.vagas' }"
            class="ml-0"
            tile
            elevation="4"
            dark
            color="red acent-3"
            small
          >
            <v-icon left small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="9">
          <vaga-details :content="content"></vaga-details>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import VagaDetails from "./VagaDetails";
import Swal from "sweetalert2";
// import Swal from "sweetalert2/dist/sweetalert2.js";

// import "sweetalert2/src/sweetalert2.scss";
export default {
  components: {
    VagaDetails,
  },

  props: {
    id: {
      required: true,
    },
  },

  data() {
    return {
      content: {
        image: "",
        titulo: "",
        instituicao: "",
        validade: "",
        categoria_id: "",
        local: "",
        fonte: "",
        conteudo: "",
      },
    };
  },

  created() {
    this.getContent(this.id);
  },

  methods: {
    getContent(id) {
      this.$store.dispatch("getVaga", id).then((response) => {
        this.content = response.data.data;
      });
    },

    removeContentWarning(content) {
      const warning = `Deseja remover o anúncio ${content.titulo}?`;

      Swal.fire({
        title: "Atenção!",
        text: warning,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        confirmButtonColor: "#FF1744",
        cancelButtonColor: "#FF1744",
      }).then((result) => {
        if (result.isConfirmed) {
          this.removeContent(content.id);
        }
      });
    },

    removeContent(content_id) {
      this.$store
        .dispatch("content_recruiter/removeContent", content_id)
        .then(() => {
          Swal.fire({
            title: "Sucesso!",
            text: "Removido com sucesso",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#FF1744",
          }).then(() => {
            this.$router.push({ name: "recruiter.contents" });
          });
        });
    },
  },

  computed: {
    deadline() {
      let today = new Date();
      let deadlineDate = new Date(this.content.validade);

      return today > deadlineDate ? "error" : "success";
    },
  },

  filters: {
    formatDate: (date) => {
      if (date) {
        let newDate = new Date(date);
        let newFormatedDate =
          newDate.getDate() +
          "-" +
          (newDate.getMonth() + 1) +
          "-" +
          newDate.getFullYear();
        return newFormatedDate;
      }

      return "";
    },
  },
};
</script>
<style scoped>
.sweet-alert {
  background-color: #2f2f2f96;
}
</style>