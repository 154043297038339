<!-- eslint-disable no-unreachable -->
<template>
  <v-container class="mt-8">
    <v-row justify="space-betwen">
      <v-col cols="12" md="8">
        <h4 style="text-transform: uppercase; font-size: 20px; color: #ff5252">
          Criar Empresa
        </h4>
      </v-col>
      <v-col cols="12" md="4" class="pl-0 mb-6 md-4 text-start">
        <span>Está enfrentando alguma dificuldade?</span>
        <br />
        <v-btn :href="`mailto:suporte@marrar.co.mz?subject=Registar Empresa`" class="ml-0" tile elevation="4" dark
          color="#FF5414" small>
          <v-icon left small> mdi-message-settings </v-icon>Suporte</v-btn>
      </v-col>
    </v-row>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1" color="#ff5252">
          Informações gerais
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2" color="#ff5252">
          Serviços
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3" color="#ff5252">
          Contactos e endereço
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <br />
          <p class="text-center text--secondary" style="font-size: 15px">
            Preencha o Formulário abaixo para criar a sua Empresas
          </p>
          <v-card tile elevation="0" outlined>
            <v-card-title>
              <h4 class="heading-4 accent--text" color="#FF5252">Dados da Empresa</h4>
            </v-card-title>

            <v-card-text>
              <v-row wrap>
                <v-col cols="12" md="6">
                  <v-text-field label="Nome da Empresa" outlined dense v-model="empresa.nome"
                    color="#ff5252"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select v-model="empresa.industria" dense outlined :items="industrias" item-text="name"
                    item-value="id" label="Industria" placeholder="Selecione o Industria" color="#ff5252"></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-6" tile elevation="0" outlined>
            <v-card-title>
              <h4 class="heading-4 accent--text">Sobre a Empresa</h4>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <vue-editor v-model="empresa.sobre_nos" label="Descreva sobre a Empresa" outlined dense
                    :editor-toolbar="customToolbar"></vue-editor>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card tile elevation="0" outlined>
            <v-card-text>
              <p class="text-center">
                Clique no icone/imagem abaixo para adicionar o logotipo da Empresa
              </p>
              <v-row wrap v-if="imageUploadPreview">
                <v-col cols="12" md="12" class="text-center">
                  <label for="file" class="inputFile">
                    <v-avatar size="120" rounded>
                      <img :src="imageUploadPreview" alt="" />
                    </v-avatar>
                  </label>
                </v-col>
              </v-row>
              <v-row wrap v-else>
                <v-col cols="12" md="12" class="text-center">
                  <label for="file" class="inputFile">
                    <v-avatar size="120" rounded>
                      <img src="../../assets/logo.png" alt="" />
                    </v-avatar>
                  </label>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <v-file-input ref="image_upload" dense truncate-length="1" label="Carregar Imagem" accept="image"
                    @change="onFileChange" id="file" v-show="hidden"></v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div class="" style="height: 20px;"></div>
          <v-btn color="#FF5414" dark small @click="firstSubmit()">
            Próximo
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-row wrap class="mt-6">
            <v-col cols="12">
              <h3 class="accent--text">SERVIÇOS QUE SUA EMPRESA OFERECE</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" md="6" v-for="(household, index) in servicos" :key="index">
              <v-card class="mb-4" tile elevation="0" outlined>
                <v-card-title>
                  <h5 class="heading-5 grey--text">Serviço {{ index + 1 }}</h5>
                </v-card-title>
                <v-card-text>
                  <v-row wrap>
                    <v-col cols="12">
                      <v-file-input
                            accept="image/*"
                            prepend-icon="mdi-camera-image"
                            placeholder="Uma imagem que representa o serviço (opcional)"
                            @change="(e) => {servicos[index].banner = e}"
                            :error-messages="error">
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field v-model="servicos[index].nome" label="Nome do Serviço" outlined dense
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <vue-editor v-model="servicos[index].descricao" label="Descrição do Serviço" outlined required
                        :editor-toolbar="customToolbar"></vue-editor>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn depressed rounded outlined color="secondary" small @click.prevent="removeHousehold(index)"
                    v-show="index || (!index && servicos.length > 1)">Remover Serviço</v-btn>
                  <v-btn rounded color="secondary" small @click.prevent="addHousehold(index)"
                    v-show="index == servicos.length - 1">Adicionar Serviço</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <v-btn color="#FF5414" dark small @click="secondSubmit()">
            Próximo
          </v-btn>

          <v-btn text small @click="e1 = 1">
            Voltar
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card tile elevation="0" outlined>

            <v-card-title>
              <h4 class="heading-4 accent--text" color="#FF5252">Contactos da Empresa</h4>
            </v-card-title>
            <v-card-text>
              <v-row wrap>
                <v-col cols="12" md="4" v-for="(item, index) in contactos" :key="index">

                  <v-text-field v-model="contactos[index].telefone" label="Número de Telefone" outlined dense>

                    <v-icon slot="prepend" color="#E61F31" @click.prevent="removeContactos(index)"
                      v-show="index || (!index && contactos.length > 1)">

                      mdi-minus</v-icon>

                    <v-icon slot="append" color="#FF5252" @click.prevent="addContactos(index)"
                      v-show="index == contactos.length - 1">

                      mdi-plus</v-icon>

                  </v-text-field>

                </v-col>

                <v-col cols="12" md="4">

                  <v-text-field v-model="empresa.email_cotacao" label="E-mail para Cotação" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="empresa.website" label="Website" outlined dense></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mt-6" tile elevation="0" outlined>
            <v-card-title>
              <h4 class="heading-4 accent--text">Endereço da Empresa</h4>
            </v-card-title>
            <v-card-text>

              <v-row>

                <v-col cols="12" md="4">

                  <v-select v-model="empresa.provincia" dense outlined :items="provinces" item-text="name"
                    item-value="map_code" label="Provincia" placeholder="Selecione a Provincia"
                    @change="changeProvince"></v-select>

                </v-col>

                <v-col cols="12" md="4">

                  <v-select v-model="empresa.distrito" dense outlined :items="districts.data" item-text="name"
                    item-value="id" label="Distrito" placeholder="Selecione o Distrito"></v-select>

                </v-col>

                <v-col cols="12" md="4">

                  <v-text-field v-model="empresa.localizacao" label="Localização" outlined dense></v-text-field>

                </v-col>

              </v-row>

            </v-card-text>

          </v-card>
          <div class="" style="height: 20px;"></div>
          <v-btn color="#FF5414" dark small @click="lastSubmit()">
            Submeter
          </v-btn>

          <v-btn text small @click="e1 = 2">
            Voltar
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <div class="" style="height: 90px;"></div>
  </v-container>
</template>
  
<script>
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    //   ValidationProvider,
    // ValidationObserver,
    VueEditor,
  },
  props: {
    empresa: {
      required: false,
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object | Array,
      default: () => {
        return {
          id: "",
          image: "",
          nome: "",
          industria: "",
          email: "",
          telefone: "",
          provincia: "",
          distrito: "",
          localizacao: "",
          servicos: "",
          sobre_nos: "",
          email_cotacao: "",
          website: "",
        };
      },
    },
    // updating: {
    //   required: true,
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      e1: 1,
      id: "",
      date: new Date().toISOString().substr(0, 10),
      districts: [],
      menu: false,
      tab: null,
      image_upload: null,
      anexo_conteudo_upload: null,
      hidden: false,
      nome: "",
      services: "",
      emails: [
        {
          email: "",
        },
      ],
      contactos: [
        {
          telefone: "",
        },
      ],
      servicos: [
        {
          banner: null,
          nome: "",
          descricao: "",
        },
      ],
      locals: [
        "Cabo Delgado",
        "Gaza",
        "Inhambane",
        "Manica",
        "Maputo",
        "Nampula",
        "Niassa",
        "Sofala",
        "Tete",
        "Zambézia",
      ],
      industrias: [
        "Administração e Secretariado Cliente",
        "Aquisições e Procurement",
        "Assistente",
        "Auditoria e Consultoria",
        "Comunicação Social",
        "Construção Civil",
        "Contabilidade e Finanças",
        "Design",
        "Direito e Justiça",
        "Electricidade",
        "Gestão de Projectos",
        "Gestão e Assessoria",
        "Gestão Financeira",
        "Gestor Provincial",
        "Higiene e Segurança",
        "Hotelaria e Turismo",
        "informática e Programação",
        "Manutenção e Técnica",
        "Marketing e Publicidade",
        "Mecânica",
        "Meio Ambiente",
        "Monitoria e Avaliação",
        "Oficial Técnico",
        "Operário",
        "Recursos",
        "Humanos",
        "Relações Públicas",
        "Saúde",
        "Seguros",
        "Serviços Gerais",
        "Serviços Sociais",
        "Supervisão e Coordenação",
        "Topografia e Geologia",
        "Transportes e Logística",
        "Vendas/Comércio",
        "Outro",
      ],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "bullet" }, { list: "ordered" }, { list: "check" }],
        [{ color: [] }],
        ["link"],
      ],
    };
  },
  created() {
    // console.log("aksn");
    this.getProvinces();
  },
  methods: {
    getProvinces() {
      this.$store.dispatch("getProvince");
    },
    changeProvince(id) {
      // console.log(item)
      this.$store.dispatch("getDistricts", id).then((res) => {
        // console.log(res.data)
        this.districts = res.data;
      });
    },
    addHousehold() {
      this.servicos.push({
        nome: "",
        descricao: "",
        banner: null
      });
    },
    addEmails() {
      this.emails.push({
        email: "",
      });
    },
    addContactos() {
      this.contactos.push({
        telefone: "",
      });
    },
    removeEmails(index) {
      this.emails.splice(index, 1);
    },
    removeContactos(index) {
      this.contactos.splice(index, 1);
    },
    removeHousehold(index) {
      this.servicos.splice(index, 1);
    },
    onFileChange(e) {
      this.image_upload = e;
    },
    onSubmit() {
      // console.log(this.servicos);
      if (this.servicos.length > 1) {
        this.servicos.forEach((element, index) => {
          if (element.nome == "" || element.descricao == "") {
            this.servicos.splice(index, 1);
          }
        });
      }
      const formData = new FormData()
      formData.append("nome", this.empresa.nome);
      formData.append("industria", this.empresa.industria);
      // formData.append("email", JSON.stringify(this.emails));
      formData.append("telefone", JSON.stringify(this.contactos));
      // formData.append("provincia", this.empresa.provincia);
      formData.append("district_id", this.empresa.distrito);
      formData.append("localizacao", this.empresa.localizacao);
      formData.append("servicos", JSON.stringify(this.servicos));
      formData.append("sobre_nos", this.empresa.sobre_nos);
      formData.append("email_cotacao", this.empresa.email_cotacao);
      formData.append("website", this.empresa.website);
      if (this.image_upload !== null) {
        formData.append("image", this.image_upload);
      }

      this.$store.dispatch("storeEmpresa", formData).then(() => {
        // this.$router.push({ name: "minhas.empresas" });
        Swal.fire({
          title: "Sucesso!",
          text: "Empresa Criada com Sucesso, notificaremos por e-mail os próximos passos.",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#FF4500",
        });
        localStorage.setItem("TEM_EMPRESA", 1);
      });
    },
    lastSubmit() {
      const formData = new FormData()
      formData.append("id", this.id);
     
      formData.append("telefone", JSON.stringify(this.contactos));
      // formData.append("provincia", this.empresa.provincia);
      formData.append("district_id", this.empresa.distrito);
      formData.append("localizacao", this.empresa.localizacao);
      formData.append("email_cotacao", this.empresa.email_cotacao);
      formData.append("website", this.empresa.website);

      this.$store.dispatch("updateEmpresa", formData).then(() => {
        // this.$router.push({ name: "minhas.empresas" });
        Swal.fire({
          title: "Sucesso!",
          text: "A sua empresa já se encontra registada com sucesso. Para que seja publicada por favor efectue o pagamento.",
          icon: "success",
          showDenyButton: true,
          confirmButtonText: "Efectuar pagamento",
          confirmButtonColor: "#FF4500",
          denyButtonText: `Ver empresas`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.$router.push({ name: 'site.available.plans' });
          } else if(result.isDenied){

            this.$router.push({ name: 'empresa.minhas' });
          }
        });
        localStorage.setItem("TEM_EMPRESA", 1);
      });
    },
    async secondSubmit() {
      // console.log(this.servicos);
      if (this.servicos.length > 1) {
        this.servicos.forEach((element, index) => {
          if (element.nome == "" || element.descricao == "") {
            this.servicos.splice(index, 1);
          }
        });
      }
      // eslint-disable-next-line no-unreachable
      // console.log(this.servicos)
      if (this.servicos.length > 1 || this.servicos[0].nome !== "") {
        this.servicos.forEach(async servico => {
          
          //   console.log(servico.descricao);
          const formData = new FormData()
          formData.append("id", this.id);
          formData.append("nome", servico.nome);
          formData.append("descricao", servico.descricao);
          if (servico.banner !== null) {
            formData.append("image", servico.banner);
          }
          // console.log(formData);
          // return;
          // eslint-disable-next-line no-unreachable
          const res = await this.$store.dispatch("addService", formData)
          // console.log(res);
        });
        // for ( const servico in this.servicos) {
        //   console.log(servico.descricao);
        //   const formData = new FormData()
        //   formData.append("id", this.id);
        //   formData.append("nome", servico.nome);
        //   formData.append("descricao", servico.descricao);
        //   if (servico.banner !== null) {
        //     formData.append("image", servico.banner);
        //   }
        //   const res = await this.$store.dispatch("addService", formData)
        //   console.log(res);
        // }
        this.e1 = 3
        localStorage.setItem("TEM_EMPRESA", 1);
      }

    },
    firstSubmit() {
      const formData = new FormData()
      formData.append("nome", this.empresa.nome);
      formData.append("industria", this.empresa.industria);
      formData.append("sobre_nos", this.empresa.sobre_nos);
      if (this.image_upload !== null) {
        formData.append("image", this.image_upload);
      }

      this.$store.dispatch("storeEmpresa", formData).then((res) => {
        // this.$router.push({ name: "minhas.empresas" });
        this.id = res.data.data.id
        // Swal.fire({
        //   title: "Sucesso!",
        //   text: "Empresa Criada com Sucesso, preencha os proximos passos para completar o registo.",
        //   icon: "success",
        //   confirmButtonText: "OK",
        //   confirmButtonColor: "#FF4500",
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     this.e1 = 2
        //   }
        // });
        this.e1 = 2
        localStorage.setItem("TEM_EMPRESA", 1);
      });
    },
  },
  computed: {
    provinces() {
      return this.$store.state.provinces;
    },
    actionSubmit() {
      return this.updating ? "Actualizar" : "Salvar";
    },

    // eslint-disable-next-line vue/return-in-computed-property
    imageUploadPreview() {
      if (this.image_upload) {
        return URL.createObjectURL(this.image_upload);
      } else if (this.empresa.image) {
        return this.empresa.image;
      }
      return;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    anexoConteudoUploadPreview() {
      if (this.anexo_conteudo_upload) {
        return URL.createObjectURL(this.anexo_conteudo_upload);
      } else if (this.vaga.anexo_conteudo) {
        return this.vaga.anexo_conteudo;
      }
      return;
    },
  },
};
</script>
  
<style>
.inputFile {
  cursor: pointer;
}
</style>