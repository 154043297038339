import axios from 'axios'


// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
// import 'sweetalert2/src/sweetalert2.scss'

function errorResponseHendler(error) {
    // console.log(error.response);
    // store.commit('loading/CHANGE_LOADING', false)
    // console.log('RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR FAAIIIIIILLLLLLL');
    if (error.config.hasOwnProperty.call('errorHandle') && error.config.errorHandle === false) {

        return Promise.reject(error);
    }
    console.log(error)

    // if has response show the error
    if (error.response) {
        // console.log("hereee", error.response.data.message)
        // testando o store
        // let error_tes = store.state.auth.test
        let error_status = error.response.status

        if (error_status == 302) {

            Swal.fire({
                title: 'Erro ao Pagar',
                text:  error.response.data.message,
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#FF1744'
            })

        }
        else if (error_status == 402) {

            Swal.fire({
                title: 'Conta bloqueada/desactivada!',
                text:  error.response.data.message,
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#FF1744'
            })

        }
        if (error_status == 404) {
            // console.log(error.response.data.message);
            // Swal.fire({
            //     // title: 'Erro!',
            //     text: 'O concurso que tentou ver já não existe no Gugla Empresas.',
            //     // text: error.response.data.message,
            //     icon: 'error',
            //     confirmButtonText: 'Ver outros Concursos',
            //     confirmButtonColor: '#FF1744'
            // })

            Swal.fire({
                // title: 'Sweet!',
                text: error.response.data.message,
                imageUrl: 'https://empresas.gugla.co.mz/img/lupa.png',
                // imageWidth: 100,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'ok',
                confirmButtonColor: '#FF1744'
            })

        } else if (error_status == 422) {

            // Swal.fire({
            //     title: 'Erro de Validação!',
            //     text: error.response.data.errors.email,
            //     icon: 'error',
            //     confirmButtonText: 'OK',
            //     confirmButtonColor: '#FF1744'
            // })
            let validation_errors = error.response.data.errors
            let first_error_key = Object.keys(validation_errors)[0]

            Swal.fire({
                title: 'Erro de Validação!',
                text: validation_errors[first_error_key],
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#FF4500'
            })

        } else if (error_status == 400) {


            Swal.fire({
                title: 'Credenciais Invalidas!',
                text: error.response.data.message,
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#FF1744'
            })
        } else if (error_status == 403) {


            Swal.fire({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#FF1744'
            })
        } else if (error_status == 405) {


            Swal.fire({
                title: 'Utilizador não esta activo!',
                text: error.response.data.message,
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#FF1744'
            })

        } else if (error_status == 500) {

            Swal.fire({
                title: 'Erro de Validação!',
                text: 'Erro interno do servidor',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#FF1744'
            })

        }
        return Promise.reject(error);
    }


}



// apply interceptor on response
axios.interceptors.response.use(
    response => response,
    errorResponseHendler
)

export default errorResponseHendler