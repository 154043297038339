<template>
  <!-- <div>
        <change-password />
	</div> -->
  <v-container fluid fill-height style="background-color: #f0f2f5">
    <v-row wrap justify="center" align="center">
      <v-col cols="12">
        <template>
          <!-- <v-container fluid fill-height> -->
          <!-- <v-layout row wrap justify-center align-center> -->
          <!-- <v-flex xs12 md4 dark color="red acent-3"> </v-flex> -->
          <v-row wrap justify="center" align-center>
            <v-col cols="12" md="4">
              <v-card elevation="2" max-width="470" :disabled="disabled">
                <v-tabs v-model="tab" background-color="#FF4500" icons-and-text dark grow>
                  <v-tabs-slider color="#2F4858"></v-tabs-slider>
                  <!-- <v-tabs-slider color="#E61F31"></v-tabs-slider> -->
                  <v-tab key="forgot_password_tab">
                    <v-icon>mdi-account</v-icon>
                    <div class="caption">Definir nova Senha</div>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <validation-observer ref="changePasswordForm" v-slot="{}">
                    <v-card>
                      <v-form @submit.prevent="changePassword">
                        <!-- <v-card-title>Gugla Empregos</v-card-title> -->
                        <!-- <v-card-subtitle>Trocar Senha</v-card-subtitle> -->
                        <v-card-text>
                          <!-- <v-text-field
                            v-if="auth.first_login"
                            v-model="formData.password_actual"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            name="new-password"
                            label="Senha Actual"
                            hint="Mínimo de 7 characters"
                            counter
                            :error-messages="errors"
                            @click:append="show1 = !show1"
                          ></v-text-field> -->
                          <validation-provider v-slot="{ errors }" name="Nova Senha" rules="required|strong_password">
                            <v-text-field v-model="formData.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="new-password"
                              label="Nova Senha" hint="Mínimo de 7 characters" counter :error-messages="errors"
                              @click:append="show1 = !show1"></v-text-field>
                          </validation-provider>
                          <validation-provider v-slot="{ errors }" name="Senha"
                            rules="required|confirmed:Nova Senha|strong_password">
                            <v-text-field v-model="formData.password_confirmation"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]"
                              :type="show1 ? 'text' : 'password'" name="confirm-new-password" label="Confirmar Nova Senha"
                              hint="Mínimo de 7 characters" counter :error-messages="errors"
                              @click:append="show1 = !show1"></v-text-field>
                          </validation-provider>
                        </v-card-text>
                        <v-card-actions>

                          <!-- <v-btn
                            type="submit"
                            small
                            elevation="4"
                            dark
                            color="secondary"
                            >Submeter</v-btn
                          > -->
                          <v-spacer></v-spacer>
                          <!-- <v-btn
                            small
                            elevation="4"
                            dark
                            color="secondary"
                            outlined
                            :to="{ name: 'site.contents' }"
                            >Cancelar</v-btn
                          > -->
                          <v-btn type="submit" small elevation="4" dark color="secondary">Submeter</v-btn>
                        </v-card-actions>
                      </v-form>

                    </v-card>
                  </validation-observer>
                </v-tabs-items>
                <v-progress-linear indeterminate color="#FF5414" height="4" v-show="loading"></v-progress-linear>
              </v-card>
            </v-col>
          </v-row>
          <!-- </v-layout> -->
          <!-- </v-container> -->
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from "vee-validate";
import {
  required,
  digits,
  email,
  confirmed,
  max,
  regex,
} from "vee-validate/dist/rules";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} não pode estar vazio!",
});

extend("digits", {
  ...digits,
  message: "{_field_} deve conter {length} dígitos. ({_value_})!",
});

extend("email", {
  ...email,
  message: "O email deve ser válido!",
});

extend("confirmed", {
  ...confirmed,
  message: "O valor para o campo {_field_} não coincide!",
});

extend("strong_password", {
  message: (field) =>
    "A " +
    field +
    " deve conter pelo menos 1 caracter minúsculo, 1 caracter maiúsculo, 1 dígito e um cumprimento não inferior a 7!",
  validate: (value) => {
    // let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/); // Com caracteres especiais
    let pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/);
    return pattern.test(value);
  },
});
export default {
  components: { ValidationProvider, ValidationObserver },
  computed: {
    passwordMatch() {
      return () =>
        this.confirm_password === this.formData.password ||
        "A senha deve ser a mesma";
    },
    validForm() {
      return this.confirm_password === this.formData.password ? true : false;
    },
    getCandidate() {
      return this.$store.getters.getCandidate;
    },
    auth() {
      return this.$store.state.candidate;
    }
  },

  data() {
    return {
      disabled: false,
      loading: false,
      // auth: {},
      formData: {
        password: "",
        password_confirmation: "",
        forced: false,
      },
      confirm_password: "",
      show1: false,
      password: "Password",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 7 || "Min 7 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      tab: null,
    };
  },

  created() {
    this.getAuth();
  },

  methods: {
    getAuth() {
      // this.auth = this.$store.state.candidate;
    },
    changePassword() {
      // alert('sbdshbdhsdhs')
      this.loading = true;
      this.disabled = true;
      this.$store.dispatch("loaderChange", true);
      /*
      if (this.getCandidate.first_login == false) {
        this.formData.forced = 1;
      } else {
        this.formData.forced = 1;
      }
      */
      this.formData.forced = 1;
      const isValidLoginForm = this.$refs.changePasswordForm.validate();
      if (!isValidLoginForm) {
        Swal.fire({
          title: "Alerta de Validação!",
          html: `Verifque o preenchimento dos campos antes de enviar o formulário.`,
          icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#FF4500",
        });

        return;
      }

      this.$store.dispatch("changePassword", this.formData)
      .then(() => {
        Swal.fire({
          title: "Sucesso!",
          text: "A sua senha foi alterada!",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#FF1744",
        });
        // this.$store.dispatch("loaderChange", false);
        this.loading = false;
        this.disabled = false;

        this.$store.dispatch("me");

        this.$router.push({ name: "site.contents" });
      })
      .catch((error) => {
        this.loading = false;
        this.disabled = false;
      });
    },
  },
};
</script>

<style scoped></style>