<template>
  <v-container>
    <v-card flat width="auto">
      <v-card-title>
        <v-row>
          <v-col cols="10"> Minhas Empresas </v-col>
        </v-row>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Nome da Empresa</th>
                <th class="text-left">Industria</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="empresa in minhasempresas" :key="empresa.id">
                <td>{{ empresa.id }}</td>
                <td>{{ empresa.nome }}</td>
                <td>{{ empresa.industria }}</td>
                <td>
                  <v-btn
                    type="submit"
                    elevation="4"
                    color="secondary"
                    dark
                    small
                    class="mr-2"
                    @click="verEmpresa(empresa)"
                  >
                    <v-icon small class="mr-2"> mdi-eye </v-icon>
                    Ver empresa
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider style=""></v-divider>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
// import ProfissionalExperience from "./forms/ProfissionalExperienceForm.vue";

export default {
  components: {
    // ProfissionalExperience,
  },

  computed: {
    getCandidate() {
      let candidate = this.$store.state.candidate;

      return candidate;
    },
    minhasempresas() {
      return this.$store.state.minhasempresas;
    },
  },
  data() {
    return {};
  },

  methods: {
    verEmpresa(empresa) {
      this.$router.push({name: 'empresa.show', params: {id: empresa.id}})
    },
  },
  filters: {},
  created() {
    this.$store.dispatch("getMinhasEmpresas");
  },
};
</script>