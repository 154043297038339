<template>
  <v-footer app inset absolute padless dark color="grey darken-1">
    <!-- dialog ao clicar appStore ou appGallery -->
    <v-dialog max-width="500" v-model="dialog">
      <v-card>
        <v-container style="display: flex; flex-direction: row;">
          <img src="../../assets/Plataformas/manutencao.png" width="25%"  height="" alt="">
          <div>
            <v-card-title>Informaçao</v-card-title>
            <v-card-subtitle>
              Aplicação ainda não esta disponível, mas estamos a trabalhar arduamente para o lançamento
            </v-card-subtitle>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
     <!-- dialog ao clicar appStore ou appGallery -->
    <v-container>
      <v-row justify="space-between">
        <v-col cols="12" md="3">
          <v-list dense dark color="grey darken-1">
            <v-list-item>
              <v-list-item-content>
                <h6 class="text-body-1 text-md-h6">Precisa de Ajuda?</h6>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :href="`mailto:comercial@marrar.co.mz?subject=Pedido de Suporte`" target="_blank">
              <v-list-item-icon>
                <v-icon> mdi-email </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>comercial@marrar.co.mz</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :href="`mailto:suporte@marrar.co.mz?subject=Pedido de Suporte`" target="_blank">
              <v-list-item-icon>
                <v-icon> mdi-email </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>suporte@marrar.co.mz</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="tel:844181881" target="_blank">
              <v-list-item-icon>
                <v-icon> mdi-alert </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Reclamações: 84 4181 881</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <!-- Numeros -->
        <v-col cols="12" md="3">
          <v-list dense dark color="grey darken-1">
            <v-list-item>
              <v-list-item-content>
                <h6 class="text-body-1 text-md-h6"></h6>
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="tel:845436085">
              <v-list-item-icon>
                <v-icon> mdi-phone </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>84 5436 085 (Chamadas)</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="https://wa.me/258845436085" target="_blank">
              <v-list-item-icon>
                <v-icon> mdi-whatsapp </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Link WhatsApp </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="3">
          <v-list dense dark color="grey darken-1">
            <v-list-item>
              <v-list-item-content>
                <h6 class="text-body-1 text-md-h6"></h6>
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="https://gugla.co.mz" target="_blank">
              <v-list-item-icon>
                <v-icon> mdi-toolbox-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Nossos Servicos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="goToPerguntas()">
              <v-list-item-icon>
                <v-icon> mdi-frequently-asked-questions </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Perguntas Frequentes</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <!-- links da app -->
        <!-- Google App -->
        <v-col cols="12" md="3">

          <v-list dense dark color="grey darken-1">
            <v-list-item>
              <v-list-item-content>
                <h6 class="text-body-1 text-md-h6">Baixar Aplicativos</h6>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <a href="https://play.google.com/store/apps/details?id=com.marrar.gugla_empresas" target="_blank">
                  <img src="../../assets/playstore.png" width="130" height="" alt="" />
                </a>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <a @click="dialog = true">
                  <img src="../../assets/apple_soon.png" width="130" height="" alt="" />
                </a>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <a href="https://appgallery.huawei.com/#/app/C107785509" target="_blank">
                  <img src="../../assets/Plataformas/AppGallery.png" width="130" height="" alt="" />
                </a>
              </v-list-item-content>
            </v-list-item>


            <!-- AppStore -->
            <!--  <v-list-item >
                  <v-list-item-content class="text-center" style="max-width: 59% ;">
                    
                      <v-dialog  width="500">

                        <template v-slot:activator="{ on, attrs }" >

                        <img
                          v-bind="attrs"
                          v-on="on"
                          src="../../assets/apple_soon.png" width="20%" height="5%" alt=""/>
                        </template>


                        <v-card >
                          <v-card-title class="text-center">Informaçao</v-card-title>
                          <v-card-text class="text-center">
                            <img src="../../assets/Plataformas/manutencao.png" width="40%" height="5%" alt="">
                          </v-card-text>
                          <v-card-text>
                            Aplicação ainda não esta disponível, mas estamos a trabalhar arduamente para o lançamento.
                          </v-card-text>
                        </v-card>
                        </v-dialog>
                        
                    
                    
                  </v-list-item-content>
                </v-list-item> -->




          </v-list>
        </v-col>


        <!-- links da app -->
        <!-- App Gallery -->
        <!--   <v-col cols="12" md="2" >
              <v-list dense dark color="grey darken-1">
                <v-list-item>
                  <v-list-item-content>
                    <h6 class="text-body-1 text-md-h6"></h6>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-dialog  width="500">

                    <template v-slot:activator="{ on, attrs }" >

                      <img
                        v-bind="attrs"
                        v-on="on"
                        src="../../assets/Plataformas/AppGallery.png" width="20%" height="5%" alt=""/>
                    </template>


                    <v-card >
                      <v-card-title class="text-center">Informaçao</v-card-title>
                      <v-card-text class="text-center">
                        <img src="../../assets/Plataformas/manutencao.png" width="50%" height="5%" alt="">
                      </v-card-text>
                      <v-card-text>
                        Aplicação ainda não esta disponível, mas estamos a trabalhar arduamente para o lançamento
                      </v-card-text>
                    </v-card>
                    </v-dialog>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              </v-col> -->
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-row>
        <v-col cols="12" class="text-center">
          <p class="mt-2 mb-1 text-subtitle-2">
            Um Producto da Marrar, Lda | Copyright ©
            {{ new Date().getFullYear() }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  data: () => ({ value: 1, dialog: false }),

  computed: {
    color() {
      switch (this.value) {
        case 0: return 'blue-grey'
        case 1: return 'teal'
        case 2: return 'brown'
        case 3: return 'indigo'
        default: return 'blue-grey'
      }
    },
  },
  methods: {
    goToPerguntas() {
      this.$router.push({ name: "perguntas" });
    },
  },
}
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>