/* eslint-disable vue/valid-v-for */
<template>
  <v-container class="mt-8">
    <!-- popup sobre login e subscricao  -->
    <v-dialog v-model="dialog" max-width="500" persistent>
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            id="openPopSubscricao"
            style="display: none"
          >
            Open Dialog
          </v-btn>
        </template> -->
        <v-card
          class="mx-auto d-flex flex-column"
          style="padding-bottom: 0px; padding-top: 50px"
        >
          <div
            class="meuCard"
            style="
              margin: 0;
              padding-bottom: 0px;
              overflow-x: hidden;
              overflow-y: hidden;
            "
          >
            <v-row wrap>
              <v-col cols="12" md="12">
                <div class="text-center">
                  <img
                    src="../../../assets/key-content.png"
                    alt=""
                    height="130"
                    width="130"
                    style="display: block; margin-left: auto; margin-right: auto"
                  />
                </div>
                <div class="textoCabelho">
                  <h1 style="font-weight: bold; color: #111" class="text-center">
                    Precisa fazer Login!
                  </h1>
                  <br />
                  <p class="text-center text-body-2 text-md-body-1">
                    Por favor faça o login com a sua conta para poder efectuar o
                    pagamento da sua subscrição. Caso ainda não tenha uma conta
                    por favor registe-se.
                  </p>
                </div>
  
                <br />
                <v-card-actions v-if="buttonCard == true">
                  <v-row wrap justify="center">
                    <v-col cols="12" md="4">
                      <v-btn block color="#FF4500" dark rounded @click="goToLogin"
                        >Login</v-btn
                      >
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn block color="#EEEEEE" rounded @click="goToRegisto"
                        >Registo</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-actions>
                <!-- <v-card-actions v-if="buttonCard == true">
                  <v-row wrap justify="center">
                    <v-col cols="12" md="6">
                      <v-btn
                        block
                        color="#D8293B"
                        dark
                        rounded
                        @click="goToSubscricao"
                        >Efectuar a Subscrição</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-actions> -->
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      
      <!-- fim do popup login e subscricao -->
    <v-row wrap>
      <v-col cols="12" md="6">
        <div class="textoPrincipal">
          <v-row wrap>
            <v-col cols="12">
              <h4>Expira em {{ timer }}</h4>
              <h3>Promoção para Visualização de Concursos e pedidos de cotações</h3>
              <p style="font-size: 18px">
                Aproveite a Promoção e não perca nenhuma oportunidade de negócio
              </p>
            </v-col>
            <v-col cols="12">
              <div class="registar">
                <!-- <h3>Registe a sua Empresa e permita que:</h3> -->
                <v-list>
                    <v-list-group no-action>
                    <template v-slot:activator>
                        <v-list-item-title><h4>Descubra o que o pacote selecionado tem a oferecer!</h4></v-list-item-title>
                    </template>
                    <v-list-item v-for="(item, index) in primeiropacote" :key="index">
                        <v-list-item-icon>
                        <v-icon color="#68B51B"> mdi-check-circle </v-icon>
                        <!-- <v-icon color="#F32A0C" v-if="checkbox1 == false && checkbox2 == true"> mdi-alpha-x-circle </v-icon> -->
                        </v-list-item-icon>
                        <v-list-item-content>
                        <p>
                            {{ item.description }}
                        </p>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-group>
                </v-list>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div id="generic_price_table">
            <section>
                <div class="container">
                    <div class="row justify-center" >
                        <div class="col-md-8">
                            <!--PRICE CONTENT START-->
                            <div class="generic_content active clearfix">
                                <!--HEAD PRICE DETAIL START-->
                                <div class="generic_head_price clearfix">
                                <!--HEAD CONTENT START-->
                                <div class="generic_head_content clearfix">
                                    <!--HEAD START-->
                                    <div class="head_bg"></div>
                                    <div class="head">
                                    <span>{{ promo.desc }}</span>
                                    </div>
                                    <!--//HEAD END-->
                                </div>
                                <!--//HEAD CONTENT END-->
                
                                <!--PRICE START-->
                                <div class="generic_price_tag clearfix">
                                    <span class="price">
                                    <span class="sign">MZN</span>
                                    <span class="currency">{{ promo.price }}</span>
                                    <!-- <span class="cent">.99</span> -->
                                    <span class="month"></span>
                                    </span>
                                </div>
                                <!--//PRICE END-->
                                </div>
                                <!--//HEAD PRICE DETAIL END-->
                
                                <!--FEATURE LIST START-->
                                <!-- <div class="generic_feature_list">
                                            <ul>
                                                <li><span>1 Mês</span></li>
                                                <li><span>Acesso aos Concursos</span></li>
                                                <li><span>24/7</span> Suporte</li>
                                            </ul>
                                        </div> -->
                                <!--//FEATURE LIST END-->
                
                                <!--BUTTON START-->
                                <div class="generic_price_btn clearfix">
                                  <a @click="comprar()">Pagar</a>
                                    <!-- Solicitar -->
                                </div>
                                
                                <!--//BUTTON END-->
                            </div>
                            
                            <!--//PRICE CONTENT END-->
                            </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- <img src="../../../assets/empresa/empresa.png" alt="" :class="isMobile ? 'home-img' : 'mobile-img'" /> -->
      </v-col>
      <!-- <v-col cols="12">asa1111111111s</v-col> -->
    </v-row>
    <v-row style="margin-top: 50px;">
        <div>

        </div>
    </v-row>
  </v-container>
</template>

<script>

export default {
  components: {
  },

  data() {
    return {
      dialog: false,
      price: 499,
      timer: '',
      buttonCard: false,
      promo: {
        price: 0,
        desc: ''
      },
      formData: {
          email: "",
          password: "",
        },
      primeiropacote: [
        // {
        //   code: 1,
        //   description: "Visualização da Lista de Empresas, para descobrir parceiros, fornecedores e prestadores de serviços"
        // },
        {
          code: 2,
          description: "Visualização de Concursos e Pedidos de Cotações"
        },
        {
          code: 3,
          description: "Notificações sobre novos concursos publicados"
        },
        {
          code: 4,
          description: "Acesso aos concursos através do aplicativo móvel"
        },
        {
          code: 5,
          description: "Acesso aos concursos através do WhatsApp"
        },
        {
          code: 6,
          description: "Guardar Concursos como Favorito para consultar futuramente"
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
  created() {
    this.getPromo()
    sessionStorage.removeItem("tipo_subscricao")
    sessionStorage.setItem("tipo_subscricao", 1)
    let token = localStorage.getItem("TOKEN_AUTH")
    if(token){
      this.subscribeLogs()
    } else {
      this.subscribeLogs("Não autenticado")
    }
    if (token) {
      this.buttonCard = false;
      this.dialog = false
    } else {
      this.buttonCard = true;
      this.dialog = true
    }
    setInterval(() => {
        var toDate=new Date();
        var tomorrow=new Date();
        tomorrow.setHours(24,0,0,0);
        var diffMS=tomorrow.getTime()/1000-toDate.getTime()/1000;
        var diffHr=Math.floor(diffMS/3600);
        diffMS=diffMS-diffHr*3600;
        var diffMi=Math.floor(diffMS/60);
        diffMS=diffMS-diffMi*60;
        var diffS=Math.floor(diffMS);
        var result=((diffHr<10)?"0"+diffHr:diffHr);
        result+=":"+((diffMi<10)?"0"+diffMi:diffMi);
        result+=":"+((diffS<10)?"0"+diffS:diffS);

        this.timer = result;
        
    }, 1000)
  },
  mounted() {
    
  },
  methods: {
    goToLogin2() {
        this.$store
          .dispatch("login", this.formData)
          .then(() => {
            if (this.mes == 1) {
              this.$router.push({
                name: "site.subscricao.passos",
                params: { valor: this.price, mes: 1 },
              });
            } else if (this.mes == 3) {
              this.$router.push({
                name: "site.subscricao.passos",
                params: { valor: this.price, mes: 3 },
              });
            } else if (this.mes == 12) {
              this.$router.push({
                name: "site.subscricao.passos",
                params: { valor: this.price, mes: 12 },
              });
            }
          })
          .catch(() => {
            console.log("erro ao logar");
          })
          .finally("Finalizou no componet");
      },
    goToLogin() {
        this.dialog = false;
        var urlAtual = window.location.href;
        sessionStorage.setItem("url", urlAtual);
        // sessionStorage.setItem("previous_router", "subscricao");
        this.$router.push({name: 'candidate.login'})
        // document.getElementById("openPop2").click();
      },
    subscribeLogs( name = null ){
      let data = { "recruiter_email": localStorage.getItem("RECRUITER_EMAIL") ? localStorage.getItem("RECRUITER_EMAIL") : name, "subscribe_page_promo": 1}
      this.$store.dispatch("subscribePageLogs", data).then((res) => {
        localStorage.setItem("id_subs", res.data.id)
      })
    },
    getPromo() {
        this.$store.dispatch("getPromo", {price: this.$route.params.valor}).then((res) => {
            this.promo = res;
      })
    },
    comprar() {
      let token = localStorage.getItem("TOKEN_AUTH");
        if (!token) {
          document.getElementById("openPopSubscricao").click();
        } else {
          // if (this.promo.dias)
          this.$router.push({
            name: "site.subscricao.passos",
            params: { valor: this.promo.price, promo: 1 },
          });
        }
    },
    goToRegister() {
      // this.$router.push({ name: 'empresa.register' })
      let token = localStorage.getItem("TOKEN_AUTH");
      if (token) {
        this.$router.push({ name: 'empresa.register' })

      } else {
        this.dialog = true
      }

    },
    goToRegisto() {
      this.$router.push({ name: "candidate.register" });
    },

  },
};
</script>

<style scoped>
.span-color {
  color: rgb(136, 136, 136);
}

.listCategory {
  font-size: 0.7em;
  cursor: pointer;
  margin-top: 5px;
}

ul li:hover {
  color: rgb(136, 136, 136);
}

.inputFile {
  cursor: pointer;
}

.texto {
  padding-top: 10%;
}

.textoPrincipal {
  padding: 0 16px;
}

.theme--light.v-list-item {
  padding: 0;
}

.v-list-item__content {
  align-items: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  overflow: hidden;
  padding: 0px 0;
}

.registar {
  padding-top: 5%;
}

h1 {
  color: #ff5252;
}
h3 {
  color: #ff5252;
}
h4 {
  color: #ff5252;
}

.mobile-img {
  width: 300px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.home-img {
  width: 600px;
  height: auto;
}
</style>