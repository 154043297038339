<template>
	<v-container fluid fill-height style="">
	</v-container>
</template>

<script>
import Swal from "sweetalert2";
export default {
	components: {},

	props: {
		id: {
			required: true,
		},
		email: {
			required: true,
		},
	},

	data() {
		return {};
	},

	created() {
        // console.log(`E-mail: ${this.email} ID: ${this.id}`)
		this.activateUser();
	},

	computed: {
		params() {
			return {
				id: this.id,
				email: this.email,
			};
		},
	},

	methods: {
		activateUser() {
			this.$store
				.dispatch("activateEmail", { ...this.params })
				.then((response) => {
                    Swal.fire({
                        title: "Ativado com sucesso!",
                        text: "Apartir de agora já pode utilizar o seu email como metódo de Login.",
                        icon: "success",
                        confirmButtonText: "Ok",
                        confirmButtonColor: "#FF4500"
                    }).then(() => {
                        this.$router.push({ name: "content.site" });
                    })
					
				})
				.catch((error) => {
                    Swal.fire({
                        title: "Falha!",
                        text: "Não foi possível activar a conta! Por favor, contacte a administração.",
                        icon: "error",
                        confirmButtonText: "Ok",
                        confirmButtonColor: "#FF4500"
                    }).then(() => {
                        this.$router.push({ name: "recruiter.login" });
                    })
					
				});
		},
	},
};
</script>

<style scoped>
.red-gugla {
	color: #ff1744;
}
</style>