var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"display":"none"},attrs:{"color":"primary","dark":"","id":"openPop1"}},'v-btn',attrs,false),on),[_vm._v(" Open Dialog ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-auto d-flex flex-column",staticStyle:{"padding-bottom":"0px"}},[_c('div',{staticClass:"meuCard",staticStyle:{"margin":"0","padding-bottom":"0px","overflow-x":"hidden","overflow-y":"hidden"}},[_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"textoCabelho"},[_c('h1',{staticStyle:{"font-weight":"bold","color":"#111"}},[_vm._v(" Ainda não é Subscrito ")]),_c('br'),_c('p',{staticClass:"text-justify-left text-body-2 text-md-body-1"},[_vm._v(" Subscreva-se e tenha acesso de todos os concursos de empresas anunciados na plataforma. ")])]),_c('br'),_c('h3',{staticStyle:{"font-weight":"bold","color":"#ff4500","margin":"10px"}},[_vm._v(" Beneficios de ter uma Subscrição ")]),_c('v-list',_vm._l((this
                .validation_notes),function(validation_note,index_validation_note){return _c('v-list-item',{key:index_validation_note},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"#111"}},[_vm._v(" mdi-checkbox-marked-circle-outline ")])],1),_c('v-list-item-content',[_c('p',{staticClass:"text-justify-left text-body-2 text-md-body-1"},[_vm._v(" "+_vm._s(validation_note.description)+" ")])])],1)}),1),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"#D5172A","dark":"","rounded":""},on:{"click":_vm.goToSubscricao}},[_vm._v("Efectuar a Subscrição")])],1),_c('p',{staticClass:"text-center font-weight-bold"})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('img',{staticClass:"hidden-sm-and-down",attrs:{"src":require("../../assets/subscricao.png"),"width":"100%","height":"100%","alt":""}})])],1)],1)])],1),(_vm.content.categoria)?_c('v-card',{staticClass:"mx-auto d-flex flex-column",attrs:{"outlined":"","elevation":"1","min-height":"180px"}},[_c('v-list-item',{attrs:{"three-line":""},on:{"click":function($event){return _vm.goToContent(_vm.content)}}},[(_vm.content.is_recomended)?_c('div',{staticClass:"recomend-class"},[_c('v-chip',{attrs:{"x-small":"","label":"","dark":"","color":'green darken-1'}},[_vm._v("recomendado")])],1):_vm._e(),_c('v-list-item-content',[_c('p',{staticClass:"text-subtitle-2 text-sm-h6 mb1"},[_vm._v(" "+_vm._s(_vm.content.titulo)+" ")]),(_vm.content.industria && _vm.content.industria != 'null')?_c('p',{class:[
            _vm.active_subscription == 0 && _vm.content.not_free == 1
              ? 'ocultarCard'
              : 'text-subtitle-2 mb1' ]},[_vm._v(" "+_vm._s(_vm.content.industria)+" ")]):_vm._e(),_c('v-list-item-subtitle',{class:[
          _vm.active_subscription == 0 && _vm.content.not_free == 1
            ? 'ocultarCard'
            : '' ]},[_vm._v(" "+_vm._s(_vm.content.instituicao)+" ")])],1),(_vm.active_subscription == 0 && _vm.content.not_free == 1)?_c('div',[_c('v-list-item-avatar',{attrs:{"size":"60","height":"auto","rounded":"","color":""}},[_c('img',{attrs:{"src":require('../../assets/key-content.png'),"alt":""}})])],1):_c('div',[(_vm.content.image)?_c('v-list-item-avatar',{attrs:{"size":"60","height":"auto","rounded":"","color":""}},[_c('img',{attrs:{"src":_vm.content.image,"alt":""}})]):_c('v-list-item-avatar',{attrs:{"size":"60","height":"auto","rounded":"","color":""}},[_c('img',{attrs:{"src":require('../../assets/img/content-default.png'),"alt":""}})])],1)],1),_c('v-spacer'),_c('div',{class:[
        _vm.active_subscription == 0 && _vm.content.not_free == 1
          ? 'ocultarCard'
          : '' ]},[_c('v-card-actions',{class:[_vm.expired ? 'left-border-expired' : 'left-border-active']},[_c('v-chip',{attrs:{"small":"","light":"","outlined":""}},[_vm._v(" Local: "),(_vm.content.local)?_c('span',[_vm._v(" "+_vm._s(_vm.content.local)+" ")]):_c('span',_vm._l((_vm.content.locals),function(local){return _c('span',{key:local.id,staticClass:"mr-1"},[_vm._v(" "+_vm._s(local.name)+" ")])}),0)]),_c('v-spacer'),_c('v-chip',{attrs:{"small":"","light":"","outlined":"","color":_vm.expired ? 'red darken-1' : 'green darken-1'}},[_vm._v(" Validade: "+_vm._s(_vm._f("formatDate")(_vm.content.validade))+" ")])],1)],1)],1):_c('v-card',{staticClass:"mx-auto d-flex flex-column",attrs:{"outlined":"","elevation":"1","min-height":"140px","target":"_blank"},on:{"click":function($event){return _vm.goToPublicity(_vm.content)}}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.content.image_mobile,"alt":_vm.content.titulo,"min-height":"140px"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }