/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div>
    <v-app-bar app clipped-left color="white">
    <!-- <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-if="isMobile"
        ></v-app-bar-nav-icon> -->
      <!-- <v-app-bar-nav-icon class="grey--text" @click.prevent="drawer = !drawer"></v-app-bar-nav-icon> -->
      <v-toolbar-title :to="{ name: 'site.contents' }">
        <router-link :to="{ name: 'site.contents' }">
          <img :src="require('../../assets/img/Logo-Empresas-830x354.png')" height="45" alt="Gugla Empresas" />
        </router-link>
        <!-- <img src="images/logo-830x354.png" height="45" alt="" /> -->
      </v-toolbar-title>
      <v-spacer></v-spacer>


      <!-- Outra divisao menu -->
      <div v-if="isMobile"
        style="display: flex; gap: 20px;padding-right: 20px; font-weight: 400; font-size: small;align-items: center;"
        class="mx-auto">
        <!-- Concursos -->
        <v-menu open-on-hover down offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">




              <span :style="{ color: hover ? '#FF4500 !important' : 'black' }" :class="{ 'on-hover': hover }" dark
                v-bind="attrs" v-on="on" style="color: black;">

                <v-icon size="22" :style="{ color: hover ? '#FF4500 !important' : 'rgba(0, 0, 0, 0.54)' }"
                  :class="{ 'on-hover': hover }" style="transform: translateY(-1px);"> mdi-table-of-contents </v-icon>

                Concursos
              </span>
            </v-hover>

          </template>

          <v-list style="font-weight: 300; font-size: small;">

            <v-list-item @click="goToConcursos()">
              <span>
                Todos Concursos
              </span>

            </v-list-item>
            <v-list-item @click="meusConcursos()">
              Meus Concursos

            </v-list-item>

          </v-list>
        </v-menu>

        <!-- Empresas -->
        <v-menu open-on-hover down offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">

              <span :style="{ color: hover ? '#FF4500 !important' : 'black' }" :class="{ 'on-hover': hover }" dark
                v-bind="attrs" v-on="on" style="color: black;">

                <v-icon size="20" :style="{ color: hover ? '#FF4500 !important' : 'rgba(0, 0, 0, 0.54)' }"
                  :class="{ 'on-hover': hover }" style="transform: translateY(-3.5px);"> mdi-domain </v-icon>
                Empresas
              </span>
            </v-hover>
          </template>

          <v-list style="font-weight: 300; font-size: small;">
            <v-list-item @click="goToEmpresa()">
              Todas Empresas
            </v-list-item>
            <v-list-item @click="minhasEmpresas()">
              Minhas Empresas
            </v-list-item>

            <v-list-item @click="goToCreateEmpresa()">
              Criar Empresas
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- Contratar Colaboradores -->
        <v-menu open-on-hover down offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">

              <span :style="{ color: hover ? '#FF4500 !important' : 'black' }" :class="{ 'on-hover': hover }" dark
                v-bind="attrs" v-on="on">
                <v-icon size="20" :style="{ color: hover ? '#FF4500 !important' : 'rgba(0, 0, 0, 0.54)' }"
                  :class="{ 'on-hover': hover }" style="transform: translateY(-3px);"> mdi-account-group-outline </v-icon>
                Contratar colaboradores
              </span>
            </v-hover>
          </template>

          <v-list style="font-weight: 300; font-size: small; background-color: #ffffff;">

            <v-list-item @click="minhasVagas()">

              Minhas Vagas


            </v-list-item>
            <v-list-item @click="baseDeDados()">
              Candidatos Buscando Emprego
            </v-list-item>
            <v-list-item @click="candidatosFavoritos()">
              Meus Candidatos Favoritos
            </v-list-item>

          </v-list>
        </v-menu>
        <!-- Sobre Nos -->
      <!--   <v-menu
          open-on-hover
          down
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-hover
            v-slot="{ hover }"
            
            >

               <span
               :style="{color: hover ? '#FF4500 !important' : 'black'}"
                :class="{ 'on-hover': hover }"
               class="nheee"
              dark
              v-bind="attrs"
              v-on="on"
              style="color: black;"
            >
              Sobre Nos
          </span>
            </v-hover>
          </template>

          <v-list style="font-weight: 300; font-size: small;">

            <v-list-item
            href="https://gugla.co.mz" target="_blank"
            >
              Nossos Servicos
            </v-list-item>
            <v-list-item
            @click="goToPerguntas()"
            >
              Perguntas Frequentes
            </v-list-item>
          
          </v-list>
          </v-menu> -->

      </div>

      <!-- Botoes de login e foto -->
      <div>
        <v-btn v-if="!logado" depressed rounded text small :to="{ name: 'candidate.login' }">
          Login | Registar
        </v-btn>

        <v-toolbar-items v-else>

          <v-btn style="display: flex; flex-direction: column" v-if="getCandidate.logo" @click="profile" depressed rounded
            text small>
            <v-avatar :size="isMobile ? '48' : '38'">
              <img :src="getCandidate.logo" />
            </v-avatar>
          </v-btn>


          <v-btn v-else @click="profile" depressed rounded text small>
            <v-avatar :size="isMobile ? '48' : '38'">
              <img :src="require('../../assets/img/user-png.png')" alt="" />
            </v-avatar>
          </v-btn>
        </v-toolbar-items>
      </div>
      
    </v-app-bar>
    

  <!-- 
    <v-navigation-drawer
      class="main-nav"
      v-model="drawer"
      fixed
      clipped
      app
      enable-resize-watcher
      height="100vh"
      width="300px"
      v-if="isMobile"
    >
        <!-- USUARIO SEM LOGIN  -->
  <!-- <v-list dense>
        <v-list-group sub-group :value="true">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="color: white; font-size: 15px"
                >CONCURSOS</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item :to="{ name: 'site.contents' }">
            <template>
              <img
                src="../../assets/allcontents.png"
                height="30"
                width="30"
                alt="Todos Concursos"
              />
              <div
                style="color: #ffffff;margin-left: 10px;font-size: 14px;text-transform: capitalize;"
              >
                Todos Concursos
              </div>
            </template>
            </v-list-item> -->
  <!-- <v-list-item :to="{ name: 'site.concursos' }">
            <template>
              <img
                src="../../assets/contFav.png"
                height="30"
                width="30"
                alt="Meus Concursos Favoritos"
              />
              <div
                style="
                  color: #ffffff;
                  margin-left: 10px;
                  font-size: 14px;
                  text-transform: capitalize;
                "
              >
                Meus Concursos Favoritos
              </div>
            </template>
            </v-list-item> -->
  <!-- 
          <v-list-item @click="meusConcursos()">
            <template>
              <img
                src="../../assets/allcontents.png"
                height="30"
                width="30"
                alt="Meus Concursos"
              />
              <div
                style="
                  color: #ffffff; margin-left: 10px;font-size: 14px;text-transform: capitalize;"
              >
                Meus Concursos
              </div>
            </template>
            </v-list-item> -->

  <!-- <v-list-item :to="{ name: 'site.concursos' }">
            <template>
              <img
                src="../../assets/publicar01.png"
                height="30"
                width="30"
                alt="Publicar Concurso"
              />
              <div
                style="
                  color: #ffffff;
                  margin-left: 10px;
                  font-size: 14px;
                  text-transform: capitalize;
                "
              >
                Publicar Concurso
              </div>
            </template>
            </v-list-item> -->
  <!-- <v-list-item :to="{ name: 'site.notificacoes' }">
            <template>
              <img
                src="../../assets/notificacaoes.png"
                height="30"
                width="30"
                alt="Notificações"
              />
              <div
                style="
                  color: #ffffff;
                  margin-left: 10px;
                  font-size: 14px;
                  text-transform: capitalize;
                "
              >
                Notificações
              </div>
            </template>
            </v-list-item> -->
  <!--    </v-list-group>
        <v-list-group sub-group :value="true">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="color: white; font-size: 15px"
                >EMPRESAS</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item :to="{ name: 'empresa' }">
            <template>
              <img
                src="../../assets/allcontents.png"
                height="30"
                width="30"
                alt="Todas Empresas"
              />
              <div
                style="
                  color: #ffffff;
                  margin-left: 10px;
                  font-size: 14px;
                  text-transform: capitalize;
                "
              >
                Todas Empresas
              </div>
            </template>
          </v-list-item>
          <v-list-item @click="minhasEmpresas()">
            <template>
              <img
                src="../../assets/empresas.png"
                height="30"
                width="30"
                alt="Minhas Empresas"
              />
              <div
                style="
                  color: #ffffff;
                  margin-left: 10px;
                  font-size: 14px;
                  text-transform: capitalize;
                "
              >
                Minhas Empresas
              </div>
            </template>
          </v-list-item>

          <v-list-item @click="goToCreateEmpresa()">
            <template>
              <img
                src="../../assets/adicionar.png"
                height="30"
                width="30"
                alt="Criar Empresa"
              />
              <div
                style="
                  color: #ffffff;
                  margin-left: 10px;
                  font-size: 14px;
                  text-transform: capitalize;
                "
              >
                Criar Empresa
              </div>
            </template>
          </v-list-item>
        </v-list-group>

        <v-list-group sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="color: white; font-size: 15px"
                >CONTRATAR COLABORADORES</v-list-item-title
              >
            </v-list-item-content>
            </template> -->
  <!-- <v-list-item :to="{ name: 'recruiter.vagas.create' }">
            <template>
              <img src="../../assets/publicar01.png" height="30" width="30" />
              <div
                style="
                  color: #ffffff;
                  margin-left: 10px;
                  font-size: 14px;
                  text-transform: capitalize;
                "
              >
                Publicar Vagas
              </div>
            </template>
            </v-list-item> -->
  <!--  <v-list-item @click="minhasVagas()">
            <template>
              <img src="../../assets/allcontents.png" height="30" width="30" />
              <div
                style="
                  color: #ffffff;
                  margin-left: 10px;
                  font-size: 14px;
                  text-transform: capitalize;
                "
              >
                Minhas Vagas
              </div>
            </template>
          </v-list-item>
          <v-list-item @click="baseDeDados()">
            <template>
              <img src="../../assets/cand.png" height="30" width="30" />
              <div
                style="
                  color: #ffffff;
                  margin-left: 10px;
                  font-size: 14px;
                  text-transform: capitalize;
                  text-align: left;
                "
              >
                Base de Dados de Candidatos <br />
                Buscando Emprego
              </div>
            </template>
          </v-list-item>
          <v-list-item @click="candidatosFavoritos()">
            <template>
              <img
                src="../../assets/candFav.png"
                height="30"
                width="30"
                alt="Meus Candidatos Favoritos"
              />
              <div
                style="
                  color: #ffffff;
                  margin-left: 10px;
                  font-size: 14px;
                  text-transform: capitalize;
                "
              >
                Meus Candidatos Favoritos
              </div>
            </template>
          </v-list-item>
        </v-list-group>
        </v-list> -->

    <!-- FAQS  -->
  <!--   <v-list-item :to="{name: 'perguntas'}" >
        <div
          style="
            color: #ffffff;
            margin-left: 10px;
            font-size: 14px;
            text-transform: capitalize;
          "
        >
          FAQ's
        </div>
      </v-list-item>
        <v-list-item href="https://gugla.co.mz" target="_blank"> -->
  <!-- <img
          src="../../assets/candFav.png"
          height="30"
          width="30"
          alt="Meus Candidatos Favoritos"
          /> -->
  <!--  <div
          style="
            color: #ffffff;
            margin-left: 10px;
            font-size: 14px;
            text-transform: capitalize;
          "
        >
          NOSSOS SERVIÇOS
        </div>
      </v-list-item>
      </v-navigation-drawer> -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
//import MainNav from './MainNavSiteResponsive.vue'

export default {
  components: {},
  data() {
    return {
      logado: false,
      initialsNameUser: "",
      dialog_newsletters_invite: false,
      dialog_newsletters: false,
      newsletter: {
        receive_newsletter: false,
      },
      image: "",
      drawer: true,
      group: null,
      candidate: {},


    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
    getCandidate() {
      let candidate = this.$store.state.candidate;
      return candidate;
    },
    returnImage() {
      if (
        this.$store.state.candidate?.personal_data &&
        this.$store.state.candidate.personal_data?.image
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("loaderChange", true);
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "candidate.login" });
        this.$store.dispatch("loaderChange", false);
      });
    },
    login() {
      this.$router.push({ name: "candidate.login" });
    },
    isChange() {
      console.log(this.initialsNameUser);
    },

    vagas() {
      this.$router.push({ name: "site.contents" });
    },
    profile() {
      this.$router.push({ name: "perfil.dash" });
    },
    goToCreateEmpresa() {
      this.$router.push({ name: "empresa.create" });
      /*
      let token = localStorage.getItem("TOKEN_AUTH");
      if (token) {
        this.$router.push({ name: "empresa.register" });
      } else {
        this.$router.push({ name: "empresa.create" });
      }
      */
    },
    goToConcursos() {
      this.$router.push({ name: "site.contents" });
    },
    meusConcursos() {
      let token = localStorage.getItem("TOKEN_AUTH");
      if (token) {
        this.$router.push({ name: "recruiter.contents" });
      } else {
        this.$router.push({ name: "site.oportunidades" });
      }
    },
    goToEmpresa() {
      this.$router.push({ name: "empresa" });
    },
    minhasEmpresas() {
      let token = localStorage.getItem("TOKEN_AUTH");
      if (token) {
        this.$router.push({ name: "empresa.minhas" });
      } else {
        this.$router.push({ name: "site.oportunidades" });
      }
    },
    minhasVagas() {
      let token = localStorage.getItem("TOKEN_AUTH");
      if (token) {
        this.$router.push({ name: "recruiter.vagas" });
      } else {
        this.$router.push({ name: "site.oportunidades" });
      }
    },
    baseDeDados() {
      let token = localStorage.getItem("TOKEN_AUTH");
      if (token) {
        this.$router.push({ name: "recruiter.candidatos" });
      } else {
        this.$router.push({ name: "site.oportunidades" });
      }
    },
    candidatosFavoritos() {
      let token = localStorage.getItem("TOKEN_AUTH");
      if (token) {
        this.$router.push({ name: "recruiter.favorites" });
      } else {
        this.$router.push({ name: "site.oportunidades" });
      }
    },
    goToPerguntas() {
      this.$router.push({ name: "perguntas" });
    },

  },
  created() {
    // this.$store.dispatch('me')
    let token = localStorage.getItem("TOKEN_AUTH");
    if (token) {
      this.logado = true;
    } else {
      this.logado = false;
    }
  },
};
/*.v-btn__content {
    display:flex;
    flex-direction:column;
}*/
</script>

<style scoped>
.v-btn__content {
  display: flex;
  flex-direction: column;
}

img {
  object-fit: cover;
}

/* .nheee :hover{
  color: #FF4500 !important;
}
.nheee ::after{
  color: #FF4500;
} */

.main-nav {
  background-image: url("../../assets/nav.png");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>