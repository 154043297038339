var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"containe",class:_vm.isMobile ? 'ml-16' : 'ma-3'},[_c('div',{class:_vm.open ? 'pergunta open' : 'contain',on:{"click":function($event){return _vm.$emit('abrir', _vm.posicao)}}},[_c('div',{staticClass:"pergunta",class:_vm.isMobile ? '' : 'pb-4'},[_c('div',{staticClass:"teste",class:{
          'mr-3': _vm.$vuetify.breakpoint.smAndDown,
          'mt-3': _vm.$vuetify.breakpoint.mdAndUp,
        }},[_c('p',{class:{
            'mr-4': _vm.$vuetify.breakpoint.smAndDown,
            ' desabilitar': _vm.$vuetify.breakpoint.smAndDown,
          }},[_vm._v(" "+_vm._s(_vm.pergunta.question)+" ")]),_c('v-icon',{staticClass:"icones"},[_vm._v(" mdi-chevron-down ")])],1)]),_c('div',{staticClass:"resposta",class:{
        'mr-15': _vm.$vuetify.breakpoint.smAndDown,
        'ml-8': _vm.$vuetify.breakpoint.smAndDown,
      }},[(_vm.pergunta.code != 3)?_c('span',[_vm._v(_vm._s(_vm.pergunta.answer))]):_c('span',[_c('b',[_vm._v("Mpesa / Paypal")]),_vm._v(" "),_c('br'),_vm._v(" Ao efetuar a subscrição no gugla empresas através do Mpesa ou paypal, o sistema de pagamento irá de forma automática habilitar a visualização dos concursos, de acordo com o pacote por si selecionado. "),_c('br'),_c('b',[_vm._v(" Transferência bancaria")]),_vm._v(" "),_c('br'),_vm._v(" Em caso de efetuar a subscrição por meio de "),_c('b',[_vm._v("transferência bancaria ou paypal")]),_vm._v(", terá de Enviar-nos o comprovativo de pagamento através do WhatsApp clicando aqui "),_c('a',{attrs:{"target":"_blank","href":"https://wa.me/+258848918441"}},[_vm._v("(https://wa.me/+258848918441)")]),_vm._v(" , comprovada o pagamento será efetuada a subscrição. ")])])]),_c('hr')])}
var staticRenderFns = []

export { render, staticRenderFns }