
<template>
  <v-container class="mt-8">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on" id="botaoPartilhar" style="display: none">
          Click Me
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5" style="background-color: #ff5252; color: #fff">
          Partilhar
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="2">
              <ShareNetwork style="text-decoration: none" network="facebook"
                :url="`https://empresas.gugla.co.mz/empresas/empresa/${empresa.id}/show`"
                :title="`Empresa - ${empresa.nome}`" hashtags="empresas,jobs,gugla,guglaempresas">
                <br />
                <v-btn icon>
                  <v-icon large dark color="#4267B2">
                    mdi-facebook
                  </v-icon></v-btn>
              </ShareNetwork>
            </v-col>
            <v-col cols="2">
              <ShareNetwork style="text-decoration: none" network="whatsapp"
                :url="`https://empresas.gugla.co.mz/empresas/empresa/${empresa.id}/show`"
                :title="`Empresa - ${empresa.nome}`" hashtags="empresas,jobs,gugla,guglaempresas">
                <br />
                <v-btn icon>
                  <v-icon large dark color="green">
                    mdi-whatsapp
                  </v-icon></v-btn>
              </ShareNetwork>
            </v-col>
            <v-col cols="2">
              <ShareNetwork style="text-decoration: none" network="linkedin"
                :url="`https://empresas.gugla.co.mz/empresas/empresa/${empresa.id}/show`"
                :title="`Empresa - ${empresa.nome}`" hashtags="empresas,jobs,gugla,guglaempresas">
                <br />
                <v-btn icon>
                  <v-icon large dark color="#0072B1">
                    mdi-linkedin
                  </v-icon></v-btn>
              </ShareNetwork>
            </v-col>
            <v-col cols="2">
              <ShareNetwork style="text-decoration: none" network="messenger"
                :url="`https://empresas.gugla.co.mz/empresas/empresa/${empresa.id}/show`"
                :title="`Empresa - ${empresa.nome}`" hashtags="empresas,jobs,gugla,guglaempresas">
                <br />
                <v-btn icon>
                  <v-icon large dark color="#33A8DA">
                    mdi-facebook-messenger
                  </v-icon></v-btn>
              </ShareNetwork>
            </v-col>
            <v-col cols="2">
              <ShareNetwork style="text-decoration: none" network="email"
                :url="`https://empresas.gugla.co.mz/empresas/empresa/${empresa.id}/show`"
                :title="`Empresa - ${empresa.nome}`" hashtags="empresas,jobs,gugla,guglaempresas">
                <br />
                <v-btn icon>
                  <v-icon large dark color="#F10273"> mdi-email </v-icon></v-btn>
              </ShareNetwork>
            </v-col>
            <v-col cols="2">
              <br />
              <v-btn @click="
                copyUrl(
                  `https://empresas.gugla.co.mz/empresas/empresa/${empresa.id}/show`
                )
              " icon>
                <v-icon dark large> mdi-link-variant </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" id="openPopDate1" style="display: none">
          Open Dialog
        </v-btn>
      </template>
      <v-card class="mx-auto d-flex flex-column" style="padding-bottom: 0px">
        <div class="meuCard" style="
                                                    margin: 0;
                                                    padding-bottom: 0px;
                                                    overflow-x: hidden;
                                                    overflow-y: hidden;
                                                  ">
          <v-row wrap>
            <v-col cols="12" md="12">
              <div class="text-center" style="background-color: #ff4300; height: 180px">
                <br />
                <v-img :src="require('../../assets/contacto.png')" max-width="100" max-height="100" alt="Gugla Empresas"
                  style="display: block; margin-left: auto; margin-right: auto">
                </v-img>
                <h1 style="font-weight: bold; color: #fff; font-size: 40px">
                  Entre em Contacto
                </h1>
                <br />
              </div>
              <div class="text-center textoCabelho">
                <br />
                <p class="text-justify-left text-body-2 text-md-body-1">
                  Preencha o formulario abaixo para saber mais
                </p>
                <v-row wrap class="pa-4">
                  <v-col cols="12" md="12">
                    <v-text-field outlined label="Nome Completo*" v-model="empresa_lead.nome"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field outlined label="E-mail*" v-model="empresa_lead.email"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field outlined label="Telefone*" v-model="empresa_lead.telefone"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-select outlined label="Como podemos ajudar?" :items="servicos" item-text="nome" item-value="nome"
                      v-model="empresa_lead.servico"></v-select>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-textarea outlined label="Detalhes e Comentários" v-model="empresa_lead.comentario"></v-textarea>
                  </v-col>
                </v-row>
              </div>

              <br />

              <div class="text-center">
                <v-btn color="#FF4300" dark rounded @click="sendMessage">Enviar Mensagem</v-btn>
              </div>

              <p class="text-center font-weight-bold"></p>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <div>
      <v-row align="center">
        <v-col cols="6" md="6" class="pl-0 mb-6">
          <v-btn :to="{ name: 'empresa' }" class="ml-0" tile elevation="4" dark color="red acent-3" small>
            <v-icon left small> mdi-arrow-left </v-icon>Empresas</v-btn>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.smAndDown" cols="6" md="6" class="text-right">
          <!--<v-avatar size="80" height="auto" v-if="content.image" rounded>
							<img :src="content.image" alt=""/>
						</v-avatar>-->
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="9">
          <!-- <v-card-title v-show="add_btn == true">
            <v-chip
              small
              label
              dark
              color="#FF5252"
              style="cursor: pointer"
              @click="update(empresa.id)"
              :to="{
                name: 'empresa.update',
                params: { id: empresa.id },
              }"
              >Actualizar Empresa</v-chip
            >
          </v-card-title> -->
          <v-card class="mx-auto" outlined min-height="180">
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" md="4">
                  <v-img v-if="empresa.image" height="auto" width="160" :src="empresa.image"
                    style="display: block; margin-left: auto; margin-right: auto;"></v-img>

                  <!-- <v-avatar class="ma-4" >
                    <v-img v-if="empresa.image" height="auto" width="80" :src="empresa.image"></v-img>
                  </v-avatar> -->
                  <!-- <v-avatar class="ma-4" size="90" height="80" width="80" rounded tile>
                    <v-img v-if="empresa.image" height="auto" width="80" :src="empresa.image"
                      style="display: block; margin-left: auto; margin-right: auto; background-color: red;"></v-img>
                    <v-img v-else height="auto" width="80" :src="require('../../assets/empresa-logo.png')"></v-img>
                  </v-avatar> -->
                </v-col>
                <v-col cols="12" md="8">
                  <v-list-item three-line>
                    <v-list-item-content three-line>
                      <v-list-item-title class="text-h5 mb-1">
                        <p class="text-wrap" style="font-weight: bold">
                          {{ empresa.nome }}
                        </p>
                      </v-list-item-title>
                      <p class="text--secondary">{{ empresa.industria }}</p>
                      <v-list-item-subtitle>{{
                        empresa.email_cotacao
                      }}</v-list-item-subtitle>
                      <br />
                      <v-list-item-subtitle>
                        <v-chip small label dark color="#FF5252" style="cursor: pointer"
                          @click="saberMais(empresa.id)">Saber Mais</v-chip>
                        <v-chip v-if="candidate.id === empresa.recruiter_id" small label dark color="#FF5252" style="cursor: pointer; margin-left: 15px;;"
                          @click="editar(empresa.id)">Editar Informação</v-chip>
                      </v-list-item-subtitle>
                      
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-container fill-height>
          <v-row wrap justify="center" align="center">
            <v-col cols="12" md="9">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h3 style="color:#FF4500"> <v-icon size="20" color="#FF4500">mdi-tools</v-icon> Serviços</h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row wrap>
                      <v-col cols="12" md="4" v-for="(item, index) in servicos" :key="index">
                        <v-card height="100%" elevation="0" outlined>
                          
                          <img v-if="item.image" height="100" width="100%" :src="item.image" />
                          <img v-else height="100" width="100%" src="../../assets/service.png" />
                            <h5 class="mb-0" style="color: #111111; padding: 16px;">{{ item.nome }}</h5>
                          <v-card-text class="pb-0">
                            <p v-html="item.descricao"></p>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>

                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <br>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h3 style="color:#FF4500"> <v-icon size="20" color="#FF4500">mdi-information</v-icon> Sobre Nós</h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p v-html="empresa.sobre_nos"></p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <br>
              <v-expansion-panels>
                <v-expansion-panel >
                  <v-expansion-panel-header>
                    <h3 style="color:#FF4500"> <v-icon size="20" color="#FF4500">mdi-phone</v-icon> Contactos</h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content >
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="#FF5252"> mdi-phone </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-subtitle v-show="!dispayDetails">
                            Clique para visualizar o contacto
                            <v-icon color="#FF5252" style="cursor: pointer" @click="verContactos">
                              mdi-eye
                            </v-icon>
                          </v-list-item-subtitle>
                          <v-list-item-title v-for="(item, index) in telefones" :key="index" v-show="dispayDetails">
                            <span>(+258) {{ item.telefone }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Telefone
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider inset></v-divider>

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="#FF5252"> mdi-email </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ empresa.email_cotacao }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            E-mail Cotação
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <!-- <v-list-item-icon>
                                  <v-icon color="#FF5252"
                                    >mdi-message-text</v-icon
                                  >
                                </v-list-item-icon> -->
                      </v-list-item>
                      <v-list-item v-if="empresa.website != 'null'">
                        <v-list-item-icon>
                          <v-icon color="#FF5252"> mdi-web </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-subtitle v-if="!dispayDetailsWeb">
                            Clique para visualizar o link do website
                            <v-icon color="#FF5252" style="cursor: pointer" @click="verWebsite">
                              mdi-eye
                            </v-icon>
                          </v-list-item-subtitle>
                          <v-list-item-title v-if="dispayDetailsWeb">
                            {{ empresa.website }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Website
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <!-- <v-list-item-icon>
                                  <v-icon color="#FF5252"
                                    >mdi-message-text</v-icon
                                  >
                                </v-list-item-icon> -->
                      </v-list-item>

                      <v-divider inset></v-divider>

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="#FF5252">
                            mdi-map-marker
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{
                              empresa.district
                              ? empresa.district.province.name
                              : ""
                            }}, {{ empresa.localizacao }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Localização
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-chip small label dark color="#FF5252" style="cursor: pointer"
                          @click="saberMais(empresa.id)">Envie nos uma Mensagem</v-chip>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </div>
    <div class="" style="padding-bottom: 70px;"></div>
  </v-container>
</template>


<script>
import api from "../../services/index";

export default {
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      dispayDetails: false,
      userEmpresa: false,
      dispayDetailsWeb: false,
      empresa: {},
      tab: null,
      telefones: "",
      servicos: "",
      dialog: false,
      dialog2: false,
      empresa_lead: {
        nome: "",
        email: "",
        telefone: "",
        servico: "",
        comentario: "",
      },
    };
  },
  computed: {
    candidate() {
      let candidate = this.$store.state.candidate;
      return candidate;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
  created() {
    // this.getEmpresa(this.id);
    let token = localStorage.getItem("TOKEN_AUTH");
    if (token) {
      // this.getContentWithAuth(this.id);
      this.getEmpresaWithAuth(this.id);
    } else {
      this.getEmpresa(this.id);
    }
    this.$store.dispatch("me");
  },
  methods: {
    saberMais() {
      this.dialog2 = true;
    },
    editar() {
      this.$router.push({name: 'empresa.edit', params: {id: this.id}})
    },
    getEmpresa(id) {
      this.$store.dispatch("loaderChange", true);
      this.$store
        .dispatch("getEmpresa", { id })
        .then((res) => {
          console.log(res.data.data);
          this.empresa = res.data.data;
          // this.recomended_content = res.data.data.recomended_content.data

          this.$store.dispatch("loaderChange", false);
          scroll(0, 0);
        })
        .catch((err) => {
          this.$store.dispatch("loaderChange", false);
          // this.$router.push({name: 'site.contents'})
          return err;
        });
    },
    getEmpresaWithAuth(id) {
      this.$store.dispatch("loaderChange", true);
      this.$store
        .dispatch("getEmpresaWithAuth", { id })
        .then((res) => {
          console.log(res.data.data);
          this.empresa = res.data.data;
          // this.recomended_content = res.data.data.recomended_content.data

          this.$store.dispatch("loaderChange", false);
          scroll(0, 0);
        })
        .catch((err) => {
          this.$store.dispatch("loaderChange", false);
          // this.$router.push({name: 'site.contents'})
          return err;
        });
    },

    verWebsite() {
      this.dispayDetailsWeb = true;
      let formData = {
        id: this.candidate ? this.candidate.id : "",
        tipo_detalhe: "Link Website",
        empresa_id: this.id,
      };
      console.log(formData);
      this.$store.dispatch("storeDetalhesEmpresa", formData).then((res) => { });
    },
    verContactos() {
      this.dispayDetails = true;
      let formData = {
        id: this.candidate ? this.candidate.id : "",
        tipo_detalhe: "Contacto",
        empresa_id: this.id,
      };
      this.$store.dispatch("storeDetalhesEmpresa", formData).then((res) => { });
    },
    share() {
      this.dialog = true;
    },
    sendMessage() {
      let formData = {
        nome: this.empresa_lead.nome,
        email: this.empresa_lead.email,
        telefone: this.empresa_lead.telefone,
        servico: this.empresa_lead.servico,
        comentario: this.empresa_lead.comentario,
        empresa_id: this.id,
      };
      this.$store
        .dispatch("storeLeadEmpresa", formData)
        .then((res) => {
          this.dialog2 = false;
          Swal.fire({
            title: "Sucesso!",
            text: "Mensagem enviada com Sucesso!",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#FF4500",
          });
        });
    },
  },
  watch: {
    empresa(empresa) {
      if (empresa.services) {
        this.servicos = empresa.services
        console.log(this.servicos);
        this.telefones = JSON.parse(empresa.telefone);
      } else {
        this.servicos = JSON.parse(empresa.servicos);
        this.telefones = JSON.parse(empresa.telefone);
      }
    },
    // candidate(cand) {
    //   if(cand.id === this.empresa.id){
    //     this.userEmpresa = true
    //   }
    // }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>