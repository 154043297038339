<template>
  <v-container class="mt-8">
    <v-container :class="isMobile ? 'pl-12' : 'pl-0'">
      <v-btn elevation="2" class="no-uppercase" small @click="goToperfiDash()">
        <v-icon style="padding-right: 14px" small> mdi-arrow-left </v-icon>
        Voltar
      </v-btn>
      <h3>Minhas Empresas</h3>
    </v-container>
    <!-- minhas Empresas  -->
    <div style="" :class="isMobile ? 'mt-7' : 'mt-1'">
      <v-row wrap justify="center" v-if="empresas.length == 0">
        <v-col cols="12" sm="12" md="12" class="text-center">
          <div>
            <img height="300x" width="300x" src="../../assets/ui/minhasempresas.png" />
          </div>
          <p style="font-weight: 900; text-transform: capitalize; font-size: 20px; ">
            Ainda não possuí empresas
          </p>
        </v-col>
      </v-row>

      <v-row wrap justify="center" v-if="empresas.length == 0">
        <v-col cols="12" sm="6" md="6">
          <div style="background-color: #fdece5; border: 1px solid #ff4500">
            <p style="padding: 10px" class="text-center">
              Registe as suas <span style="font-weight: 900; width: 300px">empresas</span>
              e veja nessa secção.
            </p>
          </div>
        </v-col>
      </v-row>
      <v-card wrap max-width="900" color="#FF5414" border="left"
        :class="empresa.expire != 2 ? 'left-border-expired mx-auto' : 'left-border-active  mx-auto'" elevation="6"
        :style="empresa.expire == 2 ? 'background-color: #e7e7e7; margin-bottom: 20px' : 'background-color: #e7e7e7; margin-bottom: 20px'"
        v-for="(empresa, i) in todas_empresas" :key="i">
        <v-row style="margin: 0.5%; cursor: pointer;" @click="goToEmpresa(empresa)">
          <v-col cols="10" style="">
            <span>
              {{ empresa.nome }} <br>
              <span v-if="empresa.expire == 0" style="color: #ff1744;">Sem subscrição</span>
              <span v-if="empresa.expire == 1" style="color: #ff1744;">Expirou no dia: {{ empresa.expire_at }}</span>
              <span v-if="empresa.expire == 2">Expira no dia: {{ empresa.expire_at }}</span>
            </span>
          </v-col>

          <v-col cols="2" class="nhe" :class="isMobile ? 'pl-10' : 'pl-0'" style="color: black"
            @click="goToEmpresa(empresa.id)">
            <v-icon :class="isMobile ? 'pl-10' : 'pl-0'" v-if="empresa.expire == 2">
              mdi-eye-outline
            </v-icon>
            <v-icon :class="isMobile ? 'pl-10' : 'pl-0'" v-else>
              mdi-eye-off
            </v-icon>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>
<script>
var moment = require('moment');
import Swal from "sweetalert2";
export default {
  name: "MinhasEmpresas",
  data() {
    return {
      todas_empresas: []
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
    empresas() {
      return this.$store.state.minhasempresas;
    },
  },
  created() {
    // this.getEmpresa(this.empresas)
    this.getMinhasEmpresas();
    // moment.locale('pt');
    // console.log("asasas", moment('2024-05-10').format('DD [de] MMMM [de] YYYY'))
  },
  methods: {
    getEmpresa(item) {
      moment.locale('pt');
      let minhasempresas = [];
      minhasempresas = item
      minhasempresas.forEach((element) => {
        let today = new Date();
        let deadLineDate = new Date(element.subscribe);
        today.setHours(0, 0, 0, 0);
        deadLineDate.setHours(0, 0, 0, 0);
        console.log("today > deadLineDate", today < deadLineDate)
        // expire_at 0 => nao tem subscricao, 1 => expirou, 2 => tem subscricao
        if (element.subscribe != null && today < deadLineDate) {
          this.todas_empresas.push(
            {
              "id": element.id,
              "nome": element.nome,
              "subscribe": element.subscribe,
              "expire": 2,
              "expire_at": moment(element.subscribe).format('DD [de] MMMM [de] YYYY')
            })
        } else if (element.subscribe == null) {
          this.todas_empresas.push(
            {
              "id": element.id,
              "nome": element.nome,
              "subscribe": element.subscribe,
              "expire": 0,
              "expire_at": ""
            })
        } else {
          this.todas_empresas.push(
            {
              "id": element.id,
              "nome": element.nome,
              "subscribe": element.subscribe,
              "expire": 1,
              "expire_at": moment(element.subscribe).format('DD [de] MMMM [de] YYYY')
            })
        }

      })

    },
    goToperfiDash() {
      this.$router.push({ name: "perfil.dash" });
    },
    getMinhasEmpresas() {
      this.$store.dispatch("getMinhasEmpresas").then(res => {
        this.getEmpresa(res.data.data.minhas_empresas.data)
      });
    },
    goToEmpresa(item) {
      if (item.expire == 0) {
        Swal.fire({
          title: "Sem subscrição",
          text: "Actualize a subscrição da sua empresa para que possa ter acesso ao relatorio da sua empresa.",
          icon: "info",
          confirmButtonText: "Pagar Subscrição",
          confirmButtonColor: "#FF4500",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'site.subscricao', params: { tipo_subscricao: 2, empresa_id: item.id } })
          }
        })
      } else if (item.expire == 1) {
        Swal.fire({
          title: "Subscrição expirada",
          text: "Actualize a subscrição da sua empresa para que possa ter acesso ao relatorio da sua empresa.",
          icon: "info",
          confirmButtonText: "Pagar Subscrição",
          confirmButtonColor: "#FF4500",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'site.subscricao', params: { tipo_subscricao: 2, empresa_id: item.id } })
          }
        })
      } else if (item.expire == 2) {
        this.$router.push({ name: "perfil.minhas.empresas", params: { id: item.id } });
      }
      // this.$router.push({ name: "perfil.minhas.empresas", params: { id: item.id } });
      // Swal.fire({
      //   title: "A subscrição empresa expirou",
      //   text: "Actualize a subscrição da sua empresa para que possa ter acesso ao relatorio da sua empresa.",
      //   icon: "info",
      //   confirmButtonText: "Pagar Subscrição",
      //   confirmButtonColor: "#FF4500",
      // });

    },
  },
};
</script>
  
<style scoped>
.nhe :hover {
  color: #ff4500;
}

.no-uppercase {
  text-transform: unset !important;
  background-color: #f66459 !important;
  color: white;
}

h3 {
  color: #ff4500;
  margin-left: 7px;
  margin-top: 40px;
}
</style>