<template>
       
   <v-container class="my-auto"   style="align-items: center; ">   
            <div style="margin:2%">
                <v-card max-width="470" class="mx-auto" >
                    <v-card-title style="justify-content: center;margin-top: 2%;">
                        <img
                            :src="require('../../assets/img/Logo-Empresas-830x354.png')"
                            height="72"
                            width="180"
                            alt="Gugla Empresas"
                        />
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-subtitle style="text-align: center;color: #F66459">
                        <h2>
                            Recuperação da senha
                    </h2></v-card-subtitle>
                    <v-spacer></v-spacer>  
                    <v-card-text style="margin-bottom: 5%; ">
                    
                        <strong style="color: #000;">Nova Senha</strong>
                        <validation-provider>
                            <v-text-field outlined placeholder="Nova senha "></v-text-field>
                        </validation-provider>
                        <strong style="color: #000;">Confirmar nova senha</strong>
                        <validation-provider>
                            <v-text-field outlined placeholder="confirmar nova senha"></v-text-field>
                        </validation-provider>
                        <v-card-actions>
                            
                             <v-spacer></v-spacer>
                            <v-btn class="no-uppercase" style="padding-inline: 39px;margin-right: -7px;">
                              Submeter
                            </v-btn>
                        </v-card-actions>
                        
                 
                    </v-card-text>
                    <!-- <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="no-uppercase" style="padding-inline: 39px;">
                            Seguinte
                        </v-btn>
                    </v-card-actions> -->
                </v-card>

                 
            </div>
           

            
            
    </v-container>
</template>
<script>
export default{
    name:'NovaSenhaRecuperacao',
}
</script>
<style scoped>
.no-uppercase {
  text-transform: unset !important;
  background-color: #ff4500 !important ;
  color: white;
  font-size: small;
}


</style>