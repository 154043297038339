<template>
  <div>
    <v-card
      class="mx-auto d-flex flex-column"
      outlined
      elevation="1"
      min-height="100"
    >
      <v-list-item :to="detailsRoute">
        <v-list-item-content three-line>
          <p class="text-subtitle-2 text-sm-h6 mb-1">
            {{ candidate.name }} {{ candidate.last_name }}
          </p>
          <v-list-item-subtitle>
            {{ personal_data.profissao }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="personal_data.endereco">
            {{ personal_data.endereco }}, Moçambique
          </v-list-item-subtitle>
          <v-list-item-subtitle> </v-list-item-subtitle>
        </v-list-item-content>
        <v-avatar size="60" color="white">
          <img v-if="personal_data.image" :src="personal_data.image" alt="" />
          <img
            v-else
            :src="require('../../../assets/user-png.png')"
            alt=""
          />
        </v-avatar>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    candidate: {
      required: true,
      type: Object,
    },

    detailsRoute: {
      required: true,
      type: Object,
      name: "",
      params: {},
    },
  },
  data() {
    return {};
  },
  computed: {
    personal_data() {
      let personal_data = {
        profissao: "",
        endereco: "",
        image: "",
      };
      if (this.candidate.personal_data?.image) {
        personal_data.image = this.candidate.personal_data.image;
      }
      if (this.candidate.personal_data?.profissao) {
        personal_data.profissao = this.candidate.personal_data.profissao;
      }
      if (this.candidate.personal_data?.endereco) {
        personal_data.endereco = this.candidate.personal_data.endereco;
      }

      return personal_data;
    },
  },
};
</script>

<style>
img {
  object-fit: cover;
}
</style>