var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"app":"","clipped-left":"","color":"white"}},[_c('v-toolbar-title',{attrs:{"to":{ name: 'site.contents' }}},[_c('router-link',{attrs:{"to":{ name: 'site.contents' }}},[_c('img',{attrs:{"src":require('../../assets/img/Logo-Empresas-830x354.png'),"height":"45","alt":"Gugla Empresas"}})])],1),_c('v-spacer'),(_vm.isMobile)?_c('div',{staticClass:"mx-auto",staticStyle:{"display":"flex","gap":"20px","padding-right":"20px","font-weight":"400","font-size":"small","align-items":"center"}},[_c('v-menu',{attrs:{"open-on-hover":"","down":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',_vm._g(_vm._b({class:{ 'on-hover': hover },staticStyle:{"color":"black"},style:({ color: hover ? '#FF4500 !important' : 'black' }),attrs:{"dark":""}},'span',attrs,false),on),[_c('v-icon',{class:{ 'on-hover': hover },staticStyle:{"transform":"translateY(-1px)"},style:({ color: hover ? '#FF4500 !important' : 'rgba(0, 0, 0, 0.54)' }),attrs:{"size":"22"}},[_vm._v(" mdi-table-of-contents ")]),_vm._v(" Concursos ")],1)]}}],null,true)})]}}],null,false,3192050378)},[_c('v-list',{staticStyle:{"font-weight":"300","font-size":"small"}},[_c('v-list-item',{on:{"click":function($event){return _vm.goToConcursos()}}},[_c('span',[_vm._v(" Todos Concursos ")])]),_c('v-list-item',{on:{"click":function($event){return _vm.meusConcursos()}}},[_vm._v(" Meus Concursos ")])],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","down":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',_vm._g(_vm._b({class:{ 'on-hover': hover },staticStyle:{"color":"black"},style:({ color: hover ? '#FF4500 !important' : 'black' }),attrs:{"dark":""}},'span',attrs,false),on),[_c('v-icon',{class:{ 'on-hover': hover },staticStyle:{"transform":"translateY(-3.5px)"},style:({ color: hover ? '#FF4500 !important' : 'rgba(0, 0, 0, 0.54)' }),attrs:{"size":"20"}},[_vm._v(" mdi-domain ")]),_vm._v(" Empresas ")],1)]}}],null,true)})]}}],null,false,133337179)},[_c('v-list',{staticStyle:{"font-weight":"300","font-size":"small"}},[_c('v-list-item',{on:{"click":function($event){return _vm.goToEmpresa()}}},[_vm._v(" Todas Empresas ")]),_c('v-list-item',{on:{"click":function($event){return _vm.minhasEmpresas()}}},[_vm._v(" Minhas Empresas ")]),_c('v-list-item',{on:{"click":function($event){return _vm.goToCreateEmpresa()}}},[_vm._v(" Criar Empresas ")])],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","down":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',_vm._g(_vm._b({class:{ 'on-hover': hover },style:({ color: hover ? '#FF4500 !important' : 'black' }),attrs:{"dark":""}},'span',attrs,false),on),[_c('v-icon',{class:{ 'on-hover': hover },staticStyle:{"transform":"translateY(-3px)"},style:({ color: hover ? '#FF4500 !important' : 'rgba(0, 0, 0, 0.54)' }),attrs:{"size":"20"}},[_vm._v(" mdi-account-group-outline ")]),_vm._v(" Contratar colaboradores ")],1)]}}],null,true)})]}}],null,false,248727261)},[_c('v-list',{staticStyle:{"font-weight":"300","font-size":"small","background-color":"#ffffff"}},[_c('v-list-item',{on:{"click":function($event){return _vm.minhasVagas()}}},[_vm._v(" Minhas Vagas ")]),_c('v-list-item',{on:{"click":function($event){return _vm.baseDeDados()}}},[_vm._v(" Candidatos Buscando Emprego ")]),_c('v-list-item',{on:{"click":function($event){return _vm.candidatosFavoritos()}}},[_vm._v(" Meus Candidatos Favoritos ")])],1)],1)],1):_vm._e(),_c('div',[(!_vm.logado)?_c('v-btn',{attrs:{"depressed":"","rounded":"","text":"","small":"","to":{ name: 'candidate.login' }}},[_vm._v(" Login | Registar ")]):_c('v-toolbar-items',[(_vm.getCandidate.logo)?_c('v-btn',{staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"depressed":"","rounded":"","text":"","small":""},on:{"click":_vm.profile}},[_c('v-avatar',{attrs:{"size":_vm.isMobile ? '48' : '38'}},[_c('img',{attrs:{"src":_vm.getCandidate.logo}})])],1):_c('v-btn',{attrs:{"depressed":"","rounded":"","text":"","small":""},on:{"click":_vm.profile}},[_c('v-avatar',{attrs:{"size":_vm.isMobile ? '48' : '38'}},[_c('img',{attrs:{"src":require('../../assets/img/user-png.png'),"alt":""}})])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }