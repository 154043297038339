<template>
  <v-container class="mt-8">
    <div>
      <v-row>
        <v-col cols="12" class="mb-6">
          <v-btn
            :to="{ name: 'recruiter.favorites' }"
            class="ml-0"
            tile
            elevation="4"
            dark
            color="red acent-3"
            small
          >
            <v-icon left small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <candidate-details :candidate="candidate" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import CandidateDetails from "./CandidateDetails";
import Swal from "sweetalert2";
// import Swal from "sweetalert2/dist/sweetalert2.js";

// import "sweetalert2/src/sweetalert2.scss";
export default {
  components: {
    CandidateDetails,
  },

  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      candidate: {},
    };
  },
  created() {
    this.getCandidate(this.id);
  },
  methods: {
    getCandidate(id) {
      this.$store.dispatch("getCandidate", id).then((response) => {
        this.candidate = response.data;
      });
    },
  },
};
</script>

<style>
</style>