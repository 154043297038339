<template>
  <div :class="isMobile ? 'ma-4' : 'ma-2'" style="text-align: justify">
    <div class="containe">
      <span style="color: #ff4500">FAQS </span>
      <p class="textoCor">Perguntas Frequentes</p>
    </div>
    <div class="contain" :class="isMobile ? '' : 'mb-4'">
      <p style="text-align: left; font-weight: 600">
        Tem alguma questão? Encontre a resposta abaixo.
      </p>

      <p>
        Caso não a encontre pedimos-lhe que envie-nos, através do
        <a href="mailto:comercial@marrar.co.mz" >email </a> ou ainda através do
        whatsapp
        <a
          href="https://api.whatsapp.com/message/EN67O5UYUXTMB1?autoload=1&app_absent=0"
          target="_blank"
          >clicando aqui</a
        >.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header1",
  props: {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
};
</script>

<style scoped>
.containe {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  font-weight: 600;
  color: black;
  /* padding-left: -12px; */
}
.textoCor {
  color: #575757;
  font-weight: 400;
  font-size: medium;
}
.contain {
  margin-top: 1rem;
  /* margin-left: 1rem ; */
  margin-bottom: 3.5rem;
  font-weight: 400;
  color: black;
}
</style>