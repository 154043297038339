<template>
  <v-row wrap>
    <v-col :cols="isMobile ? '12' : '12'" style="background-color: #f0f2f5">
      <v-container fill-height style="height: 100vh">
        <v-row wrap justify="center" align="center">
          <v-col cols="12" :md="isMobile ? '6' : '6'" xl="4">
            <v-container fill-height fluid>
              <v-row wrap justify="center" align="center">
                <v-col>
                  <v-form @submit.prevent="forgotPassword">
                    <v-card elevation="2" :disabled="disabled" max-width="470">
                      <v-card-title>
                        <v-img :src="
                          require('../../assets/img/Logo-Empresas-830x354.png')
                        " max-width="200" max-height="200" alt="Gugla Empresas"
                          style="display: block; margin-left: auto; margin-right: auto" />
                      </v-card-title>
                      <v-card-subtitle style="margin-bottom: 0px; margin-top: 0px">
                        <h2 class="text-center" style="color: #FF5414;">Recupere a sua Senha</h2>
                      </v-card-subtitle>
                      <v-card-subtitle style="font-size:smaller; color: #000;">Escolha uma das opções abaixo para receber
                        o código de recuperação da senha</v-card-subtitle>
                      <v-card-text style="margin-bottom: 5%;">
                        <validation-provider>
                          <v-radio-group mandatory v-model="choose">
                            <v-radio color="#ff4500" value="email"  v-if="email != 'empty'">
                              <template v-slot:label>
                                <div style="font-size: small; color: #000;">
                                  Uma mensagem com o código de verificação será enviado para o email
                                  <strong>{{ email }}</strong>
                                </div>
                              </template>
                            </v-radio>
                            <v-radio color="#ff4500" value="contacto"  v-if="contacto != ''">
                              <template v-slot:label>
                                <div style="font-size: small;color: #000;">
                                  Uma mensagem com o código de verificação será enviado para o número de telemóvel
                                  <strong>{{ contacto }}</strong>
                                </div>
                              </template>

                            </v-radio>
                          </v-radio-group>
                        </validation-provider>
                        <v-card-actions>
                          <v-btn text class="no-backButton" style="padding: 0;" :to="{ name: 'candidate.login' }">
                            <v-icon style="padding-right: 5px" dense>
                              mdi-arrow-left
                            </v-icon>
                            Voltar
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn @click="next()" class="no-uppercase" style="padding-inline: 39px;margin-right: -7px;">
                            Seguinte
                          </v-btn>
                        </v-card-actions>


                      </v-card-text>
                      <!-- <v-card-text>
                        <v-text-field v-model="formData.email" label="E-mail" color="#FF4500" />
                      </v-card-text>
                      <v-card-actions>
                        <v-btn type="submit" small elevation="4" dark color="secondary">Enviar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn small elevation="4" outlined dark color="secondary"
                          :to="{ name: 'candidate.login' }">Cancelar</v-btn>
                      </v-card-actions> -->
                    </v-card>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <loader />
  </v-row>
</template>

<script>
import Loader from "../loader/Loader.vue";
import Swal from "sweetalert2";
import Footer from '../footer/footer.vue'
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Loader, Footer },

  data() {
    return {
      email: "",
      disabled: false,
      choose: "",
      contacto: "",
      formData: {
        email: "",
      },
      tab: null,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
  created() {
    this.email = localStorage.getItem("email")
    this.contacto = localStorage.getItem("contacto")
  },
  methods: {
    forgotPassword() {
      this.$store.dispatch("loaderChange", true);
      this.$store
        .dispatch("forgotPassword", this.formData)
        .then(() => {
          this.$store.dispatch("loaderChange", false);
          Swal.fire({
            title: "Sucesso!",
            text: "Receberá um email para poder redefinir a sua senha dentro de instantes!",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#FF1744",
          });

          this.$router.push({ name: "candidate.login" });
        })
        .catch(() => this.$store.dispatch("loaderChange", false));
    },
    next() {
      this.$store.dispatch("loaderChange", true);
      // console.log(this.choose)
      if (this.choose == 'contacto') {
        // console.log(this.contacto.slice(4))
        this.$router.push({ name: 'recruiter.recoverypassword.otp', params: { telefonerecovery: this.contacto.slice(4) } })
        this.$store.dispatch("loaderChange", false);
      } else if (this.choose == 'email') {
        this.$router.push({ name: 'recruiter.register.email.otp', params: { email: this.email } })
        this.$store.dispatch("loaderChange", false);
      }
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

.red-gugla {
  color: #ff1744;
}

.basil {
  background-color: #fffbe6 !important;
}

.basil--text {
  color: #356859 !important;
}

.shadow-left {
  box-shadow: 20px 20px 20px 20px #888888;
  /* for all browsers */
  -moz-box-shadow: 20px 20px 20px 20px #888888;
  /* for old firefox */
  -webkit-box-shadow: 20px 20px 20px 20px #888888;
  /* override for Chrome & Safari */
}

.text1 {
  text-align: center;
  font-size: 23px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  padding: 20px;
}

.register {
  color: cornflowerblue;
}

.register:hover {
  cursor: pointer;
}

.no-uppercase {
  text-transform: unset !important;
  background-color: #ff4500 !important;
  color: white;
  font-size: small;
}

.no-backButton {
  text-transform: unset !important;
  color: #ff4500 !important;
}

.mobile {
  /* padding-top: 60px; */
  /* background-color: red; */
  /* style="background-color: #F0F2F5; padding-top: 20%;" */
  /* padding-top: 50%; */
  margin-top: 40%;
  background-color: #F0F2F5;
}
</style>