<template>
  <div>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row style="margin: 2.5%; top: 0px">
        <v-list-item>
          <v-list-item-avatar size="100">
            <img v-if="personal_data.image" :src="personal_data.image" />
            <img
              v-else
              :src="require('../../../assets/user-png.png')"
              alt=""
            />
          </v-list-item-avatar>
          <v-list-item-content style="margin-top: 15px">
            <v-list-item-title class="title" style="margin-top: ">
              {{ candidate.name }} {{ candidate.last_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ personal_data.profissao }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="personal_data.endereco">
              {{ personal_data.endereco }}, Moçambique
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    small
                    light
                    outlined
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span> informação pessoal</span>
                  </v-chip>
                </template>

                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar color="secondary" dark>
                      <v-row>
                        <v-col cols="10"> Informação Pessoal </v-col>
                        <v-col cols="2">
                          <v-btn
                            color="secondary"
                            @click="dialog.value = false"
                          >
                            <v-icon color="" large> mdi-close </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-toolbar>
                    <v-container>
                      <form>
                        <v-text-field
                          v-model="candidate.email"
                          prepend-icon="mdi-email"
                          label="Email"
                          required
                          disabled
                        ></v-text-field>
                        <v-text-field
                          v-model="personal_data.celular"
                          prepend-icon="mdi-phone"
                          label="Celular"
                          required
                          disabled
                        ></v-text-field>
                        <v-text-field
                          dense
                          v-model="personal_data.anoNascimento"
                          label="Data de nascimento"
                          prepend-icon="mdi-calendar"
                          readonly
                          disabled
                        ></v-text-field>
                        <v-text-field
                          dense
                          v-model="personal_data.profissao"
                          label="Profissão"
                          prepend-icon="mdi-job"
                          readonly
                          disabled
                        ></v-text-field>
                        <v-text-field
                          dense
                          v-model="personal_data.educacao"
                          label="Educação"
                          prepend-icon="mdi-school"
                          readonly
                          disabled
                        ></v-text-field>
                        <v-text-field
                          dense
                          v-model="personal_data.endereco"
                          label="Endereço"
                          prepend-icon="mdi-home"
                          readonly
                          disabled
                        ></v-text-field>
                      </form>
                    </v-container>
                  </v-card>
                </template>
              </v-dialog>
              <v-btn
                v-show="candidate.candidate_favorite == 0"
                color="#FF5252"
                class="white--text"
                small
                rounded
                @click="addFavorite"
              >
                Marcar como Favorito
                <v-icon right dark> mdi-star </v-icon>
              </v-btn>
              <v-btn
                v-show="candidate.candidate_favorite == 1"
                color="#E52335"
                class="white--text"
                small
                rounded
                @click="removeFavorite"
              >
                Desmarcar como Favorito
                <v-icon right dark> mdi-star </v-icon>
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-row>
    </v-card>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin: 2.5%; top: 0px">
        <v-container>
          <v-card flat width="auto">
            <v-card-title>
              <v-row>
                <v-col cols="10"> Experiência Profissional </v-col>
              </v-row>
            </v-card-title>
          </v-card>
          <v-divider class="mx-4"></v-divider>
          <v-card-text
            v-for="experience in candidate.profissional_experience"
            :key="experience.id"
          >
            <v-row>
              <v-col cols="10">
                <p class="text-h6 text--primary">
                  {{ experience.empresa }}
                </p>
                <div>{{ experience.cargo }}</div>

                <p>
                  <span>{{ experience.inicio | formatDate }} </span>
                  -
                  <span v-if="experience.atual">Atual</span>
                  <span v-else> {{ experience.fim | formatDate }}</span>
                </p>
              </v-col>
            </v-row>
            <v-divider style=""></v-divider>
          </v-card-text>
        </v-container>
      </v-row>
    </v-card>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin: 2.5%; top: 0px">
        <v-container>
          <v-card width="auto" flat>
            <v-card-title>Formação Academica</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text
              v-for="education in candidate.education"
              :key="education.id"
            >
              <v-row>
                <v-col cols="10">
                  <p class="text-h6 text--primary">
                    {{ education.instituicao }}
                  </p>
                  <div>{{ education.curso }} - {{ education.grau }}</div>

                  <p>
                    <span> {{ education.estado }} </span>
                  </p>
                </v-col>
              </v-row>
              <v-divider style=""></v-divider>
            </v-card-text>
          </v-card>
        </v-container>
      </v-row>
    </v-card>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin: 2.5%; top: 0px">
        <v-container>
          <v-card width="auto" flat>
            <v-card-title>Idiomas</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text
              v-for="language in candidate.language"
              :key="language.id"
            >
              <v-row>
                <v-col cols="10">
                  <p class="text-h6 text--primary">
                    {{ language.idioma }}
                  </p>

                  <div>fala - {{ language.fala }}</div>

                  <p>
                    Escrita - <span>{{ language.escrita }} </span>
                  </p>
                </v-col>
              </v-row>
              <v-divider style=""></v-divider>
            </v-card-text>
          </v-card>
        </v-container>
      </v-row>
    </v-card>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin: 2.5%; top: 0px">
        <v-container>
          <v-card width="auto" flat>
            <v-card-title>Documentos</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text v-for="anexo in candidate.anexos" :key="anexo.id">
              <v-row>
                <v-col md="9" sm="6" style="margin-left: 50px">
                  <v-btn icon :href="anexo.documento" x-large>
                    <v-icon>mdi-file-download</v-icon>
                    <div>{{ anexo.tipo_documento }}</div>
                  </v-btn> 
                </v-col>
              </v-row>
              <v-divider style=""></v-divider>
            </v-card-text>
          </v-card>
        </v-container>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      submit_btn: false,
    };
  },
  props: {
    candidate: {
      required: true,
      type: Object,
    },
  },
  computed: {
    image() {
      if (this.candidate.personal_data.image) {
        return true;
      }
      return false;
    },
    personal_data() {
      let personal_data = {
        profissao: "",
        endereco: "",
        image: "",
        celular: "",
        anoNascimento: "",
        educacao: "",
      };
      if (this.candidate.personal_data?.image) {
        personal_data.image = this.candidate.personal_data.image;
      }
      if (this.candidate.personal_data?.profissao) {
        personal_data.profissao = this.candidate.personal_data.profissao;
      }
      if (this.candidate.personal_data?.endereco) {
        personal_data.endereco = this.candidate.personal_data.endereco;
      }
      if (this.candidate.personal_data?.celular) {
        personal_data.celular = this.candidate.personal_data.celular;
      }
      if (this.candidate.personal_data?.anoNascimento) {
        personal_data.anoNascimento =
          this.candidate.personal_data.anoNascimento;
      }
      if (this.candidate.personal_data?.educacao) {
        personal_data.educacao = this.candidate.personal_data.educacao;
      }

      return personal_data;
    },
  },
  filters: {
    formatDate(date) {
      if (date) {
        let newDate = new Date(date);
        let newFormatedDate =
          newDate.getMonth() + 1 + "/" + newDate.getFullYear();
        return newFormatedDate;
      }

      return "";
    },
  },

  methods: {
    getCandidate() {
      this.$store
        .dispatch("getCandidate", this.candidate.id)
        .then((response) => {
          this.candidate = response.data;
        });
    },
    addFavorite() {
      // console.log("clickajsb", this.candidate.id)
      let params = {
        candidate_id: this.candidate.id,
      };
      this.$store
        .dispatch("addCandidatesFavorites", params)
        .then((res) => {
          // console.log(res);
          // this.$router.push({ name: "recruiter.candidatos" });
          this.getCandidate();
          // this.submit_btn = true
          // Swal.fire({
          //   title: "Sucesso!",
          //   text: "Candidato adicionado aos Meus Favoritos, veja em MEUS CANDIDATOS FAVORITOS.",
          //   icon: "success",
          //   confirmButtonText: "OK",
          //   confirmButtonColor: "#FF4500",
          // });
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Candidato adicionado aos Meus Favoritos.",
          });
        });
    },
    removeFavorite() {
      // console.log("clickajsb", this.candidate.id)
      let params = {
        candidate_id: this.candidate.id,
      };
      this.$store
        .dispatch("removeCandidatesFavorites", { params })
        .then((res) => {
          // console.log(res);
          // this.$router.push({ name: "recruiter.candidatos" });
          // this.submit_btn = false
          this.getCandidate();
          // Swal.fire({
          //   title: "Sucesso!",
          //   text: "Candidato removido dos meus Favoritos",
          //   icon: "success",
          //   confirmButtonText: "OK",
          //   confirmButtonColor: "#FF4500",
          // });
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Candidato removido dos Favoritos.",
          });
        });
    },
  },
};
</script>

<style>
</style>