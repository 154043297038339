<template>
  <v-container class="mt-8" style="margin-bottom: 2.5%;">
    <v-card max-width="450px" class="mx-auto bg" elevation="2">
        <v-img class="" height="200px" src="http://unblast.com/wp-content/uploads/2021/09/Real-Estate-Agent-Illustration.jpg" gradient="150deg, rgb(185 224 255 / 58%) 0%, rgb(243 220 246 / 52%) 35%, rgb(223 255 242 / 74%) 74%">
        </v-img>
        <v-row justify="center" >
            <v-col align-self="start" class="d-flex justify-center align-center pa-0" cols="12">
              <v-avatar class="profile avatar-center-heigth avatar-shadow" color="white" size="164">
                <!-- <v-btn @click="onButtonClick" class="upload-btn" x-large icon>
                  <v-icon>
                    mdi-camera
                  </v-icon>
                </v-btn>
                <input ref="uploader" class="d-none" type="file" accept="image/*" :change="onFileChanged"> -->
                <img v-if="getCandidate.logo" :src="getCandidate.logo" alt="" />
                <img v-else src="https://gugla.s3.us-east-2.amazonaws.com/public/dev/recruiters/23/522970725.png" alt="" />
              </v-avatar>
            </v-col>
        </v-row>
        <v-list-item color="#0000" class="profile-text-name ma-4 pt-16">
            <v-list-item-content>
                <v-list-item-title class="text-h6">
                    {{ getCandidate.name }} {{ getCandidate.last_name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if=" getCandidate.email !== 'empty'">{{ getCandidate.email }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else>{{ getCandidate.contacto }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn class="mx-2"  dark color="red" small @click="logout()">
                    <v-icon dark small>
                        mdi-logout
                    </v-icon>
                    <span>Logout</span>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
        <v-list dense>
            <v-list-item-group
            color="primary"
        >
            <v-list-item :to="{ name: 'perfil.infoPessoal' }">
                <v-list-item-icon>
                    <v-icon> mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title> Informação pessoal</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'perfil.estadoSubscricao' }">
                <v-list-item-icon>
                    <v-icon> mdi-arrow-right </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Estado da Subscrição</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: getCandidate.no_password ? 'candidate.define.chagepassword' :  'candidate.chagepassword' }">
                <v-list-item-icon>
                    <v-icon> mdi-form-textbox-password</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-if="getCandidate.no_password">Definir senha</v-list-item-title>
                    <v-list-item-title v-else>Alterar Senha</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'perfil.notificacoes' }">
                <v-list-item-icon>
                    <v-icon> mdi-bell-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Notificações</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'perfil.minhasEmpresas' }">
                <v-list-item-icon>
                    <v-icon> mdi-office-building-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Minhas Empresas</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'perfil.concursosFavoritos' }">
                <v-list-item-icon>
                    <v-icon> mdi-eye</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Concursos Favoritos</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
        </v-list>
    </v-card>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
export default {
    data(){
    return {
      user_exist: localStorage.getItem("user_exist"),
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
    getCandidate() {
      let candidate = this.$store.state.candidate;
      return candidate;
    },
  },
  created() {
    this.getMe()
    // this.user_exist = sessionStorage.getItem("user_exist")
  },
  methods: {
    goToConvidarAmigos() {
      this.$router.push({ name: "convidar.amigos" });
    },
    goToInfoPessoal() {
      this.$router.push({ name: "perfil.infoPessoal" });
    },
    goToNotificacoes() {
      this.$router.push({ name: "perfil.notificacoes" });
    },
    goToMinhasEmpresas() {
      this.$router.push({ name: "perfil.minhasEmpresas" });
    },
    goToEstadoSubscricao() {
      this.$router.push({ name: "perfil.estadoSubscricao" });
    },
    goToMeusConcursosFavoritos() {
      this.$router.push({ name: "perfil.concursosFavoritos" });
    },
    logout() {
      this.$store.dispatch("loaderChange", true);
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "candidate.login" });
        this.$store.dispatch("loaderChange", false);
      });
    },
    getMe() {
      this.$store.dispatch("loaderChange", true);
      this.$store.dispatch("me").then(() => {
        this.$store.dispatch("loaderChange", false);
      });
    },
    shareInvite() {
      Swal.fire({
        title: "Ainda nao esta disponivel!",
        text: "Aguarde so mais um pouco",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#FF4500",
      });
    }
  },
}
</script>

<style>
   .avatar-center-heigth {
      position: absolute;
    }

    .profile-text-name {
      margin-top: 70px;
    }

    .sutitles {
      margin: 5px;
      padding: 16px;
    }
</style>