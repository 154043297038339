<template>
  <div>
    <v-row justify="center">
      <p class="text-subtitle-1 text-md-h4">
        {{ titulo }}
      </p>
      <!-- <h4 style="text-transform: uppercase; font-size: 25px; color: #FF5252; align-items: center; justify-content: center;">
          {{ titulo }}
        </h4> -->
    </v-row>

    <v-card class="mx-auto" max-width="1000" tile>
      <v-row style="margin: 2.5%; top: 0px">
        <v-list-item>
          <v-list-item-avatar size="100">
            <img v-if="personal_data.image" :src="personal_data.image" />
            <img v-else :src="require('../../../assets/user-png.png')" alt="" />
          </v-list-item-avatar>
          <v-list-item-content style="margin-top: 15px">
            <v-list-item-title class="title" style="margin-top: ">
              {{ candidate.name }} {{ candidate.last_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ personal_data.profissao }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="personal_data.endereco">
              {{ personal_data.endereco }}, Moçambique
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    small
                    light
                    outlined
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span> informação pessoal</span>
                  </v-chip>
                </template>

                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar color="secondary" dark>
                      <v-row>
                        <v-col cols="10"> Informação Pessoal </v-col>
                        <v-col cols="2">
                          <v-btn
                            color="secondary"
                            @click="dialog.value = false"
                          >
                            <v-icon color="" large> mdi-close </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-toolbar>
                    <v-container>
                      <form>
                        <v-text-field
                          v-model="candidate.email"
                          prepend-icon="mdi-email"
                          label="Email"
                          required
                          disabled
                        ></v-text-field>
                        <v-text-field
                          v-model="personal_data.celular"
                          prepend-icon="mdi-phone"
                          label="Celular"
                          required
                          disabled
                        ></v-text-field>
                        <v-text-field
                          dense
                          v-model="personal_data.anoNascimento"
                          label="Data de nascimento"
                          prepend-icon="mdi-calendar"
                          readonly
                          disabled
                        ></v-text-field>
                        <v-text-field
                          dense
                          v-model="personal_data.profissao"
                          label="Profissão"
                          prepend-icon="mdi-job"
                          readonly
                          disabled
                        ></v-text-field>
                        <v-text-field
                          dense
                          v-model="personal_data.educacao"
                          label="Educação"
                          prepend-icon="mdi-school"
                          readonly
                          disabled
                        ></v-text-field>
                        <v-text-field
                          dense
                          v-model="personal_data.endereco"
                          label="Endereço"
                          prepend-icon="mdi-home"
                          readonly
                          disabled
                        ></v-text-field>
                      </form>
                    </v-container>
                  </v-card>
                </template>
              </v-dialog>
              <v-btn
                v-show="candidate.candidate_favorite == 0"
                color="#FF5252"
                class="white--text"
                small
                rounded
                @click="addFavorite"
              >
                Marcar como Favorito
                <v-icon right dark> mdi-star </v-icon>
              </v-btn>
              <v-btn
                v-show="candidate.candidate_favorite == 1"
                color="#E52335"
                class="white--text"
                small
                rounded
                @click="removeFavorite"
              >
                Desmarcar como Favorito
                <v-icon right dark> mdi-star </v-icon>
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-row>
    </v-card>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin: 2.5%; top: 0px">
        <v-container>
          <v-card flat width="auto">
            <v-card-title>
              <v-row>
                <v-col cols="10"> Experiência Profissional </v-col>
              </v-row>
            </v-card-title>
          </v-card>
          <v-divider class="mx-4"></v-divider>
          <v-card-text
            v-for="experience in candidate.profissional_experience"
            :key="experience.id"
          >
            <v-row>
              <v-col cols="10">
                <p class="text-h6 text--primary">
                  {{ experience.empresa }}
                </p>
                <div>{{ experience.cargo }}</div>

                <p>
                  <span>{{ experience.inicio | formatDate }} </span>
                  -
                  <span v-if="experience.atual">Atual</span>
                  <span v-else> {{ experience.fim | formatDate }}</span>
                </p>
              </v-col>
            </v-row>
            <v-divider style=""></v-divider>
          </v-card-text>
        </v-container>
      </v-row>
    </v-card>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin: 2.5%; top: 0px">
        <v-container>
          <v-card width="auto" flat>
            <v-card-title>Formação Academica</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text
              v-for="education in candidate.education"
              :key="education.id"
            >
              <v-row>
                <v-col cols="10">
                  <p class="text-h6 text--primary">
                    {{ education.instituicao }}
                  </p>
                  <div>{{ education.curso }} - {{ education.grau }}</div>

                  <p>
                    <span> {{ education.estado }} </span>
                  </p>
                </v-col>
              </v-row>
              <v-divider style=""></v-divider>
            </v-card-text>
          </v-card>
        </v-container>
      </v-row>
    </v-card>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin: 2.5%; top: 0px">
        <v-container>
          <v-card width="auto" flat>
            <v-card-title>Idiomas</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text
              v-for="language in candidate.language"
              :key="language.id"
            >
              <v-row>
                <v-col cols="10">
                  <p class="text-h6 text--primary">
                    {{ language.idioma }}
                  </p>

                  <div>fala - {{ language.fala }}</div>

                  <p>
                    Escrita - <span>{{ language.escrita }} </span>
                  </p>
                </v-col>
              </v-row>
              <v-divider style=""></v-divider>
            </v-card-text>
          </v-card>
        </v-container>
      </v-row>
    </v-card>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin: 2.5%; top: 0px">
        <v-container>
          <v-card width="auto" flat>
            <v-card-title>Documentos</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text v-for="anexo in candidate.anexos" :key="anexo.id">
              <v-row>
                <v-col md="9" sm="6" style="margin-left: 50px">
                  <v-btn target="_blank" icon :href="anexo.documento" x-large>
                    <v-icon>mdi-file-download</v-icon>
                    <div>{{ anexo.tipo_documento }}</div>
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider style=""></v-divider>
            </v-card-text>
          </v-card>
        </v-container>
      </v-row>
    </v-card>

    <!-- <v-card class="mx-auto" max-width="1000" tile>
      <v-row wrap style="margin-bottom: 30px">
        <v-col cols="12" md="6">
          <v-col cols="12" md="12">
            <v-textarea
              class="mx-2"
              label="Escreva o seu Comentário"
              v-model="comentario"
              prepend-icon="mdi-comment"
              outlined
            ></v-textarea>
          </v-col>
          <v-row justify="end">
            <v-col cols="12" md="3">
              <v-btn
                color="#E41F31"
                class="white--text"
                small
                rounded
                @click="onPostComment(candidate)"
              >
                Comentar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6">
          <v-list three-line>
            <template>
              <v-subheader>Comentários</v-subheader>
            </template>
            <p v-if="!comments" class="text-left">Ainda não tem comentários.</p>
            <v-list-item
              v-else
              two-line
              v-for="(item, index) in comments"
              :key="index"
            >
              <v-list-item-avatar>
                <img
                  height="100"
                  width="100%"
                  src="../../../assets/service.png"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.comment }}</v-list-item-title>
                <v-list-item-subtitle
                  >{{ item.who }} - {{ item.when }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <div
        class="text-center"
        style="margin-bottom: 50px; padding-bottom: 20px"
      >
        <v-btn
          color="#FF5252"
          class="white--text"
          small
          rounded
          @click="submitBadCandidate"
        >
          Reprovar
        </v-btn>
        <v-btn
          color="#008000"
          class="white--text"
          small
          rounded
          @click="submitGoodCandidate"
        >
          Aprovar
        </v-btn>
      </div>

      <v-card></v-card>
    </v-card> -->

    <!-- <p>{{ candidates.indexOf(this.candidate.id) }}</p> -->
    <!-- 
    <div class="text-center">
      <v-pagination v-model="page" :length="4" circle></v-pagination>
    </div> -->

    <v-row class="text-center" style="margin-bottom: 30px">
      <v-col cols="12" md="6">
        <v-btn
          color="#FF5252"
          class="white--text"
          small
          rounded
          @click="goToPrevios"
        >
          <v-icon>mdi-skip-previous</v-icon>
          Anterior
        </v-btn>
      </v-col>
      <v-space></v-space>
      <v-col cols="12" md="6">
        <v-btn
          color="#FF5252"
          class="white--text"
          small
          rounded
          @click="goToNext"
        >
          Proximo
          <v-icon>mdi-skip-next</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      position: "",
      submit_btn: false,
      comments: "",
      titulo: "",
      page: 1,
      comentarios: [],
      comentario: "",
      items: [
        { header: "Comentários" },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Brunch this weekend?",
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
          subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          title: "Oui oui",
          subtitle:
            '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
          title: "Birthday gift",
          subtitle:
            '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title: "Recipe to try",
          subtitle:
            '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
        },
      ],
    };
  },
  props: {
    candidate: {
      required: true,
      type: Object,
    },
  },
  computed: {
    candidates() {
      return this.$store.state.content_recruiter.candidates;
    },
    image() {
      if (this.candidate.personal_data.image) {
        return true;
      }
      return false;
    },
    personal_data() {
      let personal_data = {
        profissao: "",
        endereco: "",
        image: "",
        celular: "",
        anoNascimento: "",
        educacao: "",
      };
      if (this.candidate.personal_data?.image) {
        personal_data.image = this.candidate.personal_data.image;
      }
      if (this.candidate.personal_data?.profissao) {
        personal_data.profissao = this.candidate.personal_data.profissao;
      }
      if (this.candidate.personal_data?.endereco) {
        personal_data.endereco = this.candidate.personal_data.endereco;
      }
      if (this.candidate.personal_data?.celular) {
        personal_data.celular = this.candidate.personal_data.celular;
      }
      if (this.candidate.personal_data?.anoNascimento) {
        personal_data.anoNascimento =
          this.candidate.personal_data.anoNascimento;
      }
      if (this.candidate.personal_data?.educacao) {
        personal_data.educacao = this.candidate.personal_data.educacao;
      }

      return personal_data;
    },
  },
  filters: {
    formatDate(date) {
      if (date) {
        let newDate = new Date(date);
        let newFormatedDate =
          newDate.getMonth() + 1 + "/" + newDate.getFullYear();
        return newFormatedDate;
      }

      return "";
    },
  },

  created() {
    this.titulo = localStorage.getItem("titulo");
    this.content_id = localStorage.getItem("id_content");
    // candidates.indexOf(this.candidate.id)
    // this.position = candidates.indexOf(this.candidate.id)
    // console.log("test", JSON.parse(candidate.comentarios))
  },

  watch: {
    candidate(candidate) {
      this.comments = JSON.parse(candidate.comentarios);
    },
  },

  methods: {
    getCandidate() {
      this.$store
        .dispatch("getCandidateVaga", {
          id: this.candidate.id,
          content: this.content_id,
        })
        .then((response) => {
          this.candidate = response.data;
        });
    },
    goToPrevios() {
      this.position = this.$store.state.candidates.indexOf(this.candidate.id);
      // console.log(this.$store.state.content_recruiter.candidates[this.position+1])
      if (this.$store.state.candidates[this.position - 1] == undefined) {
        // console.log("nao existe");
        Swal.fire({
          title: "Ooopss",
          text: "Não há mais candidatos",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#FF4500",
        });
      } else {
        this.$store
          .dispatch(
            "getCandidate",
            this.$store.state.candidates[this.position - 1]
            // {id: this.$store.state.candidates[this.position - 1], content: this.content_id}
          )
          .then((response) => {
            this.candidate = response.data;
            this.$router.push({
              name: "recruiter.minhasCandidaturas.show",
              params: {
                id: this.$store.state.candidates[this.position - 1],
              },
            });
          });
      }
    },
    goToNext() {
      this.position = this.$store.state.candidates.indexOf(this.candidate.id);
      if (this.$store.state.candidates[this.position + 1] == undefined) {
        // console.log("nao existe");
        Swal.fire({
          title: "Ooopss",
          text: "Não há mais candidatos",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#FF4500",
        });
      } else {
        this.$store
          .dispatch(
            "getCandidate",
            this.$store.state.candidates[this.position + 1]
            // "getCandidateVaga",{id: this.$store.state.candidates[this.position + 1], content: this.content_id}
          )
          .then((response) => {
            // console.log("abshabs", response);
            this.$router.push({
              name: "recruiter.minhasCandidaturas.show",
              params: {
                id: this.$store.state.candidates[this.position + 1],
              },
            });
            this.candidate = response.data;
          });
      }
    },
    addFavorite() {
      // console.log("clickajsb", this.candidate.id)
      let params = {
        candidate_id: this.candidate.id,
      };
      this.$store
        .dispatch("candidate/addCandidatesFavorites", params)
        .then((res) => {
          // console.log(res);
          // this.$router.push({ name: "recruiter.candidatos" });
          this.getCandidate();
          // this.submit_btn = true
          // Swal.fire({
          //   title: "Sucesso!",
          //   text: "Candidato adicionado aos Meus Favoritos, veja em MEUS CANDIDATOS FAVORITOS.",
          //   icon: "success",
          //   confirmButtonText: "OK",
          //   confirmButtonColor: "#FF4500",
          // });
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Candidato adicionado aos Meus Favoritos.",
          });
        });
    },
    removeFavorite() {
      // console.log("clickajsb", this.candidate.id)
      let params = {
        candidate_id: this.candidate.id,
      };
      this.$store
        .dispatch("candidate/removeCandidatesFavorites", { params })
        .then((res) => {
          // console.log(res);
          // this.$router.push({ name: "recruiter.candidatos" });
          // this.submit_btn = false
          this.getCandidate();
          // Swal.fire({
          //   title: "Sucesso!",
          //   text: "Candidato removido dos meus Favoritos",
          //   icon: "success",
          //   confirmButtonText: "OK",
          //   confirmButtonColor: "#FF4500",
          // });
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Candidato removido dos Favoritos.",
          });
        });
    },
    submitBadCandidate() {
      let params = {
        candidate_id: this.candidate.id,
        content_id: Number(localStorage.getItem("id_content")),
        avaliation: 0,
      };
      // console.log(params)
      this.$store
        .dispatch("candidate/avaliateCandidates", params)
        .then((res) => {
          this.getCandidate();
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Candidato Avaliado como Mau.",
          });
        });
    },
    submitGoodCandidate() {
      let params = {
        candidate_id: this.candidate.id,
        content_id: Number(localStorage.getItem("id_content")),
        avaliation: 1,
      };
      // console.log(params)
      this.$store
        .dispatch("candidate/avaliateCandidates", params)
        .then((res) => {
          this.getCandidate();
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Candidato Avaliado como Bom.",
          });
        });
    },
    onPostComment(item) {
      // console.log(this.$store.state.auth.user.name)
      let today = new Date();
      let time = today.getHours() + ":" + today.getMinutes();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      let name = this.$store.state.auth.user.name;
      let coment = {
        comment: this.comentario,
        who: name,
        when: date + " " + time,
      };
      if (item.comentarios.length == 0) {
        this.comentarios.push(coment);
      } else {
        this.comentarios = JSON.parse(item.comentarios);
        this.comentarios.push(coment);
      }

      let params = {
        candidate_id: this.candidate.id,
        content_id: Number(localStorage.getItem("id_content")),
        comentarios: this.comentarios,
      };

      if (this.comentario == "") {
        Swal.fire({
          title: "Erro!",
          text: "Deve preencher o campo comentário.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#FF4500",
        });
      } else {
        this.$store
          .dispatch("candidate/commentCandidates", params)
          .then((res) => {
            this.getCandidate();
            this.comentario = "";
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Comentário adicionado.",
            });
          });
      }
    },
  },
};
</script>

<style>
</style>