/* eslint-disable vue/no-unused-components */
<template>
  <v-app>
    <header-component />

    <v-main>
      <router-view style="max-width: 1161px"></router-view>

      <login-dialog />
      <complete-profile />
      <apply-vaga-dialog />
      <verify-login/>
      <define-email/>
      <define-contact/>

    </v-main>

    <loader />
    <Footer v-if="isMobile" />
    <v-bottom-navigation
      v-model="value"
      :background-color="color"
      dark
      fixed
      v-if="!isMobile"
    >
      <v-btn :to="{ name: 'site.contents' }" active-class="">
        <span>Concursos</span>

        <v-icon>mdi-table-of-contents</v-icon>
      </v-btn>

      <v-btn :to="{ name: 'empresa' }">
        <span>Empresas</span>

        <v-icon>mdi-domain</v-icon>
      </v-btn>

      <!-- <v-btn>
        <span>Book</span>

        <v-icon>mdi-database</v-icon>
      </v-btn> -->

      <v-btn @click="goToPerfil()">
        <span>Perfil</span>

        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import Loader from "../components/loader/Loader.vue";
import Footer from "../components/footer/footer.vue";
import loginDialog from "../components/candidate/dialogs/loginDialog.vue";
import completeProfile from "../components/candidate/dialogs/completeProfile2.vue";
import verifyLogin from "../components/candidate/dialogs/verifyLogin.vue";
import defineEmail from "../components/candidate/dialogs/defineEmail.vue";
import defineContact from "../components/candidate/dialogs/defineContact.vue";
import applyVagaDialog from "../components/candidate/dialogs/applyVagaDialog.vue";
import HeaderComponent from "../components/header/MainNavSite.vue";
import api from "../services/index.js";
export default {
  components: {
    HeaderComponent,
    Loader,
    Footer,
    loginDialog,
    completeProfile,
    applyVagaDialog,
    verifyLogin,
    defineEmail,
    defineContact
  },
  data: () => ({ value: 1 }),
  created() {
    this.$store.dispatch('me')
    this.getPublicity();
    setTimeout(this.active, 20000);
  },
  methods: {
    goToPerfil(){
      let token = localStorage.getItem("TOKEN_AUTH");
      if (token) {
        this.$router.push({ name: "perfil.dash" });
      } else {
        this.$router.push({ name: "site.oportunidades" });
      }
    },
    checkUserProfile() {
      if (
        this.candidate?.personal_data &&
        this.candidate.personal_data == null
      ) {
        this.$store.dispatch("changeCompleteProfile", true);
      } else if (this.candidate?.anexos && this.candidate.anexos.length == 0) {
        this.$store.dispatch("changeCompleteProfile", true);
      } else if (
        this.candidate?.education &&
        this.candidate.education.length == 0
      ) {
        this.$store.dispatch("changeCompleteProfile", true);
      } else if (
        this.candidate?.language &&
        this.candidate.language.length == 0
      ) {
        this.$store.dispatch("changeCompleteProfile", true);
      }
    },
    active() {
      if (!this.$store.state.token) {
        this.$store.dispatch("changeLoginDialog", true);
      } else {
        this.checkUserProfile();
      }
    },
    getPublicity() {
      api.get(`/guest/publicity/2`).then((res) => {
        //  console.log(res.data.data)
        this.$store.dispatch("getPublicity", res.data.data);
      });
    },
  },
  computed: {
    candidate() {
      let candidate = this.$store.state.candidate;
      return candidate;
    },
    email() {
      return this.$store.state.hasEmail;
    },
    password() {
      return this.$store.state.hasPassword;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
    color() {
      switch (this.value) {
        case 0:
          return "#FF4500";
        case 1:
          return "#FF4500";
        case 2:
          return "#FF4500";
        case 3:
          return "#FF4500";
        default:
          return "#FF4500";
      }
    },
  },
};
</script>

<style>
</style>