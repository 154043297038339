/* eslint-disable vue/valid-v-for */
<template>
  <v-container class="mt-8">
    <!-- popup sobre login e subscricao  -->
    <v-dialog v-model="dialog" max-width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" id="openPopSubscricao" style="display: none">
          Open Dialog
        </v-btn>
      </template>
      <v-card class="mx-auto d-flex flex-column" style="padding-bottom: 0px; padding-top: 50px">
        <div class="meuCard" style="
              margin: 0;
              padding-bottom: 0px;
              overflow-x: hidden;
              overflow-y: hidden;
            ">
          <v-row wrap>
            <v-col cols="12" md="12">
              <div class="text-center">
                <img src="../assets/key-content.png" alt="" height="130" width="130"
                  style="display: block; margin-left: auto; margin-right: auto" />
              </div>
              <div class="textoCabelho">
                <h1 style="font-weight: bold; color: #111" class="text-center">
                  Precisa fazer Login!
                </h1>
                <br />
                <p class="text-center text-body-2 text-md-body-1">
                  Por favor faça o login com a sua conta para poder criar a sua empresa. Caso ainda não tenha uma conta
                  por favor registe-se.
                </p>
              </div>

              <br />
              <v-card-actions>
                <v-row wrap justify="center">
                  <v-col cols="12" md="4">
                    <v-btn block color="#FF4500" dark rounded @click="goToLogin">Login</v-btn>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn block color="#EEEEEE" rounded @click="goToRegisto">Registo</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
              <v-card-actions v-if="buttonCard == true">
                <v-row wrap justify="center">
                  <v-col cols="12" md="6">
                    <v-btn block color="#D8293B" dark rounded @click="goToSubscricao">Efectuar a Subscrição</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>

              <!-- <p class="text-center font-weight-bold">
                  Junte-se a mais de {{ total }} utilizadores subscritos
                </p> -->
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <!-- fim do popup login e subscricao -->
    <v-row wrap>
      <v-col cols="12" md="6">
        <div class="textoPrincipal">
          <v-row wrap>
            <v-col cols="12">
              <h1>Registo de Empresas</h1>
              <p style="font-size: 25px">
                Seja bem vindo ao processo de Registo de Empresa na plataforma
                Gugla Empresas, a plataforma das Empresas.
              </p>
            </v-col>
            <v-col cols="12">
              <div class="registar">
                <h3>Registe a sua Empresa e permita que:</h3>
                <v-list>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="#FF5252" small>
                        mdi-checkbox-blank-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        Visitantes do Gugla Empresas conheçam os seus produtos e
                        serviços;
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="#FF5252" small>
                        mdi-checkbox-blank-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        Permita que mais pessoas entrem em contacto ou sejam
                        redirecionado para o seu website;
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="#FF5252" small>
                        mdi-checkbox-blank-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        Adquira mais clientes e parceiros.
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <img src="../assets/empresa//empresa.png" alt="" :class="isMobile ? 'home-img' : 'mobile-img'" />
      </v-col>
      <!-- <v-col cols="12">asa1111111111s</v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <img src="../assets/empresa/empresaDetalhes.png" height="auto" width="700" alt=""
          :class="isMobile ? 'home-img' : 'hidden-sm-and-down'" />
      </v-col>
      <v-col cols="12" md="6">
        <div class="texto">
          <h2>Vantagens de Registar a sua Empresa no Gugla Empresas</h2>
          <v-list>
            <v-list-item v-for="(item, index) in empresaVantagens" :key="index">
              <v-list-item-icon>
                <v-icon color="#FF5252"> mdi-check-bold</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <p class="text-justify-left text-body-2 text-md-body-1">
                  {{ item.phase }}
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <p class="text-left font-weight-bold">
            Tudo isso apartir de 580,00 por mês.
          </p>
          <v-btn color="#D5172A" dark rounded @click="goToRegister" style="margin-bottom: 50px;">Registar Empresa</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  components: {
  },

  data() {
    return {
      dialog: false,
      empresaVantagens: [
        {
          code: 1,
          phase:
            "Os visitantes irão encontrar a sua empresa durante a apreciação dos concursos;",
        },
        {
          code: 2,
          phase:
            "Os visitantes irão encontrar a sua empresa através da pesquisa de serviços na lista de todas as empresas;",
        },
        {
          code: 3,
          phase:
            "Tenha um link (weblink) dedicado da sua empresa que possa servir de portfólio e que pode ser partilhado com clientes e parceiros;",
        },
        {
          code: 4,
          phase:
            "Tenha acesso a soluções digitais avançadas para a procura e seleção de recursos humanos",
        },
        {
          code: 5,
          phase:
            "Tenha a capacidade de actualizar a informação da sua empresa sempre que for necessário;",
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
  created() {

  },
  methods: {
    goToRegister() {
      // this.$router.push({ name: 'empresa.register' })
      let token = localStorage.getItem("TOKEN_AUTH");
      if (token) {
        this.$router.push({ name: 'empresa.register' })

      } else {
        this.dialog = true
      }

    },
    goToRegisto() {
      this.$router.push({ name: "candidate.register" });
    },
    goToLogin() {
      this.dialog = false;
      var urlAtual = window.location.href;
      sessionStorage.setItem("url", urlAtual);
      // sessionStorage.setItem("previous_router", "subscricao");
      this.$router.push({ name: 'candidate.login' })
      // document.getElementById("openPop2").click();
    },

  },
};
</script>

<style scoped>
.span-color {
  color: rgb(136, 136, 136);
}

.listCategory {
  font-size: 0.7em;
  cursor: pointer;
  margin-top: 5px;
}

ul li:hover {
  color: rgb(136, 136, 136);
}

.inputFile {
  cursor: pointer;
}

.texto {
  padding-top: 10%;
}

.textoPrincipal {
  padding: 0 16px;
}

.theme--light.v-list-item {
  padding: 0;
}

.v-list-item__content {
  align-items: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  overflow: hidden;
  padding: 0px 0;
}

.registar {
  padding-top: 5%;
}

h1 {
  color: #ff5252;
}

.mobile-img {
  width: 300px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.home-img {
  width: 600px;
  height: auto;
}
</style>