<template>
  <v-dialog
      v-model="completeDialog"
      scrollable
      hide-overlay
      persistent
      width="300"
    >
      <v-card
      >
        <v-card-text>
          <div style="margin-top:7px; font-size:17px;" class="text">termine de preencher o seu perfil. <span class="btn" @click="goToProfile">Ir ao perfil</span> </div>
          <div class="position-btn">
            <v-btn color="secondary" icon @click="()=>{this.$store.dispatch('changeCompleteProfile', false)}">
                <v-icon color="">
                    mdi-close
                </v-icon>
            </v-btn>
          </div>
          <v-progress-linear
            striped
            :value="total_keyEmpty_percent"
            color="#FF4500"
            height="25"
          >
            <template v-slot:default="{ value }">
              <strong style="color:white;">{{ Math.ceil(value) }}% preenchido</strong>
            </template>
          </v-progress-linear>
          <!-- <div class="btn">
            <div>
                <v-btn
                class="ml-1"
                color="#FF4500"
                tile
                elevation="4"
                dark
                small
                @click="goToProfile"
                >
                    Ir ao perfil
                </v-btn>
            </div>
            
        </div> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  
</template>

<script>
export default {
  data() {
        return {
            dialog: false,
            power: 40,
            total_keyEmpty_percent: 0,
            teste: 100,
            emptyFields: [],
            emptyFieldsObjects: []
        }
    },
    computed: {
        loginDialog() {
            return this.$store.getters.loginDialog
        },
        isLoggedIn () {
          return this.$store.getters.isLoggedIn
        },
        isMobile() {
          return this.$vuetify.breakpoint.smAndUp ? true : false;
        },
        completeDialog() {
            return this.$store.getters.completeProfile
        },
        candidate() {
            let candidate = this.$store.state.candidate
            return candidate
        },
    },
    methods: {
      isEmpty(obj) {
            for(var prop in obj) {
                if(Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
                }
            }
        

            return JSON.stringify(obj) === JSON.stringify({});
        },
        goToProfile() {
            this.$store.dispatch('changeCompleteProfile', false)
            this.$router.push({name: 'candidate.dashboard'})
        }
    },
    watch: {
        candidate(user) {
            let total_semInfo = 0
            this.emptyFieldsObjects = []
            if(!user.personal_data){
                total_semInfo = total_semInfo + 1
                this.emptyFields.push('Informação pessoal')
                this.emptyFieldsObjects.push({name: 'Informação pessoal', icon: 'mdi-account-details'})
            }
            if(user.education == 0){
                total_semInfo = total_semInfo + 1
                this.emptyFields.push('Habilitações literárias')
                this.emptyFieldsObjects.push({name: 'Habilitações literárias', icon: 'mdi-school'})
            }
            if(user.language == 0){
                total_semInfo = total_semInfo + 1
                this.emptyFields.push('Idiomas')
                this.emptyFieldsObjects.push({name: 'Idiomas', icon: 'mdi-message-bulleted'})
            }
            if(user.profissional_experience == 0){
                total_semInfo = total_semInfo + 1
                this.emptyFields.push('Experiencia Profissiona')
                this.emptyFieldsObjects.push({name: 'Experiência Profissional', icon: 'mdi-briefcase'})
            }
            if(user.anexos == 0){
                total_semInfo = total_semInfo + 1
                this.emptyFields.push('Anexos (Documentos)')
                this.emptyFieldsObjects.push({name: 'Anexos (Documentos)', icon: 'mdi-file-multiple'})
            }
            this.total_keyEmpty_percent =100 - (total_semInfo*100)/5;
        }
    }
}
</script>

<style scoped>
.btn {
  color: #FF4500;
  cursor: pointer;
}
.position-btn {
  position: absolute;
  top: 0px;
  right: 5px;
}
>>>.v-dialog {
    position: absolute;
    top: 0;
    right: -15px;
}
.text {
  display: inline-block;
  width: 98%;
}
#mySidenav a {
  position: absolute; /* Position them relative to the browser window */
  right: -140px; /* Position them outside of the screen */
  transition: 0.3s; /* Add transition on hover */
  padding: 15px; /* 15px padding */
  width: 150px; /* Set a specific width */
  text-decoration: none; /* Remove underline */
  font-size: 20px; /* Increase font size */
  color: white; /* White text color */
  border-radius: 5px 5px 5px 5px; /* Rounded corners on the top right and bottom right side */
}
#mySidenav a:hover {
  right: 0; /* On mouse-over, make the elements appear as they should */
}
#about {
  top: 20px;
  background-color: #04AA6D;
}
</style>