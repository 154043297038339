/* eslint-disable vue/valid-v-for */
<template>
  <v-container class="mt-8">
    <div>
      <v-row wrap align="center" justify="center">
        <v-col md="12">
          <template>
            <v-chip
              x-small
              label
              href="https://gugla.co.mz/publicidade.html"
              dark
              :color="'green darken-1'"
              >anuncie neste espaço</v-chip
            >
          </template>
          <publicity />
        </v-col>
      </v-row>

      <v-row v-show="isMobile" wrap align="center" justify="space-between">
        <v-col cols="12" sm="12" md="8" class="col-md-3">
          <h4
            style="text-transform: uppercase; font-size: 20px; color: #ff5252"
          >
            Todas Empresas
          </h4>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="col-md-3">
          <search-content-component
            @searchContentEvent="searchContentEvent"
          ></search-content-component>
        </v-col>
      </v-row>

      <v-row v-if="search_alert">
        <v-col cols="12">
          <v-alert
            v-if="apiContents.total > 0"
            dense
            text
            type="success"
            border="left"
          >
            <v-row justify="space-between">
              <v-col>
                <span class="caption"
                  >{{ apiContents.total }} resultados de {{ search }}.
                </span>
              </v-col>
              <v-col class="text-right">
                <v-btn @click="resetSearchResults" color="success" small text
                  >Limpar <v-icon small>mdi-close</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-alert>

          <v-alert v-else dense text type="error" border="left">
            <v-row justify="space-between">
              <v-col>
                <span class="caption"
                  >{{ apiContents.total }} resultados de {{ search }}.
                </span>
              </v-col>
              <v-col class="text-right">
                <v-btn @click="resetSearchResults" color="error" small text
                  >Limpar <v-icon small>mdi-close</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>

      <v-row wrap>
        <v-col cols="12">
          <v-row wrap>
            <v-col sm="12" md="6" class="col-md-5 mb-3">
              <v-card
                class="mx-auto d-flex flex-column"
                outlined
                elevation="1"
                min-height="180px"
                color="#FF4500"
                dark
               @click="goToEmpresa()"
              >
                <v-img
                  :src="require('../assets/img/card-empresas.png')"
                  class="white--text align-end"
                  min-height="180px"
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <p class="text-subtitle-2 text-sm-h6 mb1">
                        Registe a sua empresa nessa plataforma
                      </p>
                      <p class="text-subtitle-2 mb1">
                        Exponha os seus productos e serviços.
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-img>
              </v-card>
            </v-col>
            <v-col
              sm="12"
              md="6"
              v-for="content in apiContents.data"
              :key="content.id"
              class="col-md-5 mb-3"
            >
              <main-content-component :content="content" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-pagination
            v-model="currentPage"
            :length="apiContents.last_page"
            :total-visible="7"
            @input="handleChangePage"
            color="error"
          ></v-pagination>
        </v-col>
      </v-row>

      <v-row wrap align="center" justify="center">
        <v-col md="12" style="margin-bottom: 40px">
          <template>
            <v-chip
              x-small
              label
              href="https://gugla.co.mz/publicidade.html"
              dark
              :color="'green darken-1'"
              >anuncie neste espaço</v-chip
            >
          </template>
          <publicity />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import searchContentComponent from "./content/searchContentComponent.vue";
import searchMobileComponent from "./content/searchMobileComponent.vue";
import mainContentComponent from "./empresa/mainContentComponent.vue";

import api from "../services/index.js";
import Publicity from "./publicity/publicity.vue";

export default {
  components: {
    searchContentComponent,
    // eslint-disable-next-line vue/no-unused-components
    searchMobileComponent,
    mainContentComponent,
    Publicity,
  },

  data() {
    return {
      apiContents: [],
      currentPage: 1,
      search: "",
      search_alert: false,
      value: true,
      local: "",
      categoria: "",
      items: [
        "Cabo Delgado",
        "Gaza",
        "Inhambane",
        "Manica",
        "Maputo",
        "Nampula",
        "Niassa",
        "Sofala",
        "Tete",
        "Zambeze",
      ],
      c: 1,
      apiCategories: [], //category_name, category_count
      catgoriaFilter: "",
      categorias: [
        "Administração e Secretariado Cliente",
        "Aquisições e Procurement",
        "Assistente",
        "Auditoria e Consultoria",
        "Comunicação Social",
        "Construção Civil",
        "Contabilidade e Finanças",
        "Design",
        "Direito e Justiça",
        "Electricidade",
        "Gestão de Projectos",
        "Gestão e Assessoria",
        "Gestão Financeira",
        "Gestor Provincial",
        "Higiene e Segurança",
        "Hotelaria e Turismo",
        "informática e Programação",
        "Manutenção e Técnica",
        "Marketing e Publicidade",
        "Mecânica",
        "Meio Ambiente",
        "Monitoria e Avaliação",
        "Oficial Técnico",
        "Operário",
        "Recursos Humanos",
        "Relações Públicas",
        "Saúde",
        "Seguros",
        "Serviços Gerais",
        "Serviços Sociais",
        "Supervisão e Coordenação",
        "Topografia e Geologia",
        "Transportes e Logística",
        "Vendas/Comércio",
        "outro",
      ],
      hideFilter: false,
    };
  },
  computed: {
    candidate() {
      let candidate = this.$store.state.candidate;
      return candidate;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
  },
  created() {
    this.$store.dispatch("loaderChange", true);

    if (sessionStorage.titulo) {
      this.search_alert = true;
      this.searchContentEvent(sessionStorage.titulo);
    } else if (sessionStorage.province) {
      this.search_alert = true;
      this.filterByProvince(sessionStorage.province);
    } else if (sessionStorage.categoria) {
      this.search_alert = true;
      this.filterByCategoria(sessionStorage.categoria);
    } else if (Number(sessionStorage.page)) {
      this.currentPage = Number(sessionStorage.page);
      this.getContents(Number(sessionStorage.page));
    } else {
      this.getContents(this.currentPage);
    }
    if (localStorage.getItem("TOKEN_AUTH")) {
      this.$store
        .dispatch("me")
        .then(() => {
          this.$store.dispatch("loaderChange", false);
        })
        .catch((err) => {
          this.$store.dispatch("logout");
          this.$router.push({ name: "candidate.login" });
          this.$store.dispatch("loaderChange", false);
        });
    }
    this.getCategories();
  },
  methods: {
    getContents(page) {
      api.get(`/recruiters/empresa?page=${page}`).then((res) => {
        // console.log(res.data.data.content)
        this.apiContents = res.data.data;
        //this.$store.dispatch('getPublicity', res.data.data.publicity)
        this.$store.dispatch("loaderChange", false);
      });
    },
    checkUserProfile() {
      if (
        this.candidate?.personal_data &&
        this.candidate.personal_data == null
      ) {
        this.$store.dispatch("changeCompleteProfile", true);
      } else if (this.candidate?.anexos && this.candidate.anexos.length == 0) {
        this.$store.dispatch("changeCompleteProfile", true);
      } else if (
        this.candidate?.education &&
        this.candidate.education.length == 0
      ) {
        this.$store.dispatch("changeCompleteProfile", true);
      } else if (
        this.candidate?.language &&
        this.candidate.language.length == 0
      ) {
        this.$store.dispatch("changeCompleteProfile", true);
      }
    },
    goToEmpresa(){
      this.$router.push({name: 'empresa.create'})
    },
    //get contents from next page
    handleChangePage(page) {
      this.$store.dispatch("loaderChange", true);

      this.currentPage = page;
      sessionStorage.page = page;

      if (!this.search && !this.local) {
        api
          .get(`/recruiters/empresa?page=${this.currentPage}`)
          .then((res) => {
            this.apiContents = res.data.data;
            this.$store.dispatch("loaderChange", false);
            window.scrollTo(0, 0);
            /*if(!this.$store.state.token){
              this.$store.dispatch('changeLoginDialog', true)
          } else{
            if(page%2==0){
              this.checkUserProfile()
            }
            
          }*/
          })
          .catch((err) => {
            return err;
          });
      } else if (this.local) {
        api
          .get(`/recruiters/empresa?local=${this.local}&page=${this.currentPage}`)
          .then((res) => {
            this.apiContents = res.data.data;
            this.$store.dispatch("loaderChange", false);
            scroll(0, 0);
            // if(!this.$store.state.token){
            //   this.$store.dispatch('changeLoginDialog', true)

            // }else{
            //   if(page%2==0){
            //     this.checkUserProfile()
            //   }
            // }
          })
          .catch((err) => {
            return err;
          });
      } else if (this.catgoriaFilter) {
        api
          .get(
            `/recruiters/empresa?industria=${this.catgoriaFilter}&page=${this.currentPage}`
          )
          .then((res) => {
            this.apiContents = res.data.data;
            this.$store.dispatch("loaderChange", false);
            scroll(0, 0);
          });
      } else if (this.search) {
        //get next page searched contents
        api
          .get(`/recruiters/empresa?campo_pesquisa=${this.search}&page=${this.currentPage}`)
          .then((res) => {
            this.apiContents = res.data.data;
            this.$store.dispatch("loaderChange", false);
            scroll(0, 0);
            //   if(!this.$store.state.token){
            //     this.$store.dispatch('changeLoginDialog', true)
            //   }else{
            //     if(page%2==0){
            //       this.checkUserProfile()
            //     }
            // }
          })
          .catch((err) => {
            return err;
          });
      }
    },
    showPesquisa() {
      console.log("ok");
      this.hideFilter = true;
    },

    searchContentEvent(filter) {
      sessionStorage.titulo = filter;
      this.$store.dispatch("loaderChange", true);
      if (this.search_alert == false) {
        sessionStorage.removeItem("page");
      }
      if (Number(sessionStorage.page)) {
        api
          .get(`/recruiters/empresa?campo_pesquisa=${filter}&page${sessionStorage.page}`)
          .then((res) => {
            this.currentPage = Number(sessionStorage.page);
            this.apiContents = res.data.data;
            this.search_alert = true;
            this.search = filter;
            this.$store.dispatch("loaderChange", false);
          });
      } else {
        api.get(`/recruiters/empresa?campo_pesquisa=${filter}`).then((res) => {
          this.currentPage = 1;
          this.apiContents = res.data.data;
          this.search_alert = true;
          this.search = filter;
          this.$store.dispatch("loaderChange", false);
        });
      }
    },

    resetSearchResults() {
      this.currentPage = 1;
      this.$store.dispatch("loaderChange", true);
      this.search = "";
      this.local = "";
      this.categoria = "";
      this.catgoriaFilter = "";
      sessionStorage.clear();
      this.search_alert = false;
      this.getContents();
    },
    filterByProvince(province) {
      sessionStorage.province = province;

      this.$store.dispatch("loaderChange", true);
      if (this.search_alert == false) {
        sessionStorage.removeItem("page");
      }
      if (Number(sessionStorage.page)) {
        api
          .get(
            `/recruiters/contents?local=${province}&page=${Number(
              sessionStorage.page
            )}`
          )
          .then((res) => {
            this.currentPage = Number(sessionStorage.page);
            this.apiContents = res.data.data;
            this.local = province;
            this.search_alert = true;
            this.search = province;
            this.$store.dispatch("loaderChange", false);
          });
      } else {
        api.get(`/recruiters/contents?local=${province}`).then((res) => {
          this.currentPage = 1;
          this.apiContents = res.data.data;
          this.local = province;
          this.search_alert = true;
          this.search = province;
          this.$store.dispatch("loaderChange", false);
        });
      }
    },
    filterByCategoria(params) {
      sessionStorage.categoria = params;
      this.$store.dispatch("loaderChange", true);
      if (this.search_alert == false) {
        sessionStorage.removeItem("page");
      }
      if (Number(sessionStorage.page)) {
        api
          .get(
            `/recruiters/contents?industria=${params}&page=${Number(
              sessionStorage.page
            )}`
          )
          .then((res) => {
            this.currentPage = Number(sessionStorage.page);
            this.apiContents = res.data.data;
            this.search_alert = true;
            this.catgoriaFilter = params;
            this.search = params;
            this.$store.dispatch("loaderChange", false);
          });
      } else {
        api.get(`/recruiters/contents?industria=${params}`).then((res) => {
          this.currentPage = 1;
          this.apiContents = res.data.data;
          this.search_alert = true;
          this.catgoriaFilter = params;
          this.search = params;
          this.$store.dispatch("loaderChange", false);
        });
      }

      // console.log(params)
    },
    getCategories() {
      api.get("/recruiters/categories").then((res) => {
        this.apiCategories = res.data.data;
        // console.log(res.data.data)
      });
    },
  },
};
</script>

<style>
.span-color {
  color: rgb(136, 136, 136);
}
.listCategory {
  font-size: 0.7em;
  cursor: pointer;
  margin-top: 5px;
}
ul li:hover {
  color: rgb(136, 136, 136);
}
</style>