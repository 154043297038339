<template>
  <v-container class="mt-8">
    <v-container :class="isMobile ? 'pl-12' : 'pl-0'">
      <v-btn elevation="2" class="no-uppercase" small @click="goToperfiDash()">
        <v-icon style="padding-right: 14px" small> mdi-arrow-left </v-icon>
        Voltar
      </v-btn>
      <h3>Meus Concursos Favoritos</h3>
    </v-container>

    <!-- Concursos Favoritos -->

    <div :class="isMobile ? 'mt-7' : 'mt-1'">
      <v-row wrap justify="center" v-if="concursos.length == 0">
        <v-col cols="12" sm="12" md="12" class="text-center">
          <div>
            <img
              height="300x"
              width="300x"
              src="../../assets/ui/favoritos.png"
            />
          </div>
          <p
            style="
              font-weight: 900;
              text-transform: capitalize;
              font-size: 20px;
            "
          >
            Ainda não possuí concursos favoritos
          </p>
        </v-col>
      </v-row>

      <v-row wrap justify="center" v-if="concursos.length == 0">
        <v-col cols="12" sm="6" md="6">
          <div style="background-color: #fdece5; border: 1px solid #ff4500">
            <p style="padding: 10px">
              Selecione os seus <span style="font-weight: 900; width: 300px"
                    >Concursos Favoritos</span
                  >
              e veja nessa secção os seus concursos favoritos.
            </p>
          </div>
        </v-col>
      </v-row>
      <v-card
        wrap
        max-width="900"
        class="mx-auto"
        elevation="6"
        style="background-color: #e7e7e7; margin-bottom: 20px"
        v-for="(concurso, i) in concursos"
        :key="i"
      >
        <v-row style="margin: 0.5%">
          <v-col cols="10" style="">
            <span style="">
              {{ concurso.titulo }}
            </span>
          </v-col>

          <v-col
            cols="2"
            class="nhe"
            @click="goToConcurso(concurso.id)"
            :class="isMobile ? 'pl-10' : 'pl-0'"
            style="color: black"
          >
            <v-icon :class="isMobile ? 'pl-10' : 'pl-0'">
              mdi-eye-outline
            </v-icon>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "MeusConcursosFavoritos",

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
    concursos() {
      return this.$store.state.items;
    },
  },
  created() {
    this.getFavoriteContents();
  },
  methods: {
    goToperfiDash() {
      this.$router.push({ name: "perfil.dash" });
    },
    getFavoriteContents() {
      this.$store.dispatch("getFavoriteContents");
    },
    goToConcurso(item) {
      this.$router.push({ name: "detailsRoute", params: { id: item } });
    },
  },
};
</script>

<style scoped>
.nhe :hover {
  color: #ff4500;
}

.no-uppercase {
  text-transform: unset !important;
  background-color: #f66459 !important ;
  color: white;
}
h3 {
  color: #ff4500;
  margin-top: 40px;
}
/* .nhee{
    /* background-color: aquamarine;
    position: fixed; */

/* transform: translateX(10px) translateY(5px); 
} */
</style>