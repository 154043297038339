<template>
    <v-dialog scrollable v-model="dialog" hide-overlay persistent width="300">
        <v-card style="padding: 3px;">
            <v-card-text>
                <div style="margin-top: 7px; font-size: 18px" class="text">
                    <!-- <span class="mainText">
                        Faça </span> -->
                    <span class="login" style="text-decoration-line: underline; color: #FF4500; font-weight: bold;" @click="goToLogin">Inicie a sua sessão</span> <span class="mainText">ou</span> <span
                        class="login" style="text-decoration-line: underline; color: #FF4500; font-weight: bold;" @click="goToRegister">registe-se</span> <span class="mainText">para desbloquear todas as funcionalidades do Gugla Empresas.</span>
                </div>
                <div class="position-btn">
                    <v-btn color="secondary" icon @click="dialog = false">
                        <v-icon color=""> mdi-close </v-icon>
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
  
<script>
export default {
    data() {
        return {
            dialog: false,
        };
    },
    computed: {
    },
    created(){
        let token = localStorage.getItem("TOKEN_AUTH")
        if(!token){
            setTimeout(() => (this.dialog = true), 30000);
        }
    },
    methods: {
        goToLogin() {
            this.dialog = false
            this.$router.push({ name: "candidate.login" });
        },
        goToRegister() {
            this.dialog = false
            this.$router.push({ name: "candidate.register" });
        },
    },
};
</script>
  
  
<style scoped>
.login {
    color: cornflowerblue;
    font-size: 0.9em;
}

.login:hover {
    cursor: pointer;
}

.span-style {
    font-size: 16px;
}

.img-pop {
    height: 160px;
}

.text {
    font-size: 21px;
}

.text2 {
    font-size: 16px;
}

.mainText {
    font-size: 0.9em;
}

.mainText::after {
    display: inline-block;
    width: 100%;
}

.sub-text {
    font-size: 15px;
}

.sub-text-mob {
    font-size: 14px;
}

.img-mob {
    position: relative;
    right: 40px;
    top: 30px;
    height: 120px;
}

.close {
    position: absolute;
    right: 0;
}

>>>.v-dialog {
    position: absolute;
    top: 0;
    right: -15px;
}

.btn {
    color: #c61584;
    cursor: pointer;
}

.position-btn {
    position: absolute;
    top: 0px;
    right: 5px;
}

.text {
    display: inline-block;
    width: 96%;
}
</style>