<template>
    <v-container>
        <v-card flat width="auto">
            <v-card-title>Notificações</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
                <v-list>
                    <v-list-item>
                        <v-list-item-avatar v-if="isMobile"> 
                            <v-icon color="blue">mdi-email</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item>
                                Receber notificações dos concursos por email 
                            </v-list-item>
                        </v-list-item-content>
                        <v-list-item-action>
                            <!-- <v-btn v-if="newsletter == false" color="#ff4500" dark small @click="onSubmitReceiveNewsletter">Ativar</v-btn>
                            <v-btn v-else color="#FF4500" dark small @click="onSubmitReceiveNewsletter">Desativar</v-btn> -->
                            <v-switch
                                v-model="getCandidate.receive_newsletter"
                                color="#FF4500"
                            ></v-switch>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-avatar v-if="isMobile"> 
                            <v-icon color="green">mdi-whatsapp</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item>
                                Receber concursos pelo whatsapp
                            </v-list-item>
                        </v-list-item-content>
                        <v-list-item-action>
                                <v-btn color="#FF4500" dark small href="https://chat.whatsapp.com/C4wtywpKT0F4FfyOAqjPcw" target="__blank">
                                    Acessar
                                </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    props: {
        newsletter: {
            required: true,
            type: Boolean
        }
    },
    data() {
        return {
            image: null,
            personal_data: {},
            data: false,
            newletter1: false,
            dialog_newsletters_invite: false,
            dialog_newsletters: false,
        }
    },
    computed: {
        getCandidate() {
			let candidate = this.$store.state.candidate
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			if(candidate){this.newletter1 = candidate.receive_newsletter}
			return candidate
        },
        url() {
            if (!this.image) return;
            return URL.createObjectURL(this.image);
        },
        isMobile() {
			return this.$vuetify.breakpoint.smAndUp ? true : false;
		}
        
    },
    methods: {
        onSubmitReceiveNewsletter() {
            // console.log(this.newsletter);
            // return
            /* eslint-disable no-unreachable */
            const receive_newsletter = !this.newsletter
			this.$store
				.dispatch("activeNewsletter", {receive_newsletter})
				.then((response) => {
					if(receive_newsletter) {
                        this.$store.dispatch('me')
						Swal.fire({
						title: "Notificações",
						html: `Activou o serviço de notificação por email.<br/> Passará a Receber notificações sobre as novas vagas publicadas no Gugla Empregos.`,
						icon: "success",
						confirmButtonText: "Ok",
						confirmButtonColor: "#FF4500",
					})
					}else{
                        this.$store.dispatch('me')
                        // this.$store.state.candidate.receive_newsletter = false
						Swal.fire({
						title: "Notificações",
						html: `Desactivou o serviço de notificação por email.`,
						icon: "success",
						confirmButtonText: "Ok",
						confirmButtonColor: "#FF4500",
					})
					}
					this.dialog_newsletters = false;
				});
		},
    }
}
</script>

<style>

</style>