<template>
      <v-container class="my-auto"   style="align-items: center; ">   
            <div style="margin:2%">
                <v-card max-width="470" class="mx-auto" >
                    <v-card-title style="justify-content: center;">
                        <img
                            :src="require('../../assets/img/Logo-Empresas-830x354.png')"
                            height="72"
                            width="180"
                            alt="Gugla Empresas"
                        />
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-subtitle style="text-align: center;color: #F66459">
                        <h2>
                        Recuperação da senha
                    </h2></v-card-subtitle>
                    <v-spacer></v-spacer>
                    <v-card-subtitle style="font-size:smaller; color: #000;" >Escolha uma das opções abaixo para receber o código de recuperação da senha </v-card-subtitle>
                    <v-card-text style="margin-bottom: 5%;">
                   
                        <validation-provider>
                            
                            <v-radio-group  mandatory >
                                <v-radio
                                
                                color="#ff4500"
                                
                                >
                                <template v-slot:label>
                                    <div  style="font-size: small; color: #000;">
                                        Uma mensagem com o código de verificação será enviado para o email  <strong>email@gmail.com</strong> 
                                    </div>
                                </template>
                                </v-radio>
                                <v-radio
                                color="#ff4500"
                                
                                
                                >
                                <template v-slot:label>
                                    <div  style="font-size: small;color: #000;">
                                        Uma mensagem com o código de verificação será enviado para o número de telemóvel <strong>84 123 4567</strong> 
                                    </div>
                                </template>  
                                    
                                </v-radio>
                            </v-radio-group>
                        </validation-provider>
                        <v-card-actions>
                            <v-btn text class="no-backButton" style="padding: 0;" :to="{name: 'recuperacao.dados'}" >
                                <v-icon style="padding-right: 5px" dense>
                                    mdi-arrow-left
                                </v-icon>
                                Voltar
                            </v-btn>
                             <v-spacer></v-spacer>
                            <v-btn :to="{name: 'recuperacao.codigo'}"  class="no-uppercase" style="padding-inline: 39px;margin-right: -7px;">
                              Seguinte
                            </v-btn>
                        </v-card-actions>
                        
                   
                    </v-card-text>
                    <!-- <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="no-uppercase" style="padding-inline: 39px;">
                            Seguinte
                        </v-btn>
                    </v-card-actions> -->
                </v-card>

                 
            </div>
           

            
            
    </v-container>
</template>
<script>
export default{
    name:'EscolhaRecuperacao',
}
</script>
<style scoped>
.no-uppercase {
  text-transform: unset !important;
  background-color: #ff4500 !important ;
  color: white;
  font-size: small;
}
.no-backButton{
    
    text-transform: unset !important;
    color: #ff4500 !important;
}

</style>